import React, { useContext } from 'react';
import AutoCompleteResults from './AutoCompleteResults';
import { util } from '../core';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';

const ChatMentionsHelper: React.FC = () => {
  const { state, dispatch } = useContext(ChatMessageBoxContext);
  const { showMentionsPopup } = state;

  return (
    <AutoCompleteResults
      visible={showMentionsPopup}
      results={state.notificationGroups}
      displayKey='name'
      style={{
        bottom: 60,
        left: 85,
      }}
      onSelectedIndexChanged={(index: number) => {
        dispatch({
          type: 'set',
          value: { selectedMentionIndex: index },
        })
      }}
      onSelected={(mention: any) => {
        dispatch({
          type: 'setMention',
          value: mention,
        });

        util.delay(async () => {
          const input = document.getElementById(
            'chat-message-box-input'
          ) as HTMLIonInputElement;
          if (input) {
            await input.setFocus();
            util.moveCursorToEnd(await input.getInputElement());
          }
        }, 10);
      }}
    />
  );
};

export default ChatMentionsHelper;
