class Environment {
  get name() {
    return process.env.NODE_ENV;
  }

  get isProduction() {
    return this.name === 'production';
  }

  get isDevelopment() {
    return !this.isProduction;
  }

  get build() {
    return process.env.REACT_APP_TECOBI_BUILD ?? 'development';
  }

  get buildDate() {
    return process.env.REACT_APP_TECOBI_BUILD ?? '0';
  }

  get publicUrl() {
    return process.env.PUBLIC_URL;
  }

  get apiEnvironment() {
    return process.env.REACT_APP_TECOBI_API_ENVIRONMENT;
  }

  get clientVersion() {
    return (
      process.env.REACT_APP_CLIENT_VERSION ?? Math.round(Date.now() / 1000)
    );
  }

  get commit() {
    return (
      process.env.REACT_APP_TECOBI_COMMIT ??
      process.env.REACT_APP_IONIC_COMMIT?.substring?.(0, 7) ??
      'development'
    );
  }
}

const environment = new Environment();
export default environment;
