import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { TPage, TToggleItem, TSelectItem } from '../components';
import { IonItem, IonLabel, IonItemGroup, IonItemDivider } from '@ionic/react';
import { clientService, clientHoursService } from '../services';
import { AppContext } from '../context/AppContext';
import { appNotification } from '../core';
import { useDataLoader } from '../hooks';

type Holidays = 'Christmas' | 'Christmas Eve' | 'Thanksgiving' | 'New Years' | 'Easter';
type HolidayFields = 'open_xmas' | 'open_xmas_eve' | 'open_thanksgiving' | 'open_new_years' | 'open_easter';
type Weekday = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
interface WeekdayProps {
  weekday: Weekday;
  from_hour?: string | null;
  to_hour?: string | null;
  id?: number;
}

const weekdays: WeekdayProps[] = [
  {
    weekday: 'Sunday',
  },
  {
    weekday: 'Monday',
  },
  {
    weekday: 'Tuesday',
  },
  {
    weekday: 'Wednesday',
  },
  {
    weekday: 'Thursday',
  },
  {
    weekday: 'Friday',
  },
  {
    weekday: 'Saturday'
  }
]

const holidays: { name: Holidays, field: HolidayFields }[] = [
  {
    name: 'Christmas',
    field: 'open_xmas',
  },
  {
    name: 'Christmas Eve',
    field: 'open_xmas_eve'
  },
  {
    name: 'Thanksgiving',
    field: 'open_thanksgiving'
  },
  {
    name: 'New Years',
    field: 'open_new_years'
  },
  {
    name: 'Easter',
    field: 'open_easter'
  }
]

const HoursPage = () => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [schedule, setSchedule] = useState<WeekdayProps[]>(weekdays);
  const [dataLoaded, setDataLoaded] = useState(false);

  const loadClientData = useCallback(async () => {
    const clientData = await clientService.request(selectedClient.id);
    const clientSchedule: WeekdayProps[] = await clientHoursService.request(selectedClient.id)
    const [client, sch] = await Promise.all([clientData, clientSchedule]);
    return {
      clientData: client,
      clientSchedule: sch
    }
  }, [selectedClient]);

  const { data, loading, error } = useDataLoader(loadClientData, false);

  const open = useMemo(() => {
    return {
      open_xmas: data?.clientData.open_xmas ?? false,
      open_xmas_eve: data?.clientData.open_xmas_eve ?? false,
      open_thanksgiving: data?.clientData.open_thanksgiving ?? false,
      open_new_years: data?.clientData.open_new_years ?? false,
      open_easter: data?.clientData.open_easter ?? false
    }
  }, [data])

  const updateSchedule = (clientSchedule: WeekdayProps[]) => {
    setSchedule(prevSchedule =>
      prevSchedule.map(day => {
        const scheduleForDay = clientSchedule.find(
          item => item.weekday === day.weekday
        );
        if (scheduleForDay) {
          return {
            ...day,
            ...scheduleForDay,
          };
        }
        return day;
      })
    );
  };

  useEffect(() => {
    if (!loading) {
      updateSchedule(data.clientSchedule)
      setDataLoaded(true)
    }
  }, [data, loading])

  const handleChange = async (field: HolidayFields) => {
    await clientService.update(selectedClient.id, { [field]: !open[field] })
    appNotification.toast('Preferences updated')
  }

  const dayIsValid = (day: WeekdayProps) => {
    return (day.from_hour && day.to_hour) || (!day.to_hour && !day.from_hour);
  }

  const handleScheduleUpdate = async (day: Weekday, field: 'from_hour' | 'to_hour', value: string) => {
    if (!dataLoaded) return; // Ensures the onChange handler doesn't fire once state is updated with data

    const i = schedule.findIndex(d => d.weekday === day)

    if (schedule[i]?.[field] === value) return; // If the data didn't change, do nothing

    const newSchedule = [...schedule];

    if (value === null) { // If the user chooses closed, set both values
      newSchedule[i].from_hour = value;
      newSchedule[i].to_hour = value
    } else {
      newSchedule[i][field] = value;
    }

    setSchedule(newSchedule);

    if (!dayIsValid(newSchedule[i])) {
      appNotification.toast(`Please set opening and closing hours in order to save ${newSchedule[i].weekday}'s schedule`)
      return;
    }
    if (!!newSchedule[i].id) {
      await clientHoursService.update(
        selectedClient.id,
        newSchedule[i].id as number,
        newSchedule[i]
      )
      appNotification.toast('Hours updated')
    } else {
      await clientHoursService.create(selectedClient.id, newSchedule[i])
      appNotification.toast('Hours updated')
    }
  }

  return (
    <TPage loading={loading} error={error} headerTool={<></>}>
      <IonItemGroup>
        <IonItemDivider>
          <IonLabel>Holidays</IonLabel>
        </IonItemDivider>
        {holidays.map((holiday) => (
          <IonItem key={holiday.field}>
            <IonLabel>{`Open ${holiday.name}`}</IonLabel>
            <TToggleItem
              checked={open[holiday.field]}
              onIonChange={() => handleChange(holiday.field)}
            />
          </IonItem>
        ))}
        <IonItemDivider>
          <IonLabel>Schedule</IonLabel>
        </IonItemDivider>
        {schedule.map(day => (
          <React.Fragment key={day.weekday}>
            <TSelectItem
              options={clientHoursService.hoursChoices}
              label={`${day.weekday} Open`}
              value={day.from_hour ?? null}
              onChange={(e: CustomEvent) => {
                handleScheduleUpdate(day.weekday, 'from_hour', e.detail.value)
              }}
            />
            <TSelectItem
              options={clientHoursService.hoursChoices}
              label={`${day.weekday} Close`}
              value={day.to_hour ?? null}
              onChange={(e: CustomEvent) => {
                handleScheduleUpdate(day.weekday, 'to_hour', e.detail.value)
              }}
            />
          </React.Fragment>
        ))}
      </IonItemGroup>
    </TPage>
  )
}

export default HoursPage;
