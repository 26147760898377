import React, { useContext, useCallback } from 'react';
import { FacebookCampaignOverview, TInfiniteScrollPage } from '../components';
import { facebookService } from '../services';
import { AppContext } from '../context/AppContext';
import { IonItem, IonLabel, IonItemDivider, IonBadge } from '@ionic/react';
import moment from 'moment';
import { useDataLoader } from '../hooks';
import { util } from '../core';

const FacebookAdCampaignsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClient } = state;

  const load = useCallback(async () => {
    return await facebookService.loadAdReport(selectedClient.id);
  }, [selectedClient]);

  const { loading, next, data, refresher, error, loadNext } = useDataLoader(
    load,
    true
  );

  return (
    <TInfiniteScrollPage
      loading={loading}
      hasNext={!!next}
      items={data}
      onRefresh={refresher}
      error={error}
      onLoadNextPage={loadNext}
      renderItem={(item: any) => {
        const sold = facebookService.getTotal(item, 'insights.sold_count');
        const appts = facebookService.getTotal(item, 'insights.set_count');
        const showed = facebookService.getTotal(item, 'insights.showed_count');
        const leads = facebookService.getTotal(item, 'insights.lead_count');
        const spend = facebookService.getTotal(item, 'insights.spend');

        return (
          <IonItem
            key={item.id}
            routerLink={`/reports/facebook-ads/${item.client}/${item.id}/`}
            lines="full"
            detail
          >
            <IonLabel className="ion-multiline">
              {item.remote_read?.status && (
                <IonBadge color={util.getFacebookAdStatusBadgeColor(item.remote_read?.status)} mode="ios">
                  {item.remote_read?.status}
                </IonBadge>
              )}
              {item.name}
              <p className="detail">
                {moment(item.insights?.date_start).format('MM/DD/YY')} -{' '}
                {moment(item.insights?.date_stop).format('MM/DD/YY')}
              </p>
              <span className="detail">
                {facebookService.calcMetric(spend, leads)} CPL •{' '}
                {facebookService.calcMetric(spend, appts)} CPA •{' '}
                {facebookService.calcMetric(spend, showed)} CPSH •{' '}
                {facebookService.calcMetric(spend, sold)} CPSL
              </span>
            </IonLabel>
          </IonItem>
        );
      }}
      preContent={
        !loading ? (
          <>
            <FacebookCampaignOverview clientId={selectedClient.id} />
            <IonItemDivider>Campaigns</IonItemDivider>
          </>
        ) : (
          undefined
        )
      }
    />
  );
};

export default FacebookAdCampaignsPage;
