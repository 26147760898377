import { useContext } from 'react';
import { AppContext } from '../context/AppContext';


const useRequireAdmin = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  if (!user.is_admin && !user.is_client_admin) {
    window.location.href = '/';
  }
};

export default useRequireAdmin;
