import React, { useCallback } from 'react';
import { TPage } from '../components';
import { trainingService } from '../services';
import { IonItem, IonList, IonItemDivider } from '@ionic/react';
import { useDataLoader } from '../hooks';

const TrainingCategoriesPage: React.FC = () => {
  const loadData = useCallback(async () => {
    return await trainingService.listCategories();
  }, []);

  const { loading, data } = useDataLoader(loadData);

  return (
    <TPage loading={loading}>
      <IonList lines="full" className="no-padding no-margin">
        <IonItemDivider sticky>Training Categories</IonItemDivider>
        {data?.map((cat: any) => (
          <IonItem key={cat.id} routerLink={`/training/${cat.slug}/`} detail>
            {cat.name}
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default TrainingCategoriesPage;
