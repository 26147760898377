import React, { useState, useMemo, useContext } from 'react';
import TModal from './TModal';
import { TInputItem, TDatePicker } from '../';
import ButtonGrid from '../ButtonGrid';
import { IonButton } from '@ionic/react';
import { specialsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { ItemProps } from '../SpecialRow';

interface AddSpecialModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  type?: 'new' | 'edit';
  item?: ItemProps;
}

const AddSpecialModal: React.FC<AddSpecialModalProps> = ({
  isOpen,
  onDidDismiss,
  type = 'new',
  item
}) => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [title, setTitle] = useState(item?.title || '');
  const dateString = (date: Date) => {
    return new Date(date).toISOString().split('T')[0];
  }
  const [desc, setDesc] = useState(item?.details || '');
  const [start, setStart] = useState<string | null>((item && item.ends_at) ? dateString(item.starts_at) : new Date().toISOString().split('T')[0]);
  const [end, setEnd] = useState<string | null>((item && item.ends_at) ? dateString(item.ends_at) : null);

  const valid = useMemo(() => {
    return title !== '' && desc !== ''
  }, [title, desc])

  const resetState = () => {
    setTitle('');
    setDesc('');
    setStart(null);
    setEnd(null);
  }

  const createSpecial = async () => {
    await specialsService.create(selectedClient.id, {
      title,
      details: desc,
      starts_at: start ? new Date(start).toISOString() : new Date().toISOString(),
      ends_at: !end ? null : new Date(end).toISOString()
    })
    resetState();
    onDidDismiss();
  }

  const editSpecial = async () => {
    await specialsService.patch(selectedClient.id, {
      id: item?.id,
      title,
      details: desc,
      starts_at: start ? new Date(start).toISOString() : new Date().toISOString(),
      ends_at: !end ? null : new Date(end).toISOString()
    })
    resetState();
    onDidDismiss();
  }

  return (
    <TModal
      isOpen={isOpen}
      title="New Special"
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton
            disabled={!valid}
            color="secondary"
            onClick={type === 'edit' ? editSpecial : createSpecial}
            expand='block'
          >
            {type === 'edit' ? 'Save' : 'Add Special'}
          </IonButton>
        </ButtonGrid>
      }
    >
      <TDatePicker
        onDateChange={setStart}
        dateFormat={'MM/dd/yy'}
        dateString={start}
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        label="Start Date"
      />
      <TDatePicker
        onDateChange={setEnd}
        dateFormat={'MM/dd/yy'}
        dateString={end}
        canClear
        timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        label='End Date'
      />
      <TInputItem
        type="text"
        value={title}
        onChange={event => {
          const target = event.target as HTMLInputElement;
          setTitle(target.value);
        }}
        label="Title"
      />
      <TInputItem
        value={desc}
        multiline
        autoGrow
        onChange={event => {
          const target = event.target as HTMLInputElement;
          setDesc(target.value);
        }}
        label="Details"
      />
    </TModal>
  )
}

export default AddSpecialModal;