import React, { useState } from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import moment from 'moment';
import TAvatar from './TAvatar';
import SoftPullModal from './modals/SoftPullModal';

export interface QuickQualificationRowProps {
  item: any;
}

const QuickQualificationRow: React.FC<QuickQualificationRowProps> = ({
  item
}) => {
  const [modalOpen, setModalOpen] = useState<any>();
  const { first_name, last_name, created_at, bureau_name, score_range, result_description } = item;
  const full_name = `${first_name} ${last_name}`;

  return (
    <IonItem
      className="ion-activatable pointer"
      onClick={() => setModalOpen(true)}
    >
      <TAvatar slot="start" alt={full_name} />
      <IonLabel>
        <IonText className="ion-multiline">
          <h2>{full_name}</h2>
          <p className="subtitle ion-text-wrap">
            {bureau_name} • {score_range}
             {result_description && (<> • {result_description}</>)}
          </p>
          <span className="detail">{moment(created_at).fromNow()}</span>
        </IonText>
      </IonLabel>
      {modalOpen && (
        <SoftPullModal
          isOpen={true}
          onDidDismiss={() => setModalOpen(false)}
          data={item}
        />
      )}
    </IonItem>
  );
};

export default QuickQualificationRow;
