import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  TPage,
  TInputItem,
  TToggleItem,
  TColorPickerItem,
  HapticButton,
  TItem,
  TImage,
  TClickToEditItem
} from '../components';
import { IonList, IonButton } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import useRequireAdmin from '../hooks/useRequireAdmin';
import { clientWidgetService } from '../services';
import { image, copy } from 'ionicons/icons';
import {
  native,
  media,
  util,
  loadingIndicator,
  appNotification
} from '../core';

const ClientWidgetPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClientId } = state;
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<any>();
  const [photoFile, setPhotoFile] = useState<any>();
  useRequireAdmin();

  const onFileSelected = async (file: any) => {
    return new Promise<void>(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoFile(file);
        setSettings((s: any) => ({ ...s, logo: reader.result }));
        resolve();
      });

      reader.readAsDataURL(file);
    });
  };

  const captureNative = async () => {
    try {
      const { file, url } = await media.selectPhoto();
      setSettings((s: any) => ({ ...s, logo: url }));
      setPhotoFile(file);
    } catch (e) {
      console.error(e);
    }
  };

  const attachImage = async () => {
    if (native.isNative) {
      captureNative();
    } else {
      const res = await media.selectPhoto();
      onFileSelected(res);
    }
  };

  const updateField = (key: string) => (e: CustomEvent) => {
    const val = e.detail.checked ?? e.detail.value;
    setSettings((n: any) => ({ ...n, [key]: val }));
  };

  const loadSettings = useCallback(async () => {
    try {
      setLoading(true);
      const data = await clientWidgetService.load(selectedClientId);
      setSettings(data[0]);
    } finally {
      setLoading(false);
    }
  }, [selectedClientId]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const saveChanges = async () => {
    try {
      await loadingIndicator.create();
      if (photoFile) {
        settings.logo = photoFile;
      } else {
        delete settings.logo;
      }
      const res = await clientWidgetService.update(
        selectedClientId,
        settings.id,
        settings
      );
      setSettings(res);
      setPhotoFile(undefined);
      util.copyToClipboard(res.widget_code, false);
      appNotification.toast(
        'Settings have been updated and the code has been copied to your clipboard.',
        'Success'
      );
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TPage
      loading={loading}
      headerTool={
        !loading ? (
          <HapticButton color="secondary" slot="end" onClick={saveChanges}>
            Save
          </HapticButton>
        ) : (
          undefined
        )
      }
    >
      {!loading && (
        <IonList lines="full">
          <TItem icon={image} label="Logo" wrapLabel>
            {settings?.logo && (
              <TImage
                src={settings.logo}
                style={{
                  backgroundColor: 'var(--ion-color-step-400)',
                  maxHeight: 50,
                  margin: '10px auto',
                  width: 'auto'
                }}
                alt="Logo"
              />
            )}
            <IonButton slot="end" color="secondary" onClick={attachImage}>
              Set Logo
            </IonButton>
          </TItem>
          <TClickToEditItem
            label="Welcome Text"
            text={settings?.welcome_text}
            onChange={updateField('welcome_text')}
          />
          <TInputItem
            label="Button Text"
            value={settings?.button_text}
            onChange={updateField('button_text')}
          />
          <TColorPickerItem
            label="Background Color"
            value={settings?.background_color}
            onChange={updateField('background_color')}
          />
          <TColorPickerItem
            label="Text Color"
            value={settings?.text_color}
            onChange={updateField('text_color')}
          />
          <TToggleItem
            label="Show Powered By"
            checked={!!settings?.show_powered_by}
            onIonChange={updateField('show_powered_by')}
          />
          <TToggleItem
            label="SMS Widget Enabled"
            checked={!!settings?.is_enabled}
            onIonChange={updateField('is_enabled')}
          />
          <TToggleItem
            label="Facebook Retargeting Enabled"
            checked={!!settings?.pixel_enabled}
            onIonChange={updateField('pixel_enabled')}
          />
          <TToggleItem
            label="Show Email Field"
            checked={!!settings?.show_email_field}
            onIonChange={updateField('show_email_field')}
          />
          <TToggleItem
            label="Circle Button"
            checked={!!settings?.button_circle}
            onIonChange={updateField('button_circle')}
          />
          <TToggleItem
            label="Pin To Left"
            checked={!!settings?.pin_left}
            onIonChange={updateField('pin_left')}
          />
          <TItem label="Snippet" labelPlacement="stacked">
            <p style={{ fontFamily: 'Courier New' }}>{settings?.widget_code}</p>
            <HapticButton
              slot="end"
              icon={copy}
              onClick={() => util.copyToClipboard(settings.widget_code)}
            />
          </TItem>
        </IonList>
      )}
    </TPage>
  );
};

export default ClientWidgetPage;
