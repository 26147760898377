import React, { useCallback, useContext } from 'react';
import { TReportsPage, TChart } from '../components';
import { smsBillingService } from '../services';
import { AppContext } from '../context/AppContext';
import { IonProgressBar } from '@ionic/react';
import { IonList, IonItem, IonLabel, IonBadge } from '@ionic/react';

const SmsUsagePage: React.FC = () => {
  const appState = useContext(AppContext).state;
  const { selectedClientId } = appState;

  const loadData = useCallback(
    async ({ since, until, clientId }: any) => {
      const [smsBilling, smsBillingInbounds] = await Promise.all([
        smsBillingService.list(selectedClientId, since, until, '', ''),
        smsBillingService.list(selectedClientId, since, until, 'inbound', ''),
      ]);
      return {
        smsBilling,
        smsBillingInbounds,
      };
    },
    [selectedClientId]
  );

  return (
    <TReportsPage
      segments={['Today', 'Week', 'Month', 'Last Month']}
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => {
        const { smsBilling, smsBillingInbounds } = state.data;
        const overageCount =
          (smsBilling?.count ?? 0) -
          (smsBilling?.client_package?.included ?? 0);
        console.log('overageCount', overageCount);
        const includedRemaining =
          (smsBilling?.client_package?.included ?? 0) -
          (smsBilling?.count ?? 0);
        const overageDisplay = overageCount > 0 ? overageCount : 0;
        const outboundCount = smsBilling?.count - smsBillingInbounds?.count;
        const totals: any = [smsBillingInbounds?.count, outboundCount];
        const keys: any = ['Inbound SMS', 'Outbound SMS'];
        return (
          <TChart
            type='pie'
            title='SMS Usage'
            data={totals}
            labels={keys}
            legend
          >
            <IonList lines='full' className='reports'>
              <IonItem>
                <IonLabel>Outbound SMS</IonLabel>
                <IonBadge mode='ios'>
                  {outboundCount?.toLocaleString()}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Inbound SMS</IonLabel>
                <IonBadge mode='ios'>
                  {smsBillingInbounds?.count?.toLocaleString()}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Total SMS</IonLabel>
                <IonBadge
                  mode='ios'
                  color={
                    !!smsBilling?.client_package?.included && overageCount > 0
                      ? 'danger'
                      : 'secondary'
                  }
                >
                  {smsBilling?.count?.toLocaleString()}
                </IonBadge>
              </IonItem>
              {!!smsBilling?.client_package?.overage && (
                <>
                  <IonItem lines='none'>
                    <IonBadge mode='ios' slot='start'>
                      {smsBilling?.count?.toLocaleString()}
                    </IonBadge>
                    <IonProgressBar
                      value={
                        smsBilling?.count / smsBilling?.client_package?.included
                      }
                      color={overageCount > 0 ? 'danger' : 'success'}
                    />
                    <IonBadge mode='ios' slot='end'>
                      {smsBilling?.client_package?.included?.toLocaleString()}
                    </IonBadge>
                  </IonItem>
                  {!!smsBilling?.client_package?.included && (
                    <IonItem
                      lines='none'
                      color={overageCount > 0 ? 'danger' : 'success'}
                    >
                      {overageCount > 0 ? (
                        <>
                          <IonLabel>Overage</IonLabel>
                          <IonBadge mode='ios'>
                            {overageDisplay.toLocaleString()}
                          </IonBadge>
                        </>
                      ) : (
                        <>
                          <IonLabel>Included Remaining</IonLabel>
                          <IonBadge mode='ios'>
                            {includedRemaining?.toLocaleString()}
                          </IonBadge>
                        </>
                      )}
                    </IonItem>
                  )}
                  {overageCount > 0 && (
                    <IonItem color='danger'>
                      <IonLabel>Overage Cost</IonLabel>
                      <IonBadge mode='ios'>
                        {`${overageDisplay?.toLocaleString()} x ${
                          smsBilling?.client_package?.overage
                        } = $${(
                          overageDisplay * smsBilling?.client_package?.overage
                        )
                          ?.toFixed(2)
                          ?.toLocaleString()}`}
                      </IonBadge>
                    </IonItem>
                  )}
                </>
              )}
            </IonList>
          </TChart>
        );
      }}
    </TReportsPage>
  );
};

export default SmsUsagePage;
