import React, { useContext, useEffect, useState } from 'react';
import TModal from './TModal';
import moment from 'moment';
import { AppContext } from '../../context/AppContext';
import DataTable from 'react-data-table-component';
import { http, util } from '../../core';
import { useHistory } from "react-router-dom";
import TLoading from '../TLoading';
export interface PaginatedLeadListModalProps {
  isOpen: boolean;
  userId: number;
  useSinceUntil?: boolean;
  userIdInParams?: boolean;
  userType?: string;
  since: Date;
  until: Date;
  title: string;
  endpoint: string;
  dateLabel?: string;
  dateTimeField: string;
  tableTitle?: string;
  onDidDismiss: () => any;
}
const customStyles: any = {
  rows: {
    style: {
      cursor: 'pointer',
    }
  },
};

const PaginatedLeadListModal: React.FC<PaginatedLeadListModalProps> = ({
  isOpen,
  userId,
  since,
  until,
  title,
  endpoint,
  dateTimeField,
  tableTitle,
  dateLabel,
  useSinceUntil,
  userIdInParams,
  userType,
  onDidDismiss
}) => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  // Used for is_full_service hide avg response time chart
  const { user } = state;
  const [leads, setLeads] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const countPerPage = 7;
  util.createTecobiDarkDataTableTheme();
  useEffect(() => {
    const getLeadList = async () => {
      let url = `/${endpoint}/`;
      if (userId && !userIdInParams) {
        url += `${userId}/`;
      }
      url = useSinceUntil ?
        `${url}?page=${page}&page_size=${countPerPage}&since=${since?.toISOString()}&until=${until.toISOString()}`
        : `${url}?page=${page}&page_size=${countPerPage}&${dateTimeField}__gte=${since?.toISOString()}&${dateTimeField}__lte=${until.toISOString()}`;

      if (userId && !!userIdInParams) {
        url += `&user_id=${userId}`
      }
      if (userType) {
        url += `&user_type=${userType}`;
      }
      const res = await http.authorizedRequest({
        method: 'GET',
        url,
        type: endpoint,
        exclusive: true
      });
      setLeads(res.data);
      setLoading(false);
    }
    getLeadList();
  }, [page, userId, dateTimeField, endpoint, since, until, userIdInParams, userType, useSinceUntil]);

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.full_name,
    },
    {
      name: dateLabel ?? 'Touched',
      selector: (row: any) => row.sold ?? row.created_at,
      format: (row: any)=> moment(row.sold ?? row.created_at).format('lll'),
    }
  ];

  return (
    <TModal
      isOpen={isOpen}
      title={title}
      onDidDismiss={onDidDismiss}
    >
        <TLoading visible={loading} text="Loading" />
        <DataTable
          title={tableTitle}
          columns={columns}
          customStyles={customStyles}
          data={leads?.results}
          theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={leads?.count}
          paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
          onRowClicked={(row:any, e: any) => {
            history.push(`/text-messages/conversation/${row.client_id}/${row.id}/`);
            onDidDismiss();
          }}
          onChangePage={page => setPage(page)}
        />
    </TModal>
  );
};

export default PaginatedLeadListModal;
