import { http } from '../core';

class FeedbackService {
  async submit(type: string, message: string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: '/suggestions/',
        data: {
          type,
          message
        }
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const feedbackService = new FeedbackService();
export default feedbackService;
