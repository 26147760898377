import { createPopper, Options } from '@popperjs/core';
import { useRef, useLayoutEffect } from 'react';
export default function usePopper(
  ref: React.MutableRefObject<HTMLElement>,
  element: React.MutableRefObject<HTMLElement>,
  options?: Partial<Options>
) {
  const popper = useRef<any>();
  useLayoutEffect(() => {
    if (popper.current) {
      popper.current.destroy();
    }
    if (ref.current && element.current) {
      popper.current = createPopper(ref.current, element.current, options);
    }
  }, [ref, element, options]);
}
