import {
  Plugins,
  // FilesystemDirectory,
  // FilesystemEncoding
} from '@capacitor/core';
import { FilesystemDirectory } from '@capacitor/filesystem';

import util from './util';



const { Filesystem } = Plugins;

class FS {
  async write(
    path: string,
    data: any,
    directory = FilesystemDirectory.Documents,
    encoding?: string
  ): Promise<string> {
    if (typeof data !== 'string') {
      data = util.arraybufferToBase64(data);
    }

    await Filesystem.writeFile({
      path,
      data,
      directory,
      encoding
    });

    const { uri } = await Filesystem.getUri({
      path,
      directory
    });

    return uri;
  }
}

const fS = new FS();
export default fS;
