import { userService, clientService } from '.';
import {
  loadingIndicator,
  appNotification,
  native,
  http,
  util,
  environment
} from '../core';
import moment from 'moment';
import _ from 'lodash';
import appConfig from '../config.json';
import qs from 'qs';
import { Client } from '../types/Client';

declare global {
  interface Window {
    FB: any;
  }
}

class FacebookService {
  async login() {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (auth: any) => {
          if (!auth.authResponse) {
            return reject(auth);
          }

          window.FB.api(
            '/me?fields=cover,picture.width(800).height(800)',
            (res: any) => {
              (async res => {
                if (res) {
                  await loadingIndicator.create();
                  const user = await userService.update(
                    {
                      facebook_profile_pic: res.picture?.data?.url,
                      facebook_cover: res.cover?.source
                    },
                    { noToast: true }
                  );

                  appNotification.toast(
                    'Your facebook account has been linked.',
                    'Success'
                  );
                  loadingIndicator.dismiss();
                  resolve(user);
                } else {
                  reject(res);
                }
              })(res);
            }
          );
        },
        {
          scope: appConfig.facebook.scope
        }
      );
    });
  }

  isFacebookSupported() {
    return (
      window.location.protocol === 'https:' && !native.isNative && window.FB
    );
  }

  async api(req: string): Promise<any> {
    return new Promise(resolve => window.FB.api(req, resolve));
  }

  async createAdAccount(clientId: any, adAccount: any) {
    try {
      try {
        await http.authorizedRequest({
          url: `/ads/fb-ad-account/create/`,
          method: 'POST',
          data: {
            account_id: adAccount.account_id as number,
            name: adAccount.name,
            id: adAccount.account_id as number
          }
        });
      } catch (e: any) {
        if (e.response?.status !== 400) {
          throw e;
        }
      }

      return await clientService.update(clientId, {
        fb_ad_account: adAccount.account_id as number
      });
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async subscribeToPage(clientId: any, page: any): Promise<Client> {
    return new Promise(resolve => {
      const update = async ({ fbpage_token, fbpage }: any) => {
        resolve(await clientService.update(clientId, { fbpage_token, fbpage }));
      };
      if (page) {
        window.FB.api(
          `/${page.id}/subscribed_apps`,
          'post',
          { access_token: page.access_token, subscribed_fields: 'leadgen' },
          (res: any) => {
            update({ fbpage_token: page.access_token, fbpage: page.id });
          }
        );
      } else {
        update({ fbpage_token: null, fbpage: null });
      }
    });
  }

  async isLoggedIn(): Promise<boolean> {
    if (!this.isFacebookSupported()) {
      return false;
    }

    return new Promise(resolve =>
      window.FB.getLoginStatus((res: any) =>
        resolve(res.status === 'connected')
      )
    );
  }

  async loadAdAccounts() {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: '/facebook-ad-accounts/'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async loadPages() {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: '/facebook-pages/'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  private getAdUrl(
    clientId: string | number,
    campaignId?: string,
    adSetId?: string,
    adId?: string,
    params: any = {}
  ) {
    const url = [`/clients/${clientId}/campaigns`];

    if (campaignId) {
      url.push(campaignId);
    }

    if (adSetId) {
      url.push('adsets', adSetId);
    }

    if (adId) {
      url.push('ads', adId);
    }

    params.v = environment.commit;

    const query = qs.stringify(params);

    return `${url.join('/')}/?${query}`;
  }

  private onFacebookAdError(e: any) {
    const is500Error = e.response?.status === 500;
    http.onHttpError(e, { noToast: is500Error });

    if (is500Error) {
      appNotification.toast(
        'We are having issues reaching out to Facebook. Please try again in a bit.',
        'Error'
      );
    }
  }

  async loadAdReport(
    clientId: string | number,
    campaignId?: string,
    adSetId?: string,
    adId?: string
  ) {
    try {
      const params = {} as any;

      if (!campaignId) {
        params.paginate = true;
      }

      const url = this.getAdUrl(clientId, campaignId, adSetId, adId, params);

      const { data } = await http.authorizedRequest({
        method: 'GET',
        url,
        exclusive: true,
        type: 'fba-report'
      });

      return data;
    } catch (e) {
      this.onFacebookAdError(e);
    }
  }

  async loadAdKPIs(
    clientId: string | number,
    datePreset: string,
    campaignId?: string,
    adSetId?: string,
    adId?: string
  ) {
    try {
      const params = {
        date_preset: datePreset
      } as any;
      const url = this.getAdUrl(clientId, campaignId, adSetId, adId, params);
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url,
        exclusive: true,
        type: 'fba-kpis'
      });

      return data;
    } catch (e) {
      this.onFacebookAdError(e);
    }
  }

  getCostPerMetric(spend: number, metric: number) {
    return util.formatDollar(metric > 0 ? spend / metric : spend);
  }

  get datePresets() {
    return {
      lifetime: 'Lifetime',
      this_month: 'This Month',
      last_month: 'Last Month',
      today: 'Today',
      yesterday: 'Yesterday',
      this_quarter: 'This Quarter',
      last_quarter: 'Last Quarter',
      this_year: 'This Year',
      last_year: 'Last Year',
      this_week_mon_today: 'This Week (Mon-Today)',
      this_week_sun_today: 'This Week (Sun-Today)',
      last_week_mon_sun: 'Last Week (Mon-Sun)',
      last_week_sun_sat: 'Last Week (Sun-Sat)',
      last_3d: 'Last 3 Days',
      last_7d: 'Last 7 Days',
      last_14d: 'Last 14 Days',
      last_28d: 'Last 28 Days',
      last_30d: 'Last 30 Days',
      last_90d: 'Last 90 Days'
    } as any;
  }

  get defaultDatePreset() {
    return 'lifetime';
  }

  getTotal(data: any, key: string) {
    return Array.isArray(data)
      ? _.sumBy(data, (it: any) => {
          const val = _.get(it, key, 0);
          return typeof val === 'string' ? parseInt(val) : val ?? 0;
        })
      : _.get(data, key, 0);
  }

  calcMetric(one: number, two: number) {
    return two === 0 ? 'N/A' : util.formatDollar(one / two);
  }

  getInsightData(data: any, key: string) {
    return _.get(
      data,
      `${Array.isArray(data) ? '[0].' : ''}insights.${key}`,
      null
    );
  }

  getInsightDate(data: any, key: string) {
    return moment(this.getInsightData(data, key)).format('MM/DD/YY');
  }
}

const facebookService = new FacebookService();
export default facebookService;
