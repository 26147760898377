import React, { useContext, useCallback } from 'react';
import {
  IonCol,
  IonRow,
  IonGrid,
} from '@ionic/react';

import {
  TReportsPage,
  Leaderboard
} from '../components';
import { AppContext } from '../context/AppContext';

const TopClosers: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const loadData = useCallback(
    async ({ since, until, userId, clientId }: any) => ({}),
    []
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => (
        <IonGrid className="reports">
          <IonRow>
            <IonCol>
              <Leaderboard
                title="Sales Leaderboard"
                userType="assigned_to"
                selectedClientId={appState.selectedClientId}
                startDate={state.since}
                endDate={state.until}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Leaderboard
                title="Sales Manager Leaderboard"
                userType="sales_manager"
                selectedClientId={appState.selectedClientId}
                startDate={state.since}
                endDate={state.until}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <Leaderboard
                title="BDC Leaderboard"
                userType="bdc_rep"
                selectedClientId={appState.selectedClientId}
                startDate={state.since}
                endDate={state.until}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </TReportsPage>
  );
};

export default TopClosers;
