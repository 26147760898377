import React, { useCallback, useContext, useState } from 'react';
import { TInfiniteScrollPage, TFab } from '../../components';
import { vehicleAcquisitionLeadFollowupConfigsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { IonItem, IonLabel, IonButton, IonIcon, IonThumbnail, IonText } from '@ionic/react';
import { VehicleAcquisitionLeadFollowupConfigFormModal } from '../../components/modals';
import { useDataLoader } from '../../hooks';
import styles from './VehicleAcquisitionLeadFollowupConfigs.module.scss';
import { add, ellipsisVertical } from 'ionicons/icons';
import { actionSheet, appNotification } from '../../core';
import moment from 'moment';
const VehicleAcquisitionLeadFollowupConfigs: React.FC = () => {
  const { state } = useContext(AppContext);

  const defaultConfig = {
    client: state.selectedClientId,
    lead_age_minutes: 60,
    order: 1,
    message: 'Hi {{ first_name }} did you get my last message?',
    needs_call: false
  };

  const loadVehicleAcquisitionLeadFollowupConfigs = useCallback(async () => {
    return await vehicleAcquisitionLeadFollowupConfigsService.list(state.selectedClientId);
  }, [state.selectedClientId]);

  const showMoreActionSheet = (e: any, item: any) => {

    e.stopPropagation();

    const buttons = [
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ] as any[];

    buttons.unshift({
      text: 'Delete',
      handler: () => {
        (async () => {
          await vehicleAcquisitionLeadFollowupConfigsService.delete(item.client, item.id);
          refresher();
          appNotification.toast(
            `VehicleAcquisition lead followup has been deleted.`,
            'Deleted'
          );
        })();
        return true;
      }
    });

    actionSheet.show(buttons);
  };

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadVehicleAcquisitionLeadFollowupConfigs,
    true
  );
  const [vehicleAcquisitionLeadFollowupConfig, setVehicleAcquisitionLeadFollowupConfig] = useState<any>();

  return (
    <TInfiniteScrollPage
      className={styles.VehicleAcquisitionLeadFollowupConfigs}
      loading={loading}
      zeroStateText="Tap the green + to auto-followup on vehicle acquisition leads."
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setVehicleAcquisitionLeadFollowupConfig(item)}
        >
          { item.media && (
            <IonThumbnail slot="start">
              <img src={item.media} alt="Thumbnail" />
            </IonThumbnail>
          )}
          <IonLabel className="ion-multiline">
            <p>{item.message}</p>
            <p className="detail">{!!item.needs_call && (<IonText color="success">Needs call&nbsp;&bull;&nbsp;</IonText>)}Send {moment.duration(item.lead_age_minutes, 'minutes').humanize()} after lead submission.</p>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={(e) => {
                 showMoreActionSheet(e, item)
            }}
          >
            <IonIcon slot="icon-only" icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
      fab={
        <TFab
          icon={add}
          onClick={() => setVehicleAcquisitionLeadFollowupConfig(defaultConfig)}
        />
      }
    >
    {vehicleAcquisitionLeadFollowupConfig && (
      <VehicleAcquisitionLeadFollowupConfigFormModal
        item={vehicleAcquisitionLeadFollowupConfig}
        onDidDismiss={() => {setVehicleAcquisitionLeadFollowupConfig(undefined); refresher();}}
      />
    )}
    </TInfiniteScrollPage>
  );
};

export default VehicleAcquisitionLeadFollowupConfigs;
