import { http } from '../core';

class TeamCallReportService {
  async getTeamCallReport(clientId: number, since: string, until: string) {
    try {
      const params = new URLSearchParams();
      if (since) params.append('since', since);
      if (until) params.append('until', until);

      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/team-call-report/`,
        params,
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const teamCallReportService = new TeamCallReportService();
export default teamCallReportService;

