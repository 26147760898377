import React, { useCallback } from 'react';
import {
  TReportsPage,
  TItemGroup
} from '../components';
import moment from 'moment';
import { reportsService } from '../services';
import { IonList, IonGrid, IonRow, IonCol } from '@ionic/react';

const CustomerBlastAccountingPage: React.FC = () => {
  const loadData = useCallback(async ({ since, until }: any) => {
    const [customerBlasts] = await Promise.all([
      reportsService.retrieveCustomerBlastReports(since, until),
    ]);
    return { customerBlasts };
  }, []);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
      downloadData={(data: any) => data.customerBlasts}
    >
      {({ state }) => (
        <IonList className="reports">
          {state?.data?.customerBlasts?.map?.((it: any) => (
            <TItemGroup title={`Customer Blast #${it.id}`} key={it.id}>
              <IonGrid className="users-grid">
                <IonRow className="column-header">
                  <IonCol>Client</IonCol>
                  <IonCol>Time Sent</IonCol>
                  <IonCol>Sent By</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.client_name}</IonCol>
                  <IonCol>{moment(it.send_at).format('MM/DD/YY hh:mma zz')}</IonCol>
                  <IonCol>{it.sender}</IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Amount Sent</IonCol>
                  <IonCol>Cost Per</IonCol>
                  <IonCol>Total</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.sent_count}</IonCol>
                  <IonCol>${it.price_per_message.toFixed(2)}</IonCol>
                  <IonCol>${it.total_cost.toFixed(2)}</IonCol>
                </IonRow>
              </IonGrid>
            </TItemGroup>
          ))}
        </IonList>
      )}
    </TReportsPage>
  );
};

export default CustomerBlastAccountingPage;
