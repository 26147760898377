import React, { useContext } from 'react';
import { IonList, IonItem, IonText, IonButton, IonIcon } from '@ionic/react';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { util, browser } from '../core';
import {
  starOutline,
  arrowUp,
  arrowDown,
  addCircleOutline
} from 'ionicons/icons';
import { AddTradeModal } from './modals';
import HapticButton from './HapticButton';
import { leadsService } from '../services';

const PersonalizedLeadTrades: React.FC = () => {
  const { state, dispatch } = useContext(PersonalizedLeadContext);
  const { lead, token, showAddTradeModal } = state;

  const onTradeAdded = async (trade: any) => {
    browser.open(trade.report_url);
    const updated = await leadsService.loadByToken(token);
    dispatch({ lead: updated });
  };

  return (
    <>
      <h2>
        <span>Trades</span>{' '}
        <IonIcon
          onClick={() => dispatch({ showAddTradeModal: true })}
          icon={addCircleOutline}
        />
      </h2>
      {lead?.tradeins?.length > 0 ? (
        <IonList
          lines={lead.tradeins?.length > 1 ? 'full' : 'none'}
          className="trade-info"
        >
          {lead.tradeins.map((it: any) => (
            <IonItem key={it.id} className="trade-in">
              <IonText className="ion-multiline">
                <h3>
                  {it.year} {it.make}
                </h3>
                <span className="detail">
                  {it.model} {it.trim}
                </span>
                <p className="detail">
                  Mileage: {util.formatNumber(it.mileage)}
                </p>
                <p className="detail trade-pricing">
                  <IonIcon icon={arrowUp} color="secondary" />
                  {util.formatDollar(it.high_value)}

                  <IonIcon icon={starOutline} color="warning" />
                  {util.formatDollar(it.target_value)}

                  <IonIcon icon={arrowDown} color="danger" />
                  {util.formatDollar(it.low_value)}
                </p>
              </IonText>
              <IonButton
                color="secondary"
                href={it.url}
                target="_blank"
                slot="end"
              >
                View Report
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      ) : (
        <>
          <p className="ion-text-center">
            Have a vehicle to trade? Add it now to get an estimate!
          </p>
          <HapticButton
            expand="block"
            icon={addCircleOutline}
            color="secondary"
            onClick={() => dispatch({ showAddTradeModal: true })}
          >
            Add Trade
          </HapticButton>
        </>
      )}
      {showAddTradeModal && (
        <AddTradeModal
          isOpen
          isPublic
          onTradeAdded={onTradeAdded}
          clientId={lead?.client?.id}
          leadId={lead?.id}
          onDidDismiss={() => dispatch({ showAddTradeModal: false })}
        />
      )}
    </>
  );
};

export default PersonalizedLeadTrades;
