import React, { useContext } from 'react';
import { util, loadingIndicator } from '../core';
import { IonIcon } from '@ionic/react';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import { magicLinkService } from '../services';
import {
  arrowUpCircleOutline,
} from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';

const LeadMagicLink: React.FC = () => {
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const { state } = useContext(LeadContext);
  const { lead } = state;

  if (!lead) {
    return null;
  }

  const clientId = lead?.client;
  const hasMagicLink = !!(lead?.client_frikintech_illumiquote_slug);
  const leadId = lead?.id;
  const firstName = lead?.first_name;
  const append = (c: string) => {
    appendMessage(c);
    util.hideLeadSidebar();
  };

  return !hasMagicLink ? null : (
    <TExpandableItemGroup title="Magic Link">
      <TButtonItem
        color="secondary"
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const { short_url, long_url } = await magicLinkService.fetch(
              clientId,
              leadId
            );
            const message = `Okay ${firstName}, here is your magic link ${short_url ?? long_url}`;
            append(message);
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={arrowUpCircleOutline} slot="start" />
        Send Magic Link
      </TButtonItem>
    </TExpandableItemGroup>
  );
};

export default LeadMagicLink;
