import React, { useCallback, useContext } from 'react';
import { TReportsPage } from '../components';
import { smsBillingService, clientsService } from '../services';
import { AppContext } from '../context/AppContext';
import DataTable from 'react-data-table-component';
import { util } from '../core';

const SmsUsagePage: React.FC = () => {
  const appState = useContext(AppContext).state;
  util.createTecobiDarkDataTableTheme();
  const { user } = appState;
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: 'Total SMS',
      selector: (row: any) => row.count,
      sortable: true,
      right: true,
      maxWidth: '125px',
    },
    {
      name: 'Included SMS',
      selector: (row: any) => row.package.included,
      sortable: true,
      right: true,
      maxWidth: '125px',
    },
    {
      name: 'Remaining SMS',
      selector: (row: any) =>
        Math.max(0, -1 * (row.count - row.package.included)),
      sortable: true,
      right: true,
      maxWidth: '125px',
    },
    {
      name: 'Overage SMS',
      selector: (row: any) => Math.max(0, row.count - row.package.included),
      sortable: true,
      id: 'totalOverage',
      right: true,
      maxWidth: '125px',
    },
    {
      name: 'Per SMS Over',
      selector: (row: any) => row.package.overage,
      sortable: true,
      right: true,
      maxWidth: '125px',
    },
    {
      name: 'Overage Bill',
      selector: (row: any) =>
        Math.max(
          0,
          (row.count - row.package.included) * row.package.overage
        )?.toFixed(2),
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row: any) =>
            (row.count - row.package.included) * row.package.overage > 0,
          style: {
            backgroundColor: 'rgba(255, 0, 0, 0.2)', // Light reddish background
          },
        },
      ],
      right: true,
      maxWidth: '125px',
    },
  ];
  const loadData = useCallback(async ({ since, until, clientId }: any) => {
    try {
      // Fetch the list of clients
      const clients = await clientsService.listPerSmsClients();

      // Create a promise for each client to fetch its billing data
      const billingPromises = clients.map((client: any) =>
        smsBillingService
          .list(client.id, since, until, '', '')
          .then((data: any) => ({
            id: client.id,
            name: client.name,
            package: data.client_package,
            count: data.count,
          }))
      );

      // Wait for all the billing data promises to resolve
      const smsBillingData = await Promise.all(billingPromises);
      console.log('smsBillingData', smsBillingData);
      return {
        clients,
        smsBillingData, // This now contains an array of objects with client id and corresponding data
      };
    } catch (error) {
      console.error('Error loading data:', error);
      throw error; // Rethrow or handle as needed
    }
  }, []);

  return (
    <TReportsPage
      segments={['Today', 'Week', 'Month', 'Last Month']}
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => {
        const { smsBillingData } = state.data;
        return (
          <DataTable
            title='SMS Usage'
            columns={columns}
            data={smsBillingData}
            theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
            pagination={smsBillingData?.length > 50}
            paginationServer={false}
            highlightOnHover={true}
            defaultSortFieldId="totalOverage"
            defaultSortAsc={false}
          />
        );
      }}
    </TReportsPage>
  );
};

export default SmsUsagePage;
