import React, { useState } from 'react';
import { IonText } from '@ionic/react';
import GiphyPicker from './GiphyPicker';
import { IGif } from '@giphy/js-types';
import { haptics } from '../core';

export interface GiphyPickerButtonProps {
  onSelection?: (gif: IGif) => void;
}

const GiphyPickerButton: React.FC<GiphyPickerButtonProps> = ({
  onSelection
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <GiphyPicker
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        onSelected={(e: any) => {
          if (onSelection) {
            onSelection(e);
          }

          setIsOpen(false);
        }}
      />
      <IonText
        className="giphy-picker-button"
        onClick={() => {
          haptics.lightImpact();
          setIsOpen(true);
        }}
      >
        GIF
      </IonText>
    </>
  );
};

export default GiphyPickerButton;
