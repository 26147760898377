import native from './native';
import moment from 'moment';
import 'moment-timezone';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import util from './util';

class Analytics {
  private init: boolean = false;

  private async initializeFirebase(user: any, details: any) {
    firebase.analytics().setUserId(user.id.toString());
    firebase.analytics().setUserProperties(details);
  }

  private initializeFirebaseNative(user: any, details: any) {
    FirebaseAnalytics.setUserId(user.id.toString());
    Object.keys(details).forEach((k: string) => {
      FirebaseAnalytics.setUserProperty(k, details[k].toString());
    });
  }

  private facebookPixel(user: any) {
    const script = document.createElement('script');
    const pixelId = '669043566967700';
    script.id = 'fbp';
    script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pixelId}', {uid: '${user.id}'});
  fbq('setUserProperties', '${pixelId}', {admin: "${user.is_admin}",
      clientAdmin: "${user.is_client_admin}",
      cellPhone: "${user.cell_phone}",
      firstName: "${user.first_name}",
      lastName: "${user.last_name}",
      email: "${user.email}",
      lastLogin: "${user.last_login}",
      theme: "${user.mobile_theme}",
      timezone: "${moment.tz.guess()}"})
  fbq('track', 'PageView');`;
    document.body.appendChild(script);
  }

  async initialize(user: any) {
    try {
      const details = {
        email: user.email,
        admin: user.is_admin,
        client_admin: user.is_client_admin,
        cell_phone: user.cell_phone,
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.gravatar_url,
        theme: user.mobile_theme,
        timezone: moment.tz.guess()
      };

      if (native.isNative) {
        this.initializeFirebaseNative(user, details);
      } else {
        this.initializeFirebase(user, details);
      }
    } catch (e) {
      console.error('[FirebaseAnalytics]', e);
    }
    if (!util.userIsStaff(user)) {
      this.facebookPixel(user);
    }

    this.init = true;
  }

  setCurrentScreen(name: string) {
    try {
      if (native.isNative) {
        FirebaseAnalytics.setCurrentScreen(name);
      } else {
        firebase.analytics().setCurrentScreen(name);
      }
    } catch (e) {
      console.error('[FirebaseAnalytics]', e);
    }
  }

  async track(event: string, props: any) {
    if (this.init) {
      try {
        if (native.isNative) {
          FirebaseAnalytics.logEvent(event, {});
        } else {
          firebase.analytics().logEvent(event);
        }
      } catch (e) {
        console.error('[FirebaseAnalytics]', e);
      }
    }
  }
}
const analytics = new Analytics();
export default analytics;
