import PagedService from './paged-service';
import { http } from '../core';
import qs from 'qs';

class CommissionsService extends PagedService {

  async list(since: Date, until: Date) {
    try {
      const query = qs.stringify({
        since: since.toISOString(),
        until: until.toISOString(),
        page_size: 200,
        is_text_ninja: true,
      });
      const { data } = await http.authorizedRequest({
        url: `/commissions/?${query}`,
        type: 'commissions',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(id: number, since: Date, until: Date) {
    try {
      const query = qs.stringify({
        since: since.toISOString(),
        until: until.toISOString(),
      });

      const { data } = await http.authorizedRequest({
        url: `/commissions/${id}/?${query}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const commissionsService = new CommissionsService();
export default commissionsService;
