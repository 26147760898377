import React, { useContext, useMemo } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonIcon,
  IonCardContent,
  IonList,
  IonText
} from '@ionic/react';
import TAvatar from './TAvatar';
import { call, chatbubble } from 'ionicons/icons';
import TItem from './TItem';
import { uiUtil, util } from '../core';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { clientHoursService } from '../services';

const PersonalizedLeadTeamInfo: React.FC = () => {
  const { state } = useContext(PersonalizedLeadContext);
  const { lead } = state;
  const client = lead?.client;
  const profilePic = lead?.assigned_to?.profile_pic;
  const firstName = lead?.assigned_to?.first_name;
  const lastName = lead?.assigned_to?.last_name;
  const fullName = firstName
    ? `${firstName} ${lastName}`
    : client?.robot_name ?? client?.name;
  const clientTimezone = util.formattedTimezone(client?.timezone);

  const surveys = useMemo(() => {
    return lead?.surveys?.map((it: any, id: number) => ({
      id,
      rating: it.score,
      name: it.short_name,
      comments: it.comment
    }));
  }, [lead]);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Meet Your Team</IonCardTitle>
      </IonCardHeader>
      <IonItem lines="full" className="assigned-to-info">
        <TAvatar src={profilePic} alt={fullName} slot="start" />
        <IonLabel>
          <h3 className="ion-text-wrap">{fullName}</h3>
          <p className="detail">{firstName ? 'Sales' : 'Assistant'}</p>
        </IonLabel>
        <IonIcon
          icon={call}
          className="pointer"
          onClick={() => util.tel(lead?.twilio_number)}
          slot="end"
        />
        <IonIcon
          onClick={() => util.sms(lead?.twilio_number)}
          className="pointer"
          icon={chatbubble}
          slot="end"
        />
      </IonItem>
      <IonCardContent>
        <div>
          <strong>{client?.name}</strong>
          <p>
            {client?.address} {client?.address_2 && <br />}
            {client?.address_2}
            <br />
            {client?.city}, {client?.state} {client?.postal_code}
          </p>
          <p className="hours-info">
            {
              clientHoursService.getCurrentStatus(client?.hours, clientTimezone)
                ?.text
            }
          </p>
        </div>
      </IonCardContent>
      {surveys && (
        <IonList lines="none">
          {surveys.map((it: any) => (
            <TItem key={it.id} className="customer-survey">
              <IonText className="ion-multiline">
                <div className="survey-overview">
                  <strong>{it.name}</strong>
                  <span className="rating-stars">
                    {uiUtil.renderRating(it.rating)}
                  </span>
                </div>
                <q>{it.comments}</q>
              </IonText>
            </TItem>
          ))}
        </IonList>
      )}
    </IonCard>
  );
};

export default PersonalizedLeadTeamInfo;
