import React from 'react';
import { useRouter } from '../hooks';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import TPage from './TPage';
import { useIonViewWillEnter } from '@ionic/react';
import _ from 'lodash';
import { authorization } from '../core';

const SwitchClient: React.FC = () => {
  const router = useRouter();
  const { state } = useContext(AppContext);
  const { clients } = state;
  const { idOrSlug } = router.query;
  let clientId = -1;
  try {
    clientId = parseInt(idOrSlug);
  } catch (e) {}

  useIonViewWillEnter(() => {
    const match = clients?.find?.(
      (it: any) =>
        it.id === clientId ||
        _.toLower(_.kebabCase(it.name)) === _.toLower(idOrSlug)
    );

    if (match) {
      authorization.setSelectedClientId(match.id);
    }

    router.replace('/', {});
  });

  return <TPage loading={true} />;
};

export default SwitchClient;
