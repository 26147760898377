import React, { useState, useEffect, useContext } from 'react';
import TModal from './TModal';
import { IonList, IonItem, IonLabel, IonIcon, IonAlert } from '@ionic/react';
import { trainingService } from '../../services';
import { appNotification } from '../../core';
import { AppContext } from '../../context/AppContext';
import { hourglassOutline, checkmarkCircleOutline } from 'ionicons/icons';

import moment from 'moment';

interface ForcedTrainingSessionModalProps {
  isOpen: boolean;
  user: number;
  userName?: string;
  onClose: () => void;
}

const ForcedTrainingSessionModal: React.FC<ForcedTrainingSessionModalProps> = ({
  isOpen,
  user,
  onClose,
  userName,
}) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [userSessions, setUserSessions] = useState<any[]>([]);
  const [quizzes, setQuizzes] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState<number | null>(null);

  const appContext = useContext(AppContext);
  const { state } = appContext;

  useEffect(() => {
    const fetchUserSessions = async () => {
      const sessions = await trainingService.listSessions(user);
      setUserSessions(sessions.results);
    };

    fetchUserSessions();
  }, [user]);

  const completedTrainings = userSessions.filter(
    (session) => session.completed
  );
  const pendingTrainings = userSessions.filter((session) => !session.completed);

  useEffect(() => {
    const fetchCategories = async () => {
      // Assuming the service returns a list of categories. Update as necessary.
      const fetchedCategories = await trainingService.listCategories();
      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = async (slug: string) => {
    const fetchedQuizzes = await trainingService.getQuizzes(slug);
    setQuizzes(fetchedQuizzes?.category_quizes);
    setSelectedCategory(slug);
  };

  const handleQuizClick = (quizId: number) => {
    setSelectedQuizId(quizId);
    setShowConfirmAlert(true);
  };

  const handleConfirmQuiz = async () => {
    if (selectedQuizId !== null) {
      try {
        await trainingService.createForcedSession(
          selectedQuizId,
          user,
          state.user.id
        );

        // Display success notification
        appNotification.toast(
          'Forced training session created successfully.',
          'Success'
        );

        setShowConfirmAlert(false);
        onClose();
      } catch (error) {
        // You can also handle any potential errors here, for instance:
        appNotification.toast(
          'Failed to create the training session. Please try again.',
          'Error'
        );
      }
    }
  };

  const handleBackClick = () => {
    setSelectedCategory(null);
  };
  return (
    <TModal
      isOpen={isOpen}
      title={`${userName} training quizes`}
      onWillDismiss={onClose}
    >
      <IonList>
        <IonItem>
          <IonLabel>
            <strong>Choose Training</strong>
          </IonLabel>
        </IonItem>
        {selectedCategory === null ? (
          categories.map((category) => (
            <IonItem
              key={category.id}
              button
              onClick={() => handleCategoryClick(category.slug)}
            >
              {category.name}
            </IonItem>
          ))
        ) : (
          <>
            <IonItem button onClick={handleBackClick}>
              ⬅️ Go back to categories
            </IonItem>
            {quizzes.map((quiz) => (
              <IonItem
                key={quiz.id}
                button
                onClick={() => handleQuizClick(quiz.id)}
              >
                {quiz.name}
              </IonItem>
            ))}
          </>
        )}
        <IonItem>
          <IonLabel>
            <strong>Pending Trainings</strong>
          </IonLabel>
        </IonItem>
        {pendingTrainings.map((session) => (
          <IonItem key={session.id}>
            <IonIcon slot='start' icon={hourglassOutline} />
            <IonLabel className='ion-multi-line'>
              {session.category_name}: {session.quiz_name}
              <p className='subtitle ellipsis'>
                {`Forced: ${
                  session.force_created_by !== null ? 'Yes' : 'No'
                } - Created At: ${moment(session.created_at).format(
                  'MMMM D, YYYY, h:mm:ss a'
                )}`}
              </p>
            </IonLabel>
          </IonItem>
        ))}

        <IonItem>
          <IonLabel>
            <strong>Completed Trainings</strong>
          </IonLabel>
        </IonItem>
        {completedTrainings.map((session) => (
          <IonItem key={session.id}>
            <IonIcon slot='start' icon={checkmarkCircleOutline} />
            <IonLabel className='ion-multi-line'>
              {session.category_name}: {session.quiz_name}
              <p className='subtitle ellipsis'>
                {`Forced: ${
                  session.force_created_by !== null ? 'Yes' : 'No'
                } - Created At: ${moment(session.created_at).format(
                  'MMMM D, YYYY, h:mm:ss a'
                )}`}
              </p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
      <IonAlert
        isOpen={showConfirmAlert}
        header='Confirmation'
        message='Are you sure you want to force this quiz?'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              setShowConfirmAlert(false);
            },
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: handleConfirmQuiz,
          },
        ]}
      />
    </TModal>
  );
};

export default ForcedTrainingSessionModal;
