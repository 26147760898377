import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo
} from 'react';
import { pushNotifications } from '../services';
import { loadingIndicator } from '../core';
import { useEventListener } from '../hooks';
import _ from 'lodash';
import { Notification } from '../types/Notification';

export interface Actions {
  type: string;
  value?: any;
}

export interface NotificationState {
  notifications: Notification[];
  unreadNotifications: Notification[];
  editing: boolean;
  loading: boolean;
  selection: any[];
  next?: string;
  unread: number;
  error: boolean;
}

export interface NotificationContextProps {
  state: NotificationState;
  dispatch: Dispatch<Actions>;
  loadNotifications: (props?: any) => Promise<any>;
  loadNextPage: () => Promise<any>;
  onItemRemoved: (id: any) => any;
  onItemRead: (id: any) => any;
  markSelectionRead: () => Promise<any>;
  removeSelection: () => Promise<any>;
}

export const NotificationsContext = createContext(
  {} as NotificationContextProps
);

const sortNotifications = (arr: Notification[]) => {
  return _.reverse(
    _.sortBy(
      _.uniqBy(arr, (it: any) => it.id),
      (n: any) => n.created_at
    )
  );
};

const reducer = (state: NotificationState, action: any) => {
  const { type, value } = action;

  switch (type) {
    case 'set': {
      const s = { ...state };
      if (value.notifications) {
        value.notifications = sortNotifications(value.notifications);
      }
      if (value.unreadNotifications) {
        value.unreadNotifications = sortNotifications(
          value.unreadNotifications
        );
      }
      return Object.assign(s, value);
    }

    case 'setUnread': {
      return { ...state, unread: value };
    }

    case 'incrementUnread': {
      let count = state.unread;
      count += parseInt(value);
      return { ...state, unread: Math.max(0, count) };
    }

    case 'markLeadRead': {
      let unreadNotifications = state.unreadNotifications;

      unreadNotifications = unreadNotifications.filter(
        (it: any) => it.lead === value
      );

      const unread = unreadNotifications?.length ?? 0;
      return { ...state, unread, unreadNotifications };
    }
  }

  return state;
};

export const NotificationsContextProvider = (props: any) => {
  const init = props.initialState || {};
  const initialState: NotificationState = Object.assign(
    {
      notifications: [],
      unreadNotifications: [],
      editing: false,
      selection: [],
      unread: 0,
      error: false
    },
    init
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const loadNotifications = useCallback(
    async (props?: any) => {
      let error = false,
        next = null,
        notifications = [];

      try {
        const { page, showLoading, status } = props || {};

        if (showLoading !== false) {
          dispatch({ type: 'set', value: { loading: true } });
        }

        const firstPage = !page;
        const res = firstPage
          ? await pushNotifications.list(status)
          : await pushNotifications.next(page || '');

        if (firstPage) {
          pushNotifications
            .loadUnread()
            .then((value: any) => dispatch({ type: 'set', value }));
        }

        next = res.next;
        notifications = firstPage
          ? res.results
          : state.notifications.concat(res.results);
      } catch (e) {
        console.error(e);
        error = true;
      }

      dispatch({
        type: 'set',
        value: {
          loading: false,
          error,
          next,
          notifications
        }
      });
    },
    [dispatch, state.notifications]
  );

  const loadNextPage = useCallback(async () => {
    loadNotifications({ page: state.next, showLoading: false });
  }, [loadNotifications, state.next]);

  const onItemRemoved = useCallback(
    (id: any) => {
      let notifications = state.notifications;
      let unreadNotifications = state.unreadNotifications;
      let next = state.next;

      if (notifications.length < 10 && !!next) {
        loadNextPage();
      }

      unreadNotifications = unreadNotifications.filter(
        (it: any) => it.id !== id
      );
      const unread = unreadNotifications?.length ?? 0;
      dispatch({
        type: 'set',
        value: {
          unread,
          unreadNotifications,
          notifications: notifications.filter((row: any) => row.id !== id)
        }
      });
    },
    [
      state.notifications,
      state.unreadNotifications,
      state.next,
      dispatch,
      loadNextPage
    ]
  );

  const onItemRead = useCallback(
    (id: any) => {
      let unreadNotifications = state.unreadNotifications;

      unreadNotifications = unreadNotifications.filter(
        (it: any) => it.id !== id
      );

      const unread = unreadNotifications?.length ?? 0;

      dispatch({
        type: 'set',
        value: {
          unread,
          unreadNotifications
        }
      });
    },
    [state.unreadNotifications, dispatch]
  );

  const markSelectionRead = useCallback(async () => {
    try {
      await loadingIndicator.create();
      let selection = state.selection;
      let notifications = state.notifications;
      let unreadNotifications = state.unreadNotifications;

      if (selection.length) {
        await pushNotifications.markRead(selection);
        notifications
          .filter((it: any) => selection.indexOf(it.id) > -1)
          .forEach((it: any) => {
            it.read = new Date().toISOString();
          });
        unreadNotifications = unreadNotifications.filter(
          (it: any) => selection.indexOf(it.id) === -1
        );
      } else {
        await pushNotifications.markAllNotifications('read');
        notifications.forEach((it: any) => {
          it.read = new Date().toISOString();
        });
        unreadNotifications = [];
      }

      dispatch({
        type: 'set',
        value: {
          selection: [],
          unread: unreadNotifications?.length ?? 0,
          editing: false,
          notifications,
          unreadNotifications
        }
      });
    } finally {
      loadingIndicator.dismiss();
    }
  }, [
    dispatch,
    state.selection,
    state.notifications,
    state.unreadNotifications
  ]);

  const removeSelection = useCallback(async () => {
    try {
      await loadingIndicator.create();

      let selection = state.selection;
      let unreadNotifications = state.unreadNotifications;
      let unread = state.unread;

      let results: any[];
      if (selection.length) {
        await Promise.all(
          selection.map((it: any) =>
            pushNotifications.patchNotification(it, {
              cleared: new Date().toISOString()
            })
          )
        );

        results = state.notifications.filter(
          (it: any) => selection.indexOf(it.id) === -1
        );

        unreadNotifications = unreadNotifications.filter(
          (it: any) => selection.indexOf(it.id) === -1
        );
        unread = unreadNotifications.length;
      } else {
        await pushNotifications.markAllNotifications('cleared');
        results = [];
        unreadNotifications = [];
        unread = 0;
      }

      dispatch({
        type: 'set',
        value: {
          selection: [],
          editing: false,
          unread,
          unreadNotifications,
          notifications: results
        }
      });
    } finally {
      loadingIndicator.dismiss();
    }
  }, [
    dispatch,
    state.unreadNotifications,
    state.notifications,
    state.unread,
    state.selection
  ]);

  const value = useMemo(
    () =>
      ({
        state,
        dispatch,
        loadNotifications,
        loadNextPage,
        onItemRemoved,
        onItemRead,
        markSelectionRead,
        removeSelection
      } as NotificationContextProps),
    [
      state,
      dispatch,
      loadNotifications,
      loadNextPage,
      onItemRemoved,
      onItemRead,
      markSelectionRead,
      removeSelection
    ]
  );

  useEventListener('notification:received', async (e: any) => {
    setTimeout(async () => {
      const res = await pushNotifications.list('new', 5);
      dispatch({
        type: 'set',
        value: {
          notifications: [...res.results, ...state.notifications],
          unreadNotifications: [...res.results, ...state.unreadNotifications],
          unread: res.count
        }
      });
    }, 2000);
  });

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export const NotificationsContextConsumer = NotificationsContext.Consumer;
