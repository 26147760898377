import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AppContext } from '../context/AppContext';
import { appNotification } from '../core';
import {
  TPage,
  TInputItem,
  ButtonGrid,
  TToggleItem,
} from '../components';
import {
  IonButton,
  IonIcon,
} from '@ionic/react';
import { save } from 'ionicons/icons';
import { autobotService } from '../services';
import '../styles/components/AutoBotSettings.scss';
import { useDataLoader } from '../hooks';

const AutoBotClientSettingsPage: React.FC = () => {
  const [active, setActive] = useState(true);
  const [blastSize, setBlastSize] = useState(250);
  const [tolerance, setTolerance] = useState(25);
  const [dailyLimit, setDailyLimit] = useState(2500);
  const [companyType, setCompanyType] = useState('Car dealership');
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;

  const loadClient = useCallback(async () => {
    return await autobotService.getClientSettings(selectedClient.id);
  }, [selectedClient]);

  const { data, loading, error } = useDataLoader(loadClient, false);

  const handleSubmit = async () => {
    const patchData = {
      ai_auto_blast_size: blastSize,
      ai_auto_blast_queue_tolerance: tolerance,
      ai_auto_blast_daily_sms_limit: dailyLimit,
      company_type: companyType,
    }
    const res = await autobotService.patchClientSettings(selectedClient.id, patchData);
    if (res) {
      appNotification.toast('Settings updated');
    }
  }

  const handleActiveChange = async (a: boolean) => {
    const res = await autobotService.patchClientSettings(selectedClient.id, {
      ai_auto_blast_enabled: a,
    })

    if (res) {
      appNotification.toast(`Auto Bots turned ${res.ai_auto_blast_enabled ? 'on' : 'off'}`);
    }
  }

  useEffect(() => {
    if (data) {
      setActive(data.data.ai_auto_blast_enabled);
      setBlastSize(Number(data.data.ai_auto_blast_size));
      setTolerance(Number(data.data.ai_auto_blast_queue_tolerance));
      setDailyLimit(Number(data.data.ai_auto_blast_daily_sms_limit));
      setCompanyType(data.data.company_type)
    }
  }, [data])

  return (
    <TPage loading={loading} error={error} headerTool={<></>}>
      <TToggleItem
        label="Active"
        checked={active}
        onIonChange={(e) => {
          setActive(e.detail.checked)
          handleActiveChange(e.detail.checked)
        }}
      />
      <TInputItem
        label="Blast Size"
        type="number"
        value={`${blastSize}`}
        onChange={(e) => {
          setBlastSize(Number(e.detail.value))
        }}
      />
      <TInputItem
        label="Queue Tolerance"
        type="number"
        value={`${tolerance}`}
        onChange={(e) => {
          setTolerance(Number(e.detail.value))
        }}
      />
      <TInputItem
        label="Daily Blast Limit"
        type="number"
        value={`${dailyLimit}`}
        onChange={(e) => {
          setDailyLimit(Number(e.detail.value))
        }}
      />
      <TInputItem
        label="Company Type"
        type="text"
        style={{ minWidth: '500px' }}
        value={companyType}
        onChange={event => setCompanyType(event.detail.value)}
      />
      <ButtonGrid>
        <IonButton
          color="secondary"
          onClick={handleSubmit}
          expand="block"
        >
          <IonIcon slot="start" icon={save} />
          Save
        </IonButton>
      </ButtonGrid>
    </TPage>
  )
};

export default AutoBotClientSettingsPage;