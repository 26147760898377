import React, { useCallback, useContext } from 'react';
import {
  TReportsPage,
  MetricCard,
  TChart
} from '../components';
import moment from 'moment';
import { leadBillingService } from '../services';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { AppContext } from '../context/AppContext';

const tooltips = {
  callbacks: {
    // We want percentages not numbers in the tooltips
    title: (tooltipItem: any, data: any) => {
      const t = (tooltipItem?.[0]?.xLabel || '').split(', 12:00:00 am')[0];
      return t;
    }
  }
}

const PerLeadBillingPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const options = {tooltips, "scales":{"xAxes":[{"type":"time","time":{"unit":"day", "round": "day", "displayFormats": {"day": "MM/D"}}}],"yAxes":[{"ticks":{"beginAtZero":true}}]}};
  const { selectedClientId } = state;
  const loadData = useCallback(async ({ since, until }: any) => {
    const [leadBillings] = await Promise.all([
      leadBillingService.list(selectedClientId, since, until),
    ]);
    const oChart: any = {};
    const cost = leadBillings?.results?.[0]?.cost || 0;
    leadBillings?.results?.forEach((it: any) => {
      const sDay: string = moment(it.created_at).startOf('day').toISOString();
      if (sDay in oChart) {
        oChart[sDay] ++;
      } else {
        oChart[sDay] = 1;
      }
    });
    const chartData = Object.keys(oChart).map((k: string) => ({t: moment(k).toDate(), y: oChart[k]}));
    return { leadBillings, chartData, cost };
  }, [selectedClientId]);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
      downloadData={(data: any) => data.leadBillings.results}
    >
      {({ state }) => (
        <IonGrid>
          <IonRow>
            <IonCol>
              <MetricCard
                title="New Leads"
                value={`${state?.data?.leadBillings?.count} x $${state?.data?.cost}`}
                uom={` = $${((state?.data?.leadBillings?.count || 0)*(state?.data?.cost || 0))}`}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TChart
                title="New Leads per Day"
                type="bar"
                data={state?.data?.chartData}
                options={options}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

      )}
    </TReportsPage>
  );
};

export default PerLeadBillingPage;
