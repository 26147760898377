import { http } from '../core';
import qs from 'qs';
import PagedService from './paged-service';

class QuickQualificationsService extends PagedService {
  async list({ clientId, search }: any) {
    try {
      const params = {} as any;

      if (search) {
        params.search = search;
      }

      const query = qs.stringify(params);

      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/quick-qualifications/?${query}`
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}
const quickQualificationsService = new QuickQualificationsService();
export default quickQualificationsService;
