import CachedService, { CacheStrategy, CacheTTL } from './cached-service';
import { http } from '../core';

class ForcedTrainingSessionService extends CachedService {
  constructor() {
    super('forced-training-sessions', 'forcedTrainingSessions', [], CacheStrategy.TIMEOUT, CacheTTL.TENSECONDS);
  }

  async request() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/forced-training-sessions/'
    });
    return res.data;
  }
}

const forcedTrainingSessionService = new ForcedTrainingSessionService();
export default forcedTrainingSessionService;
