import React from 'react';

export interface TImageProps {
  src: string;
  alt?: string;
  height?: number;
  className?: string;
  unsafe?: boolean;
  style?: object;
  onClick?: (e?: any) => any;
}

const TImage: React.FC<TImageProps> = ({
  src,
  alt,
  height,
  className,
  style,
  unsafe,
  onClick,
}) => {
  height = height || 300;
  if (unsafe) {
    className = className ? className + ' unsafe-img' : 'unsafe-img';
  }
  return (
    <img
      className={className}
      style={
        Object.assign(
          {
            minWidth: 100,
            height: `calc(9 * ${height}px / 16)`,
            objectFit: 'contain',
          },
          style || {}
        ) as any
      }
      onClick={onClick}
      src={src}
      alt={alt}
    />
  );
};

export default TImage;
