import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import {
  IonList,
  IonButton,
  IonCard,
  IonFooter,
  IonIcon,
  IonItem,
  IonGrid,
  IonRow,
  IonBadge,
  IonText,
  IonCol
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import '../styles/components/ChooseBlastPreset.scss';
import { TTextItem, TPage, TButtonItem } from '../components';
import {
  chevronForward,
  call
} from 'ionicons/icons';
import {
  loadingIndicator
} from '../core';
import { blastsService } from '../services';
import { useRouter, useDarkTheme } from '../hooks';

const ChooseBlastPresetPage: React.FC = () => {
  const router = useRouter();
  const isDarkTheme = useDarkTheme();
  const blastType = useMemo(() => router.query.type, [router]);
  const isLeadBlast = blastType === 'leads';
  const [presets, setPresets] = useState([]);
  const appContext = useContext(AppContext);
  const fetchPresets = useCallback(async () => {
    if (isLeadBlast) {
      await loadingIndicator.create();
      try {
        const res = await blastsService.fetchPresets(blastType);
        setPresets(res);
      } catch (e) {
        console.error(e);
      } finally {
        loadingIndicator.dismiss();
      }
    }
  }, [blastType, isLeadBlast]);

  useEffect(() => {
    fetchPresets();
  }, [fetchPresets]);

  return (
    <TPage>

      <IonCard color={isDarkTheme ? 'dark' : 'light'}>
        <IonItem lines="full">
          Choose a Preset
          <IonButton
            routerLink={`/blasts/${blastType}/add/`}
            fill="clear"
            slot="end"
          >
            Skip Presets
            <IonIcon icon={chevronForward} slot="end" />
          </IonButton>
        </IonItem>

        <IonGrid style={{marginBottom: 30}}>
          <IonRow>
            {!presets?.length && (
              <IonCol>No presets found.</IonCol>
            )}
            {presets.map((preset: any) => (
              <IonCol className="blast-preset-card" key={preset.id} sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="4">
                <IonCard>
                    <TTextItem
                      label="Message"
                      lines="full"
                      text={preset.message}
                      labelPlacement="stacked"
                    />
                    <TTextItem
                      label="Yes"
                      lines="full"
                      text={<IonText>{preset.yes_message} {preset.yes_needs_call && (<IonBadge color="success"><IonIcon icon={call}/>&nbsp;Needs Call</IonBadge>)}</IonText>}
                      labelPlacement="stacked"
                    />
                    <TTextItem
                      label="No"
                      lines="full"
                      text={<IonText>{preset.no_message} {preset.no_needs_call && (<IonBadge color="success"><IonIcon icon={call}/>&nbsp;Needs Call</IonBadge>)}</IonText>}
                      labelPlacement="stacked"
                    />
                    <TTextItem
                      label="Statuses"
                      className="last-item"
                      lines="none"
                      text={appContext.state.statuses.filter((it: any)=> preset.status.includes(it.id)).map((it: any) => it.option_name).join(', ')}
                      labelPlacement="stacked"
                    />
                    <IonFooter>
                      <IonList>
                        <TButtonItem
                          lines="none"
                          color="secondary"
                          routerLink={`/blasts/${blastType}/add/preset/${preset.id}/`}
                        >
                          <IonIcon icon={chevronForward} slot="end" />
                          Select
                        </TButtonItem>
                      </IonList>
                    </IonFooter>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonCard>
    </TPage>
  );
};

export default ChooseBlastPresetPage;
