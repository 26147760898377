import React, { useContext } from 'react';
import TPopper from './TPopper';
import { LeadContext } from '../context/LeadContext';

const ChatProfileTooltip: React.FC = () => {
  const { markHeaderTooltipSeen } = useContext(LeadContext);
  return (
    <TPopper
      placement='bottom'
      positionFixed
      onClick={markHeaderTooltipSeen}
      isOpen={true}
      content={
        <div>Click here or swipe from the right to view the lead profile.</div>
      }
      referenceId='chat-header-avatar'
    />
  );
};

export default ChatProfileTooltip;
