import React, { useCallback } from 'react';
import { TInfiniteScrollPage } from '../components';
import { contestsService } from '../services';
import { IonItem, IonLabel } from '@ionic/react';
import { useDataLoader, useRouter } from '../hooks';

const Contests: React.FC = () => {
  const router = useRouter();
  const loadContests = useCallback(async () => {
    return await contestsService.list();
  }, []);

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadContests,
    true
  );

  return (
    <TInfiniteScrollPage
      loading={loading}
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          detail={true}
          lines="full"
          className="pointer"
          onClick={() => router.push(`/contests/${item.id}/`, {})}
        >
          <IonLabel className="ion-multiline">
            <p>{item.name}</p>
          </IonLabel>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
    />
  );
};

export default Contests;
