import PagedService from './paged-service';
import { http } from '../core';
import qs from 'qs';
import moment from 'moment';
import { send, time, checkmark } from 'ionicons/icons';

class BlastsService extends PagedService {
  private tokenForType(type: string) {
    return type === 'leads' ? 'sms-blasts' : 'customer-blasts';
  }
  async list({ clientId, type, user }: any) {
    try {
      const params = {} as any;

      if (user && user > 0) {
        params.user = user;
      }

      if (user === -1) {
        params.user = undefined
        params.is_created_by_ai = false
      }

      if (user === -2) {
        params.user = undefined
        params.is_created_by_ai = true
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/${this.tokenForType(type)}/?${query}`,
        type: 'blasts',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async fetchPreset(type: string, id: any) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/${this.tokenForType(type)}-presets/${id}/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async fetchBlastToDuplicate(type: string, id: any) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/duplicate-${this.tokenForType(type)}/${id}/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async fetchPresets(type: string) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/${this.tokenForType(type)}-presets/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(clientId: number, id: number, type: string) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/${this.tokenForType(type)}/${id}/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, isLeadBlast: boolean, filters: any) {
    try {
      const formData = new FormData();
      const f = isLeadBlast
        ? this.mapLeadFilters(filters)
        : this.mapCustomerFilters(filters);
      Object.keys(f).forEach((k: string) => {
        if (['status', 'job_titles', 'master_providers', 'assigned_to_users'].includes(k)) {
          (f[k] ?? []).forEach((it: any) => formData.append(k, it));
        } else if (f[k] !== null && f[k] !== undefined) {
          formData.append(k, f[k]);
        }
      });

      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/${
          isLeadBlast ? 'sms-blasts' : 'customer-blasts'
        }/`,
        data: formData
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  private mapLeadFilters(filters: any) {
    const params = {
      last_message_age_hours: filters.messageAge,
      limit: filters.limit,
      message: filters.message,
      ordering: filters.sorting === 'Newest First' ? '-id' : 'id',
      send_at: filters.sendAt,
      send_over: filters.sendOver,
      trade_stage: filters.tradeStage || undefined,
      credit_stage: filters.creditStage || undefined,
      vehicle_stage: filters.vehicleStage || undefined,
      appt_stage: filters.apptStage || undefined,
      status: filters.target,
      job_titles: filters.jobTitles,
      use_robot_name_in_signature: filters.useRobotNameInSignature,
      master_providers: filters.leadProviders,
      imported_replied: filters.importedReplied,
      lead_created_at_min: filters.leadsCreatedFrom,
      lead_created_at_max: filters.leadsCreatedTo,
      media: filters.photo,
      ai_generated_message: filters.useAiMessage,
      statuses: filters.target?.join?.(','),
      job_title_ids: filters.jobTitles?.join?.(','),
      master_provider_ids: filters.leadProviders?.join?.(','),
      vehicle_body_styles: filters.vehicleBodies?.join?.(','),
      vehicle_models: filters.vehicleOfInterestMakesModels?.length ? JSON.stringify(filters.vehicleOfInterestMakesModels) : undefined,
    } as any;
    // null is Unassigned
    if (filters.assignedTo?.includes(null)) {
      params.unassigned_only = true;
    } else {
      params.unassigned_only = false;
    }
    const assignToIds = filters.assignedTo?.filter((it:any) => it !== null) || [];
    if (assignToIds?.length) {
      params.assigned_to_users = assignToIds;
      params.assigned_to_users_ids = assignToIds.join?.(',');
    }
    if (filters.classification !== 'All') {
      params.lead_classification = filters.classification;
    } else {
      params.lead_classification = '';
    }

    if (filters.leadProviders !== 'All') {
      params.master_providers = filters.leadProviders;
    } else {
      params.master_providers = '';
    }

    if (filters.importedReplied !== 'All') {
      params.imported_replied = filters.importedReplied;
    } else {
      params.imported_replied = '';
    }

    return params;
  }

  private mapCustomerFilters(filters: any) {
    const dateFormat = 'YYYY-MM-DD';
    const params = {
      booked_date_since: moment(filters.soldFrom).format(dateFormat),
      booked_date_until: moment(filters.soldTo).format(dateFormat),
      last_message_age_hours: filters.messageAge * 24,
      limit: filters.limit,
      send_at: filters.sendAt,
      send_over: filters.sendOver,
      message: filters.message,
      media: filters.photo,
      makes: filters.makes?.join(','),
      models: filters.models?.join(','),
      postal_codes: filters.postalCodes?.join(','),
      postal_code_starts_with: filters.postalCodeStartsWith,
      min_year: filters.minYear,
      max_year: filters.maxYear,
      use_robot_name_in_signature: filters.useRobotNameInSignature,
    } as any;

    if (!!filters.aprRangeValue.upper) {
      params.min_apr = filters.aprRangeValue.lower;
      params.max_apr = filters.aprRangeValue.upper;
    }
    if (!!filters.termRangeValue.upper) {
      params.min_term = filters.termRangeValue.lower;
      params.max_term = filters.termRangeValue.upper;
    }
    if (!!filters.paymentRangeValue.upper) {
      params.min_monthly_payment = filters.paymentRangeValue.lower;
      params.max_monthly_payment = filters.paymentRangeValue.upper;
    }
    return params;
  }

  async getDmsPostalCodes(clientId: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/customer-blasts/postal-codes/`
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getDmsMakes(clientId: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/customer-blasts/makes/`
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getDmsModels(clientId: number, makes: string) {
    try {
      const query = makes ? `makes=${makes}` : '';
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/customer-blasts/models/?${query}`
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVehicleOfInterestMakesModels(clientId: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/inventory-likes-make-models/`
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getLeadCount(clientId: number, isLeadBlast: boolean, filters: any) {
    try {
      const postBody = isLeadBlast
        ? this.mapLeadFilters(filters)
        : this.mapCustomerFilters(filters);
      const { data } = await http.authorizedRequest({
        exclusive: true,
        method: 'POST',
        type: 'blasts-getLeadCount',
        url: `/clients/${clientId}/${
          isLeadBlast ? 'sms-blasts' : 'customer-blasts'
        }/lead-count/`,
        data: postBody
      });

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createAutoReply(clientId: number, blastId: number, isLeadBlast: boolean, data: any) {
    try {
      data.keywords = data?.keywords?.join('|');
      data.pause_duration_hours = data.pause_duration_hours || null;
      data.status = data.status || null;
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/${
          isLeadBlast ? 'sms-blasts' : 'customer-blasts'
        }/${blastId}/auto-replies/`,
        data
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async deleteBlast(clientId: number, id: number, isLeadBlast: boolean) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/${
          isLeadBlast ? 'sms-blasts' : 'customer-blasts'
        }/${id}/`
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  getStatusIcon(item: any) {
    const { started_at, completed_at } = item || {};
    let icon, color;

    if (completed_at) {
      icon = checkmark;
      color = 'success';
    } else if (started_at) {
      icon = send;
      color = 'secondary';
    } else {
      icon = time;
      color = 'warning';
    }

    return { icon, color };
  }
}

const blastsService = new BlastsService();
export default blastsService;
