import React, { useContext, useState } from 'react';
import {
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import css from 'classnames';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import { InventoryItemModal, InventorySearchModal } from './modals';
import TButtonItem from './TButtonItem';
import { search } from 'ionicons/icons';
import {
  ChatMessageBoxContext,
  appendMessageText
} from '../context/ChatMessageBoxContext';

import { inventoryService } from '../services';

const LeadLikes: React.FC = () => {
  const { state, dispatch } = useContext(LeadContext);
  const { lead } = state;
  const { addInventoryLike, removeInventoryLike } = useContext(LeadContext);
  const {
    dispatch: cbDispatch
  } = useContext(ChatMessageBoxContext);
  const [vehicle, setVehicle] = useState<any>();

  if (!lead) {
    return null;
  }

  const clientId = lead?.client;
  const leadId = lead?.id;
  const leadHash = lead?.urlhash;
  const likes = lead?.inventory_likes ?? [];
  const likedIds = likes?.map?.((it: any) => it.inventory.id) ?? [];
  const onDislike = (it: any) => {
    const like = likes?.find((l: any) => l.inventory.id === it.id);
    if (like) {
      removeInventoryLike(like.id);
    }
  };

  return (
    <TExpandableItemGroup title="Inventory" badge={likes?.length}>
      <IonGrid className="inventory-like-grid">
        <IonRow>
          {likes?.map?.((like: any, i: number) => {
            const { inventory } = like;
            return (
              <IonCol
                key={i}
                className="inventory-item"
                size="6"
                onClick={() => setVehicle(like)}
              >
                <img
                  src={inventory?.image}
                  alt={inventory?.vin}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/assets/car-placeholder.jpg";
                  }}
                />
                <IonText className="detail">
                  <strong>{inventory?.stock_number}</strong>
                  <span className="price">
                    {inventoryService.getSellingPrice(inventory?.selling_price)}
                  </span>
                </IonText>
                <span
                  className={css('stock', {
                    'in-stock': inventory?.in_stock
                  })}
                >
                  {inventory?.in_stock ? 'In Stock' : 'Not in Stock'}
                </span>
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
      {vehicle && (
        <InventoryItemModal
          isOpen={true}
          clientId={clientId}
          leadId={leadId}
          onLike={addInventoryLike}
          onDislike={onDislike}
          onSend={appendMessageText}
          item={vehicle}
          leadHash={leadHash}
          liked={true}
          onDidDismiss={() => setVehicle(null)}
          onAttachImage={(imgUrl) => {
            cbDispatch({
              type: 'set',
              value: {
                selectedGif: imgUrl,
              }
            });
          }}
        />
      )}
      <TButtonItem
        color="secondary"
        onClick={() =>
          dispatch({
            type: 'set',
            value: {
              showInventorySearchModal: true
            }
          })
        }
      >
        <IonIcon icon={search} slot="start" />
        Search Inventory
      </TButtonItem>
      {state.showInventorySearchModal && (
        <InventorySearchModal
          clientId={clientId}
          leadId={leadId}
          likedIds={likedIds}
          onSend={appendMessageText}
          onAttachImage={(imgUrl) => {
            cbDispatch({
              type: 'set',
              value: {
                selectedGif: imgUrl,
              }
            });
          }}
          onLike={addInventoryLike}
          onDislike={onDislike}
          leadHash={leadHash}
          onDidDismiss={() =>
            dispatch({
              type: 'set',
              value: {
                showInventorySearchModal: false
              }
            })
          }
        />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadLikes;
