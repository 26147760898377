import React, { useState, useEffect } from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonSegment, IonSegmentButton, IonButton } from '@ionic/react';
import '../styles/components/TSegmentItem.scss';

export interface TSegmentItemProps extends TItemProps {
  segments: string[];

  value?: string;
  onIonChange?: (e: CustomEvent) => any;
  buttonStyle?: boolean;
}

const TSegmentItem: React.FC<TSegmentItemProps> = ({
  segments,
  onIonChange,
  value,
  buttonStyle,
  children,
  ...rest
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => setSelected(value), [value]);

  return (
    <TItem className="t-segment-item" {...rest}>
      {children}
      {buttonStyle ? (
        <>
          {segments.map(it => (
            <IonButton
              expand="block"
              key={it}
              slot="end"
              onClick={() => {
                onIonChange?.(new CustomEvent('', { detail: { value: it } }));
              }}
              color={it === selected ? 'success' : 'primary'}
            >
              {it}
            </IonButton>
          ))}
        </>
      ) : (
        <IonSegment
          className="t-item-segment"
          onIonChange={onIonChange}
          value={value}
        >
          {segments.map(it => (
            <IonSegmentButton key={it} value={it}>
              {it}
            </IonSegmentButton>
          ))}
        </IonSegment>
      )}
    </TItem>
  );
};

export default TSegmentItem;
