import { liveDB } from '../core';
import { useRef, useEffect } from 'react';

export default function useLiveDBRef(
  path: string | null,
  handler: (e: any, ref: any) => any,
  enabled = true,
  listenTo = 'value'
) {
  const ref = useRef<any>();
  const savedHandler = useRef<any>(handler);

  useEffect(() => {
    if (enabled) {
      savedHandler.current = handler;
    } else {
      savedHandler.current = null;
    }
  }, [handler, enabled]);

  useEffect(() => {
    let currentRef = ref.current;
    currentRef?.off?.();

    if (path && enabled) {
      currentRef = liveDB.useReference(path);
      currentRef.on(listenTo, (snap: any) => {
        savedHandler.current?.(snap.val(), ref.current);
      });

      ref.current = currentRef;
    }

    return () => {
      if (currentRef) {
        currentRef.off();
      }
    };
  }, [path, enabled, listenTo]);

  return ref;
}
