class Geolocation {
  async getCurrentPosition() {
    return await new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    });
  }
}

const geolocation = new Geolocation();
export default geolocation;
