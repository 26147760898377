import { useEffect, useRef } from 'react';
export default function useEventListener(
  name: string,
  handler: (e?: any) => any,
  element = window
) {
  const savedHandler = useRef<any>(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: any) => savedHandler.current(event);

    element.addEventListener(name, eventListener);

    return () => {
      element.removeEventListener(name, eventListener);
    };
  }, [name, element]);
}
