import { useState, useEffect, useCallback, useMemo } from 'react';

export default function useDistanceMatrix(start: any, end: any) {
  const [data, setData] = useState<any>();

  const key = useMemo(
    () => `${start?.lat}${start?.lng}${end?.lat}${end?.lng}`,
    [start, end]
  );

  const loadDistance = useCallback(
    (key: string) => {
      setData({ key });
      if (start.lat && end.lat && window.google) {
        const DistanceMatrixService = window.google.maps.DistanceMatrixService;
        const distance = new DistanceMatrixService();
        distance.getDistanceMatrix(
          {
            origins: [start],
            destinations: [end],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.IMPERIAL
          },
          (response: any, status: string) => {
            if (status === 'OK') {
              const row = response?.rows?.[0]?.elements?.[0] ?? {};
              const { distance, duration } = row;
              setData({ key, distance, duration });
            }
          }
        );
      }
    },
    [start, end]
  );

  useEffect(() => {
    if (data?.key !== key) {
      loadDistance(key);
    }
  }, [loadDistance, key, data]);

  return data;
}
