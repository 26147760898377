import React, { useContext } from 'react';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';
import { LeadContext } from '../context/LeadContext';
import { gitMerge } from 'ionicons/icons';
import { util, loadingIndicator, appNotification } from '../core';
import { leadsService } from '../services';

export interface LeadDuplicatesProps {
  onMerged: () => any;
}

const LeadDuplicates: React.FC<LeadDuplicatesProps> = ({ onMerged }) => {
  const { state } = useContext(LeadContext);
  const duplicates = state?.lead?.duplicates;
  if (!duplicates?.length) {
    return null;
  }

  const mergeDuplicates = async () => {
    try {
      if (!state.lead) {
        return;
      }

      const leadId = state.lead.id;
      await loadingIndicator.create();
      await leadsService.mergeDuplicates(leadId);
      await appNotification.toast('Leads have been merged.', 'Success');
      onMerged();
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <IonItem lines="full" color="tertiary" onClick={mergeDuplicates}>
      <IonIcon icon={gitMerge} slot="start" />
      <IonLabel className="ion-text-center">
        <strong>
          Merge {duplicates?.length}{' '}
          {util.getPluralizedString('Duplicate', duplicates?.length)}?
        </strong>
      </IonLabel>
    </IonItem>
  );
};

export default LeadDuplicates;
