import React, { useContext, useCallback } from 'react';
import HapticButton from './HapticButton';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';
import { TrainingContext } from '../context/TrainingContext';
import { AppContext } from '../context/AppContext';
import { trainingService } from '../services';
import { useRouter } from '../hooks/';
import { loadingIndicator, appNotification } from '../core';
export interface TrainingVideoProps {
  autoPlay?: boolean;
  isRetake?: boolean;
}

const TrainingVideo: React.FC<TrainingVideoProps> = ({ autoPlay, isRetake }) => {
  const { state, dispatch } = useContext(TrainingContext);
  const { state: appState } = useContext(AppContext);
  const { forcedTrainingSessions } = appState;
  const forcedSession = forcedTrainingSessions?.[0] || null;
  const { quiz } = state;
  const router = useRouter();
  const startQuiz = useCallback(async () => {
    try {
      await loadingIndicator.create
      console.log('isRetake', isRetake)
      const res = (forcedSession && !isRetake) ?
        await trainingService.getResults(forcedSession.id) :
        await trainingService.createSession(quiz.id);
      dispatch({
        session: res,
        question: quiz.quiz_questions?.[res.answer_count],
      });
    } catch (e) {
      console.error(e);
      appNotification.toast('Failed to start quiz.', 'Error');
    } finally {
      await loadingIndicator.dismiss();
    }
  }, [quiz, dispatch, forcedSession, isRetake]);
  if (!quiz) {
    return null;
  }
  const determineContentType = () => {
    const fileExtension = quiz.video
      .split('?')?.[0]
      ?.split('.')
      .pop()
      ?.toLowerCase();
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'webp'];
    const pdfExtensions = ['pdf'];

    if (imageExtensions.includes(fileExtension)) {
      return 'image';
    } else if (pdfExtensions.includes(fileExtension)) {
      return 'pdf';
    } else {
      return 'video';
    }
  };

  const contentType = determineContentType();

  const renderContent = () => {
    switch (contentType) {
      case 'image':
        return <img src={quiz.video} alt='Content' style={{ width: '100%' }} />;
      case 'pdf':
        return (
          <iframe
            title={quiz.id}
            src={quiz.video}
            width='100%'
            height='670px'
          />
        );
      default:
        return (
          <video
            autoPlay={!!autoPlay}
            playsInline={true}
            src={quiz.video}
            controls
            onEnded={startQuiz} // Call startQuiz when video ends
          />
        );
    }
  };

  const renderTitleAndSubtitle = () => {
    switch (contentType) {
      case 'image':
        return (
          <>
            <IonCardTitle>Image</IonCardTitle>
            <IonCardSubtitle>
              View the image below
              {appState.authorized && ' and then start the quiz'}.
            </IonCardSubtitle>
          </>
        );
      case 'pdf':
        return (
          <>
            <IonCardTitle>Document</IonCardTitle>
            <IonCardSubtitle>
              View the document below
              {appState.authorized && ' and then start the quiz'}.
              <a target='_blank' rel='noreferrer' href={quiz.video} download>
                Download PDF
              </a>
            </IonCardSubtitle>
          </>
        );
      default:
        return (
          <>
            <IonCardTitle>Video</IonCardTitle>
            <IonCardSubtitle>
              Watch the video below
              {appState.authorized && ' and then start the quiz'}.
            </IonCardSubtitle>
          </>
        );
    }
  };

  return (
    <IonCard className='training-video'>
      <IonCardHeader>{renderTitleAndSubtitle()}</IonCardHeader>
      <IonCardContent>
        {renderContent()}
        {appState.authorized && !(forcedSession && contentType === 'video') && (
          <HapticButton color='secondary' fill='solid' onClick={startQuiz}>
            Start Quiz
          </HapticButton>
        )}
        {!appState.authorized && (
          <HapticButton
            color='secondary'
            fill='solid'
            onClick={() => router.history.goBack()}
          >
            Go Back
          </HapticButton>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default TrainingVideo;
