import React, { useContext } from 'react';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';

const PersonalizedLeadFeaturedVideo: React.FC = () => {
  const { state } = useContext(PersonalizedLeadContext);
  if (state.feature !== 'video') {
    return null;
  }

  const { mp4_file, video_thumb } = state.featureData ?? {};
  return (
    <video
      className="featured-video"
      src={mp4_file}
      poster={video_thumb}
      controls
    />
  );
};

export default PersonalizedLeadFeaturedVideo;
