import { http, util } from '../core';
import qs from 'qs';
import config from '../config.json';
import urlShortener from './url-shortener';

class TradesService {
  async getLinkMessage(url: string): Promise<string> {
    const { short_url } = await urlShortener.shorten(url);
    return `Here is a link to the estimate for your trade: ${short_url || url}`;
  }

  async autocomplete(query: string) {
    try {
      const current_year = new Date().getFullYear();
      const res = await http.request({
        method: 'POST',
        baseURL: 'https://thorin-us-east-1.searchly.com',
        url: '/vehicles_v15/_search',
        headers: {
          Authorization: `Basic ${btoa(
            'turncar-two-snap-access:bximuxmt0fmectt6fucw89f7fhd3nshe'
          )}`,
          'Content-Type': 'text/plain'
        },
        data: JSON.stringify({
          size: 10,
          query: {
            bool: {
              must_not: [
                {
                  term: {
                    'year.raw': (current_year + 1).toString()
                  }
                },
                {
                  term: {
                    'year.raw': (current_year + 2).toString()
                  }
                },
                {
                  term: {
                    'year.raw': (current_year + 3).toString()
                  }
                },
                {
                  term: {
                    'country.raw': 'CA'
                  }
                }
              ],
              must: {
                match: {
                  all_fields: {
                    query,
                    operator: 'and'
                  }
                }
              }
            }
          }
        })
      });

      return res;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getVehicleDetails(vehicle: any, zip_code: any, options?: any) {
    try {
      const { year, make, model, trim } = vehicle;
      const ymmt = util.ymmt(vehicle);
      const { data } = await http.request({
        baseURL: 'https://snap-api.tradepending.com/api/v4',
        url: `/select?${qs.stringify(
          Object.assign(
            {
              ymmt,
              year,
              make,
              model,
              trim,
              zip_code,
              partner_id: config.snap.partnerId
            },
            options ?? {}
          )
        )}`
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getVehicleReport(vehicle_id: number, zip_code: string, mileage?: string|number) {
    try {
      const { data } = await http.request({
        method: 'GET',
        baseURL: 'https://snap-api.tradepending.com/api/v4',
        url: `/report?${qs.stringify({
          vehicle_id,
          zip_code,
          mileage,
          url: 'tecobi.com',
          partner_id: config.snap.partnerId
        })}`
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async updatePendingTrade(clientId: any, leadId: any, data?: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/leads/${leadId}/`,
        data: {
          tradein_progress: data ? JSON.stringify(data) : null
        }
      });

      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async createTradeIn(client: any, url: string, lead: any, vehicle_id: number, postalCode: string, mileage?: number) {
    try {
      //getVehicleReport(vehicle_id: number, zip_code: string, mileage: string|number)
      const report = await this.getVehicleReport(vehicle_id, postalCode, mileage);
      if (mileage) {
        report.vehicle.mileage = mileage;
        report.report.mileage = mileage;
      }

      const res = await http.authorizedRequest({
        method: 'POST',
        url: '/vehicle/tradein/',
        data: Object.assign(report, {
          lead,
          client,
          trade_pending_report_link: url
        })
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async lookupByPlate(plate: string, state: string): Promise<any> {
      try {
          const { data } = await http.request({
              method: 'GET',
              baseURL: 'https://snap-api.tradepending.com/api/v4',
              url: `/select/plate`,
              params: {
                  plate,
                  state,
                  partner_id: config.snap.partnerId
              }
          });

          return data;
      } catch (e) {
          console.log(e);
          throw e;
      }
  }

  async lookupByVIN(vin: string, country: string = 'US'): Promise<any> {
      try {
          const { data } = await http.request({
              method: 'GET',
              baseURL: 'https://snap-api.tradepending.com/api/v4',
              url: `/select/vin`,
              params: {
                  vin,
                  country,
                  partner_id: config.snap.partnerId
              }
          });

          return data;
      } catch (e) {
          console.log(e);
          throw e;
      }
  }

  async removeTrade(clientId: number, leadId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/leads/${leadId}/tradeins/${id}/`
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const tradesService = new TradesService();
export default tradesService;
