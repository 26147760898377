import React, { useContext, useEffect, useCallback, useState } from 'react';
import { ClientUser } from '../types/ClientUser';
import {
  TPage,
  TItemGroup,
  TToggleItem,
  TInputItem,
  TSelectItem,
  HapticButton
} from '../components';
import {
  IonList,
  IonChip,
  IonLabel,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
  IonCol
} from '@ionic/react';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';
import { useParams, useHistory } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import {
  clientUsersService,
  clientGroupsService,
  clientService
} from '../services';
import { appNotification, http, util, loadingIndicator } from '../core';
import { people, closeCircle } from 'ionicons/icons';
import deepEqual from 'deep-equal';
import '../styles/pages/EditClientUser.scss';

export interface RouteParams {
  id: string;
  clientId: string;
}

const EditClientUserPage: React.FC = () => {
  const params = useParams<RouteParams>();
  const clientId = +params.clientId;
  const id = +params.id;
  const [mask, setMask] = useState<string>('');
  const { state, dispatch } = useContext(AppContext);
  const { selectedClientId, selectedClient, clientUsers, clientGroups } = state;
  const history = useHistory();
  const { jobTitles, user: loggedInUser } = state;
  const [showAllFields, setShowAllFields] = useState(!!id);
  const [userExists, setUserExists] = useState(false);
  const [user, setUser] = useState<ClientUser | undefined>();
  const [groups, setGroups] = useState<any>();
  const [client, setClient] = useState<any>();
  const [loading, setLoading] = useState(true);
  const isCreate = !id;

  const notifications = [
    {
      label: 'New Lead',
      value: 'notification_preference'
    },
    {
      label: 'New Message',
      value: 'message_notification_preference'
    },
    {
      label: 'Tags',
      value: 'tag_notification_preference'
    },
    {
      label: 'Daily Reports',
      value: 'daily_report_notifications'
    },
    {
      label: 'Monthly Reports',
      value: 'monthly_report_notifications'
    }
  ];

  const loadUser = useCallback(async () => {
    if (clientId === selectedClientId) {
      const u = clientUsers.find((u: ClientUser) => u.id === id);
      if (u) {
        setGroups(clientGroups);
        setClient(selectedClient);
        setUser(u);
      }
    }
    try {
      const [u, g, c] = await Promise.all([
        id ? clientUsersService.get(clientId, id) : async () => ({}),
        clientGroupsService.request(clientId),
        clientService.request(clientId)
      ]);
      setUser(u);
      setGroups(g);
      setClient(c);
      setLoading(false);
    } catch (e) {
      http.onHttpError(e);
    }
  }, [
    clientId,
    id,
    selectedClientId,
    clientUsers,
    clientGroups,
    selectedClient
  ]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const refreshClientUsers = async () => {
    try {
      const res = await clientUsersService.getList(clientId);
      dispatch({
        type: 'set',
        value: { clientUsers: res }
      });
    } finally {
    }
  };

  const createUser = async () => {
    if (userExists) {
      return appNotification.alertSimple('User already exists.', 'User Exists');
    }

    if (!user?.email) {
      return appNotification.toast(
        'Please enter in a valid email address.',
        'Error'
      );
    }

    try {
      await loadingIndicator.create();
      const r = await clientUsersService.create(clientId, user);
      refreshClientUsers();
      history.replace(`/client/users/${clientId}/${r.id}/`, {});
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const updateUser = async <K extends keyof ClientUser>(key: K, val: string | number | boolean) => {
    if (user?.id !== id || deepEqual(user[key], val)) {
      return;
    }

    try {
      const res = await clientUsersService.update(clientId, user.id, {
        [key]: val
      });

      setUser(res);

      appNotification.toast('User settings updated.', 'Success');
    } finally {
    }
  };

  const assignToClient = async (uid: number) => {
    try {
      await loadingIndicator.create();
      await clientUsersService.add(clientId, { id: uid });
      refreshClientUsers();
      history.replace(`/client/users/${clientId}/${uid}/`, {});
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const checkIntent = async (email: string) => {
    if (!email?.trim?.()) {
      appNotification.toast('Please enter in an email.', 'Error');
      return;
    }
    try {
      const res = await clientUsersService.checkIntent(
        clientId,
        email,
        'add_user'
      );

      const exists = res.data.count > 0;

      setShowAllFields(!exists);

      if (exists) {
        const uid = res.data.results[0].id;
        try {
          await clientUsersService.get(clientId, uid);
          setUserExists(exists);

          if (exists) {
            appNotification.alert({
              message: `${email} is already setup. Would you like to edit that user?`,
              header: 'User Exists',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  handler() {
                    setUser((u: ClientUser | undefined) => ({
                      ...u,
                      email: ''
                    } as ClientUser));
                  }
                },
                {
                  text: 'Yes',
                  handler() {
                    history.replace(`/client/users/${clientId}/${uid}/`, {});
                  }
                }
              ]
            });
          }
        } catch (e) {
          setUserExists(true);

          if (exists) {
            appNotification.alert({
              message: `${email} is already setup. Do you want to add them to your account?`,
              header: 'Add to Account?',
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  handler() {
                    setUser((u: any) => ({ ...u, email: '' }));
                  }
                },
                {
                  text: 'Yes',
                  handler() {
                    assignToClient(uid);
                  }
                }
              ]
            });
          }
        }
      }
    } catch (e) { }
  };

  const updateField = <K extends keyof ClientUser>(key: K) => async (
    e: React.ChangeEvent<HTMLInputElement> | CustomEvent
  ) => {
    const val = 'detail' in e
      ? e.detail.checked ?? e.detail.value
      : e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;

    if (isCreate) {
      if (key === 'email' && showAllFields) {
        checkIntent(val);
      }

      setUser((u: ClientUser | undefined) => ({
        ...u,
        [key]: val
      } as ClientUser));
    } else {
      updateUser(key, val);
    }
  };

  const emailField = (
    <TInputItem
      label="Email"
      value={user?.email}
      type="email"
      onEnterPressed={
        !showAllFields
          ? (e: React.KeyboardEvent<HTMLInputElement>) =>
            checkIntent((e.target as HTMLInputElement).value)
          : undefined
      }
      validation={(val: any) => !!val && !!val.match(/.*@.*\./g)}
      onBlur={updateField('email')}
    />
  );

  useRequireSettingsAdmin();
  return (
    <TPage
      loading={loading}
      headerTool={
        isCreate ? (
          <HapticButton
            color="secondary"
            slot="end"
            disabled={userExists || (showAllFields && !user?.cell_phone)}
            onClick={
              showAllFields ? createUser : (e: React.MouseEvent) => checkIntent(user?.email ?? '')
            }
          >
            {showAllFields ? 'Create' : 'Continue'}
          </HapticButton>
        ) : (
          undefined
        )
      }
    >
      {!loading && (
        <IonList lines="full" className="no-padding">
          {showAllFields ? (
            <>
              <TItemGroup title="User Details">
                {emailField}
                <TInputItem
                  label="First Name"
                  value={user?.first_name}
                  onBlur={updateField('first_name')}
                />
                <TInputItem
                  label="Last Name"
                  value={user?.last_name}
                  onBlur={updateField('last_name')}
                />
                {loggedInUser?.is_staff && (
                  <TSelectItem
                    label="Job Title"
                    multiple
                    placeholder="None"
                    value={user?.job_titles}
                    optionTextKey="name"
                    optionValueKey="id"
                    onChange={updateField('job_titles')}
                    options={jobTitles}
                  />
                )}
                <TInputItem
                  label="Cell # (required)"
                  onFocus={() => setMask('+1 (999) 999-9999')}
                  mask={mask}
                  type="tel"
                  validation={val => !!val && val.length === 12}
                  valueModifier={/[\s_()-]+/g}
                  value={user?.cell_phone}
                  onBlur={updateField('cell_phone')}
                />
                <TInputItem
                  label="Click-to-Call #"
                  onFocus={() => setMask('+1 (999) 999-9999')}
                  mask={mask}
                  type="tel"
                  validation={val => val === '' || (!!val && val.length === 12)}
                  valueModifier={/[\s_()-]+/g}
                  value={user?.click_to_call_phone}
                  onBlur={updateField('click_to_call_phone')}
                />
                <TInputItem
                  label="Click-to-Call Ext."
                  value={user?.click_to_call_extension}
                  onBlur={updateField('click_to_call_extension')}
                />
                <TInputItem
                  label="DMS ID"
                  onBlur={updateField('dms_id')}
                  value={user?.dms_id}
                />
                <TSelectItem
                  value={user?.direct_connect_preference}
                  options={[
                    { value: 'cell_phone', text: 'Cell Phone #' },
                    { value: 'click_to_call_number', text: 'Click-to-call #' }
                  ]}
                  onChange={updateField('direct_connect_preference')}
                  label="Inbound Calls"
                />
                <TSelectItem
                  value={user?.mobile_theme}
                  options={[
                    { value: 'system', text: 'System' },
                    { value: 'dark', text: 'Dark' },
                    { value: 'light', text: 'Light' }
                  ]}
                  onChange={updateField('mobile_theme')}
                  label="Theme"
                />
              </TItemGroup>

              <TItemGroup title="Permissions">
                {clientService.isRoundRobin(client) && (
                  <TToggleItem
                    label="Round Robin Enabled"
                    checked={!!user?.round_robin_enabled}
                    onIonChange={updateField('round_robin_enabled')}
                  />
                )}
                <TToggleItem
                  label="Administrator"
                  checked={!!user?.is_client_admin}
                  onIonChange={updateField('is_client_admin')}
                />
                <TToggleItem
                  label="Can Edit Account Settings"
                  checked={!!user?.is_settings_admin}
                  onIonChange={updateField('is_settings_admin')}
                />
                <TToggleItem
                  label="Can View Credit Apps"
                  checked={!!user?.can_view_credit_apps}
                  onIonChange={updateField('can_view_credit_apps')}
                />
              </TItemGroup>

              <TItemGroup title="Notifications">
                <TSelectItem
                  label="Groups"
                  multiple
                  value={user?.notification_groups}
                  selectedText={`${user?.notification_groups?.length ??
                    0} ${util.getPluralizedString(
                      'Group',
                      user?.notification_groups?.length ?? 0
                    )}`}
                  onChange={updateField('notification_groups')}
                  options={groups?.map?.((grp: any) => ({
                    value: grp.id,
                    text: `@${grp.name}`
                  }))}
                />
                {user?.notification_groups && user.notification_groups.length > 0 && (
                  <IonItem className="groups-chips">
                    <IonGrid>
                      <IonRow>
                        {user.notification_groups?.map?.((id: any) => (
                          <IonCol
                            sizeLg="6"
                            sizeMd="6"
                            sizeXs="12"
                            sizeXl="4"
                            sizeSm="12"
                            key={id}
                          >
                            <IonChip>
                              <IonIcon color="primary" icon={people} />
                              <IonLabel>
                                {`@${groups.find((s: any) => s.id === id)?.name
                                  }`}
                              </IonLabel>
                              <IonIcon
                                color="primary"
                                icon={closeCircle}
                                onClick={(e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  updateField('notification_groups')(
                                    new CustomEvent('custom', {
                                      detail: {
                                        value: user?.notification_groups?.filter((i: any) => i !== id)
                                      }
                                    })
                                  );
                                }}
                              />
                            </IonChip>
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                )}
                {notifications.map(it => (
                  <TSelectItem
                    key={it.value}
                    value={user?.[it.value as keyof ClientUser]}
                    onChange={updateField(it.value as keyof ClientUser)}
                    options={[
                      { value: 'none', text: 'None' },
                      { value: 'email', text: 'Email' },
                      { value: 'sms', text: 'Push' },
                      { value: 'both', text: 'Both' }
                    ]}
                    label={it.label}
                  />
                ))}
              </TItemGroup>
            </>
          ) : (
            <TItemGroup title="User Details">{emailField}</TItemGroup>
          )}
        </IonList>
      )}
    </TPage>
  );
};

export default EditClientUserPage;
