export const emotionOptions = [
  {
    value: null,
    text: 'All',
  },
  {
    value: 'anger',
    text: 'anger',
  },
  {
    value: 'annoy',
    text: 'annoyance',
  },
  {
    value: 'anx',
    text: 'anxiety',
  },
  {
    value: 'apolog',
    text: 'apologetic',
  },
  {
    value: 'apprec',
    text: 'appreciation',
  },
  {
    value: 'calm',
    text: 'calm',
  },
  {
    value: 'concern',
    text: 'concern',
  },
  {
    value: 'confus',
    text: 'confused',
  },
  {
    value: 'content',
    text: 'content',
  },
  {
    value: 'curio',
    text: 'curiosity',
  },
  {
    value: 'defensive',
    text: 'defensiveness',
  },
  {
    value: 'desire',
    text: 'desire',
  },
  {
    value: 'determin',
    text: 'determination',
  },
  {
    value: 'disagree',
    text: 'disagreement',
  },
  {
    value: 'disappoin',
    text: 'disappointed',
  },
  {
    value: 'disbelie',
    text: 'disbelief',
  },
  {
    value: 'disinterest',
    text: 'disinterest',
  },
  {
    value: 'displeased',
    text: 'displeased',
  },
  {
    value: 'disrespect',
    text: 'disrespect',
  },
  {
    value: 'dissatisf',
    text: 'dissatisfaction',
  },
  {
    value: 'distress',
    text: 'distress',
  },
  {
    value: 'distrust',
    text: 'distrust',
  },
  {
    value: 'doubt',
    text: 'doubt',
  },
  {
    value: 'eager',
    text: 'eager',
  },
  {
    value: 'emotion',
    text: 'emotional',
  },
  {
    value: 'empathy',
    text: 'empathy',
  },
  {
    value: 'encourage',
    text: 'encouragement',
  },
  {
    value: 'enthus',
    text: 'enthusiasm',
  },
  {
    value: 'envy',
    text: 'envy',
  },
  {
    value: 'excite',
    text: 'excited',
  },
  {
    value: 'fear',
    text: 'fear',
  },
  {
    value: 'firm',
    text: 'firm',
  },
  {
    value: 'friendl',
    text: 'friendliness',
  },
  {
    value: 'frustrat',
    text: 'frustrated',
  },
  {
    value: 'grat',
    text: 'grateful',
  },
  {
    value: 'happy',
    text: 'happy',
  },
  {
    value: 'helpful',
    text: 'helpful',
  },
  {
    value: 'helpless',
    text: 'helplessness',
  },
  {
    value: 'hesita',
    text: 'hesitant',
  },
  {
    value: 'hope',
    text: 'hope',
  },
  {
    value: 'hopeful',
    text: 'hopefulness',
  },
  {
    value: 'hopeless',
    text: 'hopelessness',
  },
  {
    value: 'humor',
    text: 'humor',
  },
  {
    value: 'hungry',
    text: 'hungry',
  },
  {
    value: 'hurt',
    text: 'hurt',
  },
  {
    value: 'impati',
    text: 'impatience',
  },
  {
    value: 'inconven',
    text: 'inconvenience',
  },
  {
    value: 'indifferen',
    text: 'indifference',
  },
  {
    value: 'interest',
    text: 'interest',
  },
  {
    value: 'irrita',
    text: 'irritated',
  },
  {
    value: 'love',
    text: 'love',
  },
  {
    value: 'mad',
    text: 'mad',
  },
  {
    value: 'need',
    text: 'need',
  },
  {
    value: 'negotiat',
    text: 'negotiating',
  },
  {
    value: 'nervous',
    text: 'nervous',
  },
  {
    value: 'neutral',
    text: 'neutral',
  },
  {
    value: 'offens',
    text: 'offense',
  },
  {
    value: 'overwhelm',
    text: 'overwhelming',
  },
  {
    value: 'pain',
    text: 'pain',
  },
  {
    value: 'patience',
    text: 'patience',
  },
  {
    value: 'persuasion',
    text: 'persuasion',
  },
  {
    value: 'persuasive',
    text: 'persuasive',
  },
  {
    value: 'pissed off',
    text: 'pissed off',
  },
  {
    value: 'playful',
    text: 'playful',
  },
  {
    value: 'polite',
    text: 'polite',
  },
  {
    value: 'politeness',
    text: 'politeness',
  },
  {
    value: 'positive',
    text: 'positive',
  },
  {
    value: 'pride',
    text: 'pride',
  },
  {
    value: 'reassurance',
    text: 'reassurance',
  },
  {
    value: 'reassuring',
    text: 'reassuring',
  },
  {
    value: 'regret',
    text: 'regret',
  },
  {
    value: 'relief',
    text: 'relief',
  },
  {
    value: 'relieved',
    text: 'relieved',
  },
  {
    value: 'reluctance',
    text: 'reluctance',
  },
  {
    value: 'reluctant',
    text: 'reluctant',
  },
  {
    value: 'resignation',
    text: 'resignation',
  },
  {
    value: 'sad',
    text: 'sad',
  },
  {
    value: 'sadness',
    text: 'sadness',
  },
  {
    value: 'satisfaction',
    text: 'satisfaction',
  },
  {
    value: 'satisfied',
    text: 'satisfied',
  },
  {
    value: 'scared',
    text: 'scared',
  },
  {
    value: 'seeking guidance',
    text: 'seeking guidance',
  },
  {
    value: 'shock',
    text: 'shock',
  },
  {
    value: 'shocked',
    text: 'shocked',
  },
  {
    value: 'shook up',
    text: 'shook up',
  },
  {
    value: 'skepticism',
    text: 'skepticism',
  },
  {
    value: 'specific',
    text: 'specific',
  },
  {
    value: 'stress',
    text: 'stress',
  },
  {
    value: 'stressed',
    text: 'stressed',
  },
  {
    value: 'stubborn',
    text: 'stubborn',
  },
  {
    value: 'surprise',
    text: 'surprise',
  },
  {
    value: 'surprised',
    text: 'surprised',
  },
  {
    value: 'suspicion',
    text: 'suspicion',
  },
  {
    value: 'sympathetic',
    text: 'sympathetic',
  },
  {
    value: 'sympathy',
    text: 'sympathy',
  },
  {
    value: 'tension',
    text: 'tension',
  },
  {
    value: 'threatening',
    text: 'threatening',
  },
  {
    value: 'tired',
    text: 'tired',
  },
  {
    value: 'unacceptable',
    text: 'unacceptable',
  },
  {
    value: 'uncertain',
    text: 'uncertain',
  },
  {
    value: 'uncertainty',
    text: 'uncertainty',
  },
  {
    value: 'unclear',
    text: 'unclear',
  },
  {
    value: 'understanding',
    text: 'understanding',
  },
  {
    value: 'unhappiness',
    text: 'unhappiness',
  },
  {
    value: 'unhappy',
    text: 'unhappy',
  },
  {
    value: 'uninterested',
    text: 'uninterested',
  },
  {
    value: 'unknown',
    text: 'unknown',
  },
  {
    value: 'upset',
    text: 'upset',
  },
  {
    value: 'urgency',
    text: 'urgency',
  },
  {
    value: 'urgent',
    text: 'urgent',
  },
  {
    value: 'welcoming',
    text: 'welcoming',
  },
  {
    value: 'willingness',
    text: 'willingness',
  },
  {
    value: 'worried',
    text: 'worried',
  },
];
