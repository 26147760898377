import React, { useCallback, useState, useMemo } from 'react';
import useRequireAdmin from '../hooks/useRequireAdmin';
import { funnel, refresh } from 'ionicons/icons';
import { UpcomingBlastsFiltersModal } from '../components/modals';
import {
  TInfiniteScrollPage,
  BlastRow,
  HapticButton
} from '../components';
import {
  IonItemDivider,
  IonRouterLink
} from '@ionic/react';
import { upcomingBlastsService } from '../services';
import { BlastDetailModal } from '../components/modals';
import { useRouter, useDataLoader } from '../hooks';

const UpcomingBlastsPage: React.FC = () => {
  useRequireAdmin();
  const router = useRouter();
  const [blast, setBlast] = useState();
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const blastType = useMemo(() => router.query.type, [router]);
  const isCustomer = useMemo(() => blastType === 'customers', [blastType]);

  const customerFontWeight = isCustomer ? 800 : '';
  const leadsFontWeight = isCustomer ? '' : 800;

  const loadBlasts = useCallback(async () => {
    return await upcomingBlastsService.list({
      type: blastType,
      user: undefined,
      deletedAtIsNull: !showDeleted,
      completedAtIsNull: !showCompleted,
    });
  }, [blastType, showDeleted, showCompleted]);

  const {
    refresher,
    data,
    error,
    loadNext,
    next,
    loading,
    dispatch: dataDispatch
  } = useDataLoader(loadBlasts, true);

  const currentlySending = data?.filter((it:any) => {
    return !!it.started_at
  })

  return (
    <TInfiniteScrollPage
      headerTool={
        <>
          <HapticButton
            icon={refresh}
            onClick={() => {
              dataDispatch({
                type: 'set',
                value: {
                  loading: true,
                  error: false
                }
              });
              refresher();
            }}
            className="btn-sm-padding"
            slot="end"
          />
          <HapticButton
            icon={funnel}
            onClick={() => setShowFilters(true)}
            className="btn-sm-padding"
            slot="end"
          />
      </>
      }
      preContent={
        <IonItemDivider mode="md" sticky>
          <div style={{width: '100%'}} className="ion-text-center">
            <IonRouterLink
              style={{fontWeight: leadsFontWeight}}
              routerLink="/upcoming-blasts/leads/"
              routerDirection="none"
            >
              Lead Blasts
            </IonRouterLink>
             &nbsp;&nbsp;|&nbsp;&nbsp;
            <IonRouterLink
              style={{fontWeight: customerFontWeight}}
              routerLink="/upcoming-blasts/customers/"
              routerDirection="none"
            >
              Customer Blasts
            </IonRouterLink>
            {!showDeleted && !showCompleted && (
              <><br/>{currentlySending?.length} Currently Sending</>
            )}
          </div>
        </IonItemDivider>}
      loading={loading}
      items={data}
      error={error}
      hasNext={!!next}
      renderItem={(item: any) => (
        <BlastRow
          key={item.id}
          item={item}
          clientName={item.client_name}
          hideActionButton={true}
          blastType={router.query.type}
          onClick={() => setBlast(item)}
          onBlastRemoved={(id: any) =>
            dataDispatch({ type: 'remove', value: (
              it: any) => it.id === id })
          }
        />
      )}
      onRefresh={refresher}
      onLoadNextPage={loadNext}
      zeroStateText={loading ? '' : 'No Text Blasts Found.'}
    >

      {blast && (
        <BlastDetailModal
          item={blast}
          blastType={blastType}
          onDidDismiss={() => setBlast(undefined)}
        />
      )}
      {showFilters && (
        <UpcomingBlastsFiltersModal
          onDidDismiss={() => setShowFilters(false)}
          onApplyFilters={(filters) => {
            setShowFilters(false);
            setShowCompleted(filters.showCompleted);
            setShowDeleted(filters.showDeleted);
          }}
          filters={{showCompleted, showDeleted}}
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default UpcomingBlastsPage;
