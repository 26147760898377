import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonBadge,
  IonProgressBar,
  IonSkeletonText,
} from "@ionic/react";
import { smsBillingService } from "../services";
import moment from "moment";

const SmsUsageStats = (props: any) => {
  //const [startOfMonth, setStartOfMonth] = useState<any>();
  //const [endOfMonth, setEndOfMonth] = useState<any>();
  const [usageStats, setUsageStats] = useState<any>(null); // State variable to hold usage statistics
  const [overageCount, setOverageCount] = useState<any>();
  const [includedRemaining, setIncludedRemaining] = useState<any>();
  const [overageDisplay, setOverageDisplay] = useState<any>();

  useEffect(() => {
    const startDate = moment().startOf("month").toDate();
    const endDate = moment().endOf("month").toDate();

    //setStartOfMonth(startDate);
    //setEndOfMonth(endDate);

    // Call smsBillingService.list with start and end dates
    smsBillingService
      .list(props.selectedClientId, startDate, endDate, "", "")
      .then((result: any) => {
        setUsageStats(result); // Save the result in state
        console.log(result);
        const overageCount =
          (result?.count ?? 0) - (result?.client_package?.included ?? 0);
        console.log("overageCount", overageCount);
        const includedRemaining =
          (result?.client_package?.included ?? 0) - (result?.count ?? 0);
        const overageDisplay = overageCount > 0 ? overageCount : 0;
        setOverageCount(overageCount);
        setIncludedRemaining(includedRemaining);
        setOverageDisplay(overageDisplay);
      })
      .catch((error: any) => {
        console.error("Error fetching SMS usage stats:", error);
      });
  }, [props.selectedClientId]);

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>SMS Usage Statistics</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {!usageStats &&
            Array.from(Array(3).keys()).map((it: any, i: number) => (
              <IonItem key={i}>
                <IonLabel>
                  <IonSkeletonText key={i} animated />
                </IonLabel>
              </IonItem>
            ))}
          {usageStats && (
            <div>
              <IonItem>
                <IonLabel>Total SMS</IonLabel>
                <IonBadge
                  mode="ios"
                  color={
                    !!usageStats?.client_package?.included && overageCount > 0
                      ? "danger"
                      : "secondary"
                  }
                >
                  {usageStats?.count?.toLocaleString()}
                </IonBadge>
              </IonItem>
              {!!usageStats?.client_package?.overage && (
                <>
                  <IonItem lines="none">
                    <IonBadge mode="ios" slot="start">
                      {usageStats?.count?.toLocaleString()}
                    </IonBadge>
                    <IonProgressBar
                      value={
                        usageStats?.count / usageStats?.client_package?.included
                      }
                      color={overageCount > 0 ? "danger" : "success"}
                    />
                    <IonBadge mode="ios" slot="end">
                      {usageStats?.client_package?.included?.toLocaleString()}
                    </IonBadge>
                  </IonItem>
                  {!!usageStats?.client_package?.included && (
                    <IonItem
                      lines="none"
                      color={overageCount > 0 ? "danger" : "success"}
                    >
                      {overageCount > 0 ? (
                        <>
                          <IonLabel>Overage</IonLabel>
                          <IonBadge mode="ios">
                            {overageDisplay.toLocaleString()}
                          </IonBadge>
                        </>
                      ) : (
                        <>
                          <IonLabel>Included Remaining</IonLabel>
                          <IonBadge mode="ios">
                            {includedRemaining?.toLocaleString()}
                          </IonBadge>
                        </>
                      )}
                    </IonItem>
                  )}{" "}
                  {overageCount > 0 && (
                    <IonItem color='danger'>
                      <IonLabel>Overage Cost</IonLabel>
                      <IonBadge mode='ios'>
                        {`${overageDisplay?.toLocaleString()} x ${
                          usageStats?.client_package?.overage
                        } = $${(
                          overageDisplay * usageStats?.client_package?.overage
                        )
                          ?.toFixed(2)
                          ?.toLocaleString()}`}
                      </IonBadge>
                    </IonItem>
                  )}
                  {/* Add missing closing tag for IonItem */}
                </>
              )}
            </div>
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default SmsUsageStats;
