import React, { useState } from 'react';
import { IonList, IonButton } from '@ionic/react';
import TToggleItem from '../TToggleItem';
import ButtonGrid from '../ButtonGrid';
import { TModal } from '.';

export interface UpcomingBlastsFiltersModalProps {
  filters: any;
  onDidDismiss?: () => void;
  onApplyFilters?: (filters: any) => void;
}

const UpcomingBlastsFiltersModal: React.FC<UpcomingBlastsFiltersModalProps> = ({
  onDidDismiss,
  onApplyFilters,
  filters
}) => {
  const [showCompleted, setShowCompleted] = useState<boolean>(filters.showCompleted);
  const [showDeleted, setShowDeleted] = useState<boolean>(filters.showDeleted);

  return (
    <TModal
      isOpen={true}
      onDidDismiss={onDidDismiss}
      title="Filters"
      footer={
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              onApplyFilters?.({showCompleted: true, showDeleted: true});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              onApplyFilters?.({showCompleted, showDeleted});
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full">
        <TToggleItem
          label="Completed"
          checked={showCompleted}
          onIonChange={() => setShowCompleted(!showCompleted)}
          wrapLabel
        />
        <TToggleItem
          label="Deleted"
          checked={showDeleted}
          onIonChange={() => setShowDeleted(!showDeleted)}
          wrapLabel
        />
      </IonList>
    </TModal>
  );
};

export default UpcomingBlastsFiltersModal;
