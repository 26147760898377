import React from 'react';
import { IonFabButton, IonIcon, IonFab } from '@ionic/react';
import '../styles/components/TFab.scss';

export interface TFabProps
  extends Partial<React.ComponentProps<typeof IonFabButton>> {
  icon: HTMLIonIconElement['icon'];
  onClick?: (e: any) => any;
  horizontal?: 'start' | 'end';
}

const TFab: React.FC<TFabProps> = ({
  icon,
  onClick,
  horizontal = 'end',
  ...rest
}) => {
  return (
    <IonFab
      slot="fixed"
      className="t-fab"
      vertical="bottom"
      horizontal={horizontal}
      onClick={onClick}
    >
      <IonFabButton color="success" {...rest}>
        <IonIcon icon={icon} />
      </IonFabButton>
    </IonFab>
  );
};

export default TFab;
