import React, { useContext, useEffect, useState } from 'react';
import TModal from './TModal';
import moment from 'moment';
import { AppContext } from '../../context/AppContext';
import DataTable from 'react-data-table-component';
import { http, util } from '../../core';
import TLoading from '../TLoading';
export interface UserCallListModalProps {
  isOpen: boolean;
  userId: number;
  clientId?: number;
  since: Date;
  until: Date;
  title: string;
  endpoint: string;
  dateTimeField: string;
  tableTitle?: string;
  onDidDismiss: () => any;
}
const customStyles: any = {
  rows: {
    style: {
      cursor: 'pointer',
    }
  },
};
const columns = [
  {
    name: 'Name',
    selector: (row: any) => row.lead.full_name,
  },
  {
    name: 'Time',
    selector: (row: any) => row.created_at,
    format: (row: any)=> moment(row.created_at).format('lll'),
  },
  {
    name: 'Duration',
    selector: (row: any) => row.duration,
    format: (row: any)=> moment.utc(row.duration * 1000).format('mm:ss'),
    maxWidth: '70px'
  },
  {
    name: 'Listen',
    selector: (row: any) => row.audio_file,
    cell: (row: any) => row.audio_file ? <a href={row.audio_file} target="_blank" rel="noopener noreferrer">Play</a> : <em>No audio</em>,
  }
];

const UserCallListModal: React.FC<UserCallListModalProps> = ({
  isOpen,
  userId,
  clientId,
  since,
  until,
  title,
  endpoint,
  dateTimeField,
  tableTitle,
  onDidDismiss
}) => {
  const { state } = useContext(AppContext);
  // Used for is_full_service hide avg response time chart
  const { user } = state;
  const [leads, setLeads] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const countPerPage = 7;
  util.createTecobiDarkDataTableTheme();
  useEffect(() => {
    const getCallList = async () => {
      let url = `/${endpoint}/?user=${userId}&page=${page}&page_size=${countPerPage}&${dateTimeField}__gte=${since?.toISOString()}&${dateTimeField}__lte=${until?.toISOString()}`;
      if (!!clientId) {
        url += `&client=${clientId}`
      }
      const res = await http.authorizedRequest({
        method: 'GET',
        url,
        type: endpoint,
        exclusive: true
      });
      setLeads(res.data);
      setLoading(false);
    }
    getCallList();
  }, [clientId, page, userId, dateTimeField, endpoint, since, until]);

  return (
    <TModal
      isOpen={isOpen}
      title={title}
      onDidDismiss={onDidDismiss}
    >
        <TLoading visible={loading} text="Loading" />
        <DataTable
          title={tableTitle}
          columns={columns}
          customStyles={customStyles}
          data={leads?.results}
          theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={leads?.count}
          paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
          onChangePage={page => setPage(page)}
        />
    </TModal>
  );
};

export default UserCallListModal;
