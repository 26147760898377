import React, { useContext } from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import moment from 'moment';
import TAvatar from './TAvatar';
import { notificationsOutline, archive, pause, flame } from 'ionicons/icons';
import css from 'classnames';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import { appNotification } from '../core';

export interface TextMessageRowProps {
  item: any;
  noRowColor?: boolean;
}

const TextMessageRow: React.FC<TextMessageRowProps> = ({
  item,
  noRowColor
}: TextMessageRowProps) => {
  const {
    id,
    last_sms_message,
    last_message_type,
    lead_source,
    best_name,
    status,
    assigned_to,
    client_info,
    email_address,
    pause_date,
    convo_archived,
    sms_opt_out,
    needs_call
  } = item || {};

  const { canViewLead } = useContext(LeadContext);
  const { state } = useContext(AppContext);
  const { user } = state;
  const { message, created } = last_sms_message || {};
  const { option_name } = status;
  const { first_name, last_name } = assigned_to || {};
  const timestamp = moment(created).fromNow();
  const lastInbound = last_message_type === 'inbound';
  let avatarBadge,
    badgeColor = 'warning';

  if (convo_archived === true) {
    avatarBadge = <IonIcon icon={archive} />;
    badgeColor = 'danger';
  } else if (pause_date) {
    avatarBadge = <IonIcon icon={pause} />;
  } else if (lastInbound) {
    avatarBadge = <IonIcon icon={notificationsOutline} />;
    badgeColor = 'secondary';
  } else if (needs_call) {
    badgeColor = 'orange';
    avatarBadge = <IonIcon icon={flame} />;
  }

  const canView = canViewLead(user, item);

  return (
    <IonItem
      lines="full"
      className={css(
        'text-message-row',
        !noRowColor
          ? {
              archived: !!convo_archived || (sms_opt_out && sms_opt_out > 9),
              paused: !convo_archived && !!pause_date,
              inbound: !convo_archived && !pause_date && lastInbound,
              soft_opt_out: !convo_archived && (sms_opt_out && sms_opt_out < 10),
              needs_call: needs_call
            }
          : {}
      )}
      routerLink={
        canView
          ? `/text-messages/conversation/${client_info.id}/${id}/`
          : undefined
      }
      onClick={() =>
        !canView &&
        appNotification.alertSimple(
          'This lead is assigned to another user.',
          'Already Assigned'
        )
      }
      detail
    >
      <TAvatar
        slot="start"
        email={email_address}
        alt={best_name}
        badge={avatarBadge}
        badgeTextColor="black"
        badgeColor={`var(--ion-color-${badgeColor})`}
      />
      <IonLabel className="ion-multi-line">
        <h2>{best_name}</h2>
        <p className="subtitle ellipsis">{message}</p>
        <p className="detail ion-text-wrap">
          {first_name ? [first_name, last_name].join(' ') : 'Unassigned'} •{' '}
          {option_name} • {lead_source}
        </p>
        <p className="detail ion-hide-md-up">{timestamp}</p>
      </IonLabel>
      <span className="detail ion-hide-sm-down" slot="end">
        {timestamp}
      </span>
    </IonItem>
  );
};

export default TextMessageRow;
