import React, { useContext, useEffect } from 'react';

import {
  IonList,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonThumbnail,
  IonCol
} from '@ionic/react';
import { useRouter } from '../hooks';
import {
  TTextItem,
  TPage
} from '../components';

import {
  checkmarkCircle,
  ellipseOutline
} from 'ionicons/icons';
import { AppContext } from '../context/AppContext';
import { MailDropContext } from '../context/MailDropContext';
import ButtonGrid from '../components/ButtonGrid';
import {
  loadingIndicator,
  appNotification
} from '../core';
import { mailDropService } from '../services';
import moment from 'moment';

const AddMailDropStep3Page: React.FC = () => {
  const router = useRouter();
  const { state: appState } = useContext(AppContext);
  const { state } = useContext(MailDropContext);
  const {
    selectedClientId
  } = appState;


  useEffect(() => {
    if(!state?.name || !state.dropDate || !state?.columnMap) {
      router.push(`/maildrops/add/`, {})
    }
  }, [router, state]);

  return (
    <TPage
      customFooterContent={
        <ButtonGrid>
          <IonButton
            disabled={false}
            color="secondary"
            onClick={async () => {
              try {
                await loadingIndicator.create();
                await mailDropService.create(selectedClientId, {
                  name: state?.name,
                  drop_date: state?.dropDate?.split('T')[0],
                  column_map: JSON.stringify(state?.columnMap),
                  mailer_proof: state?.proof,
                  uploaded_file: state?.fileUploaded,
                  auto_reply: state?.autoReply,
                });
                /*
                dispatch({
                  name: '',
                  dropDate: '',
                  autoReply: '',
                  proof: '',
                  proofPreview: '',
                  columnMap: undefined,
                  fileUploaded: undefined
                });
                */
                // TODO: use router once we figure out reloading the mail drop list
                window.location.href = `/maildrops/`;
                appNotification.alert({
                  message: `Your mail drop was successfully created.`,
                  header: 'Success',
                  buttons: [
                    {
                      text: 'Dismiss',
                      role: 'cancel'
                    }
                  ]
                });
              } catch(err) {
                console.error(err);
                router.push(`/maildrops/`, {});
                appNotification.alert({
                  message: `Sorry there was an error.`,
                  header: 'Error',
                  buttons: [
                    {
                      text: 'Dismiss',
                      role: 'cancel'
                    }
                  ]
                });
              } finally {
                loadingIndicator.dismiss();
              }
            }}
            expand="block"
          >
            Confirm
          </IonButton>
        </ButtonGrid>
      }
    >

      <IonGrid className="training-quiz">
        <IonRow>
          <IonCol size="12" className="lesson-name">
            <strong className="lesson-category">Review and Confirm</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="training-progress">
            <div className="training-progress-item">
              <IonIcon
                color="success"
                icon={checkmarkCircle}
                slot="start"
              />
              <span>Settings</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                color="success"
                icon={checkmarkCircle}
                slot="start"
              />
              <span>Data</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Review</span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonList lines="full" className="no-padding">
        <IonItemDivider sticky>
            Settings
        </IonItemDivider>
        <TTextItem
          label="Name"
          labelPlacement="stacked"
          text={state.name}
        />
        <TTextItem
          label="Drop Date"
          labelPlacement="stacked"
          text={moment(state.dropDate).format('MMM DD, YYYY')}
        />
        {!!state.proof && (
          <IonItem>
            <IonThumbnail slot="start">
              <img src={state.proofPreview} alt="proof" />
            </IonThumbnail>
            <IonLabel>{state.proof.name}</IonLabel>
          </IonItem>
        )}
        <IonItemDivider sticky>
            CSV Data File
        </IonItemDivider>
        <IonItem>
          <IonLabel>{state.fileUploaded?.name}</IonLabel>
        </IonItem>
      </IonList>
    </TPage>
  );
};

export default AddMailDropStep3Page;
