import { useRef, useMemo, useEffect } from 'react';

export default function useDebounce(fn: any, delay: number) {
  const timer = useRef<any>();
  const handler = useRef<any>(fn);

  useEffect(() => {
    handler.current = fn;
  }, [fn]);

  return useMemo(
    () => (...args: any[]) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => handler.current?.(...args), delay);
    },
    [delay]
  );
}
