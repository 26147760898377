import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { haptics } from '../core';

export interface HapticButtonProps
  extends React.ComponentProps<typeof IonButton> {
  icon?: any;
  cancelBubble?: boolean;
}

const HapticButton: React.FC<HapticButtonProps> = ({
  icon,
  onClick,
  cancelBubble,
  fill = 'clear',
  children,
  ...rest
}) => {
  const onButtonClick = (e: any) => {
    if (cancelBubble) {
      e.stopPropagation();
      e.preventDefault();
    }

    haptics.lightImpact();
    onClick?.(e);
  };

  return (
    <IonButton fill={fill} onClick={onButtonClick} {...rest}>
      {icon && (
        <IonIcon
          style={{ marginRight: children ? 10 : undefined }}
          icon={icon}
          slot="icon-only"
        />
      )}
      {children}
    </IonButton>
  );
};

export default HapticButton;
