import React, { useCallback } from 'react';
import {
  TReportsPage,
  TItemGroup
} from '../components';
import { commissionsService } from '../services';
import { IonList, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { time } from 'ionicons/icons';

const MyCommissionReportPage: React.FC = () => {
  const loadData = useCallback(async ({ userId, since, until }: any) => {
    return await commissionsService.list(since, until);
  }, []);

  return (
    <TReportsPage
      downloadData={(data: any) => data.results}
      onLoadData={loadData}
      filterProps={{ hideClassification: true, showAdminFilter: false }}
    >
      {({ state }) => (
        <IonList className="reports no-padding no-margin" lines="full">
          {state.data.results?.map((it: any) => (
            <TItemGroup
              key={`${it.first_name}${it.last_name}`}
              iconColor={it.is_clocked_in ? 'success' : 'danger'}
              icon={time}
              title={`${it.first_name} ${it.last_name}`}
            >
              <IonGrid className="users-grid">
                <IonRow className="column-header">
                  <IonCol>Set Appts</IonCol>
                  <IonCol>Cancelled Appts</IonCol>
                  <IonCol>Net Appts</IonCol>
                  <IonCol>Commission</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.total_appointments}</IonCol>
                  <IonCol>{it.cancelled_appointments}</IonCol>
                  <IonCol>{it.net_appointments}</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${it.appt_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Set Appts</IonCol>
                  <IonCol>Cancelled Appts</IonCol>
                  <IonCol>Sold Appts</IonCol>
                  <IonCol>Sold Commission</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.total_appointments}</IonCol>
                  <IonCol>{it.cancelled_appointments}</IonCol>
                  <IonCol>{it.sold_appointments}</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${it.sold_appt_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Net Appts</IonCol>
                  <IonCol>Touched Leads</IonCol>
                  <IonCol>Conversion %</IonCol>
                  <IonCol>Conversion Bonus</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.net_appointments}</IonCol>
                  <IonCol>{it.touched_leads}</IonCol>
                  <IonCol>{it.conversion_percentage}%</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${it.bonus}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Clocked In</IonCol>
                  <IonCol>Total SMS</IonCol>
                  <IonCol>SMS Avg/hr</IonCol>
                  <IonCol>Total</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.time_clocked_in}</IonCol>
                  <IonCol>{it.total_sms}</IonCol>
                  <IonCol>{Math.round(it.sms_per_hour)}</IonCol>
                  <IonCol>
                    <IonText color="success">
                      ${it.total_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </TItemGroup>
          ))}
        </IonList>
      )}
    </TReportsPage>
  );
};

export default MyCommissionReportPage;
