import React, { createContext, useReducer, Dispatch } from 'react';
import authorization from '../core/authorization';
import theme from '../core/theme';
import { ICurrentConfig } from 'cordova-plugin-ionic/dist/IonicCordova';
import useDeepLinks from '../hooks/useDeepLinks';
import { User } from '../types/User';
import { Client } from '../types/Client';
import { IDAndName } from '../types/IDAndName';
import { ClientHour } from '../types/ClientHour';
import { ClientNumber } from '../types/ClientNumber';
import { ClientMention } from '../types/ClientMention';
import { Status } from '../types/Status';
import { Language } from '../types/Language';
import { ClientUser } from '../types/ClientUser';
import { QuickReplyCategory } from '../types/QuickReplyCategory';
import { Shortcodes } from '../types/Shortcodes';

export interface Actions {
  type: string;
  value?: any;
}

export interface State {
  authorized: boolean;
  showAddLeadModal: boolean;
  authorizationToken?: string;
  forcedTrainingSessions: any;
  clients: IDAndName[];
  clientUsers: ClientUser[];
  clientHours: ClientHour[];
  clientGroups: IDAndName[];
  clientMentionUsers: ClientMention[];
  clientProviders: IDAndName[];
  clientNumbers: ClientNumber[];
  quickReplyCategories: QuickReplyCategory[];
  shortcodes: Shortcodes;
  selectedClientId: number;
  selectedClient: Client;
  user: User;
  otpData: any;
  keyboardOpen: boolean;
  teamQueue: boolean;
  statuses: Status[];
  ninjaTeams: IDAndName[];
  jobTitles: IDAndName[];
  isOnline: boolean;
  clientInboundCount: number;
  clientNeedsCallCount: number;
  languages: Language[];
  currentVersion?: any;
  updateConfig?: ICurrentConfig;
  leadSorting?: string;
  deviceInfo?: any;
  isClockedIn: boolean;
}

export interface ContextProps {
  state: State;
  dispatch: Dispatch<Actions>;
}

export const AppContext = createContext({} as ContextProps);

export const dispatch = (detail: Actions) => {
  window.dispatchEvent(new CustomEvent('state:dispatch', { detail }));
};

const reducer = (state: State, action: any) => {
  const { type, value } = action;

  switch (type) {
    case 'set': {
      const s = { ...state };
      if (value?.sorting) {
        window.localStorage.setItem('leadSorting', value.sorting);
      }
      return Object.assign(s, value);
    }

    case 'setToken': {
      return { ...state, authorized: true, authorizationToken: value };
    }

    case 'logout': {
      authorization.clear();
      theme.reset();
      return {
        ...state,
        authorizationToken: null,
        authorized: false,
        user: null,
        clients: [],
        forcedTrainingSessions: [],
        selectedClientId: null
      };
    }

    case 'setUserInfo': {
      const { mobile_theme = 'system' } = value || {};
      theme.setPreference(mobile_theme);
      return { ...state, user: value };
    }

    case 'needsCallCountUpdated': {
      return {
        ...state,
        clientNeedsCallCount: value
      };
    }

    case 'inboundUpdated': {
      return {
        ...state,
        clientInboundCount: value.count
      };
    }
  }
  return state;
};

let initialized = false;

export const AppContextProvider = (props: any) => {
  const init = props.initialState || {};

  const initialState: State = Object.assign(
    {
      authorized: false,
      showAddLeadModal: false,
      keyboardOpen: false,
      otpData: {},
      teamQueue: false,
      selectedClientId: null,
      clients: [],
      forcedTrainingSessions: [],
      clientUsers: [],
      clientGroups: [],
      clientHours: [],
      clientProviders: [],
      clientNumbers: [],
      clientMentionUsers: [],
      quickReplyCategories: [],
      user: {},
      statuses: [],
      ninjaTeams: [],
      jobTitles: [],
      languages: [],
      selectedClient: {},
      shortcodes: {},
      isOnline: true,
      clientInboundCount: 0,
      clientNeedsCallCount: 0,
      isClockedIn: false,
      leadSorting: localStorage.getItem('leadSorting') ?? 'Newest First'
    },
    init
  );

  useDeepLinks();
  const [state, dispatch] = useReducer(reducer, initialState);

  if (!initialized) {
    window.addEventListener('state:dispatch', (e: any) => {
      dispatch(e.detail as Actions);
    });

    window.addEventListener('network:changed', (e: any) => {
      const { detail } = e;
      dispatch({
        type: 'set',
        value: { isOnline: detail }
      });
    });

    initialized = true;
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;
