import React, { useCallback } from 'react';
import { FacebookCampaignOverview, TPage } from '../components';
import { facebookService } from '../services';
import { IonItem, IonLabel, IonItemDivider, IonList, IonBadge } from '@ionic/react';
import { useRouter, useDataLoader } from '../hooks';
import { util } from '../core';

const FacebookAdSetsPage: React.FC = () => {
  const router = useRouter();
  const { clientId, campaignId } = router.query;
  const load = useCallback(
    async () => await facebookService.loadAdReport(clientId, campaignId),
    [clientId, campaignId]
  );
  const { data, loading, error } = useDataLoader(load);

  return (
    <TPage
      loading={loading}
      error={error}
      preContent={
        <>
          {!loading && (
            <IonItemDivider>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                className="ion-text-center"
              >
                {data.remote_read?.status && (
                  <IonBadge color={util.getFacebookAdStatusBadgeColor(data.remote_read?.status)} mode="ios">
                    {data.remote_read?.status}
                  </IonBadge>
                )}
                {data?.name}
              </div>
            </IonItemDivider>
          )}
          <FacebookCampaignOverview
            clientId={clientId}
            campaignId={campaignId}
          />
          {!loading && (
            <IonItemDivider>
              {data?.name} &gt; Ad Sets
            </IonItemDivider>
          )}
        </>
      }
    >
      <IonList lines="full" className="no-margin no-padding">
        {data?.adsets?.map?.((item: any) => (
          <IonItem
            key={item.id}
            routerLink={`/reports/facebook-ads/${clientId}/${campaignId}/${item.id}/`}
            detail
          >
            <IonLabel className="ion-multiline" style={{ display: 'flex', alignItems: 'center'}}>
              {item.remote_read?.status && (
                <IonBadge color={util.getFacebookAdStatusBadgeColor(item.remote_read?.status)} mode="ios">
                  {item.remote_read?.status}
                </IonBadge>
              )}
              {item.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default FacebookAdSetsPage;
