import React, { useState, useContext } from 'react';
import TModal from './TModal';
import { IonList, IonButton, NavContext } from '@ionic/react';
import TSelectItem from '../TSelectItem';
import TInputItem from '../TInputItem';
import ButtonGrid from '../ButtonGrid';
import { leadsService, phoneNumberLookupService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { loadingIndicator, util, appNotification, http } from '../../core';
import '../../styles/components/AddLeadModal.scss';
import {
  briefcase,
  business,
  person,
  call,
  globe,
  mailOpen,
} from 'ionicons/icons';
import { modalController } from '@ionic/core';
import moment from 'moment';

export interface AddLeadModalProps {
  isOpen: boolean;
  onDidDismiss?: () => any;

  onCreated?: (lead: any) => any;
}

const AddLeadModal: React.FC<AddLeadModalProps> = ({ onCreated, ...rest }) => {
  const { state } = useContext(AppContext);
  const navContext = useContext(NavContext);
  const { selectedClientId, clientProviders, languages } = state;
  const [classification, setClassification] = useState('Sales');
  const [provider, setProvider] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [creating, setCreating] = useState(false);
  const [mask, setMask] = useState<string>('');
  const [language, setLanguage] = useState(1);

  const canCreate =
    firstName &&
    lastName &&
    classification &&
    (phoneNumber || emailAddress) &&
    message;

  const createLead = async () => {
    if (canCreate) {
      setCreating(true);
      await loadingIndicator.create();
      let carrier = null;
      try {
        // Check the phone number first
        if (phoneNumber) {
          carrier = await phoneNumberLookupService.lookup(
            selectedClientId,
            phoneNumber
          );
          if (carrier?.error_code === 500) {
            appNotification.toast(
              `${phoneNumber} is not a valid phone number`,
              'Error'
            );
            loadingIndicator.dismiss();
            setCreating(false);
            return;
          } else if (carrier?.type === 'landline') {
            appNotification.toast(
              `${phoneNumber} is a landline and cannot receive text messages.`,
              'Error'
            );
            loadingIndicator.dismiss();
            setCreating(false);
            return;
          }
        }

        // Now we can create the lead
        const res = await leadsService.create(selectedClientId, {
          phone_number: phoneNumber ? util.formatPhone(phoneNumber) : '',
          email_address: emailAddress,
          lead_type: 'Other',
          status: 1,
          carrier_type: carrier?.type,
          carrier_created_at: !!carrier ? moment().toISOString() : null,
          carrier_error_code: carrier?.error_code,
          carrier_mobile_country_code: carrier?.mobile_country_code,
          carrier_mobile_network_code: carrier?.mobile_network_code,
          carrier_name: carrier?.name,
          first_name: firstName,
          last_name: lastName,
          master_provider: provider ? provider : undefined,
          language,
          classification,
        });
        if (res?.client) {
          await leadsService.sendMessage(res.client, res.id, {
            twilio_number: res.twilio_number,
            number: res.phone_number,
            is_email: !!res.email_address && !res.phone_number,
            message: `${message} You can reply stop to opt-out.`,
          });
          onCreated?.(res);
          appNotification.toast(
            `Lead ${res.first_name} ${res.last_name} successfully created.`,
            'Success',
            {
              buttons: [
                {
                  text: 'Go to lead',
                  handler() {
                    navContext.navigate(
                      `/text-messages/conversation/${res.client}/${res.id}/`,
                      'forward'
                    );
                  },
                },
              ],
            }
          );
        }
        modalController.dismiss();
      } catch (e: any) {
        const msg = http.getErrorMessage(e);
        appNotification.toast(msg, 'Error');
        if (e.response?.status === 400) {
          modalController.dismiss();
        }
      } finally {
        loadingIndicator.dismiss();
        setCreating(false);
      }
    }
  };

  return (
    <TModal
      mode="md"
      title='New Lead'
      className='add-lead-modal'
      footer={
        <ButtonGrid>
          <IonButton
            disabled={!canCreate}
            color='secondary'
            onClick={!creating ? createLead : undefined}
            expand='block'
          >
            Add Lead
          </IonButton>
        </ButtonGrid>
      }
      {...rest}
    >
      <IonList lines='full'>
        <TSelectItem
          label='Type'
          icon={briefcase}
          value={classification}
          onChange={(e: any) => setClassification(e.detail.value)}
          options={['Sales', 'Service', 'Employment', 'Collections']}
        />
        <TSelectItem
          label='Language'
          labelPlacement='fixed'
          icon={globe}
          value={language}
          onChange={(e: any) => setLanguage(e.detail.value)}
          options={[
            ...languages?.map?.((it) => ({
              value: it.id,
              text: it.name,
            })),
          ]}
        />
        <TSelectItem
          label='Provider'
          icon={business}
          value={provider}
          onChange={(e: any) => setProvider(e.detail.value)}
          options={[
            { value: '', text: 'None' },
            ...clientProviders?.map?.((it) => ({
              value: it.id,
              text: it.name,
            })),
          ]}
        />
        <TInputItem
          label='First Name'
          icon={person}
          value={firstName}
          required
          onChange={(e: any) => setFirstName(e.detail.value)}
        />
        <TInputItem
          label='Last Name'
          icon={person}
          value={lastName}
          required
          onChange={(e: any) => setLastName(e.detail.value)}
        />
        <TInputItem
          label='Cell Phone'
          mask={mask}
          type='tel'
          icon={call}
          onFocus={() => setMask('+1 (999) 999-9999')}
          value={phoneNumber}
          required={!emailAddress}
          onChange={(e: any) => setPhoneNumber(e.detail.value)}
          valueModifier={/[\s_()-]+/g}
          validation={(val: any) => !!val && val.length === 12}
        />
        <TInputItem
          label='Email Address'
          type='email'
          icon={mailOpen}
          value={emailAddress}
          required={!phoneNumber}
          onChange={(e: any) => setEmailAddress(e.detail.value)}
        />
        <TInputItem
          className='lead-message-box'
          label='Message'
          labelPlacement='stacked'
          value={message}
          onChange={(e: any) => setMessage(e.detail.value)}
          multiline
        />
      </IonList>
    </TModal>
  );
};

export default AddLeadModal;
