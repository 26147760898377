import React, { useState, useContext } from 'react';
import {
  IonPopover,
  IonList,
  IonButton,
  IonIcon,
  IonRange,
} from '@ionic/react';
import TSelectItem from './TSelectItem';
import ButtonGrid from './ButtonGrid';
import TDateTimeItem from './TDateTimeItem';
import TItem from './TItem';
import { emotionOptions } from '../core';
import {
  compass,
  paperPlane,
  personCircle,
  happy,
  albums,
} from 'ionicons/icons';
import '../styles/components/CallFilters.scss';
import { popoverController } from '@ionic/core';
import { AppContext } from '../context/AppContext';
import moment from 'moment';

export interface CallFiltersProps {
  isOpen: boolean;
  onDidDismiss?: () => any;
  onApply?: (filters: any) => any;
  filters?: any;
}

const CallFilters: React.FC<CallFiltersProps> = ({
  isOpen,
  onDidDismiss,
  onApply,
  filters,
}) => {
  const [direction, setDirection] = useState(filters?.direction || '');
  const [provider, setProvider] = useState(filters?.provider || '');
  const [user, setUser] = useState(filters?.user || '');
  const [since, setSince] = useState(filters?.created_at__gte);
  const [until, setUntil] = useState(filters?.created_at__lte);
  const [maxDuration, setMaxDuration] = useState(
    filters?.duration__lte / 60 || 120
  );
  const [minDuration, setMinDuration] = useState(
    filters?.duration__gte / 60 || 0
  );
  const { state: appState } = useContext(AppContext);
  const [emotion, setEmotion] = useState(filters?.emotions__icontains || '');
  const [category, setCategory] = useState(filters?.category__icontains || '');

  // Assuming emotions and categories arrays are available
  const categoriesOptions = [
    null,
    'complaint',
    'finance',
    'sales',
    'service',
    'voicemail',
    'other',
  ];

  return (
    <IonPopover
      isOpen={isOpen}
      mode='md'
      className='call-filters'
      onDidDismiss={onDidDismiss}
    >
      <IonList lines='full'>
        <TDateTimeItem
          range
          toValue={until}
          fromValue={since}
          label="Date Range"
          onFromIonChange={(e: any) => setSince(e.detail.value)}
          onToIonChange={(e: any) => setUntil(e.detail.value)}
        />
        <TSelectItem
          label='Direction'
          value={direction}
          onChange={(e: any) => setDirection(e.detail.value)}
          options={[
            { value: '', text: 'All' },
            { value: 'inbound', text: 'Inbound' },
            { value: 'outbound-api', text: 'Outbound' },
          ]}
        >
          <IonIcon slot='start' icon={compass} className='ion-hide-md-down' />
        </TSelectItem>
        <TSelectItem
          label='User'
          value={user}
          onChange={(e: any) => setUser(e.detail.value)}
          options={[
            { value: '', text: 'All' },
            ...appState.clientUsers?.map?.((it) => ({
              value: it.id,
              text: it.full_name,
            })),
          ]}
        >
          <IonIcon
            slot='start'
            icon={personCircle}
            className='ion-hide-md-down'
          />
        </TSelectItem>
        <TSelectItem
          label='Lead Provider'
          value={provider}
          onChange={(e: any) => setProvider(e.detail.value)}
          options={[
            { value: '', text: 'All' },
            ...appState.clientProviders?.map?.((it) => ({
              value: it.id,
              text: it.name,
            })),
          ]}
        >
          <IonIcon
            slot='start'
            icon={paperPlane}
            className='ion-hide-md-down'
          />
        </TSelectItem>
        {/* Emotion Dropdown */}
        <TSelectItem
          label='Emotion'
          icon={happy}
          value={emotion}
          onChange={(e: any) => setEmotion(e.detail.value)}
          options={emotionOptions}
        />
        {/* Category Dropdown */}
        <TSelectItem
          label='Category'
          icon={albums}
          value={category}
          onChange={(e: any) => setCategory(e.detail.value)}
          options={categoriesOptions.map((c) => ({
            value: c,
            text: c === null ? 'All' : c,
          }))}
        />
        <TItem
          className='price-range-item'
          label='Duration Minutes'
          labelPlacement='stacked'
          lines='none'
        >
          <div className='ion-multiline price-range-container'>
            <IonRange
              dualKnobs
              value={{ lower: minDuration, upper: maxDuration }}
              min={0}
              max={120}
              step={1}
              pin
              debounce={500}
              slot='end'
              onIonChange={(e: any) => {
                const { lower, upper } = e.detail.value;
                setMinDuration(lower);
                setMaxDuration(upper);
              }}
            />
            <div className='price-range'>
              <span className='price-range-label'>{minDuration}</span>
              <span className='price-range-label'>{maxDuration}</span>
            </div>
          </div>
        </TItem>
        <ButtonGrid>
          <IonButton
            expand='block'
            color='primary'
            onClick={() => {
              popoverController.dismiss();
              setProvider('');
              setDirection('');
              onApply?.({});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand='block'
            color='secondary'
            onClick={() => {
              popoverController.dismiss();
              onApply?.({
                provider,
                direction,
                user,
                emotions__icontains: emotion,
                category__icontains: category,
                created_at__gte: since
                  ? moment(since).startOf('day').toISOString()
                  : undefined,
                created_at__lte: until
                  ? moment(until).endOf('day').toISOString()
                  : undefined,
                duration__gte: minDuration * 60,
                duration__lte: maxDuration * 60,
              });
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      </IonList>
    </IonPopover>
  );
};

export default CallFilters;
