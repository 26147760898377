import React from 'react';
import { IonTitle } from '@ionic/react';
import Logo from './Logo';

export interface THeaderLogoProps {
  logoType?: string;
}


const THeaderLogo: React.FC<THeaderLogoProps> = ({
  logoType
}) => {
  return (
    <IonTitle className="ion-text-center">
      <Logo logoType={logoType} style={{ height: 15, width: 'auto', margin: 'auto' }} />
    </IonTitle>
  );
};

export default THeaderLogo;
