import React, { useCallback } from 'react';
import { TPage, TMap } from '../components';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useDataLoader, useWindowSize } from '../hooks';
import { util } from '../core';
import { http } from '../core';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const CovidInformation: React.FC = () => {
  util.setTitle('COVID-19 Heatmap');
  const load = useCallback(async () => {
    const { data } = await http.request({
      method: 'GET',
      url: `/covid-heatmap/?sms_type=inbound`
    });

    return data?.map((it: any) => ({
      lat: it.location[0],
      lng: it.location[1]
    }));
  }, []);

  const { width } = useWindowSize();
  const { loading, error, data } = useDataLoader(load);

  return (
    <TPage loading={loading} error={error}>
      <IonGrid
        style={{
          height: '100%'
        }}
      >
        <IonRow>
          <IonCol>
            <h1 className="no-padding no-margin">COVID-19</h1>
            <p
              style={{
                color: 'var(--ion-color-step-550)',
                fontSize: 13
              }}
              className="no-padding no-margin"
            >
              We have a database of almost 90,000,000 text messages between
              customers and auto dealerships. The below map indicates how
              frequently customers are mentioning the words corona, covid or
              wohan in their conversations with auto dealers. To date there has
              been <strong>{util.formatNumber(data?.length ?? 0)}</strong>{' '}
              mentions. This will help isolate areas where dealers should be
              more sensitive to customer concerns about the virus.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <script
              data-ad-client="ca-pub-1319558534393982"
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ height: 'calc(100% - 100px)' }}>
          <IonCol>
            {data && (
              <TMap
                coords={{ lat: 39.0902, lng: -97.7129 }}
                hideMarker
                hideTraffic
                zoom={(width ?? 0) < 600 ? 3 : 4}
                options={{ gestureHandling: 'none', disableDefaultUI: true }}
                heatmap={data}
                forceDark
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </TPage>
  );
};

export default CovidInformation;
