import { Device } from '@capacitor/device';
import { isPlatform } from '@ionic/core';
import { UAParser } from 'ua-parser-js';

// const { Device } = Plugins;
class DeviceInfo {
  getBrowserInfo() {
    const parser = new UAParser(navigator.userAgent);
    const browser = parser.getBrowser();
    const device = parser.getDevice();
    const os = parser.getOS();
    return {
      model: device.model,
      appBuild: `${browser.name}/${browser.version}`,
      manufacturer: device.vendor,
      osVersion: os.version,
      appVersion: browser.major,
      platform: os.name
    };
  }

  async getInfo() {
    try {
      return isPlatform('capacitor')
        ? await Device.getInfo()
        : this.getBrowserInfo();
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async load() {
    return {
      deviceInfo: await this.getInfo()
    };
  }
}

const deviceInfo = new DeviceInfo();
export default deviceInfo;
