import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { TReportsPage, TSearchBar } from '../components';
import { IonToolbar } from '@ionic/react';
import { needsCallService } from '../services';
import '../styles/Reports.scss';
import DataTable from 'react-data-table-component';
import { util } from '../core';
import { useDarkTheme } from '../hooks';

const customStyles: any = {
  cells: {
    style: {
      '&:nth-of-type(10)': {
        backgroundColor: '#8BC642',
      },
    },
  },
};

const NeedsCallReport: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const isDark = useDarkTheme();
  const { state } = useContext(AppContext);
  const [reports, setReports] = useState<any>();
  const [filteredReports, setFilteredReports] = useState<any>();
  const doSearch = (val: string) => {
    const searchResults = reports?.filter((it:any) => {
      return it.name.toLowerCase().includes(val.toLowerCase());
    })
    setFilteredReports(searchResults);
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: 'Total',
      selector: (row: any) => row.count,
      sortable: true,
    },
    {
      name: 'Called',
      selector: (row: any) => row.hasDuration,
      sortable: true,
    },
    {
      name: 'Waiting',
      selector: (row: any) => row.stillWaiting,
      sortable: true,
    },
    {
      name: 'Wait Time',
      selector: (row: any) => row.avgWaitDuraton,
      sortable: true,
      right: true,
      format: (row: any) => {
        return new Date(row.avgWaitDuraton * 1000).toISOString().substr(11, 8);
      }
    },
  ];

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const loadData = useCallback(
    async ({ since, until, filters }: any) => {


      const requests:Array<any> = [];
      state.clients.forEach((client:any) => {
        requests.push(needsCallService.retrieveReport(
          since,
          until,
          client.id,
          undefined
        ))
      });
      const reports = await Promise.all(requests);
      const needsCallReports:Array<any> = [];
      reports.forEach((it:any) => {
        if (it.length) {
          needsCallReports.push(needsCallService.aggregateReport(it, state.clients));
        }
      });
      return {
        // remove ones that have no avgWaitDuraton
        needsCallReports: needsCallReports.filter((it: any) => !!it.avgWaitDuraton),
      };
    },
    [state.clients]
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ showNinjaTeamsFilter: false, showMultiClientSelector: false, hideClassification: true }}
    >
      {({ state }) => {
        const { data } = state;
        setReports(data.needsCallReports);
        return (
          <>
            <IonToolbar>
              <TSearchBar onSearch={doSearch} />
            </IonToolbar>

            <DataTable
              title="Team Report"
              columns={columns}
              data={filteredReports}
              theme={isDark ? 'tecobi-dark' : 'default'}
              pagination={filteredReports?.length > 10}
              paginationServer={false}
              highlightOnHover={true}
              customStyles={customStyles}
            />
          </>
        );
      }}
    </TReportsPage>
  );
};

export default NeedsCallReport;
