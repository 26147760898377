import React, { useState, useCallback, useContext } from 'react';
import { TInfiniteScrollPage, TAvatar } from '../components';
import { surveyService } from '../services';
import { AppContext } from '../context/AppContext';
import { IonItem, IonLabel } from '@ionic/react';
import moment from 'moment';
import { SurveyModal } from '../components/modals';
import { uiUtil } from '../core';
import { useDataLoader } from '../hooks';

const Surveys: React.FC = () => {
  const { state } = useContext(AppContext);
  const loadSurveys = useCallback(async () => {
    return await surveyService.listResults(state.selectedClientId);
  }, [state.selectedClientId]);

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadSurveys,
    true
  );
  const [survey, setSurvey] = useState<any>();

  return (
    <TInfiniteScrollPage
      loading={loading}
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setSurvey(item)}
        >
          <TAvatar alt={item.full_name} slot="start" />
          <IonLabel className="ion-multiline">
            <p>{item.full_name}</p>
            <p className="detail">{uiUtil.renderRating(item.average_rating)}</p>
          </IonLabel>
          <p className="detail" slot="end">
            {moment(item.updated).fromNow()}
          </p>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
    >
      {survey && (
        <SurveyModal
          survey={survey}
          onWillDismiss={() => setSurvey(null)}
          showLeadButton
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default Surveys;
