import React, { useCallback, useMemo } from 'react';
import { useRouter } from '../hooks';
import { TReportsPage, TChart } from '../components';
import { reportsService } from '../services';
import '../styles/Reports.scss';
import moment from 'moment';

const AppointmentReport: React.FC = () => {
  // Used for is_full_service hide avg response time chart
  const router = useRouter();
  const reportType = useMemo(() => router.query.type, [router]);
  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const { classification, leadProvider, staff, topNinja } = filters;
      const reportData = await reportsService.retrieveAppointmentReport(
        since,
        until,
        staff,
        topNinja,
        classification,
        leadProvider,
        reportType,
      );
      const mSince = moment(since);
      const mUntil = moment(until);
      const duration = moment.duration(mUntil.diff(mSince));
      const days = duration.asDays();
      const chartData:Array<any> = [];
      const unorderedChartObj:any = {};
      const chartObj:any = {};
      const startOf = days > 70 ? 'month' : days > 41 ? 'week' : days < 1 ? 'hour' : 'day';

      const options = {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: startOf,
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
      reportData.forEach((it: any) => {
        const startOfDt = moment(it[reportType]).startOf(startOf).toISOString()
        if (startOfDt in unorderedChartObj) {
          unorderedChartObj[startOfDt] += 1;
        } else {
          unorderedChartObj[startOfDt] = 1;
        }
      });
      Object.keys(unorderedChartObj).sort().forEach((key) => {
        chartObj[key] = unorderedChartObj[key];
      });

      for (const k in chartObj) {
        const t = moment(k).toDate();
        const y = chartObj[k];
        chartData.push({t, y});
      }
      return {
        reportData,
        chartData,
        options,
      };
    },
    [reportType]
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ showLeadProvider: true, showStaffFilter: true, showTopNinjaFilter: true }}
    >
      {({ state }) => {
        const { data } = state;
        return (
          <>
            <TChart
              type="bar"
              data={data.chartData}
              options={data.options}
            />
          </>
        );
      }}
    </TReportsPage>
  );
};

export default AppointmentReport;
