import inventory from './inventory';
import { appointmentsService } from '.';

class PersonalizedFeaturesService {
  async loadFeatureData(lead: any, type: string, id: string) {
    switch (type) {
      case 'vehicle':
        return await inventory.showcase(id);
      case 'appointment':
        return appointmentsService.findActive(lead?.appointments);
      case 'video':
        return lead?.videos?.find((it: any) => it.id.toString() === id);
    }
  }
}

const personalizedFeaturesService = new PersonalizedFeaturesService();
export default personalizedFeaturesService;
