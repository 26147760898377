import React, { useContext, useState, useEffect, useCallback } from 'react';
import TModal from './TModal';
import { LeadContext } from '../../context/LeadContext';
import { IonToolbar, IonButtons, IonButton } from '@ionic/react';
import { modalController } from '@ionic/core';
import { TDatePicker } from '../';
import moment from 'moment';
import '../../styles/components/SetAppointmentModal.scss';
import {
  leadsService,
  clientHoursService,
  appointmentsService
} from '../../services';
import loading from '../../core/loading';
import { appNotification } from '../../core';
import { warning } from 'ionicons/icons';
import TBanner from '../TBanner';

export interface SetAppointmentModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const SetAppointmentModal: React.FC<SetAppointmentModalProps> = ({
  onDidDismiss,
  isOpen = false
}) => {
  const [validation, setValidation] = useState<any>();
  const { state, dispatch } = useContext(LeadContext);
  const { lead, clientHours } = state;
  const appointments = lead?.appointments ?? [];
  const { id, client } = lead ?? {};
  const tz = leadsService.getTimezone(lead);
  const appointment = appointmentsService.findActive(appointments);
  const [appointmentDate, setAppointmentDate] = useState<
    string | null | undefined
  >();

  useEffect(() => {
    setAppointmentDate(appointment?.appointment_time);
  }, [appointment]);

  const doSetAppointment = useCallback(async () => {
    if (!id || !client) {
      return;
    }

    if (!appointmentDate) {
      appNotification.toast('Please choose an appointment date.', 'Error');
      return;
    }

    try {
      loading.create();
      let updated;
      const time = moment(appointmentDate)
        .tz(tz)
        .startOf('minute');

      const timeString = time.toISOString();
      if (appointment) {
        updated = await leadsService.updateAppointmentTime(
          client,
          id,
          appointment.id,
          {
            appointment_time: timeString
          }
        );
      } else {
        updated = await leadsService.createAppointment(client, id, timeString);
      }

      dispatch({
        type: 'set',
        value: {
          lead: updated?.lead
        }
      });
      modalController.dismiss();
    } finally {
      loading.dismiss();
    }
  }, [appointment, tz, appointmentDate, dispatch, client, id]);

  const setAppointment = useCallback(() => {
    if (validation?.during === false) {
      appNotification.alert({
        message:
          'Appointment is outside of business hours. Are you sure you want to schedule it?',
        header: 'Warning',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Continue',
            handler() {
              doSetAppointment();
            }
          }
        ]
      });
    } else {
      doSetAppointment();
    }
  }, [doSetAppointment, validation]);

  const checkInsideBusinessHours = useCallback(() => {
    if (!appointmentDate) {
      return;
    }

    setValidation(
      clientHoursService.isDuringBusinessHours(
        moment(appointmentDate),
        clientHours,
        tz
      )
    );
  }, [appointmentDate, clientHours, tz]);

  useEffect(() => checkInsideBusinessHours(), [checkInsideBusinessHours]);

  return (
    <TModal
      title={`Appointment for ${lead?.best_name}`}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      className="set-appointment-modal"
      footer={
        <>
          {validation?.during === false && (
            <TBanner icon={warning} color="danger">
              Appointment is outside of business hours
            </TBanner>
          )}
          <IonToolbar>
            <IonButtons slot="primary">
              <IonButton
                slot="primary"
                color="success"
                id="btn-set-appointment"
                onClick={setAppointment}
              >
                Set Appointment
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </>
      }
    >
      <TDatePicker
        minDate={new Date()}
        dateString={appointmentDate}
        timezone={tz}
        onDateChange={setAppointmentDate}
        dateFormat="MM/dd/yyyy h:mma"
        timeIntervals={15}
        showTimeSelect
      />
    </TModal>
  );
};

export default SetAppointmentModal;
