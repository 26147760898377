import CachedService, { CacheStrategy, CacheTTL } from './cached-service';
import { http } from '../core';

class QuickReplyCategoriesService extends CachedService {
  constructor() {
    super(
      'quickReplyCategories',
      'quickReplyCategories',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.FIFTEENMINUTES
    );
  }

  async request() {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/reply-categories/',
      exclusive: true,
      type: 'reply-categories'
    });

    return data;
  }
}

const quickReplyCategoriesService = new QuickReplyCategoriesService();
export default quickReplyCategoriesService;
