import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { TLoading } from '../components';
import { Route } from 'react-router';

const Loading: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <TLoading visible paused={false} />
      </IonContent>
    </IonPage>
  );
};

const AppLoading: React.FC = () => {
  return <Route component={Loading} />;
};

export default AppLoading;
