import React, { useState, useRef, useEffect, useMemo } from 'react';
import { IonAvatar, IonLabel } from '@ionic/react';
import { util } from '../core';

export interface TAvatarProps extends React.ComponentProps<typeof IonAvatar> {
  color?: string;
  randomColor?: boolean;
  src?: string;
  email?: string;
  fallback?: string;
  alt?: string;
  text?: string;
  badge?: boolean | string | JSX.Element;
  badgeColor?: string;
  badgeTextColor?: string;
}

const validColors = [
  'success',
  'secondary',
  'tertiary',
  'warning',
  'dark',
  'medium'
];

const TAvatar: React.FC<TAvatarProps> = ({
  alt,
  text,
  src,
  email,
  color = 'success',
  randomColor,
  fallback = '404',
  style = {},
  badge,
  badgeColor,
  badgeTextColor,
  ...rest
}) => {
  const [valid, setValid] = useState(!!src || !!email);
  const fallbackColor = useRef<any>();
  const secureSrc = useMemo(() => {
    if (src) {
      return src.replace('http:', 'https:');
    } else if (email) {
      return util.gravatarUrl(email, fallback);
    }
  }, [src, email, fallback]);

  if (!fallbackColor.current) {
    if (randomColor) {
      fallbackColor.current = util.randomItem(validColors);
    } else {
      fallbackColor.current = color;
    }
  }

  useEffect(() => {
    let ignore = false;
    if (secureSrc) {
      const el = new Image(1, 1);
      el.onload = () => {
        if (!ignore) {
          setValid(true);
        }
      };
      el.onerror = () => {
        if (!ignore) {
          setValid(false);
        }
      };
      el.src = secureSrc;
    }

    setValid(!!secureSrc || !!email);
    return () => {
      ignore = true;
    };
  }, [secureSrc, email]);

  return (
    <IonAvatar
      style={Object.assign(
        {
          alignItems: 'center',
          backgroundColor: `var(--ion-color-${fallbackColor.current})`,
          backgroundImage: secureSrc ? `url(${secureSrc})` : 'none',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          margin: '0 15px 0 0',
          position: 'relative'
        },
        style
      )}
      {...rest}
    >
      <IonLabel
        style={{
          color: `var(--ion-color-${fallbackColor.current}-contrast)`,
          fontWeight: 500,
          position: 'relative'
        }}
      >
      {!valid && !text && util.initials(alt ?? ' ')}
      {!valid && text}
      </IonLabel>
      {badge && (
        <div
          className="avatar-badge"
          style={{
            alignItems: 'center',
            backgroundColor: badgeColor,
            boxShadow: '1px 1px 10px rgba(0,0,0,.2)',
            color: badgeTextColor,
            borderRadius: '100%',
            display: 'flex',
            fontSize: 10,
            justifyContent: 'center',
            position: 'absolute',
            bottom: -3,
            right: -3,
            height: 16,
            width: 16
          }}
        >
          {badge === true ? '' : badge}
        </div>
      )}
    </IonAvatar>
  );
};

export default TAvatar;
