import { http } from '../core';
import PagedService from './paged-service';

class NewLeadAutoRepliesService extends PagedService {
  async list(clientId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/new-lead-auto-replies/`,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async delete(clientId: number, objId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/new-lead-auto-replies/${objId}/`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async update(clientId: number, objId: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/new-lead-auto-replies/${objId}/`,
        data,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async create(clientId: number, data: any) {
    try {
      data['client'] = clientId;

      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/new-lead-auto-replies/`,
        data,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const newLeadAutoRepliesService = new NewLeadAutoRepliesService();
export default newLeadAutoRepliesService;
