import React, { useEffect, useState } from 'react';
import { util } from '../core';

export interface TVideoProps {
  src: string;
  poster?: string;
  height?: number;
  style?: object;
}

const TVideo: React.FC<TVideoProps> = ({ src, poster, style, height }) => {
  height = height || 360;
  const [preview, setPreview] = useState(poster);

  useEffect(() => {
    (async () => {
      if (!poster) {
        try {
          const poster = await util.getVideoPoster(src);
          setPreview(poster);
        } catch (e) {}
      }
    })();
  }, [poster, src]);

  return (
    <video
      playsInline
      style={Object.assign(
        {
          minWidth: 100,
          maxWidth: '100%',
          height: `calc(9 * ${height}px / 16)`,
          objectFit: 'cover'
        },
        style || {}
      ) as any}
      preload="metadata"
      src={src}
      poster={preview}
      controls
    />
  );
};

export default TVideo;
