import React from 'react';
import { IonChip } from '@ionic/react';
import { emotion } from '../core';

export interface PhoneCallChipsProps {
  call: any;
}

const PhoneCallChips: React.FC<PhoneCallChipsProps> = (
  props: PhoneCallChipsProps
) => {
  const { call } = props;

  return (
    <div style={{ whiteSpace: 'normal' }}>
      <IonChip color='primary'>
        {emotion.getEmoji(call.category)}{' '}
        {call.category.replace(/[^\w\d]/g, '')}
      </IonChip>
      {!!call?.emotions &&
        call?.emotions?.split('|').map((emo: any, index: number) => (
          <IonChip color='primary' key={`emo-${index}`}>
            <span aria-label={emo} role='img'>
              {emotion.getEmoji(emo)}
            </span>
            &nbsp;{emo}
          </IonChip>
        ))}
      {!!call?.keywords &&
        call?.keywords
          ?.split('|')
          .slice(0, 6)
          .map((keyword: any, index: number) => (
            <IonChip color='primary' key={`kw-${index}`}>
              <span aria-label='keyword' role='img'>
                🔍
              </span>
              &nbsp;{keyword}
            </IonChip>
          ))}
      {!!call?.score && (
        <IonChip color='primary'>
          <span aria-label='score' role='img'>
            🏆
          </span>
          &nbsp;{call?.score}
        </IonChip>
      )}
    </div>
  );
};

export default PhoneCallChips;
