import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { TReportsPage, TSearchBar } from '../components';
import { IonToolbar } from '@ionic/react';
import { reportsService } from '../services';
import '../styles/Reports.scss';
import DataTable from 'react-data-table-component';
import { util } from '../core';

const customStyles: any = {
  cells: {
    style: {
      '&:nth-of-type(2)': {
        backgroundColor: '#8BC642',
      },
      '&:nth-of-type(3)': {
        backgroundColor: '#ffc409',
        color: '#222327',
      },
    },
  },
};

const KPIReport: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const { state } = useContext(AppContext);
  // Used for is_full_service hide avg response time chart
  const { user } = state;
  const [columns, setColumns] = useState<any>();
  const [reports, setReports] = useState<any>();
  const [filteredReports, setFilteredReports] = useState<any>();
  const doSearch = (val: string) => {
    const searchResults = reports?.filter((it:any) => {
      return it.name.toLowerCase().includes(val.toLowerCase());
    })
    setFilteredReports(searchResults);
  };

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const { classification, leadProvider, newLeadsOnly, clientIds, pipelineStatusIds} = filters;

      const engagedColumns = [
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Working Pipeline',
          selector: 'working_pipeline',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Paused Pipeline',
          selector: 'paused_pipeline',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Engaged Leads',
          selector: 'engaged_leads',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Set',
          selector: 'appointments_set',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Set Rate',
          selector: 'appointments_set_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Showed',
          selector: 'appointments_showed',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Show Rate',
          selector: 'appointments_showed_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Sold',
          selector: 'appointments_sold',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Sold Rate',
          selector: 'appointments_sold_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
      ];

      const newColumns = [
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Working Pipeline',
          selector: 'working_pipeline',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Paused Pipeline',
          selector: 'paused_pipeline',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'New Leads',
          selector: 'leads',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Engaged New Leads',
          selector: 'engaged_leads',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Engagement Rate',
          selector: 'engagement_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Set',
          selector: 'appointments_set',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Set Rate',
          selector: 'appointments_set_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Showed',
          selector: 'appointments_showed',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Show Rate',
          selector: 'appointments_showed_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Appts Sold',
          selector: 'appointments_sold',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
        {
          name: 'Sold Rate',
          selector: 'appointments_sold_rate',
          sortable: true,
          right: true,
          maxWidth: '55px'
        },
      ];

      if (newLeadsOnly) {
        setColumns(newColumns)
      } else {
        setColumns(engagedColumns)
      }
      const requests:Array<any> = [];
      clientIds.forEach((cId:any) => {
        requests.push(reportsService.retrieveKPIReport(
          cId,
          since,
          until,
          classification,
          leadProvider,
          pipelineStatusIds,
          newLeadsOnly,
        ))
      });
      const reports = await Promise.all(requests);
      const kpis:Array<any> = [];
      reports.forEach((it:any) => {
        kpis.push(it);
      });
      return {
        kpis,
      };
    },
    []
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ showLeadProvider: true, showNewLeadsOnly: true, showMultiClientSelector: true, showPipelineStatuses: true }}
    >
      {({ state }) => {
        const { data } = state;
        setReports(data.kpis);
        return (
          <>
            <IonToolbar>
              <TSearchBar onSearch={doSearch} />
            </IonToolbar>
            <DataTable
              title="KPIs"
              columns={columns}
              data={filteredReports}
              theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
              pagination={filteredReports?.length > 10}
              paginationServer={false}
              highlightOnHover={true}
              customStyles={customStyles}
            />
          </>
        );
      }}
    </TReportsPage>
  );
};

export default KPIReport;
