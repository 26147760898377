import { http, authorization } from '../core';
import qs from 'qs';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class ClientsService extends CachedService {
  constructor() {
    super('clients', 'clients', [], CacheStrategy.TIMEOUT, CacheTTL.ONEMINUTE);
  }

  async listPerSmsClients() {
    const params = {
      package__included__isnull: false,
      package__overage__isnull: false,
      is_active: true,
      simple_view: true,
    };

    const query = qs.stringify(params);

    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/?${query}`,
      exclusive: false,
    });
    return data;
  }

  async request() {
    let { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/clientselector/',
      type: 'clients',
      exclusive: true,
    });

    const currentId = await authorization.getSelectedClientId();
    const firstClientId = data[0]?.id;
    if (!currentId && firstClientId) {
      await authorization.setSelectedClientId(firstClientId);
    }

    return data;
  }
}

const clientsService = new ClientsService();
export default clientsService;
