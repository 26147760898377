import PagedService from './paged-service';
import { http } from '../core';
import qs from 'qs';


class LeadBillingService extends PagedService {

  async list(clientId: number, since: Date, until: Date) {
    try {
      const params = {
        created_at__gte: since.toISOString(),
        created_at__lte: until.toISOString(),
        page_size: 2000,
        client: clientId
      } as any;
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/lead-billing/?${query}`,
        type: 'lead-billing',
        exclusive: true
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const leadBillingService = new LeadBillingService();
export default leadBillingService;
