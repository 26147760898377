import React from 'react';
import Logos from '../resources/logos.json';
import { useDarkTheme } from '../hooks';

export interface LogoProps {
  style?: any;
  logoType?: string;
}

const Logo: React.FC<LogoProps> = ({
  logoType = 'default',
  ...rest
}) => {
  const isDarkTheme = useDarkTheme();
  const logoObj = logoType === 'calendars' ? Logos.calendars : Logos.default;
  const imgSrc = isDarkTheme ? logoObj.dark : logoObj.light;
  return (
    <img
      src={imgSrc}
      alt="TECOBI Logo"
      {...rest}
    />
  );
};

export default Logo;
