import { http } from '../core';
import qs from 'qs';

interface AppointmentsListParams {
  created_at__gte?: string;
  created_at__lte?: string;
  sold__gte?: string;
  sold__lte?: string;
  distinct?: boolean;
  trade_pending_id__isnull?: boolean;
}

class AllAppointmentsService {
  async list(params: AppointmentsListParams) {
    try {
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/appointments/?${query}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
      return [];
    }
  }
}

const allAppointmentsService = new AllAppointmentsService();
export default allAppointmentsService;
