import CachedService, { CacheStrategy, CacheTTL } from './cached-service';
import { http } from '../core';
import qs from 'qs';

class ShortcodesService extends CachedService {
  constructor() {
    super(
      'shortcodes',
      'shortcodes',
      {},
      CacheStrategy.TIMEOUT,
      CacheTTL.ONEMINUTE
    );
  }

  async request() {
    const query = qs.stringify({ shortcut__isnull: false, page_size: 100 });
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/quick-replies/?${query}`
    });

    return data?.results?.reduce?.((obj: any, it: any) => {
      obj[it.shortcut] = it;
      return obj;
    }, {});
  }
}

const shortcodesService = new ShortcodesService();
export default shortcodesService;
