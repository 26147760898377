import React, { useContext } from 'react';
import { TModal } from '.';
import { TSelectItem, TInput } from '../';
import { IonButton } from '@ionic/react';
import '../../styles/components/QuickRepliesModal.scss';
import { AppContext } from '../../context/AppContext';

interface NewQuickReplyModalProps {
  isOpen: boolean;
  handleSubmit: (e: any) => Promise<void>;
  onDidDismiss: () => void;
  createCategory: number;
  setCreateCategory: React.Dispatch<React.SetStateAction<number>>;
  editPlaceholder: string;
  editValue: string | null | undefined;
  setEditValue: React.Dispatch<React.SetStateAction<string>>;
}

const NewQuickReply: React.FC<NewQuickReplyModalProps> = ({
  isOpen,
  handleSubmit,
  onDidDismiss,
  createCategory,
  setCreateCategory,
  editPlaceholder,
  editValue,
  setEditValue,
}) => {
  const { state } = useContext(AppContext);
  const { quickReplyCategories } = state;

  return (
    <TModal
      isOpen={isOpen}
      title='New Quick Reply'
      onDidDismiss={onDidDismiss}
      footer={
        <IonButton
          color="secondary"
          onClick={(e) => {
            handleSubmit(e);
            onDidDismiss();
          }}
          type="submit"
          expand="full"
          className="quick-replies-footer-button"
        >
          Save
        </IonButton>
      }
    >
      <TSelectItem
        label="Category"
        value={createCategory}
        onChange={(e: any) =>
          setCreateCategory(parseInt(e.detail.value))
        }
        options={quickReplyCategories.map((it: any) => ({
          value: it.id,
          text: it.name
        }))}
      />
      <TInput
        multiline
        autoGrow
        placeholder={editPlaceholder}
        style={{ marginLeft: '1rem' }}
        value={editValue}
        onIonInput={e => setEditValue(e.detail.value as string)}
      />
    </TModal>
  )
};

export default NewQuickReply;