import React, { useCallback } from 'react';
import { TPage } from '../components';
import { trainingService } from '../services';
import { IonItem, IonList, IonItemDivider } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { util } from '../core';
import { useDataLoader } from '../hooks';

const TrainingQuizzesPage: React.FC = () => {
  const { slug } = useParams<any>();
  const loadData = useCallback(async () => {
    return await trainingService.getQuizzes(slug!);
  }, [slug]);

  const { loading, data } = useDataLoader(loadData);

  return (
    <TPage loading={loading}>
      <IonList lines="full" className="no-padding no-margin">
        <IonItemDivider sticky>{data?.name} Quizzes</IonItemDivider>
        {util.sortByProp(data?.category_quizes, 'order')?.map((q: any) => (
          <IonItem
            key={q.id}
            routerLink={`/training/${slug}/${q.slug}/`}
            detail
          >
            {q.name}
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default TrainingQuizzesPage;
