import React, { useCallback, useState, useContext } from 'react';
import { TReportsPage } from '../components';
import DataTable from 'react-data-table-component';
import { teamCallReportService } from '../services';
import { AppContext } from '../context/AppContext';

interface LoadDataParams {
  since: Date;
  until: Date;
}

const TeamCallActivity = () => {
  const { state: appState } = useContext(AppContext);
  const { selectedClientId } = appState;
  const [reports, setReports] = useState([]);

  const loadData = useCallback(
    async ({ since, until }: LoadDataParams) => {
      const sinceStr = since.toISOString();
      const untilStr = until.toISOString();
      if (selectedClientId) {
        const data = await teamCallReportService.getTeamCallReport(
          selectedClientId,
          sinceStr,
          untilStr
        );
        setReports(data);
      }
    },
    [selectedClientId]
  );

  const columns = [
    { name: 'User', sortable: true, selector: (row: any) => row.user },
    {
      name: 'Total Outbound',
      sortable: true,
      selector: (row: any) => row.total_outbound,
    },
    {
      name: 'Unique Outbound',
      sortable: true,
      selector: (row: any) => row.unique_outbound,
    },
    {
      name: 'Live Conversations',
      sortable: true,
      selector: (row: any) => row.live_convo,
    },
    {
      name: 'Vehicle Appt Conversations',
      sortable: true,
      selector: (row: any) => row.vehicle_appt_convo,
    },
    {
      name: 'Avg Talk Time',
      sortable: true,
      selector: (row: any) => row.avg_talk_time,
    },
    {
      name: 'Sales Opps Claimed',
      sortable: true,
      selector: (row: any) => row.sales_opps_claimed,
    },
    {
      name: 'Appt Requests',
      sortable: true,
      selector: (row: any) => row.appt_requests,
    },
    {
      name: 'Firm Appointments',
      sortable: true,
      selector: (row: any) => row.appointments_booked_firm,
    },
    {
      name: 'Soft Appointments',
      sortable: true,
      selector: (row: any) => row.appointments_booked_soft,
    },
    {
      name: 'Inbound Calls',
      sortable: true,
      selector: (row: any) => row.inbound_calls,
    },
    /*
    {
      name: 'Inbound Not Connected',
      sortable: true,
      selector: (row: any) => row.inbound_agent_line_not_connected,
    },
    */
  ];

  return (
    <TReportsPage
      onLoadData={loadData}
      downloadData={(data: any) => reports}
      filterProps={{ hideClassification: true }}
      defaultDateRange='This Month'
    >
      {({ state }) => (
        <DataTable
          title='Team Call Report'
          columns={columns}
          data={reports}
          pagination={reports?.length > 50}
          highlightOnHover
        />
      )}
    </TReportsPage>
  );
};

export default TeamCallActivity;
