import React, { useEffect, useRef, useState } from 'react';
import { Popper, PopperProps } from 'react-popper';
import css from 'classnames';
import '../styles/components/TPopper.scss';
import { Modifier } from '@popperjs/core';

export interface TPopperProps extends Partial<PopperProps<Modifier<string, any>>> {
  referenceId?: string;
  component?: JSX.Element;
  content?: JSX.Element;
  isOpen?: boolean;
  noBubble?: boolean;
  onClick?: () => any;
  placement?: any;
  children?: any;
  positionFixed?: any;
}

const TPopper: React.FC<TPopperProps> = ({
  component,
  children,
  content,
  placement = 'right',
  isOpen,
  referenceId,
  onClick,
  noBubble,
  ...rest
}) => {
  const contentRef = useRef<any>();
  const [virtualReference, setVirtualReference] = useState<any>();

  useEffect(() => {
    if (referenceId) {
      contentRef.current = document.getElementById(referenceId) as HTMLElement;
      setVirtualReference({
        getBoundingClientRect() {
          return (
            contentRef.current?.getBoundingClientRect?.() ?? {
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: 0,
              height: 0
            }
          );
        },

        get clientWidth() {
          return contentRef.current?.getBoundingClientRect?.()?.width ?? 0;
        },

        get clientHeight() {
          return contentRef.current?.getBoundingClientRect?.()?.height ?? 0;
        }
      });
    }
  }, [referenceId, isOpen]);

  return (
    <>
      {component && <component.type {...component.props} ref={contentRef} />}
      <Popper
        referenceElement={virtualReference}
        placement={placement}
        {...rest}
      >
        {({ ref, style, placement, arrowProps }) => (
          <div
            onClick={onClick}
            className={css('t-popper', { 'no-bubble': noBubble })}
            ref={ref}
            style={Object.assign(Object.assign({}, style), {
              display: isOpen ? 'block' : 'none'
            })}
            data-placement={placement}
          >
            {!noBubble && <div ref={arrowProps.ref} style={arrowProps.style} />}
            <div className="t-popper-content">{content}</div>
          </div>
        )}
      </Popper>
    </>
  );
};

export default TPopper;
