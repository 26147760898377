import React from 'react';
import {
  IonModal,
  IonTitle,
  IonButton,
  IonContent,
  IonToolbar,
  IonHeader,
  IonFooter,
  IonItemDivider
} from '@ionic/react';
import { modalController } from '@ionic/core';
import '../../styles/components/TModal.scss';
import { haptics } from '../../core';
import TLoading from '../TLoading';

export interface TModalProps
  extends Partial<React.ComponentProps<typeof IonModal>> {
  title?: string;
  footer?: JSX.Element;
  noContent?: boolean;
  toolbar?: JSX.Element;
  headerButton?: any;
  hideClose?: boolean;
  loading?: boolean;
  cssClass?: string;
}

const TModal: React.FC<TModalProps> = ({
  isOpen,
  title,
  footer,
  children,
  cssClass,
  noContent,
  toolbar,
  headerButton,
  hideClose,
  loading,
  ...rest
}) => {
  cssClass = cssClass || '';
  cssClass += ' t-modal';

  if (!isOpen) {
    return null;
  }

  const closeModal = () => {
    haptics.lightImpact();
    modalController.dismiss();
  };

  const presentingElement = (document.querySelector('.t-split-pane') ??
    document.querySelector('.t-page:not(.ion-page-hidden)')) as HTMLElement;

  return (
    <IonModal
      isOpen={true}
      presentingElement={presentingElement}
      canDismiss={true}
      className={cssClass}
      {...rest}
    >
      <IonHeader>
        <IonToolbar>
          {headerButton}
          <IonTitle slot="start">{title}</IonTitle>
          {!hideClose && (
            <IonButton
              slot="end"
              fill="clear"
              color="secondary"
              onClick={closeModal}
            >
              Close
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      {toolbar && (
        <IonItemDivider className="t-modal-toolbar" sticky>
          {toolbar}
        </IonItemDivider>
      )}
      {!noContent && (
        <IonContent fullscreen>
          {loading && (
            <TLoading visible={true} paused={false} absolute={false} />
          )}
          {!loading && children}
        </IonContent>
      )}
      {noContent && children}
      {footer && (
        <IonFooter>
          <IonToolbar>{footer}</IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  );
};

export default TModal;
