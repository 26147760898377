import React, { useCallback } from 'react';
import {
  TReportsPage,
  TItemGroup
} from '../components';
import { commissionsService } from '../services';
import { IonList, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { time } from 'ionicons/icons';

const MyCommissionReportPage: React.FC = () => {
  const loadData = useCallback(async ({ userId, since, until }: any) => {
    return await commissionsService.get(userId, since, until);
  }, []);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true, showAdminFilter: false }}
    >
      {({ state }) => (
        <IonList className="reports no-padding no-margin" lines="full">
            <TItemGroup
              key={`${state.data.first_name}${state.data.last_name}`}
              iconColor={state.data.is_clocked_in ? 'success' : 'danger'}
              icon={time}
              title={`${state.data.first_name} ${state.data.last_name}`}
            >
              <IonGrid className="users-grid">
                <IonRow className="column-header">
                  <IonCol>Set Appts</IonCol>
                  <IonCol>Cancelled Appts</IonCol>
                  <IonCol>Net Appts</IonCol>
                  <IonCol>Commission</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{state.data.total_appointments}</IonCol>
                  <IonCol>{state.data.cancelled_appointments}</IonCol>
                  <IonCol>{state.data.net_appointments}</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${state.data.appt_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Set Appts</IonCol>
                  <IonCol>Cancelled Appts</IonCol>
                  <IonCol>Sold Appts</IonCol>
                  <IonCol>Sold Commission</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{state.data.total_appointments}</IonCol>
                  <IonCol>{state.data.cancelled_appointments}</IonCol>
                  <IonCol>{state.data.sold_appointments}</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${state.data.sold_appt_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Net Appts</IonCol>
                  <IonCol>Touched Leads</IonCol>
                  <IonCol>Conversion %</IonCol>
                  <IonCol>Conversion Bonus</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{state.data.net_appointments}</IonCol>
                  <IonCol>{state.data.touched_leads}</IonCol>
                  <IonCol>{state.data.conversion_percentage}%</IonCol>
                  <IonCol>
                    <IonText
                      className="ion-align-self-end"
                      color="success"
                    >
                      ${state.data.bonus}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Clocked In</IonCol>
                  <IonCol>Total SMS</IonCol>
                  <IonCol>SMS Avg/hr</IonCol>
                  <IonCol>Total</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{state.data.time_clocked_in}</IonCol>
                  <IonCol>{state.data.total_sms}</IonCol>
                  <IonCol>{Math.round(state.data.sms_per_hour)}</IonCol>
                  <IonCol>
                    <IonText color="success">
                      ${state.data.total_commission}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </TItemGroup>
        </IonList>
      )}
    </TReportsPage>
  );
};

export default MyCommissionReportPage;
