import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import {
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonProgressBar,
  IonSpinner
} from '@ionic/react';
import { heart, funnelOutline, heartOutline } from 'ionicons/icons';
import TSearchBar from './TSearchBar';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { util, loadingIndicator } from '../core';
import { InventoryItemModal, TModal } from './modals';
import RippleContainer from './RippleContainer';
import { inventoryService } from '../services';
import HapticButton from './HapticButton';
import InventoryFilters from './InventoryFilters';
import { dispatch } from '../context/AppContext';
import { useDataLoader } from '../hooks';

const PersonalizedLeadInventory: React.FC = () => {
  const searchBar = useRef<any>();
  const [detail, setDetail] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | null | undefined>();
  const [priceMin, setPriceMin] = useState<any>();
  const [priceMax, setPriceMax] = useState<any>();
  const [mileageMin, setMileageMin] = useState<any>();
  const [mileageMax, setMileageMax] = useState<any>();
  const [condition, setCondition] = useState<any>('');
  const [bodyStyle, setBodyStyle] = useState<any>();
  const [ordering, setOrdering] = useState<any>('');
  const [yearName, setYearName] = useState<any>();
  const [makeName, setMakeName] = useState<any>();
  const [exteriorColorName, setExteriorColorName] = useState<any>();
  const [driveTypeName, setDriveTypeName] = useState<any>();
  const [transmissionTypeName, setTransmissionTypeName] = useState<any>();
  const [engineName, setEngineName] = useState<any>();
  const [fuelTypeName, setFuelTypeName] = useState<any>();
  const [modelName, setModelName] = useState<any>();
  const [trimName, setTrimName] = useState<any>();
  const [showFilters, setShowFilters] = useState(false);
  const [likedIds, setLikedIds] = useState<any>([]);
  const { state } = useContext(PersonalizedLeadContext);
  const { lead } = state;

  useEffect(
    () =>
      setLikedIds(
        lead?.inventory_likes?.map((it: any) => it.inventory.id) ?? []
      ),
    [lead]
  );

  const loadData = useCallback(async () => {
    return await inventoryService.searchPublicInventory(lead?.client?.id, {
      search: searchValue,
      pageSize: searchValue ? 20 : 6,
      bodyStyle,
      ordering,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax,
      mileageMin,
      mileageMax,
      condition
    });
  }, [
      searchValue,
      bodyStyle,
      ordering,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax,
      mileageMin,
      mileageMax,
      condition,
      lead
    ]);

  const { data, hasNext, loadNext, loading } = useDataLoader(loadData, true);

  const renderVehicle = (it: any, liked: boolean) => (
    <IonCol
      sizeXl="4"
      sizeLg="4"
      sizeMd="4"
      sizeSm="6"
      sizeXs="12"
      key={it.id}
      className="inventory-column"
      onClick={() => setDetail({ inventory: it })}
    >
      <RippleContainer className="inventory">
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/assets/car-placeholder.jpg";
          }}
          src={it.image}
          alt={util.ymmt(it)}
        />
        <IonIcon
          className="heart-icon"
          color={liked ? 'danger' : 'medium'}
          icon={liked ? heart : heartOutline}
        />
        <div className="vehicle-info">
          <strong>
            {it.year} {it.make}
          </strong>
          <span className="detail">
            {it.model} {it.trim}
          </span>
          <p className="detail vehicle-detail">
            <strong>{it.condition}</strong>
            <span>{inventoryService.getSellingPrice(it.selling_price)}</span>
          </p>
        </div>
      </RippleContainer>
    </IonCol>
  );

  const hasInventoryLikes = useMemo(() => lead?.inventory_likes?.length > 0, [
    lead
  ]);

  const respond = (like: boolean) => async (item: any) => {
    try {
      await loadingIndicator.create();
      const res = await inventoryService.showcaseResponse(
        lead?.client?.id,
        lead?.id,
        item.id,
        like
      );

      if (like) {
        lead.inventory_likes = lead.inventory_likes ?? [];
        lead.inventory_likes.push({ ...res, inventory: item });
        setLikedIds((it: any) => [...it, item.id]);
      } else {
        lead.inventory_likes = lead.inventory_likes?.filter(
          (it: any) => it.inventory?.id !== item.id
        );
        setLikedIds((it: any) => it.filter((id: any) => id === item.id));
      }

      dispatch({ type: 'set', value: { lead } });
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <>
      <IonGrid className="inventory-grid">
        {hasInventoryLikes && (
          <>
            <IonRow>
              <IonCol>
                <h2 className="subtitle">Favorites</h2>
              </IonCol>
            </IonRow>
            <IonRow>
              {lead.inventory_likes.map((it: any) =>
                renderVehicle(it.inventory, true)
              )}
            </IonRow>
          </>
        )}

        <IonRow className="inventory-search">
          <IonCol size="12">
            <TSearchBar
              mode="ios"
              ref={searchBar}
              id="inventory-search-input"
              onSearch={setSearchValue}
              placeholder="Search Vehicles"
            >
              {loading && (
                <IonProgressBar
                  className="searching-indicator"
                  type="indeterminate"
                  color="secondary"
                />
              )}
            </TSearchBar>
            <IonIcon
              icon={funnelOutline}
              onClick={() => setShowFilters(true)}
              className="pointer show-filters"
            />
          </IonCol>
        </IonRow>
        {data && (
          <>
            <IonRow>
              <IonCol>
                <h2 className="subtitle">
                  {searchValue ? 'Search Results' : 'Browse'}
                </h2>
              </IonCol>
            </IonRow>
            <IonRow className="inventory-search-results">
              {data.length > 0 ? (
                data.map((it: any) =>
                  renderVehicle(it, likedIds.indexOf(it.id) > -1)
                )
              ) : (
                <IonCol size="12">
                  <p className="ion-text-center">No Results Found</p>
                </IonCol>
              )}
              {hasNext && (
                <IonCol size="12">
                  <HapticButton expand="block" onClick={loadNext}>
                    {loading && !!data ? <IonSpinner /> : 'Load More'}
                  </HapticButton>
                </IonCol>
              )}
            </IonRow>
          </>
        )}
      </IonGrid>
      {detail && (
        <InventoryItemModal
          isOpen={true}
          clientId={lead?.client?.id}
          leadId={lead?.id}
          leadHash={state.token}
          liked={likedIds.indexOf(detail?.inventory?.id) > -1}
          onLike={respond(true)}
          onDislike={respond(false)}
          onDidDismiss={() => setDetail(null)}
          item={detail}
        />
      )}
      <TModal
        isOpen={showFilters}
        title="Inventory Filters"
        onDidDismiss={() => setShowFilters(false)}
        className="inventory-filters"
      >
        <InventoryFilters
          clientId={lead?.client?.id}
          bodyStyle={bodyStyle}
          ordering={ordering}
          yearName={yearName}
          makeName={makeName}
          exteriorColorName={exteriorColorName}
          driveTypeName={driveTypeName}
          transmissionTypeName={transmissionTypeName}
          engineName={engineName}
          fuelTypeName={fuelTypeName}
          modelName={modelName}
          trimName={trimName}
          condition={condition}
          priceMin={priceMin}
          priceMax={priceMax}
          mileageMin={mileageMin}
          mileageMax={mileageMax}
          onConditionChange={setCondition}
          onPriceMinChange={setPriceMin}
          onPriceMaxChange={setPriceMax}
          onMileageMinChange={setMileageMin}
          onMileageMaxChange={setMileageMax}
          onBodyStyleChange={setBodyStyle}
          onOrderingChange={setOrdering}
          onYearNameChange={setYearName}
          onMakeNameChange={setMakeName}
          onExteriorColorNameChange={setExteriorColorName}
          onDriveTypeNameChange={setDriveTypeName}
          onTransmissionTypeNameChange={setTransmissionTypeName}
          onEngineNameChange={setEngineName}
          onFuelTypeNameChange={setFuelTypeName}
          onModelNameChange={setModelName}
          onTrimNameChange={setTrimName}
        />

      </TModal>
    </>
  );
};

export default PersonalizedLeadInventory;
