import React, { useCallback, useContext, useState } from 'react';
import { TInfiniteScrollPage, TFab } from '../../components';
import { appointmentRemindersService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { IonItem, IonLabel, IonButton, IonIcon, IonThumbnail, IonText } from '@ionic/react';
import { AppointmentReminderFormModal } from '../../components/modals';
import { useDataLoader } from '../../hooks';
import styles from './AppointmentReminders.module.scss';
import { add, ellipsisVertical } from 'ionicons/icons';
import { actionSheet, appNotification } from '../../core';
import moment from 'moment';
import { AppointmentReminder } from '../../types/Appointments';
import { ActionSheetButton } from '../../types/Common';

const AppointmentReminders: React.FC = () => {
  const { state } = useContext(AppContext);

  const defaultConfig = {
    id: 0,
    client: state.selectedClientId,
    minutes_before_appointment: 120,
    order: 1,
    include_ics: false,
    needs_call: false,
    message: 'Hi {{ first_name }}, your appointment at {{ company_name }} is coming up on {{ date }} @ {{ time }}. The address is {{ address }}. Please let me know if you cannot make it. See you then 😀'
  };

  const loadAppointmentReminders = useCallback(async () => {
    return await appointmentRemindersService.list(state.selectedClientId);
  }, [state.selectedClientId]);

  const showMoreActionSheet = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, item: AppointmentReminder) => {
    e.stopPropagation();

    const buttons: ActionSheetButton[] = [
      {
        text: 'Delete',
        handler: () => {
          (async () => {
            await appointmentRemindersService.delete(item.client, item.id);
            refresher();
            appNotification.toast(
              `Appointment reminder has been deleted.`,
              'Deleted'
            );
          })();
          return true;
        }
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ];

    actionSheet.show(buttons);
  };

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadAppointmentReminders,
    true
  );
  const [appointmentReminder, setAppointmentReminder] = useState<AppointmentReminder | undefined>();

  return (
    <TInfiniteScrollPage
      className={styles.AppointmentReminders}
      loading={loading}
      zeroStateText="Tap the green + to auto-remind leads with appointments."
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setAppointmentReminder(item)}
        >
          {item.media && (
            <IonThumbnail slot="start">
              <img src={item.media} alt="Thumbnail" />
            </IonThumbnail>
          )}
          <IonLabel className="ion-multiline">
            <p>{item.message}</p>
            <p className="detail">{!!item.needs_call && (<IonText color="success">Needs call&nbsp;&bull;&nbsp;</IonText>)}Send {moment.duration(item.minutes_before_appointment, 'minutes').humanize()} {item.minutes_before_appointment ? 'before appointment' : 'after appointment set'}.</p>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={(e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
              showMoreActionSheet(e, item)
            }}
          >
            <IonIcon slot="icon-only" icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
      fab={
        <TFab
          icon={add}
          onClick={() => setAppointmentReminder(defaultConfig)}
        />
      }
    >
      {appointmentReminder?.id !== 0 && (
        <AppointmentReminderFormModal
          item={appointmentReminder}
          onDidDismiss={() => { setAppointmentReminder(undefined); refresher(); }}
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default AppointmentReminders;
