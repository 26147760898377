import React, { useState, useMemo, useContext, useEffect } from 'react';
import {
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonModal,
  IonContent,
  IonActionSheet,
  IonButton,
  IonFooter,
} from '@ionic/react';
import { ellipsisVertical, download, eye, close } from 'ionicons/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import moment from 'moment';
import TAvatar from './TAvatar';
import { creditApplicationsService } from '../services';
import { appNotification, native } from '../core';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { modalController } from '@ionic/core';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface CreditApplicationRowProps {
  item: any;
}

const CreditApplicationRow: React.FC<CreditApplicationRowProps> = ({
  item,
}) => {
  const { state } = useContext(AppContext);
  const { user } = state;
  const { id, lead, created_at, cobuyer_lead } = item;
  const { full_name, script, lead_status, assigned_to } = lead;

  const [showModal, setShowModal] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const history = useHistory();

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    if (showModal) {
      const getPdfUrl = async () => {
        try {
          const url = await creditApplicationsService.getCreditAppPdfUrl(id);
          setPdfUrl(url);
        } catch (e) {
          console.error(e);
        }
      };
      getPdfUrl();
    }
  }, [showModal, id]);

  const canViewCreditApps = useMemo(() => {
    return (
      user &&
      (user.is_admin || user.is_client_admin || user.can_view_credit_apps)
    );
  }, [user]);

  const handleViewLead = () => {
    modalController.dismiss();
    history.push(`/text-messages/conversation/${lead.client}/${lead.id}/`);
  };

  const downloadPdf = async () => {
    modalController.dismiss();
    await creditApplicationsService.openApplicationPDF(id);
  };

  return (
    <>
      <IonItem className='ion-activatable pointer'>
        <TAvatar slot='start' alt={full_name} />
        <IonLabel
          onClick={async () =>
            canViewCreditApps
              ? native.isNative ? await downloadPdf() : setShowModal(true)
              : appNotification.alertSimple(
                  'You do not have permission to view credit applications',
                  'Error'
                )
          }
        >
          <IonText className='ion-multiline'>
            <h2>{full_name}</h2>
            <p className='subtitle ion-text-wrap'>
              {cobuyer_lead && `${cobuyer_lead.full_name} (co-buyer) • `}
              {lead_status} • {script} • {assigned_to}
            </p>
            <span className='detail'>{moment(created_at).fromNow()}</span>
          </IonText>
        </IonLabel>
        {canViewCreditApps && (
          <IonIcon
            slot='end'
            icon={ellipsisVertical}
            onClick={() => setShowActionSheet(true)}
          />
        )}
      </IonItem>

      <IonModal
        className='pdfModal'
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
      >
        <IonContent>
          {pdfUrl ? (
            <Document
              file={pdfUrl}
              onLoadSuccess={(dta) => setNumPages(dta.numPages)}
            >
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page
                    key={`pdf-page-${page}`}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={page}
                    width={
                      window.innerWidth > 767
                        ? window.innerWidth * 0.8
                        : window.innerWidth
                    }
                  />
                ))}
            </Document>
          ) : (
            <span>Loading PDF...</span>
          )}
        </IonContent>
        <IonFooter className='pdfModalFooter'>
          <IonButton
            className='pdfModalButton'
            color='primary'
            onClick={async () => downloadPdf()}
          >
            <IonIcon slot='start' icon={download} />
            Download
          </IonButton>
          <IonButton
            className='pdfModalButton'
            color='secondary'
            onClick={handleViewLead}
          >
            <IonIcon slot='start' icon={eye} />
            Go to Lead
          </IonButton>
          <IonButton
            className='pdfModalButton closeButton ion-float-right'
            color='danger'
            onClick={() => modalController.dismiss()}
          >
            <IonIcon slot='start' icon={close} />
            Close
          </IonButton>
        </IonFooter>
      </IonModal>

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          {
            text: 'Go to Lead',
            icon: eye,
            handler: () => {
              handleViewLead();
            },
          },
          {
            text: 'Download Credit Application',
            icon: download,
            handler: async () => {
              await downloadPdf();
            },
          },
        ]}
      />
    </>
  );
};

export default CreditApplicationRow;
