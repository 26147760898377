import React, { useContext } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';
import { NotificationsContext } from '../context/NotificationsContext';
import NotificationRow from './NotificationRow';
import { util } from '../core';

const UnreadNotificationsCard: React.FC = () => {
  const { state } = useContext(NotificationsContext);

  if (!state.unread) {
    return null;
  }

  const arr = state.unreadNotifications?.slice?.(0, 3);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {state.unread} Unread{' '}
          {util.getPluralizedString('Notification', state.unread)}
        </IonCardTitle>
      </IonCardHeader>
      <IonList>
        {arr.map((it: any) => (
          <NotificationRow key={it.id} item={it} noDot />
        ))}
        <IonItem
          lines="none"
          detail={true}
          routerDirection="root"
          routerLink="/notifications/"
        >
          <IonLabel>View All Notifications</IonLabel>
        </IonItem>
      </IonList>
    </IonCard>
  );
};

export default UnreadNotificationsCard;
