import React from 'react';
import { TTextItemProps, default as TTextItem } from './TTextItem';

export interface TNumberItemProps extends Partial<TTextItemProps> {
  number: any;
}

const TNumberItem: React.FC<TNumberItemProps> = ({ number, ...rest }) => {
  return <TTextItem text={number} textFormat="#,#" {...rest} />;
};

export default TNumberItem;
