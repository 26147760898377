import React, { useCallback, useState, useContext } from 'react';
import {
  TTextItem,
  TReportsPage,
  TItemGroup,
  TNumberItem
} from '../components';
import { AppContext } from '../context/AppContext';
import { reportsService, clientEmployeeService } from '../services';
import { IonList, IonGrid, IonRow, IonCol } from '@ionic/react';
import {
  time,
  speedometer,
  mail,
  arrowDown,
  arrowUp,
  laptop
} from 'ionicons/icons';
import { util } from '../core';

const TeamActivityPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const { clientUsers } = appContext.state;
  const [messagesActivity, setMessagesActivity] = useState<any>();
  const loadData = useCallback(async ({ since, until, clientId }: any) => {
    setMessagesActivity(undefined);

    // This report takes a while to load so we lazy load it
    reportsService.retrieveTeamMessagesActivityReport(clientId, since, until).then((res) => {
      setMessagesActivity(res);
    });
    const [app, sms] = await Promise.all([
      reportsService.retrieveTeamActivityReport(clientId, since, until),
      reportsService.retrieveSMSCounts(clientId, since, until, false)
    ]);
    const employeeIds = await clientEmployeeService.list(clientId);
    // filter out admins and staff unless its the TECOBI client ID
    const filteredUsers = clientUsers.filter((it:any) => clientId === 5 ? true : employeeIds.includes(it.id));

    const users = await Promise.all(filteredUsers.map((it: any) => reportsService.retrieveUserReport(clientId, it.id, since, until)));

    return { app, users, sms };
  }, [clientUsers]);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
      downloadData={(data: any) => data.users}
    >
      {({ state }) => (
        <IonList className="reports">
          <TItemGroup title="Phone Calls">
            <TNumberItem
              icon={arrowDown}
              label="Inbound"
              number={state.data?.app?.calls?.inbound}
            />
            <TNumberItem
              icon={arrowUp}
              label="Outbound"
              number={state.data?.app?.calls?.outbound}
            />
            <TTextItem
              icon={time}
              label="Average Talk Time"
              text={util.timeStringToHoursMinutesAndSeconds(
                state.data?.app?.calls?.talk
              )}
            />
          </TItemGroup>
          <TItemGroup title="Text Messages">
            <TTextItem
              icon={speedometer}
              label="Avg. Response Time"
              text={
                state.data?.sms?.avg_response_time
                  ? `${Math.round(
                      state.data?.sms?.avg_response_time / 60
                    )} minutes`
                  : 'N/A'
              }
            />
            <TNumberItem
              icon={mail}
              label="Total"
              loading={!messagesActivity}
              number={messagesActivity?.sms_messages?.total}
            />
            <TNumberItem
              icon={arrowDown}
              label="Inbound"
              loading={!messagesActivity}
              number={messagesActivity?.sms_messages?.inbound}
            />
            <TNumberItem
              icon={arrowUp}
              label="Outbound"
              loading={!messagesActivity}
              number={messagesActivity?.sms_messages?.outbound}
            />
            <TNumberItem
              icon={laptop}
              label="System"
              loading={!messagesActivity}
              number={messagesActivity?.sms_messages?.system}
            />
          </TItemGroup>
          {state?.data?.users?.map?.((it: any) => (
            <TItemGroup title={it.full_name} key={it.id}>
              <IonGrid className="users-grid">
                <IonRow className="column-header">
                  <IonCol>Calls</IonCol>
                  <IonCol>Score</IonCol>
                  <IonCol>Talk</IonCol>
                  <IonCol>Texts</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.total_outbound_calls}</IonCol>
                  <IonCol>{it.average_call_score ?? 'N/A'}</IonCol>
                  <IonCol>
                    {util.timeStringToHoursMinutesAndSeconds(it.talk_average)}
                  </IonCol>
                  <IonCol>{it.total_outbound_sms}</IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Response</IonCol>
                  <IonCol>Appt</IonCol>
                  <IonCol>Show</IonCol>
                  <IonCol>Sold</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {util.timeStringToHoursMinutesAndSeconds(
                      it.average_response_time
                    )}
                  </IonCol>
                  <IonCol>{it.total_appointments}</IonCol>
                  <IonCol>{it.total_shows}</IonCol>
                  <IonCol>{it.sold_appointments}</IonCol>
                </IonRow>
              </IonGrid>
            </TItemGroup>
          ))}
        </IonList>
      )}
    </TReportsPage>
  );
};

export default TeamActivityPage;
