import React, { useContext, useState, useRef } from 'react';
import { IonIcon, IonItem, IonLabel, IonButton } from '@ionic/react';
import { swapHorizontal } from 'ionicons/icons';
import { AppContext } from '../context/AppContext';
import PopoverSelector from './PopoverSelector';
import { TToggleItem } from '../components';
import {
  haptics,
  sentry,
  util,
  loadingIndicator,
  appNotification,
  data as coreData,
  authorization
} from '../core';
import css from 'classnames';
import { useKeyboardShortcuts, useEventListener } from '../hooks';

interface ClientSelectorProps {
  closeMenu: () => void;
}

const ClientSelector: React.FC<ClientSelectorProps> = ({ closeMenu }) => {
  const popover = useRef<any>();
  const switchTimer = useRef<any>();
  const { dispatch, state } = useContext(AppContext);
  const client = state.selectedClient;
  const [showPopover, setShowPopover] = useState(false);

  const canSelect = state.clients?.length > 1;

  const onClientChanged = async (id: number | string) => {
    try {
      //Clear out the timeout once we've received the event
      clearTimeout(switchTimer.current);
      await loadingIndicator.create('Switching Accounts...');
      const data = await coreData.loadClientInfo(id);
      //Set the new data
      dispatch({ type: 'set', value: { ...data, selectedClientId: id } });
      closeMenu();
    } catch (e) {
      appNotification.toast('There was an error switching accounts', 'Error');
      sentry.addBreadcrumb({
        message: 'Switched Client'
      });
      sentry.capture(e as Error);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  useEventListener('client:changed', ({ detail }) => onClientChanged(detail));

  const selectClient = (item: any) => {
    haptics.lightImpact();
    setShowPopover(false);
    authorization.setSelectedClientId(item.id);
    sentry.configure(state.user, item.id);
    //5s timer in case the event doesn't trigger for some reason
    switchTimer.current = setTimeout(() => onClientChanged(item.id), 5000);
  };

  useKeyboardShortcuts({
    'CmdOrCtrl+Shift+Space': () => {
      if (canSelect) {
        setShowPopover(true);
        util.delay(() => popover.current?.searchBar?.setFocus?.(true), 500);
      }
      return true;
    }
  });

  return (
    <>
    {canSelect && (
      <TToggleItem
        lines="none"
        onIonChange={async () => {
          const val = localStorage.getItem('multiClientQueue');
          const value = !!val ? '' : '1';
          localStorage.setItem('multiClientQueue', value);
          // reload
          window.setTimeout(() => window.location.reload(), 500);
        }}
        label="Multi Client Queue Mode"
        checked={!!localStorage.getItem('multiClientQueue')}
        wrapLabel
      />
    )}
    <IonItem lines="none" className={css('client-selector')}>
      <IonLabel>{client?.name}</IonLabel>
      {canSelect && (
        <IonButton
          fill="clear"
          slot="end"
          size="default"
          style={{ marginRight: 5 }}
          onClick={() => {
            if (canSelect) {
              haptics.lightImpact();
              setShowPopover(true);
            }
          }}
        >
          <IonIcon slot="icon-only" icon={swapHorizontal} />
        </IonButton>
      )}
      <PopoverSelector
        ref={popover}
        isOpen={showPopover}
        items={state.clients}
        filter={(it: any) => it.name}
        renderLabel={(it: any) => it.name}
        idField="id"
        onSelectionMade={selectClient}
        placeholder="Search Accounts"
        onDidDismiss={() => setShowPopover(false)}
      />
    </IonItem>
    </>
  );
};

export default ClientSelector;
