import React, { useCallback } from 'react';
import { TTextItem, TReportsPage, TNumberItem } from '../components';
import { reportsService } from '../services';
import { IonList } from '@ionic/react';
import { chatbubble, call, time, speedometer, calendar } from 'ionicons/icons';
import { util } from '../core';

const MyActivityPage: React.FC = () => {
  const loadData = useCallback(
    async ({ since, until, userId, clientId }: any) => {
      const [user_data, user] = await Promise.all([
        reportsService.retrieveMyActivityReport(clientId, since, until, ''),
        reportsService.retrieveSMSCounts(
          clientId,
          since,
          until,
          false,
          '',
          userId
        )
      ]);

      return { user_data, user };
    },
    []
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => (
        <IonList>
          <TNumberItem
            label="Outbound Texts"
            number={state.data?.user_data?.sms}
            icon={chatbubble}
          />
          <TNumberItem
            label="Outbound Calls"
            number={state.data?.user_data?.calls}
            icon={call}
          />
          <TTextItem
            label="Average Talk Time"
            text={util.timeStringToHoursMinutesAndSeconds(
              state.data?.user_data?.talk
            )}
            icon={time}
          />
          <TTextItem
            icon={speedometer}
            label="Average Response Time"
            text={
              state.data?.user?.avg_response_time
                ? `${Math.round(
                    state.data?.user?.avg_response_time / 60
                  )} minutes`
                : 'N/A'
            }
          />
          <TNumberItem
            icon={calendar}
            label="Appointments Set"
            number={state.data?.user_data?.appts}
          />
        </IonList>
      )}
    </TReportsPage>
  );
};

export default MyActivityPage;
