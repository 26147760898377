import { http } from '../core';
import qs from 'qs';
import moment from 'moment';

const convertDates = (s: Date) => {
  // Here we need to convert to UTC month range so we really just need since.
  const since = moment(s);
  return {
    since: moment.utc([since.year(), since.month(), 1]).toISOString(),
    until: moment.utc([since.year(), since.month(), since.endOf('month').date()]).toISOString()
  };
};

class NinjaTeamCommissionsService {

  async retrieveNinjaTeamCommissionsReport(
    from: Date,
    to: Date
  ) {
    try {
      const { since, until } = convertDates(from);
      const params = {
        since,
        until
      } as any;

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/ninja-team-commissions/?${query}`,
        exclusive: true,
        type: 'ninja-team-commissions'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveNinjaTeamOtherCommissionsReport(
    from: Date,
    to: Date
  ) {
    try {
      const { since, until } = convertDates(from);
      const params = {
        since,
        until
      } as any;

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/ninja-team-other-commissions/?${query}`,
        exclusive: true,
        type: 'ninja-team-other-commissions'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const ninjaTeamCommissionsService = new NinjaTeamCommissionsService();
export default ninjaTeamCommissionsService;
