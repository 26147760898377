import React from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import '../styles/components/ButtonGrid.scss';
import css from 'classnames';

export interface ButtonGridProps {
  children: any;
  className?: string;
  columns?: number[];
}

const ButtonGrid: React.FC<ButtonGridProps> = ({
  children,
  className,
  columns
}) => {
  children = Array.isArray(children) ? children : [children];
  return (
    <IonGrid className={css('button-grid', className)}>
      <IonRow>
        {children
          ?.filter?.((it: any) => !!it)
          ?.map?.((it: any, i: number) => (
            <IonCol key={i} size={columns?.[i]?.toString()}>
              {it}
            </IonCol>
          ))}
      </IonRow>
    </IonGrid>
  );
};

export default ButtonGrid;
