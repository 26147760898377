import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { IonButton, IonIcon } from '@ionic/react';
import { personAddOutline } from 'ionicons/icons';
import { haptics } from '../core';

const AddLeadButton: React.FC = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <>
      <IonButton
        onClick={() => {
          haptics.lightImpact();
          dispatch({ type: 'set', value: { showAddLeadModal: true } });
        }}
        style={{ marginRight: '10px' }}
      >
        <IonIcon slot='icon-only' icon={personAddOutline} />
      </IonButton>
    </>
  );
};

export default AddLeadButton;
