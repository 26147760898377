import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
  IonCol,
  IonRow,
  IonGrid
} from '@ionic/react';
import { leadsService } from '../services';
import { AppContext } from '../context/AppContext';
import { MetricCard } from '../components';

const UnassignedLeadsCountCard: React.FC = () => {
  const { state } = useContext(AppContext);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<any>(true);
  const loadData = useCallback(
    async () => {
      const count = await leadsService.getUnassignedCount(state.selectedClientId);
      setData(count);
      setLoading(false);
      return count;
    },
    [state.selectedClientId]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <IonGrid className="reports">
      {!loading && (
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <MetricCard
              title="Unassigned"
              value={data}
              uom="Lead"
            />
          </IonCol>
        </IonRow>
      )}

    </IonGrid>
  );
};

export default UnassignedLeadsCountCard;
