import React, { useState, useEffect, useCallback } from 'react';
import TModal from './TModal';
import { IonList, IonItem, IonLabel, IonBadge } from '@ionic/react';
import { inboundLeadsService } from '../../services';

export interface NinjaTeamClientQueueCountsModalProps {
  clientId: number;
  title: string;
  onDidDismiss: () => any;
}

const NinjaTeamClientQueueCountsModal: React.FC<NinjaTeamClientQueueCountsModalProps> = ({
  clientId,
  title,
  onDidDismiss
}) => {
  const [data, setData] = useState<any>();
  const getData = useCallback(async () => {
    try {
      const res = await inboundLeadsService.getNinjaTeamClientQueueCounts(clientId);
      setData(res);
    } catch (e) {}
  }, [clientId]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TModal
      isOpen={true}
      title={title}
      loading={!data}
      onDidDismiss={onDidDismiss}
    >
      <IonList lines="full">
        {data?.map((it:any , i:number) => (
          <IonItem key={`item-${i}`}>
            <IonLabel>{it?.client_name}</IonLabel>
            <IonBadge color="tertiary" mode="ios">
              {it?.needs_call_count}
            </IonBadge>
            <IonBadge color="secondary" mode="ios">
              {it?.count}
            </IonBadge>
          </IonItem>
        ))}
      </IonList>
    </TModal>
  );
};

export default NinjaTeamClientQueueCountsModal;
