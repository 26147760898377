import React, { useCallback, useState, useContext, useEffect } from 'react';
import { TReportsPage, TChart, TSelectItem } from '../components';
import { reportsService, smsBillingService } from '../services';
import { AppContext } from '../context/AppContext';
import { IonProgressBar } from '@ionic/react';
import { IonList, IonItem, IonLabel, IonBadge } from '@ionic/react';

const SmsDeliveryStatusPage: React.FC = () => {
  const appState = useContext(AppContext).state;
  const { clientNumbers, selectedClientId } = appState;
  const [twilioNumber, setTwilioNumber] = useState('');
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // TODO: This causes a quick "canceled" request with the previous selected client's twilio number
  useEffect(() => {
    setTwilioNumber('');
  }, [selectedClientId]);

  const loadData = useCallback(
    async ({ since, until, clientId }: any) => {
      const [smsDeliveryStatusCounts, smsBilling, smsBillingInbounds] =
        await Promise.all([
          reportsService.retrieveSmsDeliveryReports(clientId, since, until, {
            twilio_number: twilioNumber,
          }),
          smsBillingService.list(
            selectedClientId,
            since,
            until,
            '',
            twilioNumber
          ),
          smsBillingService.list(
            selectedClientId,
            since,
            until,
            'inbound',
            twilioNumber
          ),
        ]);
      console.log('smsDeliveryStatusCounts', smsDeliveryStatusCounts);
      return {
        smsDeliveryStatusCounts,
        smsBilling,
        smsBillingInbounds,
      };
    },
    [twilioNumber, selectedClientId]
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => {
        const totals: any = Object.values(state.data?.smsDeliveryStatusCounts);
        const keys: any = Object.keys(
          state.data?.smsDeliveryStatusCounts
        ).map?.((it: any) => it.split('_count')[0]);
        const { smsBilling, smsBillingInbounds } = state.data;
        const overageCount =
          (smsBilling?.count ?? 0) -
          (smsBilling?.client_package?.included ?? 0);
        const includedRemaining =
          (smsBilling?.client_package?.included ?? 0) -
          (smsBilling?.count ?? 0);
        const overageDisplay = overageCount > 0 ? overageCount : 0;
        return (
          <TChart
            type='pie'
            title='SMS Status Counts'
            data={totals}
            labels={keys}
            legend
          >
            <IonList lines='full' className='reports'>
              <TSelectItem
                label='Phone Number'
                value={twilioNumber}
                onChange={(e: any) => setTwilioNumber(e.detail.value)}
                options={[{ value: '', text: 'All' }].concat(
                  clientNumbers.map((it: any) => ({
                    value: it.id,
                    text: `${it.twilio_number} ${it.script.name}`,
                  }))
                )}
              />
              {Object.entries(state.data?.smsDeliveryStatusCounts).map(
                (it: any) => (
                  <IonItem key={it[0]}>
                    <IonLabel>{capitalize(it[0].split('_')[0])}</IonLabel>
                    <IonBadge mode='ios'>{it[1]?.toLocaleString()}</IonBadge>
                  </IonItem>
                )
              )}
              <IonItem>
                <IonLabel>Inbound SMS</IonLabel>
                <IonBadge mode='ios'>
                  {smsBillingInbounds?.count?.toLocaleString()}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Total SMS</IonLabel>
                <IonBadge
                  mode='ios'
                  color={
                    !!smsBilling?.client_package?.included && overageCount > 0
                      ? 'danger'
                      : 'secondary'
                  }
                >
                  {smsBilling?.count?.toLocaleString()}
                </IonBadge>
              </IonItem>
              {!!smsBilling?.client_package?.overage && (
                <>
                  <IonItem lines='none'>
                    <IonBadge mode='ios' slot='start'>
                      {smsBilling?.count?.toLocaleString()}
                    </IonBadge>
                    <IonProgressBar
                      value={
                        smsBilling?.count / smsBilling?.client_package?.included
                      }
                      color={overageCount > 0 ? 'danger' : 'success'}
                    />
                    <IonBadge mode='ios' slot='end'>
                      {smsBilling?.client_package?.included?.toLocaleString()}
                    </IonBadge>
                  </IonItem>
                  {!!smsBilling?.client_package?.included && (
                    <IonItem
                      lines='none'
                      color={overageCount > 0 ? 'danger' : 'success'}
                    >
                      {overageCount > 0 ? (
                        <>
                          <IonLabel>Overage</IonLabel>
                          <IonBadge mode='ios'>
                            {overageDisplay.toLocaleString()}
                          </IonBadge>
                        </>
                      ) : (
                        <>
                          <IonLabel>Included Remaining</IonLabel>
                          <IonBadge mode='ios'>
                            {includedRemaining?.toLocaleString()}
                          </IonBadge>
                        </>
                      )}
                    </IonItem>
                  )}
                  {overageCount > 0 && (
                    <IonItem color='danger'>
                      <IonLabel>Overage Cost</IonLabel>
                      <IonBadge mode='ios'>
                        {`${overageDisplay?.toLocaleString()} x ${
                          smsBilling?.client_package?.overage
                        } = $${(
                          overageDisplay * smsBilling?.client_package?.overage
                        )
                          ?.toFixed(2)
                          ?.toLocaleString()}`}
                      </IonBadge>
                    </IonItem>
                  )}
                </>
              )}
            </IonList>
          </TChart>
        );
      }}
    </TReportsPage>
  );
};

export default SmsDeliveryStatusPage;
