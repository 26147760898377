import { http } from '../core';

class TemplateSyntaxCheckerService {
  async submit(tpl: string) {
    try {
      const { data } = await http.request({
        method: 'POST',
        url: 'https://template-syntax-checker-4dzvfxebxa-uc.a.run.app/',
        data: {
          tpl
        }
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const templateSyntaxCheckerService = new TemplateSyntaxCheckerService();
export default templateSyntaxCheckerService;
