import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

const useRequireSettingsAdmin = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  if (!user.is_admin && !user.is_settings_admin) {
    window.location.href = '/';
  }
};

export default useRequireSettingsAdmin;
