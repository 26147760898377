import React, { useCallback, useContext, useMemo } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonText
} from '@ionic/react';
import { calendar as calendarIcon } from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { calendar, util } from '../core';
import { appointmentsService } from '../services';
import moment from 'moment';

const PersonalizedLeadAppointment: React.FC = () => {
  const { state } = useContext(PersonalizedLeadContext);
  const { lead, token } = state;
  const client = lead?.client;
  const appointment = useMemo(
    () => appointmentsService.findActive(lead.appointments),
    [lead]
  );

  const ad = useMemo(
    () =>
      moment(appointment?.appointment_time).tz(
        util.formattedTimezone(client?.timezone)
      ),
    [appointment, client]
  );

  const downloadICS = useCallback(async () => {
    await calendar.downloadICS(
      {
        title: 'Purchase New Vehicle',
        duration: { hours: 1, minutes: 0 },
        geo: client?.lat ? { lat: client.lat, lon: client.lng } : undefined,
        start: ad.toDate(),
        status: 'CONFIRMED',
        url: `https://app.tecobi.com/d/${token}/`,
        location: `${client?.address} ${client?.address_2} ${client?.city}, ${client?.state} ${client?.postal_code}`
      },
      `${client?.name}-${appointment?.appointment_time}.ics`
    );
  }, [appointment, ad, client, token]);

  if (state.feature === 'appointment') {
    return null;
  }

  return appointment ? (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Your Appointment</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem lines="none">
          <IonIcon icon={calendarIcon} slot="start" />
          <IonLabel>
            <IonText className="ion-multiline">
              <h3>{ad.format('ddd, MMM D, YYYY')}</h3>
              <strong>{ad.format('h:mma')}</strong>
            </IonText>
          </IonLabel>
        </IonItem>
      </IonCardContent>
      <TButtonItem lines="none" color="secondary" onClick={downloadICS}>
        Add to Calendar
      </TButtonItem>
    </IonCard>
  ) : null;
};

export default PersonalizedLeadAppointment;
