import React, { useCallback, useContext, useState } from 'react';
import { TInfiniteScrollPage, TFab } from '../../components';
import { birthdayFollowupConfigsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { IonItem, IonLabel, IonButton, IonIcon, IonThumbnail, IonText } from '@ionic/react';
import { BirthdayFollowupConfigFormModal } from '../../components/modals';
import { useDataLoader } from '../../hooks';
import styles from './BirthdayFollowupConfigs.module.scss';
import { add, ellipsisVertical } from 'ionicons/icons';
import { actionSheet, appNotification } from '../../core';
const BirthdayFollowupConfigs: React.FC = () => {
  const { state } = useContext(AppContext);

  const defaultConfig = {
    client: state.selectedClientId,
    hours_after_midnight: 10,
    order: 1,
    message: '{{ first_name }}, happy birthday from your friends at {{ company_name }}! Hope you have a great one 🥳🤩🎂🎉!',
    needs_call: false
  };

  const loadBirthdayFollowupConfigs = useCallback(async () => {
    return await birthdayFollowupConfigsService.list(state.selectedClientId);
  }, [state.selectedClientId]);

  const showMoreActionSheet = (e: any, item: any) => {

    e.stopPropagation();

    const buttons = [
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ] as any[];

    buttons.unshift({
      text: 'Delete',
      handler: () => {
        (async () => {
          await birthdayFollowupConfigsService.delete(item.client, item.id);
          refresher();
          appNotification.toast(
            `Cold lead followup has been deleted.`,
            'Deleted'
          );
        })();
        return true;
      }
    });

    actionSheet.show(buttons);
  };

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadBirthdayFollowupConfigs,
    true
  );
  const [birthdayFollowupConfig, setBirthdayFollowupConfig] = useState<any>();

  return (
    <TInfiniteScrollPage
      className={styles.BirthdayFollowupConfigs}
      loading={loading}
      zeroStateText="Tap the green + to auto-followup on cold leads."
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setBirthdayFollowupConfig(item)}
        >
          { item.media && (
            <IonThumbnail slot="start">
              <img src={item.media} alt="Thumbnail" />
            </IonThumbnail>
          )}
          <IonLabel className="ion-multiline">
            <p>{item.message}</p>
            <p className="detail">{!!item.needs_call && (<IonText color="success">Needs call&nbsp;&bull;&nbsp;</IonText>)}Send {item.hours_after_midnight} hours after midnight on birthday.</p>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={(e) => {
                 showMoreActionSheet(e, item)
            }}
          >
            <IonIcon slot="icon-only" icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
      fab={
        <TFab
          icon={add}
          onClick={() => setBirthdayFollowupConfig(defaultConfig)}
        />
      }
    >
    {birthdayFollowupConfig && (
      <BirthdayFollowupConfigFormModal
        item={birthdayFollowupConfig}
        onDidDismiss={() => {setBirthdayFollowupConfig(undefined); refresher();}}
      />
    )}
    </TInfiniteScrollPage>
  );
};

export default BirthdayFollowupConfigs;
