import React, { useCallback } from 'react';
import AddressAutoComplete from 'react-google-autocomplete';

export interface Address {
  streetAddress?: string;
  extendedAddress?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  postalCodeSuffix?: string;
  country?: string;
  formatted?: string;
  county?: string;
  id?: string;
  lat?: number;
  lng?: number;
  name?: string;
}

export interface TAddressSearchProps {
  placeholder?: string;
  onSelected?: (address: Address) => any;

  style?: any;
  value?: any;
  className?: string;

  types?: string[];
}

const TAddressSearch: React.FC<TAddressSearchProps> = ({
  placeholder,
  types,
  style,
  onSelected,
  className,
  value
}) => {
  const getValueByType = (place: any, type: string) =>
    place?.address_components?.find((it: any) => it.types.indexOf(type) > -1);

  const onChange = useCallback(
    (e: any) => {
      if (!e.target.value && value) {
        onSelected?.({});
      }
    },
    [onSelected, value]
  );

  return (
    <AddressAutoComplete
      className={className}
      defaultValue={value}
      fields={[
        'address_components',
        'geometry.location',
        'place_id',
        'formatted_address',
        'name'
      ]}
      onPlaceSelected={(place: any) => {
        const streetAddress = `${getValueByType(place, 'street_number')
          ?.short_name ?? ''} ${getValueByType(place, 'route')?.short_name ??
          ''}`.trim();
        const address: Address = {
          streetAddress,
          extendedAddress: getValueByType(place, 'subpremise')?.long_name ?? '',
          locality: getValueByType(place, 'locality')?.short_name,
          county: getValueByType(place, 'administrative_area_level_2')
            ?.short_name,
          region: getValueByType(place, 'administrative_area_level_1')
            ?.short_name,
          postalCode: getValueByType(place, 'postal_code')?.short_name,
          postalCodeSuffix: getValueByType(place, 'postal_code_suffix')
            ?.short_name,
          country: getValueByType(place, 'country')?.short_name,
          formatted: place?.formatted_address,
          lat: place?.geometry?.location?.lat?.(),
          lng: place?.geometry?.location?.lng?.(),
          id: place?.place_id
        };

        if (Object.values(address).indexOf(place?.name) === -1) {
          address.name = place?.name;
        }

        onSelected?.(address);
      }}
      style={Object.assign(
        {
          appearance: 'none',
          backgroundColor: 'transparent',
          border: 'none',
          display: 'block',
          margin: '10px 0',
          outline: 'none',
          padding: 0,
          width: '100%'
        },
        style ?? {}
      )}
      onBlur={onChange}
      placeholder={placeholder ?? ''}
      options={{
        types: types || ['establishment', 'geocode'],
        componentRestrictions: { country: "us" },
      }}
    />
  );
};

export default TAddressSearch;
