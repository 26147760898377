import React, { useContext } from 'react';
import css from 'classnames';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { IonIcon } from '@ionic/react';
import { call, chatbubble } from 'ionicons/icons';
import HapticButton from './HapticButton';
import { checkListItems } from './PersonalizedLeadChecklist';
import { util } from '../core';

export interface PersonalizedLeadCollapseHeaderProps {
  visible?: boolean;
}

const PersonalizedLeadCollapsedHeader: React.FC<PersonalizedLeadCollapseHeaderProps> = ({
  visible
}) => {
  const { state, dispatch } = useContext(PersonalizedLeadContext);
  const { lead } = state;
  const nextStep = checkListItems.find(it => !it.isComplete(lead, state));

  return (
    <div className={css('personalized-lead-collapsed-header', { visible })}>
      <div className="greeting">
        <strong>Welcome,</strong> <span>{lead?.first_name}!</span>
      </div>
      <div className="quick-actions">
        {nextStep?.action && (
          <HapticButton
            color="secondary"
            onClick={() => nextStep?.action?.(lead, state, dispatch)}
          >
            Next: {nextStep.text}
          </HapticButton>
        )}
        <div className="contact-action">
          <IonIcon
            icon={call}
            className="pointer"
            onClick={() => util.tel(lead?.twilio_number)}
          />
        </div>
        <div className="contact-action">
          <IonIcon
            onClick={() => util.sms(lead?.twilio_number)}
            className="pointer"
            icon={chatbubble}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalizedLeadCollapsedHeader;
