import { http } from '../core';

class RealTimeService {

  async delete(user_id: number, action: string, key: string) {
    try {
      const overallKey = action === 'typing' ? user_id : key;
      const overallAction = action === 'video-meeting' ? 'videoMeeting' : action;
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/remove-real-time-in-progress/`,
        data: { action: overallAction, key: overallKey }
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const realTimeService = new RealTimeService();
export default realTimeService;
