import React from 'react';
import { IonIcon, IonButton } from '@ionic/react';
import { haptics } from '../core';
import css from 'classnames';

export interface ChatToolButtonProps {
  icon?: any;
  title?: string;
  className?: string;
  color?: string;
  fill?: 'default' | 'clear' | 'outline' | 'solid' | undefined;
  action: () => void;
  children?: JSX.Element;
  disabled?: boolean;
}

const ChatToolButton: React.FC<ChatToolButtonProps> = ({
  icon,
  color,
  action,
  title,
  fill,
  children,
  className,
  disabled,
}) => {
  return (
    <IonButton
      className={css('no-padding-button chat-tool', className)}
      fill={fill || 'clear'}
      onClick={() => {
        if (!disabled) {
          haptics.lightImpact();
          action();
        }
      }}
      title={title}
      disabled={disabled}
    >
      {icon && (
        <IonIcon slot='icon-only' title={title} icon={icon} color={color} size="large" />
      )}
      {children}
    </IonButton>
  );
};

export default ChatToolButton;
