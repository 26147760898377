import { http, util, sentry } from '../core';
import qs from 'qs';
import PagedService from './paged-service';
import { liveDB } from '../core';
import { leadsService } from '.';
import moment from 'moment';

class InboundLeadsService extends PagedService {
  private dbref: any = null;
  private teamQueueRef: any = null;
  private teamQueue: boolean = true;
  private servedRef: any = null;
  private retryTimer: any = null;
  inboundWaitTime: number = 0;
  inboundCount: number = 0;
  needsCallCount: number = 0;
  needsTextCount: number = 0;
  async load(clientId?: any) {
    const { results, next, count } = await this.list({ clientId });
    return {
      clientInbound: results,
      clientInboundNext: next,
      clientInboundCount: count,
      needsTextCount: count,
      needsCallCount: 0
    };
  }

  async list({ clientId, user, search, pageSize, claimLead }: any) {
    const multiClientQueue = typeof localStorage == 'object' ? !!localStorage.getItem('multiClientQueue') : false;
    try {
      const params = {} as any;

      if (claimLead) {
        params.claim_lead = claimLead;
      }

      if (user) {
        params.user = user;
      }

      if (search) {
        params.search = search;
      }

      if (pageSize) {
        params.page_size = pageSize;
      }

      const sorting = localStorage.getItem('leadSorting') ?? 'Newest First';

      if (sorting) {
        params.ordering =
          sorting === 'Newest First' ? '-last_message' : 'last_message';
      }

      const query = qs.stringify(params);
      const url = multiClientQueue ? `/multi-client-inbound-queue/?${query}` : `/clients/${clientId}/inbound-queue/?${query}`
      const res = await http.authorizedRequest({
        method: 'GET',
        url,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getNeedsCallCount() {
    if (!this.dbref) {
      return 0;
    }
    return new Promise<number>(resolve => {
      this.dbref.once('value', (snap: any) =>
        resolve(Object.values(snap.val() ?? {}).filter((it: any) => it.needs_call && it.client_phone_ninja).length)
      );
    });
  }

  async getInboundLeadsCount(clientId?: any) {
    if (clientId) {
      const { count } = await this.list({ clientId, pageSize: 1 });
      return count;
    }

    if (!this.dbref) {
      return 0;
    }

    return new Promise<number>(resolve => {
      this.dbref.once('value', (snap: any) =>
        resolve(Object.keys(snap.val() ?? {}).length)
      );
    });
  }

  async getInboundWaitTime(): Promise<number> {
    if (!this.dbref) {
      return 0;
    }

    return new Promise(resolve => {
      this.dbref.once('value', (snap: any) => {
        const item = util.sortByProp(
          Object.values(snap.val() ?? {}),
          'last_message'
        )?.[0];

        if (!item) {
          return resolve(0);
        }

        const duration = moment().diff(moment(item.last_message), 'minutes');
        resolve(duration);
      });
    });
  }
  async getNinjaTeamQueueCounts() {
    try {
      const res = await http.authorizedRequest({
        method: 'GET',
        url: '/ninja-team-queue-counts/'
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getNinjaTeamClientQueueCounts(teamId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/ninja-team/${teamId}/client-queue-counts/`
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getNinjaTeamNeedsCallQueueCounts() {
    try {
      const res = await http.authorizedRequest({
        method: 'GET',
        url: '/ninja-team-needs-call-queue-counts/'
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async getClientCounts() {
    try {
      const params = {} as any;
      const query = qs.stringify(params);
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/blast-offenders/?${query}`
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async loadCount() {
    const inboundCount: number = await this.getInboundLeadsCount();
    const needsCallCount: number = await this.getNeedsCallCount();
    const needsTextCount: number =  inboundCount - needsCallCount;
    return {
      inboundCount,
      needsCallCount,
      needsTextCount
    };
  }

  async loadWaitTime() {
    return {
      inboundWait: await this.getInboundWaitTime()
    };
  }

  async getNextInboundLead(user: any, clientId?: any, needsCall?: boolean): Promise<any> {
    const sortByField = needsCall ? 'needs_call_since' : 'last_message';
    clearTimeout(this.retryTimer);
    try {
      if (clientId) {
        const client = await this.list({ clientId, pageSize: 1, claimLead: true });
        const result = client?.results?.[0];
        return result
          ? { id: result.id, client_id: result.get_client_info?.id }
          : null;
      }

      if (this.dbref && user.is_clocked_in && user.is_staff) {
        const userSpeaksSpanish = user.languages.some(
          (it: any) => it.code === 'es'
        );

        return new Promise(resolve => {
          const retry = () => {
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(
              async () => resolve(await this.getNextInboundLead(user, clientId, needsCall)),
              1500
            );
          };

          this.dbref.once('value', async (snap: any) => {
            try {
              const val = snap.val();
              const filteredValues: any = Object.values(val ?? {}).filter((it: any) => {
                // Only serve leads from clients the user has access to.
                const clientCheck = user.clients?.includes(it.client_id);
                const teamCheck = this.teamQueue ? user.ninja_teams?.includes(it.client_ninja_team_id) : true;
                const needsCallCheck = needsCall ? (!!it.needs_call && it.client_phone_ninja) : it.last_message_type === 'inbound' && (!it.needs_call || !it.client_phone_ninja);
                return clientCheck && teamCheck && needsCallCheck;
              })
              const values = util.sortByProp(
                filteredValues,
                sortByField
              )
              // Create 2 lists 1 with clients that have phone ninja turned on, and the other with PN's off
              /*
              const phoneNinjaClientLeads: any = filteredValues.filter((it: any) => it.client_phone_ninja);
              const nonPhoneNinjaClientLeads: any = filteredValues.filter((it: any) => !it.client_phone_ninja);
              // sort each list by oldest to newest
              const pnValues = util.sortByProp(
                phoneNinjaClientLeads,
                sortByField
              )
              const nonPnValues = util.sortByProp(
                nonPhoneNinjaClientLeads,
                sortByField
              )

              // Combine the 2 lists with PN clients first
              const values = pnValues.concat(nonPnValues);
              */
              if (values.length === 0) {
                return retry();
              }

              const lead = userSpeaksSpanish
                ? values.find((it: any) => it.language === 'es') ?? values[0]
                : values[0];

              this.dbref.child(lead.id).remove();

              this.servedRef.child(lead.id).once('value', async (snap: any) => {
                try {
                  const val = snap.val();
                  const overLimit = val && Date.now() - val.ts > 900000; //15 minutes
                  if (
                    !val ||
                    lead.last_message !== val.lm ||
                    user.id === val.uid ||
                    overLimit
                  ) {
                    const served = await leadsService.update(
                      {
                        client: lead.client_id,
                        id: lead.id,
                        served_to: user.id,
                        served_at: new Date().toISOString()
                      },
                      { noToast: true }
                    );

                    if (served.served_to === user.id) {
                      this.servedRef.child(lead.id).set({
                        ts: Date.now(),
                        lm: lead.last_message,
                        uid: user.id
                      });
                      clearTimeout(this.retryTimer);
                      return resolve(lead);
                    }
                  }
                  return retry();
                } catch (e) {
                  sentry.capture(e as Error);
                  return retry();
                }
              });
            } catch (e) {
              sentry.capture(e as Error);
              return retry();
            }
          });
        });
      }
    } catch (e) {
      http.onHttpError(e);
    }
  }

  removeServedLead(leadId: number) {
    if (leadId) {
      try {
        this.servedRef.child(leadId).remove();
        console.info('Removed last served lead', leadId);
      } catch (e) {
        console.error(e);
        sentry.capture(e as Error);
      }
    }
  }

  private async updateMetrics() {
    const [inboundCount, needsCallCount, inboundWait] = await Promise.all([
      this.getInboundLeadsCount(),
      this.getNeedsCallCount(),
      this.getInboundWaitTime()
    ]);

    this.inboundCount = inboundCount;
    this.needsCallCount = needsCallCount;
    this.needsTextCount = inboundCount - needsCallCount;
    this.inboundWaitTime = inboundWait;
    window.dispatchEvent(new CustomEvent('inbound:metrics'));
  }

  private refreshMetricsTimer() {
    setInterval(() => this.updateMetrics(), 10000);
  }

  private initializeTeamQueueRef() {
    this.teamQueueRef = liveDB.useReference('/config/teamQueue/');
    // Attach an asynchronous callback to read the data at our posts reference
    this.teamQueueRef.on('value', (snapshot: any) => {
      const teamQueueVal = snapshot.val();
      if (teamQueueVal !== this.teamQueue) {
        this.teamQueue = teamQueueVal;
        window.dispatchEvent(new CustomEvent('inbound:teamQueue'));
      }
    }, (errorObject: any) => {
      console.log('The read failed: ' + errorObject.name);
    });
  }

  async initialize(user: any) {
    this.dbref = liveDB.useReference('/ninja/queue/');
    this.servedRef = liveDB.useReference('/ninja/served/');
    this.initializeTeamQueueRef();
    await this.updateMetrics();
    this.refreshMetricsTimer();
  }
}

const inboundLeadsService = new InboundLeadsService();
export default inboundLeadsService;
