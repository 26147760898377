import { toastController, alertController, AlertOptions } from '@ionic/core';

class AppNotification {
  async toast(message: string, header?: string, options: any = {}) {
    try {
      await toastController.dismiss();
    } catch (e) {}

    const t = await toastController.create(
      Object.assign(
        {
          position: 'top',
          header,
          message,
          duration: 5000,
          buttons: [{ text: 'Dismiss', role: 'cancel' }],
        },
        options
      )
    );
    return t.present();
  }

  async alertSimple(message: string, header?: string, subHeader?: string) {
    return await this.alert({
      message,
      header,
      subHeader,
      buttons: ['OK']
    });
  }

  async alert(alert: AlertOptions) {
    const a = await alertController.create(alert);
    return a.present();
  }
}

export interface GlobalNotification {
  title: string;
  message: string;
}

const appNotification = new AppNotification();
export default appNotification;
