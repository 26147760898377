import React, { useState, useRef, useContext, useEffect } from 'react';
import moment from 'moment';
import {
  IonItem,
  IonLabel,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonIcon,
  IonCheckbox
} from '@ionic/react';
import { ellipsisVertical } from 'ionicons/icons';
import pushNotifications from '../services/push-notifications';
import { http, util, native, actionSheet } from '../core';
import '../styles/components/NotificationRow.scss';
import css from 'classnames';
import { NotificationsContext } from '../context/NotificationsContext';
import { userService } from '../services';
import { AppContext } from '../context/AppContext';

export interface NotificationRowProps {
  item: any;
  noDot?: boolean;
}

const NotificationRow: React.FC<NotificationRowProps> = ({ item, noDot }) => {
  const { state, onItemRemoved, onItemRead, dispatch } = useContext(
    NotificationsContext
  );

  const appContext = useContext(AppContext);
  const { user } = appContext.state;

  const slider = useRef<any>();
  const [removing, setRemoving] = useState(false);
  const unread = !item.read;
  const { data, created_at, id } = item;
  const { title, body, client, lead } = data;

  let created;
  try {
    created = moment(created_at).fromNow();
  } catch (e) {}

  const closeSlider = () => {
    if (slider.current) {
      slider.current.close();
    }
  };

  useEffect(() => {
    if (!item.viewed && !!slider.current?.offsetParent) {
      item.viewed = new Date().toISOString();
      pushNotifications.markViewed(item);
    }
  }, [slider, item]);

  const markAsRead = async () => {
    try {
      closeSlider();
      await pushNotifications.markRead([id]);
      item.read = new Date().toISOString();
      onItemRead(id);
      return true;
    } catch (e) {
      http.onHttpError(e);
      return false;
    }
  };

  const removeNotification = async () => {
    try {
      setRemoving(true);
      item.cleared = new Date().toISOString();
      await pushNotifications.patchNotification(id, item);
      util.delay(() => onItemRemoved(item.id), 500);
      return true;
    } catch (e) {
      http.onHttpError(e);
      return false;
    }
  };

  const actionSheetButtons = [
    {
      text: 'Remove',
      role: 'destructive',
      handler: removeNotification
    },
    {
      text: 'Cancel',
      role: 'cancel'
    }
  ];

  if (unread) {
    actionSheetButtons.unshift({
      text: 'Mark as Read',
      role: '',
      handler: markAsRead
    });
  }

  let routerLink: any;

  if (!state.editing && lead) {
    routerLink = `/${
      userService.isNinja(user) ? 'inbound-queue' : 'text-messages'
    }/conversation/${client}/${lead}`;
  }

  return (
    <IonItemSliding
      className={css('notification-row-slider', {
        rowCollapse: removing
      })}
      disabled={state.editing}
      ref={slider}
    >
      {unread && (
        <IonItemOptions side="start" onIonSwipe={markAsRead}>
          <IonItemOption expandable color="secondary" onClick={markAsRead}>
            Mark as Read
          </IonItemOption>
        </IonItemOptions>
      )}
      <IonItem
        routerLink={routerLink}
        onClick={() => {
          if (routerLink && unread) {
            markAsRead();
          }
        }}
        className={css('notifications-row', { unread })}
      >
        {state.editing && (
          <IonCheckbox
            slot="start"
            onIonChange={() => {
              const index = state.selection.indexOf(id);
              let selection: number[];
              if (index > -1) {
                selection = state.selection.filter((it: number) => it !== id);
              } else {
                selection = [...state.selection, id];
              }
              dispatch({
                type: 'set',
                value: {
                  selection
                }
              });
            }}
          />
        )}
        {!noDot && (
          <div slot="start" className={css('unread-dot', { unread })} />
        )}
        <IonLabel class="ion-text-wrap">
          <h2>{title}</h2>
          <p>{body}</p>
          {created && <span className="detail">{created}</span>}
        </IonLabel>
        {!native.isNative && (
          <IonIcon
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              actionSheet.show(actionSheetButtons);
            }}
            slot="end"
            icon={ellipsisVertical}
          />
        )}
      </IonItem>
      <IonItemOptions side="end" onIonSwipe={removeNotification}>
        <IonItemOption expandable color="danger" onClick={removeNotification}>
          Remove
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default NotificationRow;
