import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
  IonCol,
  IonRow,
  IonGrid
} from '@ionic/react';
import moment from 'moment';
import { needsCallService } from '../services';
import { AppContext } from '../context/AppContext';
import { MetricCard } from '../components';

const TimeToCallCard: React.FC = () => {
  const { state } = useContext(AppContext);
  const [data, setData] = useState<any>();
  const loadData = useCallback(
    async () => {
      const requests:Array<any> = [
        needsCallService.retrieveReport(
          moment()
            .startOf('day').toDate(),
          moment()
            .endOf('day').toDate(),
          state.selectedClientId,
          false,
          false
        ),
        needsCallService.retrieveReport(
          moment()
            .startOf('month').toDate(),
          moment()
            .endOf('month').toDate(),
          state.selectedClientId,
          false
        )
      ];

      const reports = await Promise.all(requests);
      const needsCallReports:Array<any> = [];
      reports.forEach((it:any) => {
        needsCallReports.push(needsCallService.aggregateReport(it, state.clients));
      });
      setData(needsCallReports);
      return needsCallReports;
    },
    [state.selectedClientId, state.clients]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <IonGrid className="reports">
      {!!data && (
        <IonRow>
          {data?.length > 0 && (
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <MetricCard
                title="Avg Needs Call Wait"
                value={new Date(data?.[0]?.avgWaitDuraton * 1000).toISOString().substr(11, 8)}
                uom="Today"
              />
          </IonCol>
          )}

          {data?.length > 1 && (
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <MetricCard
              title="Avg Needs Call Wait"
              value={new Date(data?.[1]?.avgWaitDuraton * 1000).toISOString().substr(11, 8)}
              uom="MTD"
            />
          </IonCol>
          )}
        </IonRow>
      )}

    </IonGrid>
  );
};

export default TimeToCallCard;
