import React from 'react';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';

export interface TBannerProps {
  icon?: any;
  color?: string;
  children?: any;
}

const TBanner: React.FC<TBannerProps> = ({
  icon,
  color = 'warning',
  children
}) => {
  return (
    <IonItem color={color} lines="none">
      {icon && <IonIcon icon={icon} slot="start" />}
      <IonLabel style={{ textAlign: 'center' }}>{children}</IonLabel>
    </IonItem>
  );
};

export default TBanner;
