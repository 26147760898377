import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TPage, ChatBubble } from '../components';
import { useLiveDBRef, useRequireAdmin } from '../hooks';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import { AppContext } from '../context/AppContext';

const DebugPage: React.FC = () => {
  useRequireAdmin();
  const appContext = useContext(AppContext);
  const { clientUsers } = appContext.state;
  const [leads, setLeads] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>({});

  useEffect(() => {
    setProfiles(
      clientUsers.reduce((obj: any, it: any) => {
        obj[it.id] = it;
        return obj;
      }, {})
    );
  }, [clientUsers]);

  const onLeads = useCallback((val: any) => {
    const typing = Object.values(val).reduce((obj: any, it: any) => {
      return { ...obj, ...(it['typing-users'] ?? {}) };
    }, {});

    setLeads(typing);
  }, []);

  const onClockedIn = useCallback((val: any) => {
    const u = [];
    for (var k in val) {
      if (val[k] === true) {
        u.push(parseInt(k));
      }
    }

    setUsers(u);
  }, []);

  useLiveDBRef('/leads/', onLeads);
  useLiveDBRef('/users-clocked-in/', onClockedIn);

  return (
    <TPage loading={false}>
      <IonList lines="full" style={{ minHeight: '100%' }}>
        {users?.map((u: string) => {
          const typing = leads[u];
          const user = profiles[u];
          return (
            <IonItem key={u}>
              <IonLabel>{user?.full_name ?? u}</IonLabel>
              <IonLabel>
                {typing && (
                  <ChatBubble
                    key={typing.uid}
                    id={typing.uid}
                    yellow={typing.internalOnly}
                    internal={typing.internalOnly}
                    sender={typing.fullName}
                    outgoing
                    typing
                    message={typing.messagePreview}
                  />
                )}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </TPage>
  );
};

export default DebugPage;
