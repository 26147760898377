import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route } from 'react-router-dom';
import { LeadContextProvider } from './context/LeadContext';
import {
  TrainingQuiz,
} from './pages';
import { TrainingContextProvider } from './context/TrainingContext';

export interface AuthorizedForcedTrainingViewProps {
  initialState: any;
}

const Router: React.FC = () => {
  return (
    <IonRouterOutlet id="main">
      <Route exact component={TrainingQuiz} />
    </IonRouterOutlet>
  );
};


const AuthorizedForcedTrainingView: React.FC<AuthorizedForcedTrainingViewProps> = ({ initialState }) => {

  return (

      <LeadContextProvider>
            <TrainingContextProvider>
                {<Router />}
            </TrainingContextProvider>
      </LeadContextProvider>
  );
};

export default AuthorizedForcedTrainingView;
