import React, { useState, useContext, useEffect } from 'react';
import { IonPopover, IonList, IonButton, IonIcon } from '@ionic/react';
import TSelectItem from './TSelectItem';
import ButtonGrid from './ButtonGrid';
import { briefcase, checkmarkCircleOutline, person } from 'ionicons/icons';
import '../styles/components/AppointmentFilters.scss';
import { popoverController } from '@ionic/core';
import { AppContext } from '../context/AppContext';
import { clientProvidersService } from '../services';

export interface AppointmentFiltersProps {
  isOpen: boolean;
  onDidDismiss?: () => any;
  onApply?: (filters: any) => any;
}

const AppointmentFilters: React.FC<AppointmentFiltersProps> = ({
  isOpen,
  onDidDismiss,
  onApply,
}) => {
  const { state } = useContext(AppContext);
  const { user, selectedClientId, clientUsers } = state;
  const [classification, setClassification] = useState('All');
  const [status, setStatus] = useState('All');
  const [assignedTo, setAssignedTo] = useState<number>(-1);
  const [leadProvider, setLeadProvider] = useState<number>(-1);
  const [leadProviderOptions, setLeadProviderOptions] = useState([]);

  const getLeadProviders = async () => {
    const providers = await clientProvidersService.request(selectedClientId);
    setLeadProviderOptions(providers);
  }

  useEffect(() => {
    getLeadProviders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IonPopover
      isOpen={isOpen}
      mode="md"
      onDidDismiss={onDidDismiss}
      className="appointment-filters"
    >
      <IonList lines="full">
        <TSelectItem
          label="Type"
          value={classification}
          onChange={(e: any) => setClassification(e.detail.value)}
          options={['All', 'Sales', 'Service', 'Employment', 'Collections']}
        >
          <IonIcon slot="start" icon={briefcase} className="ion-hide-md-down" />
        </TSelectItem>
        <TSelectItem
          label="Appointment Status"
          value={status}
          onChange={(e: any) => setStatus(e.detail.value)}
          options={['All', 'Pending', 'Missed', 'Cancelled', 'Showed', 'Sold']}
        >
          <IonIcon
            slot="start"
            icon={checkmarkCircleOutline}
            className="ion-hide-md-down"
          />
        </TSelectItem>
        <TSelectItem
          label="Lead Provider"
          value={leadProvider}
          onChange={(e: any) => setLeadProvider(e.detail.value)}
          options={[
            { value: -1, text: 'All' },
            ...leadProviderOptions.map((it: any) => ({ value: it.id, text: it.name }))
          ]}
        >
          <IonIcon
            slot="start"
            icon={person}
            className="ion-hide-md-down"
          />
        </TSelectItem>
        {(user.is_admin || user.is_client_admin) &&
          <TSelectItem
            label="Assigned To"
            value={assignedTo}
            onChange={(e: any) => setAssignedTo(e.detail.value)}
            options={[
              { value: -1, text: 'All' },
              ...clientUsers.map((it: any) => ({ value: it.id, text: it.full_name }))
            ]}
          >
            <IonIcon
              slot="start"
              icon={person}
              className="ion-hide-md-down"
            />
          </TSelectItem>
        }
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              popoverController.dismiss();
              setStatus('All');
              setClassification('All');
              setAssignedTo(-1);
              setLeadProvider(-1)
              onApply?.({});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              popoverController.dismiss();
              const filterParams: any = {
                status: status?.toLowerCase?.(),
                lead__classification: classification,
              }
              if (leadProvider > 0) {
                filterParams.lead__master_provider = leadProvider
              }
              if (assignedTo > 0) {
                filterParams.lead__assigned_to = assignedTo
              }
              onApply?.(filterParams);
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      </IonList>
    </IonPopover>
  );
};

export default AppointmentFilters;
