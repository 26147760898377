import React from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { volumeOff } from 'ionicons/icons';
import css from 'classnames';

export interface AudioNotAvailableProps {
  background?: boolean;
}

const AudioNotAvailable: React.FC<AudioNotAvailableProps> = ({
  background
}) => {
  return (
    <IonItem
      className={css('no-audio-available', { 'audio-background': background })}
      lines="none"
    >
      <IonIcon icon={volumeOff} />
      <IonLabel style={{ marginLeft: 5 }}>No Audio Available</IonLabel>
    </IonItem>
  );
};

export default AudioNotAvailable;
