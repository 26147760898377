import React, { useState, useContext } from 'react';
import '../../styles/components/ReportFilters.scss';
import TModal from './TModal';
import { TToggleItem } from '../../components';
import { IonList, IonButton } from '@ionic/react';
import TDateTimeItem from '../TDateTimeItem';
import TSelectItem from '../TSelectItem';
import ButtonGrid from '../ButtonGrid';
import { briefcase, construct, paperPlane, documentText, people, calendar, business, locate, call, ribbon } from 'ionicons/icons';
import { AppContext } from '../../context/AppContext';
import { statusesService } from '../../services';
import { modalController } from '@ionic/core';
import { haptics } from '../../core';

export interface ReportFiltersProps {
  onDidDismiss: () => void;
  hideClassification?: boolean;
  showAdminFilter?: boolean;
  showStaffFilter?: boolean;
  showTopNinjaFilter?: boolean;
  showContractorFilter?: boolean;
  showPhoneNinjaFilter?: boolean;
  showNinjaTeamsFilter?: boolean;
  showLeadProvider?: boolean;
  showExcludeSaleAppointments?: boolean;
  showNewLeadsOnly?: boolean;
  showMultiClientSelector?: boolean;
  showSelectedClientOnlyToggle?: boolean;
  showPipelineStatuses?: boolean;
  initialSelectedClients?: Array<any>;
  state: any;
  dispatch: any;
}

const ReportFilters: React.FC<ReportFiltersProps> = ({
  onDidDismiss,
  hideClassification,
  showLeadProvider,
  showExcludeSaleAppointments,
  showNewLeadsOnly,
  showMultiClientSelector,
  showSelectedClientOnlyToggle,
  showPipelineStatuses,
  initialSelectedClients,
  showAdminFilter,
  showStaffFilter,
  showTopNinjaFilter,
  showContractorFilter,
  showPhoneNinjaFilter,
  showNinjaTeamsFilter,
  state,
  dispatch
}) => {
  const { state: appState } = useContext(AppContext);
  const {
    since,
    until,
    classification,
    dateRange,
    admins,
    staff,
    topNinja,
    contractors,
    isPhoneNinja,
    ninjaTeams,
    leadProvider,
    newLeadsOnly,
    excludeSaleAppointments,
    clientIds,
    showSelectedClientOnly,
    pipelineStatusIds,
  } = state;
  const [changes, setChanges] = useState({
    since,
    until,
    classification,
    dateRange,
    admins,
    staff,
    topNinja,
    contractors,
    isPhoneNinja,
    ninjaTeams,
    leadProvider,
    newLeadsOnly,
    excludeSaleAppointments,
    clientIds,
    showSelectedClientOnly,
    pipelineStatusIds,
  });

  const [fromValue, setFromValue] = useState<string>(changes.since);
  const [toValue, setToValue] = useState<string>(changes.until);

  const updateFilter = (key: string, e: any) => {
    const value = e.detail.checked ?? e.detail.value;
    const updates = { [key]: value };
    if (['since', 'until'].indexOf(key) > -1) {
      updates.dateRange = '';
    }
    if (key === 'since') {
      setFromValue(value);
    }
    if (key === 'until') {
      setToValue(value);
    }
    setChanges((c: any) => Object.assign(c, updates));
  };

  const apply = () => {
    haptics.lightImpact();
    modalController.dismiss();
    dispatch(changes);
    onDidDismiss();
  };

  return (
    <TModal
      className="report-filters"
      isOpen={true}
      title="Filters"
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton color="secondary" expand="block" onClick={apply}>
            Apply
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full">
        <TDateTimeItem
          label="Dates"
          lines="full"
          fromValue={fromValue}
          toValue={toValue}
          onFromIonChange={(e: any) => updateFilter('since', e)}
          onToIonChange={(e: any) => updateFilter('until', e)}
          range
        />
        {!hideClassification && (
          <TSelectItem
            icon={briefcase}
            label="Classification"
            options={['All', 'Sales', 'Service', 'Employment', 'Collections']}
            value={changes.classification}
            onChange={(e: any) => updateFilter('classification', e)}
          />
        )}

        {showLeadProvider && (
          <TSelectItem
            label="Lead Provider"
            value={changes?.leadProvider ?? 'All'}
            onChange={(e: any) => updateFilter('leadProvider', e)}
            options={[
              { value: undefined, text: 'All' },
              ...appState.clientProviders?.map?.(it => ({
                value: it.id,
                text: it.name
              }))
            ]}
            icon={paperPlane}
          />
        )}
        {showExcludeSaleAppointments && (
          <TSelectItem
            label="Exclude Sold-only Appointments"
            value={changes?.excludeSaleAppointments ?? 'All'}
            onChange={(e: any) => updateFilter('excludeSaleAppointments', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'Exclude' },
            ]}
            icon={calendar}
          />
        )}
        {showNewLeadsOnly && (
          <TSelectItem
            label="Leads"
            value={changes?.newLeadsOnly ?? 'All'}
            onChange={(e: any) => updateFilter('newLeadsOnly', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'New Leads' },
            ]}
            icon={people}
          />
        )}
        {showAdminFilter && (
          <TSelectItem
            label="Admins"
            value={changes?.admins ?? false}
            onChange={(e: any) => updateFilter('admins', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'Admins Only' },
              { value: false, text: 'Exclude Admins' },
            ]}
            icon={construct}
          />
        )}

        {showStaffFilter && (
          <TSelectItem
            label="Tecobi Staff"
            value={changes?.staff}
            onChange={(e: any) => updateFilter('staff', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'Staff Only' },
              { value: false, text: 'Exclude Staff' },
            ]}
            icon={construct}
          />
        )}
        {showTopNinjaFilter && (
          <TSelectItem
            label="Top Ninja"
            value={changes?.topNinja ?? false}
            onChange={(e: any) => updateFilter('topNinja', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'Has top ninja' },
              { value: false, text: 'No top ninja' },
            ]}
            icon={ribbon}
          />
        )}

        {showPipelineStatuses && (
          <TSelectItem
            label="Pipeline Statuses"
            multiple={true}
            value={changes.pipelineStatusIds}
            onChange={(e: any) => updateFilter('pipelineStatusIds', e)}
            icon={locate}
            options={appState.statuses
              ?.filter?.(
                (it: any) =>
                  statusesService.ineligibleBlastStatuses.indexOf(it.id) === -1
              )
              .map?.(it => ({
                value: it.id,
                text: it.option_name
              }))}
          />
        )}
        {showSelectedClientOnlyToggle && (
          <TToggleItem
            onIonChange={(e: any) => updateFilter('showSelectedClientOnly', e)}
            label="Selected Client Only"
            checked={!!changes.showSelectedClientOnly}
            icon={business}
            wrapLabel
          />
        )}
        {showMultiClientSelector && (
          <TSelectItem
            label="Clients"
            multiple={true}
            value={changes.clientIds}
            onChange={(e: any) => {
              return updateFilter('clientIds', e)
            }}
            options={[
              ...appState.clients?.map?.(it => ({
                value: it.id,
                text: it.name
              }))
            ]}
            icon={business}
          />
        )}
        {showPhoneNinjaFilter && (
          <TSelectItem
            label="Phone Ninjas"
            value={changes?.isPhoneNinja ?? 'All'}
            onChange={(e: any) => updateFilter('isPhoneNinja', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: 'PNs Only' },
              { value: false, text: 'Exclude PNs' },
            ]}
            icon={call}
          />
        )}

        {showNinjaTeamsFilter && (
          <TSelectItem
            label="Ninja Teams"
            placeholder="All"
            multiple={true}
            value={changes.ninjaTeams}
            onChange={(e: any) => updateFilter('ninjaTeams', e)}
            icon={people}
            options={appState.ninjaTeams
              ?.map?.(it => ({
                value: it.id,
                text: it.name
              }))}
          />
        )}

        {showContractorFilter && (
          <TSelectItem
            label="1099 Contractors"
            value={changes?.contractors ?? 'All'}
            onChange={(e: any) => updateFilter('contractors', e)}
            options={[
              { value: undefined, text: 'All' },
              { value: true, text: '1099 Contractors' },
              { value: false, text: 'Employees' },
            ]}
            icon={documentText}
          />
        )}

      </IonList>
    </TModal>
  );
};

export default ReportFilters;
