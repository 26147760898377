import React, { createContext, useReducer, Dispatch } from 'react';

export interface MailDropState {
  name: string;
  dropDate: string;
  autoReply?: string;
  proof?: any;
  proofPreview?: string;
  columnMap?: any;
  fileUploaded?: any;
}

export interface MailDropContextProps {
  state: MailDropState;
  dispatch: Dispatch<any>;
}

export const MailDropContext = createContext({} as MailDropContextProps);

let reducer = (state: MailDropState, value: any) => {
  const s = { ...state };
  return Object.assign(s, value || {});
};

export const MailDropContextProvider = (props: any) => {
  const init = props.initialState || {};
  const initialState: MailDropState = Object.assign(
    {
      name: '',
    },
    init
  );

  let [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch
  } as MailDropContextProps;

  return (
    <MailDropContext.Provider value={value}>
      {props.children}
    </MailDropContext.Provider>
  );
};

export const MailDropContextConsumer = MailDropContext.Consumer;
