import React, { useCallback } from 'react';
import { TReportsPage, TChart } from '../components';
import { reportsService } from '../services';
// import { AppContext } from '../context/AppContext';
import { IonList, IonItem, IonLabel, IonBadge } from '@ionic/react';

const EmailDeliveryReportPage: React.FC = () => {
  // const appState = useContext(AppContext).state;
  // const { selectedClientId } = appState;
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const loadData = useCallback(async ({ since, until, clientId }: any) => {
    const [smsDeliveryStatusCounts] =
      await Promise.all([
        reportsService.retrieveEmailDeliveryReports(clientId, since, until, {}),
      ]);
    // console.log('smsDeliveryStatusCounts', smsDeliveryStatusCounts);
    return {
      smsDeliveryStatusCounts,
    };
  }, []);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => {
        const totals: any = Object.values(state.data?.smsDeliveryStatusCounts);
        const keys: any = Object.keys(
          state.data?.smsDeliveryStatusCounts
        ).map?.((it: any) => it.split('_count')[0]);
        return (
          <TChart
            type='pie'
            title='Outbound Email Status Counts'
            data={totals}
            labels={keys}
            legend
          >
            <IonList lines='full' className='reports'>
              {Object.entries(state.data?.smsDeliveryStatusCounts).map(
                (it: any) => (
                  <IonItem key={it[0]}>
                    <IonLabel>{capitalize(it[0].split('_')[0])}</IonLabel>
                    <IonBadge mode='ios'>{it[1]?.toLocaleString()}</IonBadge>
                  </IonItem>
                )
              )}
            </IonList>
          </TChart>
        );
      }}
    </TReportsPage>
  );
};

export default EmailDeliveryReportPage;
