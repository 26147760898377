import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction
} from 'react';
import { IonPopover, IonSearchbar, IonList, IonItem, IonContent } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import '../styles/components/PopoverSelector.scss';

export interface Item {
  id?: number;
  label?: string;
  value?: string;
}

export interface PopoverSelectorProps {
  isOpen: boolean;
  items: Item[];
  filter: (item: any) => string;
  idField: string;
  renderLabel: (item: any) => string;
  onSelectionMade: (obj: Item) => void;
  onDidDismiss: () => void;
  placeholder?: string;
}

const PopoverSelector: ForwardRefRenderFunction<
  unknown,
  PopoverSelectorProps
> = (
  props: PopoverSelectorProps,
  ref: any
) => {
    const {
      onDidDismiss,
      isOpen,
      items,
      onSelectionMade,
      renderLabel,
      placeholder
    } = props;
    const [searching, setSearching] = useState(false);
    const [filtered, setFiltered] = useState<object[]>([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const searchBar = useRef<any>();
    const contentRef = useRef<HTMLIonContentElement>(null);

    useImperativeHandle(ref, () => ({
      get searchBar() {
        return searchBar.current;
      }
    }));

    const search = ({ detail }: any) => {
      const { value } = detail;
      if (!!value) {
        setFiltered(
          props.items.filter(it =>
            props.filter(it).match(new RegExp(detail.value, 'gi'))
          )
        );
        setSearching(true);
        setFocusedIndex(-1);
      } else {
        setFiltered([]);
        setSearching(false);
        setFocusedIndex(-1);
      }
    };

    const scrollToItem = (index: number) => {
      const itemElement = document.getElementById(`popover-item-${index}`);
      itemElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      const itemList = searching ? filtered : items;
      if (event.key === 'ArrowDown') {
        setFocusedIndex((prev) => {
          const newIndex = (prev + 1) % itemList.length;
          scrollToItem(newIndex);
          return newIndex;
        });
      } else if (event.key === 'ArrowUp') {
        setFocusedIndex((prev) => {
          const newIndex = prev === 0 ? itemList.length - 1 : prev - 1;
          scrollToItem(newIndex);
          return newIndex;
        });
      } else if (event.key === 'Enter' && focusedIndex !== -1) {
        const selectedItem = itemList[focusedIndex];
        onSelectionMade(selectedItem);
      }
    };

    useEffect(() => {
      if (!isOpen) {
        if (searchBar.current) {
          searchBar.current.value = '';
        }

        setFiltered(props.items);
        setFocusedIndex(-1);
      }
    }, [isOpen, searchBar, props.items]);

    return (
      <IonPopover
        mode={isPlatform('android') ? 'md' : 'ios'}
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        className="popover-selector"
        onDidPresent={() => searchBar.current.setFocus()}
      >
        <div className="searchbar-background">
          <IonSearchbar
            slot="fixed"
            className="no-margin no padding popover-searchbar"
            mode={isPlatform('android') ? 'md' : 'ios'}
            onIonInput={search}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || 'Search'}
            ref={searchBar}
          />
        </div>
        <IonContent ref={contentRef} className="popover-content-container">
          <IonList>
            {(searching ? filtered : items).map((it: any, index: number) => (
              <IonItem
                key={it.name}
                id={`popover-item-${index}`}
                lines="full"
                className={`pointer ${Number(focusedIndex) === index ? 'focused' : ''}`}
                onClick={e => {
                  e.stopPropagation();
                  onSelectionMade(it);
                }}
              >
                {renderLabel(it)}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    );
  };

export default forwardRef(PopoverSelector);
