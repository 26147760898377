import React, { useState, useContext } from 'react';
import TButtonItem from './TButtonItem';
import { leadsService } from '../services';
import { IonIcon, IonLoading, IonItem } from '@ionic/react';
import { arrowUndoCircle, archive } from 'ionicons/icons';
import { LeadContext } from '../context/LeadContext';
import { Lead } from '../types/Lead';
import { util, appNotification } from '../core';

export interface OptinButtonsProps {
  lead: Lead;
}

const OptinButton: React.FC<OptinButtonsProps> = ({
  lead
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useContext(LeadContext);
  const confirmOptin = () => {
    appNotification.alert({
      message: `Are you sure you want to opt-in ${lead.first_name} ${lead.last_name}?`,
      header: 'Opt-in Lead?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Continue',
          async handler() {
            setLoading(true);
            const res = await leadsService.update(
              {
                client: lead?.client,
                id: lead?.id,
                sms_opt_out: 0,
                status: 1,
                convo_archived: false
              },
              { noToast: false }
            );
            setLoading(false);
            if(res.id === lead.id) {
              dispatch({ type: 'set', value: { lead: res } });
            }
          }
        }
      ]
    });
  };
  return (
    <>
      {lead?.sms_opt_out && lead?.sms_opt_out >= 10 && (
        <IonItem color="danger">
          This customer has requested to not be text messaged any longer. The only way to reactivate texting is to have the customer text in the word START to {util.formatHumanReadablePhone(lead?.twilio_number_str)}
        </IonItem>
      )}
      {lead?.sms_opt_out && lead?.sms_opt_out < 10 && (
        <>
          <IonItem color="danger">
            We have detected that this customer may not want to be contacted anymore. You can archive the lead if this is accurate. You can override this notice to enable texting again. Please be aware that if you contact the customer after they have requested to not be contacted it is a violation of the TCPA.
          </IonItem>
          <TButtonItem
            color="danger"
            onClick={confirmOptin}
          >
            <IonIcon icon={arrowUndoCircle} slot="start" />
            Opt Back In
          </TButtonItem>
          {!lead?.convo_archived && (
            <TButtonItem
              color="warning"
              onClick={async () => {
                setLoading(true);
                const res = await leadsService.archive(lead?.client, lead?.id, {
                  noToast: true
                });
                setLoading(false);
                if(res.id === lead.id) {
                  dispatch({ type: 'set', value: { lead: res } });
                }

              }}
            >
              <IonIcon icon={archive} slot="start" />
              Archive
            </TButtonItem>
          )}
        </>
      )}
      <IonLoading isOpen={loading} />
    </>
  );
};

export default OptinButton;
