import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { AutoBotRow, TPage } from '../components';
import { AutoBotItemProps } from '../components/AutobotRow';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import { autobotService } from '../services';
import { add } from 'ionicons/icons';
import { IonIcon, IonList, IonReorderGroup, IonRouterLink } from '@ionic/react';
import { ItemReorderEventDetail } from '@ionic/core';

type UpdateOrder = { id: number, order: number }[]

const AutoBotsPage: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [order, setOrder] = useState([]);
  const loadBots = useCallback(async () => {
    return await autobotService.list(selectedClient.id)
  }, [selectedClient])

  const {
    error,
    loading,
    data,
  } = useDataLoader(loadBots, false);

  const updateOrder = async (order: UpdateOrder) => {
    await autobotService.updateOrder(selectedClient.id, order);
  }

  const getOrder = useMemo(() => {
    return data?.map((bot: AutoBotItemProps) => bot.id)
  }, [data])

  useEffect(() => {
    setOrder(getOrder)
  }, [getOrder])

  const reorder = async (event: CustomEvent<ItemReorderEventDetail>) => {
    event.detail.complete()
    const newOrder = [...order];
    const { to, from } = event.detail;
    const removedId = order[from];
    newOrder.splice(from, 1);
    newOrder.splice(to, 0, removedId);
    setOrder(newOrder)
    const patchData = newOrder.map((bot, i) => {
      return { id: bot, order: i }
    })
    updateOrder(patchData);
  }

  return (
    <>
      <TPage
        loading={loading}
        loadingText="Loading..."
        error={error}
        headerTool={(
          <IonRouterLink routerLink={`/autobots/wizard`}>
            <IonIcon icon={add} size="large" />
          </IonRouterLink>
        )}
      >
        {!loading && (
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={reorder}>
              {data && data.sort((a: AutoBotItemProps, b: AutoBotItemProps) => a.order - b.order).map((bot: any) => (
                <AutoBotRow
                  key={bot.id}
                  id={bot.id}
                  order={bot.order}
                  pre_template={bot.pre_template}
                  last_message_age_hours={bot.last_message_age_hours}
                  botStatuses={bot.statuses}
                  name={`${bot.name ?? 'No Bot Name'}`}
                  lead_classification={bot.lead_classification}
                />
              ))}
            </IonReorderGroup>
          </IonList>
        )}
      </TPage>
    </>
  )
};

export default AutoBotsPage;