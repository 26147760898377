import React, { useContext } from 'react';
import { IonMenuButton } from '@ionic/react';
import '../styles/components/TMenuButton.scss';
import { haptics } from '../core';
import { NotificationsContext } from '../context/NotificationsContext';

const TMenuButton: React.FC = () => {
  const { state } = useContext(NotificationsContext);
  return (
    <div className="t-menu" onClick={() => haptics.lightImpact()}>
      <IonMenuButton />
      {!!state?.unread && <i className="dot ion-hide-lg-up" />}
    </div>
  );
};

export default TMenuButton;
