
// import {
//   Plugins,
//   // PushNotificationToken,
//   // PushNotification,
//   // PushNotificationActionPerformed,
//   // App,
//   // AppState
// } from "@capacitor/core";
import {
  PushNotifications,
  PushNotification,
  // PushNotificationSchema,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from "@capacitor/push-notifications";
// import { FCM } from '@capacitor-community/fcm';

import { App as app, AppState } from "@capacitor/app";
import qs from "qs";
import http from "../core/http";
import device from "../core/device";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import appConfig from "../config.json";
import { native, network } from "../core";
import PagedService from "./paged-service";
import { useRouter, useEventListener } from "../hooks";
import _ from "lodash";
// const { App } = Plugins;

class PushNotificationsService extends PagedService {
  private firebaseWeb: any = null;
  private waitingAction: any = null;

  constructor() {
    super();
    firebase.initializeApp(appConfig.firebase.config);
    app.addListener("appStateChange", (state: AppState) => {
      if (state.isActive) {
        this.removeAllDeliveredNotifications();
      }
    });
    // PushNotifications.register();
    // if (native.isNative) {
    //   PushNotifications.requestPermissions().then(result => {
    //     if (result.receive === 'granted') {
    //       // Register with Apple / Google to receive push via APNS/FCM
    //       PushNotifications.register();
    //     } else {
    //       console.log("Push notifications permission denied");
    //     }
    //   });
    // }

    this.init();
  }

  private init() {
    native.isNative ? this.initNative() : this.initBrowser();
  }

  private initNative() {
    PushNotifications.addListener(
      "registration",
      this.onRegistration.bind(this)
    );

    PushNotifications.addListener(
      "registrationError",
      this.onRegistrationError.bind(this)
    );

    PushNotifications.addListener(
      "pushNotificationReceived",
      this.onNativePushReceived.bind(this)
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      this.onNativePushActionPerformed.bind(this)
    );
  }

  private async initBrowser() {
    if (firebase.messaging.isSupported()) {
      try {

        // firebase.messaging().getToken({ vapidKey: "BHpzUCBNFGEGhVCB_Y2ECDsZ5bEhqhdb752v8RzUHvmE2GNOz1df82roA5QpnQ1guo3TkOs7e2T71wpEn-JGMg4" }).then((token => {
        //   console.log('I AM IN THEN')
        //   console.log('TOKEN WEB: ', token)
        // }))

        this.firebaseWeb = firebase.messaging();
        await this.firebaseWeb.getToken({ vapidKey: appConfig.firebase.messaging.webApiKey });

        this.firebaseWeb.onMessage(this.onBrowserMessage.bind(this));
        navigator.serviceWorker.addEventListener(
          "message",
          _.debounce((event) => {
            switch (event.data.msg) {
              case "notification:received":
                this.onPushReceived(event.data.data);
                break;
            }
          }, 500)
        );
      } catch (error) {
        console.error('Error initializing Firebase Messaging:', error);
      }
    } else {
      console.warn('Firebase Messaging is not supported in this browser');
    }
  }


  onBrowserMessage(e: any) {
    const { data } = e;
    const { body, title } = data;
    this.onPushReceived(e);
    const notif = new Notification(title, {
      body,
      icon: "/assets/app-icon.png",
    });
    notif.onclick = () => this.onPushActionPerformed(data);
  }

  checkPendingAction() {
    if (this.waitingAction) {
      this.onPushActionPerformed(this.waitingAction);
    }
  }

  onPushActionPerformed(detail?: any) {
    if (document.body.classList.contains("t-init")) {
      window.dispatchEvent(new CustomEvent("notification:action", { detail }));
      this.waitingAction = null;
    } else {
      this.waitingAction = detail;
    }
  }

  onNativePushActionPerformed(e: PushNotificationActionPerformed) {
    this.onPushActionPerformed(e.notification.data);
  }

  async onPushReceived(notification: any) {
    window.dispatchEvent(
      new CustomEvent("notification:received", { detail: notification })
    );
  }

  onNativePushReceived(notification: PushNotification) {
    this.onPushReceived(notification);
  }

  async onRegistration(token: PushNotificationToken) {
    try {
      const deviceInfo = await device.getInfo();
      const data = Object.assign(deviceInfo || {}, {
        token: token.value,
      });

      await http.authorizedRequest({
        method: "POST",
        url: "/notifications/devices/",
        data,
      });
    } catch (e) {
      console.log(http.getErrorMessage(e));
      console.error(e);
    }
  }

  onRegistrationError(e: any) {
    console.error("REGISTRATION ERROR", e);
  }

  async registerBrowser() {
    try {
      if (firebase.messaging.isSupported()) {
        await Notification.requestPermission();
        this.firebaseWeb = firebase.messaging()
        const value = await this.firebaseWeb.getToken({vapidKey: appConfig.firebase.messaging.webApiKey});
        this.onRegistration({ value } as PushNotificationToken);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async registerNative() {
    try {
      await PushNotifications.requestPermissions();
      await PushNotifications.register();
    } catch (e) {
      console.log(e);
    }
  }

  register() {
    native.isNative ? this.registerNative() : this.registerBrowser();
  }

  async loadUnread() {
    try {
      if (!network.hasNetworkConnection()) {
        return {};
      }

      const { results, count } = await this.list("new", 10);
      return {
        unreadNotifications: results,
        unread: count,
      };
    } catch (e) {
      console.error("Could not load unread notifications", e);
    }
  }

  async list(status?: string, page_size: number | string = 20) {
    try {
      const params = { page_size } as any;
      params.status__in = status ?? "new,read";

      const data = qs.stringify(params);

      const res = await http.authorizedRequest({
        method: "GET",
        url: `/push-notifications/?${data}`,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async markRead(ids: number[]) {
    try {
      const data = { ids };
      const res = await http.authorizedRequest({
        method: "POST",
        url: "/push-notifications/",
        data,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async patchNotification(id: number, data: object) {
    try {
      const res = await http.authorizedRequest({
        method: "PATCH",
        url: `/push-notifications/${id}/`,
        data,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async markAllNotifications(action: "read" | "cleared", lead_id?: any) {
    try {
      const res = await http.authorizedRequest({
        method: "POST",
        url: `/user-notification-status/`,
        data: { action, lead_id },
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async markViewed(notification: any) {
    notification.viewed = new Date().toISOString();
    return await this.patchNotification(notification.id, {
      viewed: notification.viewed,
    });
  }

  removeAllDeliveredNotifications() {
    if (native.isNative) {
      PushNotifications.removeAllDeliveredNotifications();
    }
  }
}

export const PushNotificationActions: React.FC = () => {
  const router = useRouter();
  useEventListener("notification:action", ({ detail }: CustomEvent) => {
    if (detail.client && detail.lead) {
      router.replace(
        `/text-messages/conversation/${detail.client}/${detail.lead}/`
      );
    } else {
      router.replace("/notifications/");
    }
  });
  return null;
};

const pushNotificationsService = new PushNotificationsService();
export default pushNotificationsService;
