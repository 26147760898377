import { http } from '../core';

class ClientTopClosersService {
  async list(clientId: number, userType: string, since: string, until: string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/top-closers/`,
        params: {
          since,
          until,
          user_type: userType
        }
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const clientTopClosersService = new ClientTopClosersService();
export default clientTopClosersService;
