import React, { useState, useContext, useCallback, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import ChatBubble from './ChatBubble';
import { useLiveDBRef, useEventListener } from '../hooks';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';
import moment from 'moment';
import { util, liveDB } from '../core';
import { scrollToBottom } from '../context/LeadContext';

interface TypingUsersProps {
  leadId: any;
  leadFullName: any;
  clientId: any;
  clientName?: string;
  userHasScrolled: boolean;
}

const LeadTypingUsers: React.FC<TypingUsersProps> = ({
  leadId,
  leadFullName,
  clientId,
  clientName,
  userHasScrolled
}) => {
  const [typingUsers, setTypingUsers] = useState<any>();
  const { state: appState } = useContext(AppContext);
  const { state: cbState } = useContext(ChatMessageBoxContext);
  const { internalOnly } = cbState;
  const { user } = appState;

  useEffect(() => {
    setTypingUsers([]);
  }, [leadId]);

  const onTypingUsers = useCallback(
    (e: any) => {
      const typingUsers = Object.values(e ?? {}).filter(
        (it: any) => it.messagePreview && it.uid !== user?.id
      );

      setTypingUsers(typingUsers);

      if (!userHasScrolled && typingUsers?.length) {
        util.delay(scrollToBottom, 500);
      }
    },
    [userHasScrolled, user]
  );

  const typing = useLiveDBRef(
    `/leads/${leadId}/typing-users/`,
    onTypingUsers,
    !!leadId
  );

  const inProgress = liveDB.useReference('/in-progress/typing/');

  const onMessageChange = useCallback(
    async (e: CustomEvent) => {
      const inProgressKey = `${user?.id}`;
      const msg = {
        uid: user?.id,
        lid: leadId,
        clientId,
        clientName,
        leadFullName,
        isStaff: user?.is_staff,
        fullName: `${user?.first_name} ${user?.last_name}`,
        messagePreview: e.detail.value,
        internalOnly,
        updatedAt: moment().unix()
      };

      if (msg.messagePreview) {
        typing.current?.child(user?.id).set(msg);
        inProgress?.child(inProgressKey).set(msg);
      } else {
        typing.current?.child(user?.id).remove();
        inProgress?.child(inProgressKey)?.remove();
      }
    },
    [user, typing, internalOnly, leadId, leadFullName, clientId, clientName, inProgress]
  );

  useEventListener('chatboxmessage:change', onMessageChange);

  return (
    <>
      {typingUsers?.map?.((user: any) => (
        <ChatBubble
          key={user.uid}
          id={user.uid}
          message={user.messagePreview}
          sender={`${user.fullName} is typing`}
          internal={user.internalOnly}
          yellow={user.internalOnly}
          typing
          outgoing
        />
      ))}
    </>
  );
};

export default LeadTypingUsers;
