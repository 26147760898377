import React, { useRef, useContext, useState, useCallback, useEffect } from 'react';
import {
  IonList,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonThumbnail,
  IonCol
} from '@ionic/react';
import { useRouter } from '../hooks';
import {
  TPage
} from '../components';

import {
  ellipseOutline,
  close
} from 'ionicons/icons';
import TDateTimeItem from '../components/TDateTimeItem';
import { MailDropContext } from '../context/MailDropContext';
import { AppContext } from '../context/AppContext';
import TInputItem from '../components/TInputItem';
import TButtonItem from '../components/TButtonItem';
import ButtonGrid from '../components/ButtonGrid';
import { modalController } from '@ionic/core';
import {  appNotification } from '../core';

import moment from 'moment';

const AddMailDropStep1Page: React.FC = () => {
  const router = useRouter();
  const { dispatch } = useContext(MailDropContext);
  const { state: appState } = useContext(AppContext);
  const [acknowledgedCost, setAcknowledgedCost] = useState(false);
  const { selectedClient } = appState;
  const fileInput = useRef(null);
  const [name, setName] = useState('');
  const [proofPreview, setProofPreview] = useState('');
  const [proof, setProof] = useState<any>();
  const [dropDate, setDropDate] = useState();
  const [autoReply, setAutoReply] = useState<string>('Hi {{ first_name }}, this is {{ robot_name }} with {{ company_name }}. Do you know where we are located? You can reply stop to opt-out.');

  let fileReader: any;

  const handleFileRead = (e: any) => {
    const content = fileReader.result;
    console.log(content);
    setProofPreview(content);
  };

  const handleFileChosen = (file: any) => {
    setProofPreview('');
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    if (file instanceof Blob) {
      setProof(file);
      fileReader.readAsDataURL(file);
    }
  };


    const showCost = useCallback(async () => {
      if (!acknowledgedCost) {
        appNotification.alert({
          message: `You will be billed $${selectedClient.mail_recipient_price} per record uploaded.`,
          header: 'Mail Drop Uploads',
          buttons: [
            {
              text: 'Cancel',
              handler() {
                modalController.dismiss();
              }
            },
            {
              text: 'Continue',
              handler() {
                setAcknowledgedCost(true);
              }
            }
          ]
        });
      }
    }, [acknowledgedCost, selectedClient]);

    useEffect(() => {
      showCost();
    }, [showCost]);


  return (
    <TPage
      customFooterContent={
        <ButtonGrid>
          <IonButton
            disabled={!name || !dropDate || !autoReply}
            color="secondary"
            onClick={()=>{
              dispatch({
                name,
                dropDate,
                proofPreview,
                autoReply,
                proof
              });
              router.push(`/maildrops/add/2/`, {})
            }}
            expand="block"
          >
            Next Step
          </IonButton>
        </ButtonGrid>
      }
    >

      <IonGrid className="training-quiz">
        <IonRow>
          <IonCol size="12" className="lesson-name">
            <strong className="lesson-category">Create a New Mail Drop</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="training-progress">
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Settings</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Data</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Review</span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonList lines="full" className="no-padding">
        <IonItemDivider sticky>
            Enter Details
        </IonItemDivider>
        <TInputItem
          label="Name *"
          type="text"
          value={name}
          onChange={(e: any) => setName(e.detail.value)}
        />
        <input
          ref={fileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={(e: any) => handleFileChosen(e.target.files[0])}
        />
        <TDateTimeItem
          label="Drop Date*"
          min={moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD')}
          max="2099"
          displayFormat="MMM DD, YYYY"
          value={dropDate}
          onIonChange={(e: any) => setDropDate(e.detail.value)}
        />
        <TInputItem
          label="Auto Reply*"
          multiline
          type="text"
          value={autoReply}
          onChange={(e: any) => setAutoReply(e.detail.value)}
        />
        {!proofPreview && (
          <TButtonItem
             color="success"
             onClick={() => {
               // @ts-ignore
               fileInput?.current?.click();
             }}
          >
             Upload Mailer Proof
          </TButtonItem>
        )}
        {!!proofPreview && (
          <IonItem>
            <IonThumbnail slot="start">
              <img src={proofPreview} alt="proof" />
            </IonThumbnail>
            <IonLabel>{proof?.name}</IonLabel>
            <IonIcon
              icon={close}
              onClick={() => {
                setProofPreview('');
                setProof('');
              }}
              slot="end"
            />
          </IonItem>
        )}
      </IonList>
    </TPage>
  );
};

export default AddMailDropStep1Page;
