// import { Plugins } from '@capacitor/core';
import { Haptics, ImpactStyle} from '@capacitor/haptics';

// import { isPlatform } from '@ionic/core';

// const { Haptics } = Plugins;

class HapticsUtil {
  // impact(style: HapticsImpactOptions) {
  //   if (isPlatform('capacitor')) {
  //     Haptics.impact({
  //       style: ImpactStyle
  //     });
  //   }
  // }
  
  lightImpact() {
    // this.impact(Haptics.Light);
    Haptics.impact({style: ImpactStyle.Light})
  }

  mediumImpact() {
    // this.impact(Haptics.Medium);
    Haptics.impact({style: ImpactStyle.Medium})

  }

  heavyImpact() {
    // this.impact(Haptics.Heavy);
    Haptics.impact({style: ImpactStyle.Heavy})
  }
}

const hapticsUtil = new HapticsUtil();
export default hapticsUtil;
