import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

class LiveDB {
  useReference(path: string) {
    return firebase.database().ref(path);
  }
}

const liveDB = new LiveDB();
export default liveDB;
