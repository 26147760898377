import React, { useContext, useMemo } from 'react';
import { LeadContext } from '../context/LeadContext';
import { useDistanceMatrix } from '../hooks';
import { IonIcon, IonText } from '@ionic/react';
import TMap from './TMap';
import { loadingIndicator, util } from '../core';
import { car } from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { leadsService } from '../services';
import { appendMessageText } from '../context/ChatMessageBoxContext';
import TExpandableItemGroup from './TExpandableItemGroup';

const LeadMap: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;

  const clientCoords = useMemo(
    () => ({
      lat: lead?.client_lat ? parseFloat(lead.client_lat) : null,
      lng: lead?.client_lng ? parseFloat(lead.client_lng) : null
    }),
    [lead]
  );

  const leadCoords = useMemo(
    () => ({
      lat: lead?.lat ? parseFloat(lead.lat) : null,
      lng: lead?.lng ? parseFloat(lead.lng) : null
    }),
    [lead]
  );

  const distance = useDistanceMatrix(leadCoords, clientCoords);

  const append = (c: string) => {
    appendMessageText(c);
    util.hideLeadSidebar();
  };

  const info = useMemo(
    () => (
      <IonText>
        <strong>{lead?.client_name}</strong>
        <div>
          {lead?.client_address} {lead?.client_address_2}
        </div>
        <div>
          {lead?.client_city}, {lead?.client_state} {lead?.client_postal_code}
        </div>
      </IonText>
    ),
    [lead]
  );

  if (state.loading || !lead || !lead?.client_lat) {
    return null;
  }

  return (
    <TExpandableItemGroup title="Directions" renderWhenExpanded>
      <div style={{ height: 200, overflow: 'hidden', position: 'relative' }}>
        <TMap
          coords={clientCoords}
          startCoords={leadCoords}
          infoContent={info}
        />
      </div>
      <TButtonItem
        color="secondary"
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const clientAddress = await leadsService.getLeadClientAddressLink(
              lead
            );
            append(
              `Here are the directions to ${lead?.client_name}: ${clientAddress}`
            );
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={car} slot="start" />
        Send Directions
        {distance?.duration?.text ? ` • ${distance.duration?.text}` : ''}
      </TButtonItem>
    </TExpandableItemGroup>
  );
};

export default LeadMap;
