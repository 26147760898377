import useEventListener from './useEventListener';
import { inboundLeadsService } from '../services';
import { useState } from 'react';

export default function useInboundMetrics() {
  const [inboundCount, setInboundCount] = useState(
    inboundLeadsService.inboundCount
  );
  const [needsCallCount, setNeedsCallCount] = useState(
    inboundLeadsService.needsCallCount
  );
  const [needsTextCount, setNeedsTextCount] = useState(
    inboundLeadsService.needsTextCount
  );

  const [inboundWaitTime, setInboundWaitTime] = useState(
    inboundLeadsService.inboundWaitTime
  );

  useEventListener('inbound:metrics', () => {
    setInboundCount(inboundLeadsService.inboundCount);
    setNeedsCallCount(inboundLeadsService.needsCallCount);
    setNeedsTextCount(inboundLeadsService.needsTextCount);
    setInboundWaitTime(inboundLeadsService.inboundWaitTime);
  });

  return {
    count: inboundCount,
    needsCallCount: needsCallCount,
    needsTextCount: needsTextCount,
    wait: inboundWaitTime
  };
}
