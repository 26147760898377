import {
  init,
  configureScope,
  captureException,
  addBreadcrumb,
  Breadcrumb
} from '@sentry/browser';
import config from '../config.json';
import native from './native';
import environment from './environment';

class Sentry {
  initialize() {
    init({
      dsn: config.sentry.id,
      release: environment.commit,
      environment: environment.name
    });
  }

  async configure(user: any, selectedClientId: any) {
    return new Promise<void>(resolve => {
      configureScope((scope: any) => {
        scope.setUser({
          id: user.id,
          username: user.email.substring(0, user.email.indexOf('@')),
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          is_admin: user.is_admin,
          is_client_admin: user.is_client_admin,
          selected_client_id: selectedClientId
        });

        let platform = 'web';

        if (native.isAndroid) {
          platform = 'android';
        } else if (native.isIOS) {
          platform = 'ios';
        } else if (native.isElectron) {
          platform = 'electron';
        }

        scope.setTag(
          'apiEnvironment',
          environment.apiEnvironment ?? 'production'
        );
        scope.setTag('platform', platform);
        scope.setTag('commit', environment.commit);
        scope.setTag('buildDate', environment.buildDate);
        resolve();
      });
    });
  }

  capture(ex: Error) {
    captureException(ex);
  }

  addBreadcrumb(obj: Breadcrumb) {
    addBreadcrumb(obj);
  }
}

const sentry = new Sentry();
export default sentry;
