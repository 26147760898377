import { useState, useEffect } from 'react';
import { theme } from '../core';

export default function useDarkTheme() {
  const [darkTheme, setDarkTheme] = useState(theme.isDark());

  useEffect(() => {
    const handler = () => setDarkTheme(theme.isDark());
    window.addEventListener('theme:changed', handler);
    return () => {
      window.removeEventListener('theme:changed', handler);
    };
  });

  return darkTheme;
}
