import React from 'react';
import { TModal } from './';
import { IonContent, IonText } from '@ionic/react';
import { updates } from '../../updates';

interface UpdateModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UpdateModal: React.FC<UpdateModalProps> = ({
  isOpen,
  handleClose
}) => {
  return (
    <TModal
      isOpen={isOpen}
      onDidDismiss={handleClose}
      title="Updates"
    >
      <IonContent>
        <IonText>
          <ul>
            {updates.map(update => (
              <p key={update}>
                <li>
                  {update}
                </li>
              </p>
            ))}
          </ul>
        </IonText>
      </IonContent>
    </TModal>
  )
}

export default UpdateModal;
