import React from 'react';
import { IonButton, IonItem } from '@ionic/react';
import '../styles/components/TButtonItem.scss';
import HapticButton from './HapticButton';

export interface TButtonItemsProps
  extends React.ComponentProps<typeof IonButton> {
  lines?: HTMLIonItemElement['lines'];
  icon?: HTMLIonIconElement['icon'];
}

const TButtonItem: React.FC<TButtonItemsProps> = ({
  expand,
  lines = 'full',
  children,
  icon,
  className,
  ...rest
}) => {
  className = className ? `${className} t-button-item` : 't-button-item';
  return (
    <IonItem className={className} lines={lines}>
      <HapticButton
        expand="block"
        style={{ flex: '1 1 auto' }}
        icon={icon}
        fill="solid"
        {...rest}
      >
        {children}
      </HapticButton>
    </IonItem>
  );
};

export default TButtonItem;
