import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { IonAlert } from '@ionic/react';
import { specialsService } from '../../services';

interface DeleteSpecialModalProps {
  id: number;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const DeleteSpecialModal: React.FC<DeleteSpecialModalProps> = ({
  id,
  isOpen,
  onDidDismiss
}) => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;

  const handleSubmit = async () => {
    await specialsService.end(selectedClient.id, id)
    onDidDismiss()
  }

  return (
    <IonAlert
      isOpen={isOpen}
      header="End special?"
      message="Are you sure you want to end this special?"
      onDidDismiss={onDidDismiss}
      buttons={[
        {
          text: 'No',
          role: 'cancel',
          handler: onDidDismiss
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: handleSubmit
        }
      ]}
    />
  )
}

export default DeleteSpecialModal;
