import React, { useState, useContext, useCallback } from 'react';
import {
  TInfiniteScrollPage,
  TSearchBar,
  QuickQualificationRow
} from '../components';
import { IonItemDivider } from '@ionic/react';
import { quickQualificationsService } from '../services';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';

const QuickQualificationsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const [search, setSearch] = useState<string | null>(null);

  const loadQuickQualifications = useCallback(async () => {
    return await quickQualificationsService.list({
      clientId: state.selectedClientId,
      search
    });
  }, [state.selectedClientId, search]);

  const { next, error, loadNext, loading, data, refresher } = useDataLoader(
    loadQuickQualifications,
    true
  );

  return (
    <TInfiniteScrollPage
      onLoadNextPage={loadNext}
      hasNext={!!next}
      toolbarLoadingIndicator={!!search && loading}
      onRefresh={refresher}
      toolbar={
        <IonItemDivider className="no-padding no-margin">
          <TSearchBar
            onSearch={(value: string) => {
              setSearch(value);
            }}
            style={{ width: '100%' }}
            placeholder="Search Soft Pulls"
          />
        </IonItemDivider>
      }
      zeroStateText={
        search ? 'No Soft Pulls Found Matching Criteria' : 'No Soft Pulls'
      }
      renderItem={(item: any) => (
        <QuickQualificationRow key={item.id} item={item} />
      )}
      items={data}
      loading={loading}
      error={error}
    />
  );
};

export default QuickQualificationsPage;
