import { useMemo } from 'react';

const DEFAULT_PORTAL = 'portal';
export default function usePortal(id = DEFAULT_PORTAL) {
  return useMemo(() => {
    let element = document.getElementById(id);
    if (!element) {
      element = document.createElement('div');
      element.id = id;
      document.body.appendChild(element);
    }

    return element;
  }, [id]);
}
