import native from './native';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

export interface IABOptions {
  presentationStyle?: any;
  toolbarColor?: any;
  windowName?: string | null;
}

class InAppBrowser {
  open(url: string | undefined | null, options: IABOptions = {}) {
    if (!url) {
      return;
    }

    if (native.isNative) {
      return Browser.open(Object.assign({ url }, options) as any);
    }

    return window.open(url);
  }
}

const inAppBrowser = new InAppBrowser();
export default inAppBrowser;
