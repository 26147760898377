import { http } from '../core';

class LockOutService {
  async list() {
    try {
      const { data } = await http.authorizedRequest({
        url: '/user-lock-out/?unlocked_at__isnull=true&page_size=500',
        exclusive: true,
        type: 'user-lock-out-list'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(id: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/user-lock-out/${id}/`,
        exclusive: true,
        type: `user-lock-out-${id}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/user-lock-out/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(id: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/user-lock-out/${id}/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/user-lock-out/${id}/`
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
const lockOutService = new LockOutService();
export default lockOutService;
