import React, { createContext, useReducer, Dispatch } from 'react';

export interface PersonalizedLeadState {
  loading: boolean;
  feature?: string;
  featureData?: any;
  coords?: any;
  lead?: any;
  token?: any;
  skippedFinancing: boolean;
  showAddTradeModal: boolean;
  skippedTrade: boolean;
}

export interface PersonalizedLeadContextProps {
  state: PersonalizedLeadState;
  dispatch: Dispatch<any>;
}

export const PersonalizedLeadContext = createContext(
  {} as PersonalizedLeadContextProps
);

let reducer = (state: PersonalizedLeadState, value: any) => {
  const s = { ...state };
  return Object.assign(s, value || {});
};

export const PersonalizedLeadContextProvider = (props: any) => {
  const init = props.initialState || {};
  const initialState: PersonalizedLeadState = Object.assign(
    {
      loading: true,
      skippedFinancing:
        window.localStorage.getItem('skippedFinancing') === 'true',
      showAddTradeModal: false,
      skippedTrade: window.localStorage.getItem('skippedTrade') === 'true'
    },
    init
  );

  let [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch
  } as PersonalizedLeadContextProps;

  return (
    <PersonalizedLeadContext.Provider value={value}>
      {props.children}
    </PersonalizedLeadContext.Provider>
  );
};

export const PersonalizedLeadContextConsumer = PersonalizedLeadContext.Consumer;
