import React from 'react';

const Dropzone: React.FC = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.1)',
        borderRadius: 6,
        border: '3px dashed rgba(220,220,220,.2)',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100
      }}
    ></div>
  );
};

export default Dropzone;
