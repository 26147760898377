import React, { useContext, useState } from 'react';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import { appendMessageText } from '../context/ChatMessageBoxContext';
import TItem from './TItem';
import TButtonItem from './TButtonItem';
import { IonLabel, IonIcon } from '@ionic/react';
import { urlShortenerService } from '../services';
import { uiUtil, loadingIndicator } from '../core';
import { SurveyModal } from './modals';
import { arrowUpCircleOutline } from 'ionicons/icons';
import moment from 'moment';

const LeadSurveys: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const [survey, setSurvey] = useState<any>();

  return (
    <TExpandableItemGroup title="Surveys" badge={state.surveys?.length}>
      {state.surveys?.map((it: any) => (
        <TItem
          key={it.id}
          className="ion-activatable pointer"
          onClick={() => setSurvey(it)}
        >
          <IonLabel className="ion-multiline">
            <p>{it.full_name}</p>
            <p className="detail">{uiUtil.renderRating(it.average_rating)}</p>
          </IonLabel>
          <p className="detail" slot="end">
            {moment(it.updated).fromNow()}
          </p>
        </TItem>
      ))}
      <TButtonItem
        color="secondary"
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const surveyUrl = `https://survey.tecobi.com/${lead?.client}/${lead?.id}/1`;
            const { short_url } = await urlShortenerService.shorten(
              surveyUrl
            );
            const message = `Let us know how we did: ${short_url ?? surveyUrl}`;
            appendMessageText(message);
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={arrowUpCircleOutline} slot="start" />
        Send Survey
      </TButtonItem>
      {survey && (
        <SurveyModal survey={survey} onWillDismiss={() => setSurvey(null)} />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadSurveys;
