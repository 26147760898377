import React, { useContext, useState, useEffect, useCallback } from 'react';
import { TPage, TItemGroup, AutoReplyItem } from '../components';
import {
  IonAccordionGroup,
  IonAlert,
  IonList,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { appNotification, loadingIndicator } from '../core';
import { add, save, trashOutline } from 'ionicons/icons';
import { AppContext } from '../context/AppContext';
import { clientProvidersService, newLeadAutoRepliesService } from '../services';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';

type AutoReplyType = {
  id: number | null;
  message: string;
  trade_message?: string;
  inventory_message?: string;
  employment_message?: string;
  service_message?: string;
  isNewReply?: boolean;
  master_providers: number[];
  // Include other fields that an auto reply may have
};

const ClientAutoRepliesPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClientId } = state;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteReplyId, setDeleteReplyId] = useState<number | null>(null);
  const [newLeadAutoReplies, setNewLeadAutoReplies] = useState<AutoReplyType[]>(
    []
  );
  // Update expanded accordion state
  const handleAccordionChange = (event: CustomEvent) => {
    setExpandedAccordion(event.detail.value);
  };

  const handleFieldChange = useCallback(async (
    replyId: number,
    fieldName: string,
    value: any
  ) => {
    console.log(value)
    if (!value) return;
    // Update local state first for a responsive UI
    setNewLeadAutoReplies((currentReplies: any) =>
      currentReplies.map((reply: any) =>
        reply.id === replyId ? { ...reply, [fieldName]: value } : reply
      )
    );

    // Prepare data for API call
    const dataToUpdate = {
      [fieldName]: value,
    };

    try {
      await loadingIndicator.create();
      // Make API call to update the field
      const response = await newLeadAutoRepliesService.update(
        selectedClientId,
        replyId,
        dataToUpdate
      );
      console.log('Update successful:', response);
      loadingIndicator.dismiss();
      appNotification.toast('Auto reply updated successfully.', 'Success');
    } catch (error) {
      console.error('Error updating auto reply:', error);
      appNotification.toast('Error updating auto reply.', 'Error');
      loadingIndicator.dismiss();
    }
  }, [selectedClientId]);

  useRequireSettingsAdmin();

  const [masterProviders, setMasterProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for tracking loading status

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Fetch master providers
        const providers = await clientProvidersService.request(
          selectedClientId
        );
        setMasterProviders(providers);

        // Fetch new lead auto replies
        const replies = await newLeadAutoRepliesService.list(selectedClientId);
        setNewLeadAutoReplies(replies);

        // Find the default auto-reply and update the expanded accordion
        // const defaultReply = replies.find((reply: any) => reply.is_default);
        // setExpandedAccordion(`reply-${defaultReply?.id}`);

        setIsLoading(false); // Set loading to false after fetching all data
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    if (selectedClientId) {
      fetchData();
    }
  }, [selectedClientId]);


  // State to track the currently expanded accordion
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    null
  );

  const handleDeleteClick = (replyId: number) => {
    setShowConfirmDelete(true);
    setDeleteReplyId(replyId);
  };

  async function handleDeleteNewLeadAutoReply(replyId: number) {
    try {
      await loadingIndicator.create();
      await newLeadAutoRepliesService.delete(selectedClientId, replyId);
      setNewLeadAutoReplies((prevReplies) => {
        // Find the default auto-reply and update the expanded accordion
        // const defaultReply = prevReplies.find((reply: any) => reply.is_default);
        // setExpandedAccordion(`reply-${defaultReply?.id}`);
        return prevReplies.filter((reply) => reply.id !== replyId);
      });

      appNotification.toast('Auto reply deleted successfully.', 'Success');
      loadingIndicator.dismiss();
    } catch (error) {
      console.error('Error deleting auto reply:', error);
      appNotification.toast('Error deleting auto reply.', 'Error');
      loadingIndicator.dismiss();
    }
  }

  const handleConfirmDelete = async () => {
    if (deleteReplyId !== null) {
      // Call your delete function here
      handleDeleteNewLeadAutoReply(deleteReplyId);
    }
    setShowConfirmDelete(false);
    setDeleteReplyId(null);
  };

  const [isAddingNewReply, setIsAddingNewReply] = useState(false);
  const defaultNewReply: any = {
    message: `👋 Hi {{ first_name }}, this is {{ assignee }} from {{ company_name }}! 🎉 I'm super excited to help you find the perfect vehicle over text message. 🚗 Where do you want to start?

1. Vehicle Selection: 🤔 Have questions about a specific vehicle?
2. Trade-In Value: 💰 Curious about your trade-in value?
3. Easy Financing: 💸 Ready to dive into financing options?
4. Visit Us: 🏢 Want to come in for a test drive?

Just reply with a number to kickstart our transparent, stress-free car buying process! 🚀 Or, if you prefer a call, just reply "call".`,
    master_providers: [],
    id: 0,
  };
  const [newReplyData, setNewReplyData] = useState(defaultNewReply);

  const isFormValid =
    newReplyData.message && newReplyData.master_providers.length > 0;

  const handleAddNewLeadAutoReply = () => {
    setNewReplyData(defaultNewReply);
    setIsAddingNewReply(true);
    setExpandedAccordion('reply-0');
  };

  const handleSaveNewLeadAutoReply = async () => {
    if (isFormValid) {
      try {
        await loadingIndicator.create();
        const response = await newLeadAutoRepliesService.create(
          selectedClientId,
          newReplyData
        );
        setIsAddingNewReply(false);
        setNewLeadAutoReplies([...newLeadAutoReplies, response]); // Add the new reply to the state
        setExpandedAccordion(`reply-${response?.id}`);
        appNotification.toast('Auto reply created successfully.', 'Success');
        loadingIndicator.dismiss();
      } catch (error) {
        console.error('Error saving new auto reply:', error);
        appNotification.toast('Error creating auto reply.', 'Error');
        loadingIndicator.dismiss();
      }
    }
  };

  const handleCancelNewLeadAutoReply = () => {
    setIsAddingNewReply(false);
    setNewReplyData(defaultNewReply);
  };

  const handleNewReplyFieldChange = useCallback(
    (fieldName: string, value: any) => {
      console.log('handleNewReplyFieldChange', fieldName, value);
      setNewReplyData((prev: any) => ({ ...prev, [fieldName]: value }));
    },
    [setNewReplyData]
  );

  return (
    <TPage loading={isLoading}>
      <IonList className='no-padding no-margin'>
        <TItemGroup title='Auto Responses'>
          <IonAccordionGroup
            value={expandedAccordion}
            onIonChange={handleAccordionChange} // Update state when accordion changes
          >
            {/* Default auto reply at the top */}
            {newLeadAutoReplies
              ?.filter((reply: any) => reply.is_default)
              .map((reply: any) => (
                <AutoReplyItem
                  key={`auto-reply-default-${reply.id}`}
                  reply={reply}
                  isDefault={true}
                  handleFieldChange={handleFieldChange}
                  masterProviders={masterProviders}
                />
              ))}

            {/* Other auto replies */}
            {newLeadAutoReplies
              ?.filter((reply: any) => !reply.is_default)
              .map((reply: any) => (
                <AutoReplyItem
                  key={`auto-reply-custom-${reply.id}`}
                  reply={reply}
                  isDefault={false}
                  handleDeleteClick={handleDeleteClick}
                  handleFieldChange={handleFieldChange}
                  masterProviders={masterProviders}
                />
              ))}

            {/* New auto reply form */}
            {isAddingNewReply && (
              <AutoReplyItem
                key='auto-reply-new'
                reply={newReplyData}
                isDefault={false}
                handleNewFieldChange={handleNewReplyFieldChange} // New handler for new replies
                masterProviders={masterProviders}
                isNewReply={true}
              />
            )}
          </IonAccordionGroup>
          <div style={{ padding: 4 }}>
            {!isAddingNewReply ? (
              <IonButton onClick={handleAddNewLeadAutoReply}>
                <IonIcon icon={add} /> Add Auto Response
              </IonButton>
            ) : (
              <>
                <IonButton
                  disabled={!isFormValid}
                  style={{ marginRight: 4 }}
                  onClick={handleSaveNewLeadAutoReply}
                >
                  <IonIcon icon={save} slot='start' />
                  Save
                </IonButton>
                <IonButton onClick={handleCancelNewLeadAutoReply}>
                  <IonIcon icon={trashOutline} slot='start' />
                  Cancel
                </IonButton>
              </>
            )}
          </div>
        </TItemGroup>
      </IonList>
      <IonAlert
        isOpen={showConfirmDelete}
        header='Confirmation'
        message='Are you sure you want to delete this auto reply?'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              setShowConfirmDelete(false);
            },
          },
          {
            text: 'OK',
            handler: handleConfirmDelete,
          },
        ]}
      />
    </TPage>
  );
};

export default ClientAutoRepliesPage;
