import { http } from '../core';

class LeadDocumentService {
  async list(clientId: number, leadId: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/leads/${leadId}/lead-documents/`,
        exclusive: true,
        type: 'lead-documents-list'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(clientId: number, leadId: number, id: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/leads/${leadId}/lead-documents/${id}/`,
        exclusive: true,
        type: `lead-documents-${id}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, leadId: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/leads/${leadId}/lead-documents/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(clientId: number, leadId: number, id: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/leads/${leadId}/lead-documents/${id}/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(clientId: number, leadId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/leads/${leadId}/lead-documents/${id}/`
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
const leadDocumentService = new LeadDocumentService();
export default leadDocumentService;
