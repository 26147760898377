import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { TReportsPage, TSearchBar, TFab } from '../components';
import { IonToolbar, IonIcon } from '@ionic/react';
import { mailDropService } from '../services';
import '../styles/Reports.scss';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { util } from '../core';
import { cloudDownloadOutline, add } from 'ionicons/icons';

const MailDropList: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const { state: appState } = useContext(AppContext);
  // Used for is_full_service hide avg response time chart
  const { user } = appState;
  const tableTitle = 'Direct Mail Drops';
  const [token, setToken] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const doSearch = (val: string) => {
    setSearchTerm(val);
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: 'Number',
      selector: (row: any) => row.number,
      sortable: true,
      maxWidth: '165px',
      format: (row: any)=> util.formatHumanReadablePhone(row.number),
    },
    {
      name: 'Uploaded',
      selector: (row: any) => row.upload_count,
      sortable: true,
      right: true,
      maxWidth: '100px',
      format: (row: any)=> !!row.upload_count ? row.upload_count : 'processing',
    },
    {
      name: 'Leads',
      selector: (row: any) => row.lead_count,
      sortable: true,
      right: true,
      maxWidth: '100px'
    },
    {
      name: 'Response %',
      selector: (row: any) => row.response_rate,
      format: (row: any)=> `${row.response_rate.toFixed(2)}%`,
      sortable: true,
      right: true,
      maxWidth: '100px'
    },
    {
      name: 'Drop Date',
      selector: (row: any) => row.drop_date,
      sortable: true,
      right: true,
      format: (row: any)=> moment(row.drop_date).format('ll'),
      maxWidth: '165px'
    },
    {
      name: 'Upload Date',
      selector: (row: any) => row.upload_date,
      sortable: true,
      right: true,
      format: (row: any)=> moment(row.upload_date).format('ll'),
      maxWidth: '165px'
    },
    {
      name: 'Actions',
      selector: (row: any) => row.id,
      right: true,
      button: true,
		  cell: (row: any) => (
  			<a href={`https://api.tecobi.com/directmail/maildrop/${row.id}/recipients/export/?token=${token}`} target="_blank" rel="noopener noreferrer">
  				<IonIcon icon={cloudDownloadOutline} />
  			</a>
		  ),
      maxWidth: '165px'
    },
  ];

  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const token = (await mailDropService.getTempDownloadToken()) ?? '';
      await setToken(token);
      return await mailDropService.list({ clientId, params: { upload_date__gte: since.toISOString(), upload_date__lte: until.toISOString() } })
    },
    []
  );

  return (
    <TReportsPage
      defaultDateRange="Year"
      defaultSince={moment().startOf('year').toISOString()}
      defaultUntil={moment().toISOString()}
      onLoadData={loadData}
      fab={
        <TFab
          icon={add}
          routerLink={
            '/maildrops/add/'
          }
        />
      }
    >
      {({ state }) => {
        const { data } = state;
        const { results } = data;
        return (
          <>
            <IonToolbar>
              <TSearchBar onSearch={doSearch} />
            </IonToolbar>
            <DataTable
              title={tableTitle}
              columns={columns}
              data={results?.filter((it:any) => {
                return it.name.toLowerCase().includes(searchTerm.toLowerCase());
              })}
              theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
              pagination={results?.filter((it:any) => {
                return it.name.toLowerCase().includes(searchTerm.toLowerCase());
              })?.length > 10}
              paginationServer={false}
              highlightOnHover={true}
            />
          </>
        );
      }}
    </TReportsPage>
  );
};

export default MailDropList;
