import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef
} from 'react';
import { useParams } from 'react-router-dom';
import {
  IonPage,
  IonHeader,
  IonContent,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  isPlatform,
  IonPopover,
  IonButtons
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { inventoryService } from '../services';
import { loadingIndicator, util, appNotification } from '../core';
import {
  ButtonGrid,
  HapticButton,
  TExpandableItemGroup,
  TTextItem,
  TNumberItem,
  TItem,
  TMenuButton
} from '../components';
import {
  heart,
  thumbsDown,
  call,
  chevronBack,
  chevronForward,
  closeCircle
} from 'ionicons/icons';
import appConfig from '../config.json';
import css from 'classnames';
import '../styles/pages/VehicleShowcase.scss';
import { AppContext } from '../context/AppContext';

const VehicleShowcase: React.FC = () => {
  const appContext = useContext(AppContext);
  const params = useParams() as any;
  const slides = useRef<any>();
  const { clientId, leadId, vehicleId } = params;
  const [vehicle, setVehicle] = useState<any>({});
  const [photo, setPhoto] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [responded, setResponded] = useState(false);
  const [hasPrevSlide, setHasPrevSlide] = useState(false);
  const [hasNextSlide, setHasNextSlide] = useState(true);
  const [mapUrl, setMapUrl] = useState<any>();

  const loadData = useCallback(async () => {
    setLoading(true);
    await loadingIndicator.create();
    const v = await inventoryService.showcase(vehicleId, leadId);
    setVehicle(v);
    setMapUrl(`https://www.google.com/maps/embed/v1/place?key=${
      appConfig.google.maps.apiKey
    }
    &q=${encodeURIComponent(
      `${v?.client?.name},${v?.client?.address},${v?.client?.postal_code}`
    )}`);
    await loadingIndicator.dismiss();
    document.title = `${util.ymmt(v)} - ${v?.client?.name}`;
    setLoading(false);
  }, [leadId, vehicleId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const renderFeatures = useCallback(
    (type: string) => {
      return (
        <TExpandableItemGroup title={`${type} Features`}>
          <TItem className="features">
            <ul>
              {vehicle?.option_categories?.[type]?.map((it: any) => (
                <li key={it.description}>{it.description}</li>
              ))}
            </ul>
          </TItem>
        </TExpandableItemGroup>
      );
    },
    [vehicle]
  );

  const respond = useCallback(
    (like: boolean) => async () => {
      try {
        await loadingIndicator.create();
        await inventoryService.showcaseResponse(
          clientId,
          leadId,
          vehicleId,
          like
        );
        appNotification.alertSimple(
          like
            ? `Thank you for letting us know, ${vehicle?.lead?.first_name}! We're glad you love it! We'll be in touch soon.`
            : `Not to worry, ${vehicle?.lead?.first_name}, we'll find you a vehicle you love. We'll be in touch soon.`,
          'Thanks!'
        );
        setResponded(true);
      } finally {
        await loadingIndicator.dismiss();
      }
    },
    [clientId, leadId, vehicleId, vehicle]
  );

  const enlarge = useCallback((url: string) => () => setPhoto(url), []);

  const onSlideNavigate = useCallback(async (current: any) => {
    setHasPrevSlide(!(await current.isBeginning()));
    setHasNextSlide(!(await current.isEnd()));
  }, []);

  const prevSlide = useCallback(async () => {
    if (slides.current) {
      await slides.current?.slidePrev();
      await onSlideNavigate(slides.current);
    }
  }, [slides, onSlideNavigate]);

  const nextSlide = useCallback(async () => {
    if (slides.current) {
      await slides.current.slideNext();
      await onSlideNavigate(slides.current);
    }
  }, [slides, onSlideNavigate]);

  return (
    <IonPage className={css('vehicle-showcase', { loading })}>
      <IonHeader>
        <IonToolbar>
          {appContext.state.authorized && (
            <IonButtons slot="start">
              <TMenuButton />
            </IonButtons>
          )}
          <IonTitle>{vehicle.client?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="slides-container">
          <IonIcon
            className={css('arrow', { disabled: !hasPrevSlide })}
            icon={chevronBack}
            onClick={prevSlide}
          />
          <Swiper
            ref={slides}
            className="photos"
            slidesPerView={isPlatform('mobile') ? 2 : 3}
            scrollbar
          >
            {vehicle?.images_list?.map((it: any) => (
              <SwiperSlide key={it.url} onClick={enlarge(it.url)}>
                <img src={it.url} alt="Car" />
              </SwiperSlide>
            ))}
          </Swiper>
          <IonIcon
            className={css('arrow', { disabled: !hasNextSlide })}
            onClick={nextSlide}
            icon={chevronForward}
          />
        </div>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="6" sizeXl="6" sizeMd="6" sizeSm="12" sizeXs="12">
              <IonCard className="quick-view">
                <IonCardHeader>
                  <IonCardTitle>{util.ymmt(vehicle)}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList lines="none" className="no-margin no-padding">
                    <TTextItem
                      label="Condition"
                      labelPlacement="stacked"
                      text={vehicle?.condition_name ?? 'Unknown'}
                    />

                    <TTextItem
                      label="VIN"
                      labelPlacement="stacked"
                      text={vehicle?.vin}
                    />

                    <TTextItem
                      label="Stock Number"
                      labelPlacement="stacked"
                      text={vehicle?.stock_number}
                    />

                    <TTextItem
                      label="Color"
                      labelPlacement="stacked"
                      text={vehicle?.exterior_color}
                    />

                    <TTextItem
                      label="Transmission"
                      labelPlacement="stacked"
                      text={vehicle?.transmission_type}
                    />

                    <TTextItem
                      label="MPG"
                      labelPlacement="stacked"
                      text={`${vehicle?.mpg_highway} highway / ${vehicle?.mpg_city} city`}
                    />

                    <TNumberItem
                      label="Mileage"
                      labelPlacement="stacked"
                      number={vehicle?.mileage}
                    />
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol sizeLg="6" sizeXl="6" sizeMd="6" sizeSm="12" sizeXs="12">
              <IonGrid className="dealer-info">
                <IonRow>
                  <IonCol>
                    <h4>{vehicle?.client?.name}</h4>
                    <p>
                      {vehicle?.client?.address}
                      {vehicle?.client?.address_2 && <br />}
                      {vehicle?.client?.address_2}
                      <br />
                      {vehicle?.client?.city}, {vehicle?.client?.state},{' '}
                      {vehicle?.client?.postal_code}
                    </p>
                  </IonCol>
                  <IonCol size="4">
                    {vehicle?.client?.phone && (
                      <HapticButton
                        fill="outline"
                        expand="block"
                        href={`tel:${vehicle?.client?.phone}`}
                      >
                        <IonIcon icon={call} slot="start" />
                        <IonLabel>Call Us</IonLabel>
                      </HapticButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonList lines="full" className="no-padding no-margin">
                {renderFeatures('Exterior')}
                {renderFeatures('Interior')}
                {renderFeatures('Safety')}
                {renderFeatures('Mechanical')}
              </IonList>
              {mapUrl && (
                <iframe
                  className="map"
                  frameBorder="0"
                  title="directions"
                  style={{ border: 0 }}
                  src={mapUrl}
                  allowFullScreen
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="prepared-text">
                <span>Prepared with</span>
                <IonIcon color="danger" icon={heart} />
                <span>
                  {vehicle?.client?.robot_name
                    ? `by ${vehicle?.client?.robot_name}`
                    : ''}{' '}
                  especially for {vehicle?.lead?.first_name}{' '}
                  {vehicle?.lead?.last_name}
                </span>
              </div>
              <div className="powered-by">
                Powered by <img src="/assets/logo.png" alt="TECOBI Logo" />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {photo && (
        <IonPopover
          className="large-vehicle-photo"
          isOpen={true}
          onDidDismiss={() => setPhoto(null)}
        >
          <img src={photo} alt="car" />
          <IonIcon icon={closeCircle} onClick={() => setPhoto(null)} />
        </IonPopover>
      )}
      <IonFooter>
        <IonToolbar className="ion-multiline">
          {responded ? (
            <div className="thank-you">
              <strong>Thanks</strong>, {vehicle?.lead?.first_name}!
            </div>
          ) : (
            <>
              <div className="callout">
                <strong>{vehicle?.lead?.first_name}</strong>, what do you think
                of this <strong>{util.ymmt(vehicle)}</strong>?
              </div>
              <ButtonGrid>
                <HapticButton
                  color="danger"
                  expand="block"
                  fill="solid"
                  onClick={respond(true)}
                >
                  <IonIcon slot="start" icon={heart} />
                  <IonLabel>Love It</IonLabel>
                </HapticButton>
                <HapticButton
                  color="primary"
                  expand="block"
                  fill="solid"
                  onClick={respond(false)}
                >
                  <IonIcon slot="start" icon={thumbsDown} />
                  <IonLabel>Not For Me</IonLabel>
                </HapticButton>
              </ButtonGrid>
            </>
          )}
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default VehicleShowcase;
