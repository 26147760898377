import { useContext } from 'react';
import { useIonViewWillLeave } from '@ionic/react';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';

const useResetOnLeave = () => {
  const { dispatch: cbDispatch } = useContext(ChatMessageBoxContext);

  useIonViewWillLeave(() => {
    cbDispatch({
      type: 'set',
      value: {
        isEmail: false,
      },
    });
  });
};

export default useResetOnLeave;
