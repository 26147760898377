import { http } from '../core';
class LoginService {

  async login(data: any) {
    const res = await http.request({
      method: 'POST',
      // TODO: switch this to /auth/login/ (no-hyphen) once API
      // is switched after 6/9/23
      url: '/auth/log-in/',
      data,
      headers: http.FORM_HEADER
    });

    return res.data;
  }

  async verifyOtp(otp: string, userId: number) {
    const res = await http.request({
      method: 'POST',
      url: '/auth/verify-otp/',
      data: {
        otp,
        user_id: userId,
      },
    });

    return res.data;
  }

  async sendPasswordResetRequest({ url, data }: any) {
    return await http.request({
      method: 'POST',
      url,
      data
    });
  }
}

const loginService = new LoginService();
export default loginService;
