import React from 'react';
import { IonItemGroup, IonItemDivider, IonLabel, IonIcon } from '@ionic/react';

export interface TItemGroupProps {
  title?: string | JSX.Element;
  icon?: any;
  iconColor?: HTMLIonIconElement['color'];
  children?: any;
}

const TItemGroup: React.FC<TItemGroupProps> = ({
  title,
  icon,
  iconColor,
  children
}) => {
  return (
    <IonItemGroup>
      <IonItemDivider sticky>
        {icon && (
          <IonIcon
            slot="start"
            style={{ marginRight: 5 }}
            size="small"
            color={iconColor}
            icon={icon}
          />
        )}
        <IonLabel>{title}</IonLabel>
      </IonItemDivider>
      {children}
    </IonItemGroup>
  );
};

export default TItemGroup;
