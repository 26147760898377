import React from 'react';
import { TItemProps } from './TItem';
import { IonSelect, IonSelectOption, IonItem, IonIcon } from '@ionic/react';

export interface TSelectItemProps extends TItemProps {
  value?: any;
  defaultValue?: string;
  options: any[];
  interface?: 'popover' | 'alert' | 'action-sheet';
  optionValueKey?: string;
  optionTextKey?: string;
  onChange?: (e: CustomEvent) => any;
  placeholder?: string;
  multiple?: boolean;
  selectedText?: string;
  icon?: string;
}

const TSelectItem: React.FC<TSelectItemProps> = ({
  options,
  interface: interfc,
  optionValueKey = 'value',
  optionTextKey = 'text',
  value,
  onChange,
  children,
  label,
  labelPlacement,
  defaultValue,
  placeholder,
  multiple,
  selectedText,
  icon,
  ...rest
}) => {
  return (
    <IonItem {...rest}>
      {icon && <IonIcon icon={icon} slot="start" />}
      {children}
      <IonSelect
        label={label}
        labelPlacement={labelPlacement}
        style={{
          textAlign: labelPlacement === 'stacked' ? null : 'right',
          width: '100%'
        }}
        interface={interfc}
        value={value}
        defaultValue={defaultValue}
        onIonChange={onChange}
        placeholder={placeholder}
        multiple={multiple}
        selectedText={selectedText}
      >
        {options.map(it => {
          const value = typeof it === 'object' ? it[optionValueKey] : it;
          const text =
            typeof it === 'object' ? it[optionTextKey || 'text'] : it;
          return (
            <IonSelectOption key={value ? typeof value === 'object' ? JSON.stringify(value) : value : text} value={value}>
              {text}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};

export default TSelectItem;
