import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonList,
  IonButton
} from '@ionic/react';
import { calendarsService } from '../../services';
import { modalController } from '@ionic/core';
import '../../styles/components/AddCalendarAppointmentModal.scss';
import TModal from './TModal';
import ButtonGrid from '../ButtonGrid';
import ControlledInput from '../ControlledInput';

import {
  mail,
  business,
  browsers,
  personCircle,
  peopleCircle,
  call
} from 'ionicons/icons';

import {
  loadingIndicator,
  util,
  appNotification,
  sentry
} from '../../core';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface AddCalendarAppointmentModalProps {
  appointment: any;
  timezone: string;
  initialData?: any;
  eventType?: any;
  isOpen?: boolean;
  initialGuestEmails:Array<string>;
  onDidDismiss?: () => void;
  onAppointmentConfirmed?: () => any;
}

const AddCalendarAppointmentModal: React.FC<AddCalendarAppointmentModalProps> = ({
  appointment,
  timezone,
  initialData,
  eventType,
  onDidDismiss,
  initialGuestEmails,
  onAppointmentConfirmed,
  isOpen = false
}) => {

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  const guestEmailsRegExp = /^(((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*(\r\n)?\s?)+)*)$/
  const [showGuesEmailsField, setShowGuesEmailsField] = useState<boolean>(false);
  const [mask, setMask] = useState<string>('');

  const schema = object().shape({
    name: string().required().min(3).max(32),
    companyName: string(),
    title: string(),
    email: string().required().email(),
    cellPhone: string()
      .required('Cell phone is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(17, 'too short')
      .max(17, 'too long'),
    guestEmails: string()
      .matches(guestEmailsRegExp, '1 valid email per line.'),
  });

  const { control, handleSubmit, formState: { errors }, formState } = useForm({
    defaultValues: {
      name: initialData?.full_name,
      email: initialData?.email_address,
      companyName: initialData?.company_name,
      cellPhone: util.formatHumanReadablePhone(initialData?.phone_number),
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onError = (errors: any, e:any) => {
    console.log(errors, e, 'errors');
  }

  const formFields: any[] = [
    {
      name: 'name',
      label: 'Name*',
      autocomplete: 'no',
      icon: personCircle,
    },
    {
      name: 'email',
      label: 'Email*',
      type: 'email',
      autocomplete: 'no',
      icon: mail,

    },
    {
      name: 'cellPhone',
      label: 'Cell Phone*',
      mask: mask,
      type: 'text',
      inputmode: 'numeric',
      onFocus: () => setMask('+1 (999) 999-9999'),
      autocomplete: 'no',
      icon: call,
    },
    {
      name: 'title',
      label: 'Title',
      icon: browsers,
    },
    {
      name: 'companyName',
      label: 'Company Name',
      icon: business,
    },
    {
      name: 'guestEmails',
      multiline: true,
      label: 'Guest Email(s)',
      icon: peopleCircle,
      tagify: true,
      maxTags: 10,
      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
  ].filter((it) => {
    if (!eventType?.is_business_event) {
      return it.name !== 'companyName' && it.name !== 'title';
    }
    return true;
  });

  const { slug, eventTypeSlug, leadHash } = useParams<any>();


  let toolbar: any;

  const onSubmit = async (formData: any, e: any) => {
    try {
      await loadingIndicator.create();
      const {start, end} = appointment;
      const { guestEmails, companyName, name, title, email, cellPhone } = formData;
      let attendees = ''
      if (guestEmails) {
        attendees = `${guestEmails}\n${initialGuestEmails?.join('\n')}`;
      } else {
        attendees = initialGuestEmails?.join('\n');
      }

      const data: any = {
        start,
        end,
        name,
        email,
        title,
        timezone,
        company_name: companyName,
        cell_phone: cellPhone.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', ''),
        guest_emails: attendees,
        lead: leadHash
      };
      await calendarsService.createEvent(
        slug,
        eventTypeSlug,
        data
      );
      appNotification.toast('Your appointment is confirmed.  Check your email for details.', 'Success');
    } catch (e) {
      sentry.initialize();
      sentry.addBreadcrumb({
        message: 'Confirmed Self-Scheduling'
      });
      sentry.capture(e as Error);
    } finally {
      loadingIndicator.dismiss();
      modalController.dismiss();
      onAppointmentConfirmed?.();
    }
  };

  return (
    <TModal
      mode="md"
      className={`add-calendar-appointment-modal ${eventType?.is_business_event ? 'long-form' : 'short-form'}`}
      title={util.timezoneMoment(appointment?.start, timezone).format('llll')}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      toolbar={toolbar}
      footer={
        <ButtonGrid>
          <IonButton
            expand='block'
            disabled={!formState.isValid}
            color='success'
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            Confirm Appointment
          </IonButton>

        </ButtonGrid>
      }
    >
      <IonList lines='full'>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {formFields.filter((it) => {
            if (it.name === 'guestEmails' && !showGuesEmailsField) {
              return false;
            }
            return true;
          }).map((field, index) => (<ControlledInput errors={errors} {...field} control={control} key={index} />))}
          {!showGuesEmailsField && (
            <IonButton
              style={{marginTop: 12, marginLeft: 12}}
              size="small"
              color="secondary"
              fill="outline"
              onClick={() => setShowGuesEmailsField(true)}
            >
              Add Guests
            </IonButton>
          )}
        </form>
      </IonList>
    </TModal>
  );
};

export default AddCalendarAppointmentModal;
