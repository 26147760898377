import React from 'react';
import TImage from './TImage';
import TVideo from './TVideo';
import { IonIcon, IonLabel } from '@ionic/react';
import { attach } from 'ionicons/icons';

export interface TMediaProps {
  src: string;
  alt?: string;
  name?: string;
  style?: any;
  poster?: string;
  unsafe?: boolean;
  contentType: string;
  onClick?: (e?: any) => any;
}

const TMedia: React.FC<TMediaProps> = (props) => {
  const { contentType, poster, onClick, src, name, ...rest } = props;

  if (contentType.match(/^image\//i)) {
    return <TImage src={src} onClick={onClick} {...rest} />;
  }

  if (contentType.match(/^video\//i)) {
    return <TVideo src={src} poster={poster} {...rest} />;
  }

  // When it's neither an image nor a video
  if (name) {
    return (
      <a
        href={src}
        target='_blank'
        rel="noreferrer"
        download={name}
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <IonIcon icon={attach} style={{ marginRight: '8px' }} />
        <IonLabel>{name}</IonLabel> {/* Display the filename */}
      </a>
    );
  }

  return null;
};

export default TMedia;
