import React, { useEffect, useContext, useCallback } from 'react';
import { NotificationRow, TInfiniteScrollPage } from '../components';
import { IonButtons, IonButton } from '@ionic/react';
import { NotificationsContext } from '../context/NotificationsContext';
import { haptics } from '../core';

const NotificationsPage: React.FC = () => {
  const {
    state,
    dispatch,
    loadNotifications,
    loadNextPage,
    markSelectionRead,
    removeSelection
  } = useContext(NotificationsContext);
  // eslint-disable-next-line
  const load = useCallback(loadNotifications, []);

  useEffect(() => {
    load();
  }, [load]);

  const renderNotificationRow = (item: any) => (
    <NotificationRow key={item.id} item={item} />
  );

  const onRefresh = async () => await loadNotifications({ showLoading: false });

  const toggleEditMode = () => {
    haptics.lightImpact();
    dispatch({ type: 'set', value: { editing: !state.editing } });
  };

  return (
    <TInfiniteScrollPage
      loading={state.loading}
      headerTool={
        state.notifications?.length > 0 ? (
          <IonButton
            slot="end"
            fill="clear"
            color="secondary"
            onClick={toggleEditMode}
          >
            {state.editing ? 'Done' : 'Edit'}
          </IonButton>
        ) : (
          undefined
        )
      }
      customFooterContent={
        state.editing ? (
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={markSelectionRead}>
              {state.selection.length > 0
                ? `Mark Selected Read`
                : 'Mark All Read'}
            </IonButton>
            <IonButton color="danger" onClick={removeSelection}>
              {state.selection.length > 0 ? `Remove Selected` : 'Remove All'}
            </IonButton>
          </IonButtons>
        ) : (
          undefined
        )
      }
      onRefresh={onRefresh}
      onLoadNextPage={loadNextPage}
      items={state.notifications}
      hasNext={!!state.next}
      error={state.error}
      zeroStateText="You don't have any notifications."
      renderItem={renderNotificationRow}
    />
  );
};

export default NotificationsPage;
