import React, { useState, useContext } from 'react';
import TButtonItem from './TButtonItem';
import { leadsService } from '../services';
import { IonIcon, IonLoading } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { appNotification } from '../core';
import { LeadContext } from '../context/LeadContext';
import { Lead } from '../types/Lead';

export interface DoRoundRobinButtonsProps {
  lead: Lead;
}

const DoRoundRobinButton: React.FC<DoRoundRobinButtonsProps> = ({
  lead
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useContext(LeadContext);

  return (
    <>
      <TButtonItem
        color="secondary"
        onClick={async () => {
          setLoading(true);
          const res = await leadsService.doRoundRobin(lead.client, lead.id);
          setLoading(false);
          const msg = !!res.user_full_name ? `Lead has been assigned to ${res?.user_full_name}.` : 'No round robin users available';
          const heading = !!res.user_full_name ? 'Success' : 'Failure';
          if(res.user_id) {
            const updated = Object.assign({}, lead);
            updated.assigned_to = res.user_id;
            dispatch({ type: 'set', value: { lead: updated } });
          }

          appNotification.toast(
            msg,
            heading
          );
        }}
      >
        <IonIcon icon={personCircle} slot="start" />
        Do Round Robin
      </TButtonItem>
      <IonLoading isOpen={loading} />
    </>
  );
};

export default DoRoundRobinButton;
