import React from 'react';
import TModal from './TModal';
import { IonList, IonItemDivider, IonIcon } from '@ionic/react';
import TItem from '../TItem';
import TButtonItem from '../TButtonItem';
import ChatBubble from '../ChatBubble';
import { person, chatbubble } from 'ionicons/icons';

export interface UserTypingModalProps {
  isOpen?: boolean;
  sms: any;
  user: any;
  clientId: number;
  onDidDismiss: () => any;
}

const UserTypingModal: React.FC<UserTypingModalProps> = ({
  isOpen,
  sms,
  user,
  clientId,
  onDidDismiss
}) => {

  return (
    <TModal
      isOpen={isOpen}
      title={`${user.full_name} Typing`}
      onDidDismiss={onDidDismiss}
    >
      <IonList lines="full">
        {sms && (
          <>
            <IonItemDivider mode="md" sticky>
              <IonIcon icon={person} />
              &nbsp;Lead: {sms?.leadFullName || sms?.lid}
            </IonItemDivider>
            <TItem lines="full">
              <div style={{ width: '100%' }}>
                <ChatBubble
                  key={sms.lid}
                  id={sms.lid}
                  yellow={sms.internalOnly}
                  internal={sms.internalOnly}
                  sender={sms.fullName}
                  outgoing
                  typing
                  message={sms.messagePreview}
                />
              </div>
            </TItem>
            <TButtonItem
              color="secondary"
              routerLink={`/text-messages/conversation/${clientId}/${sms.lid}/`}
              onClick={onDidDismiss}
            >
              <IonIcon icon={chatbubble} slot="start" />
              Go to Conversation
            </TButtonItem>
          </>
        )}
      </IonList>
    </TModal>
  );
};

export default UserTypingModal;
