import { http } from '../core';
import qs from 'qs';

class CalendarsService {
  timezones: string[] = [
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Godthab",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "US/Alaska",
    "US/Arizona",
    "US/Central",
    "US/Eastern",
    "US/Hawaii",
    "US/Mountain",
    "US/Pacific"
  ]
  hoursChoices: {value: string | null, text: string}[] = [
    {
      "value": null,
      "text": "Closed"
    },
    {
      "value": "0:00",
      "text": "12:00 am"
    },
    {
      "value": "0:30",
      "text": "12:30 am"
    },
    {
      "value": "1:00",
      "text": "1:00 am"
    },
    {
      "value": "1:30",
      "text": "1:30 am"
    },
    {
      "value": "2:00",
      "text": "2:00 am"
    },
    {
      "value": "2:30",
      "text": "2:30 am"
    },
    {
      "value": "3:00",
      "text": "3:00 am"
    },
    {
      "value": "3:30",
      "text": "3:30 am"
    },
    {
      "value": "4:00",
      "text": "4:00 am"
    },
    {
      "value": "4:30",
      "text": "4:30 am"
    },
    {
      "value": "5:00",
      "text": "5:00 am"
    },
    {
      "value": "5:30",
      "text": "5:30 am"
    },
    {
      "value": "6:00",
      "text": "6:00 am"
    },
    {
      "value": "6:30",
      "text": "6:30 am"
    },
    {
      "value": "7:00",
      "text": "7:00 am"
    },
    {
      "value": "7:30",
      "text": "7:30 am"
    },
    {
      "value": "8:00",
      "text": "8:00 am"
    },
    {
      "value": "8:30",
      "text": "8:30 am"
    },
    {
      "value": "9:00",
      "text": "9:00 am"
    },
    {
      "value": "9:30",
      "text": "9:30 am"
    },
    {
      "value": "10:00",
      "text": "10:00 am"
    },
    {
      "value": "10:30",
      "text": "10:30 am"
    },
    {
      "value": "11:00",
      "text": "11:00 am"
    },
    {
      "value": "11:30",
      "text": "11:30 am"
    },
    {
      "value": "12:00",
      "text": "12:00 am"
    },
    {
      "value": "12:30",
      "text": "12:30 am"
    },
    {
      "value": "13:00",
      "text": "1:00 pm"
    },
    {
      "value": "13:30",
      "text": "1:30 pm"
    },
    {
      "value": "14:00",
      "text": "2:00 pm"
    },
    {
      "value": "14:30",
      "text": "2:30 pm"
    },
    {
      "value": "15:00",
      "text": "3:00 pm"
    },
    {
      "value": "15:30",
      "text": "3:30 pm"
    },
    {
      "value": "16:00",
      "text": "4:00 pm"
    },
    {
      "value": "16:30",
      "text": "4:30 pm"
    },
    {
      "value": "17:00",
      "text": "5:00 pm"
    },
    {
      "value": "17:30",
      "text": "5:30 pm"
    },
    {
      "value": "18:00",
      "text": "6:00 pm"
    },
    {
      "value": "18:30",
      "text": "6:30 pm"
    },
    {
      "value": "19:00",
      "text": "7:00 pm"
    },
    {
      "value": "19:30",
      "text": "7:30 pm"
    },
    {
      "value": "20:00",
      "text": "8:00 pm"
    },
    {
      "value": "20:30",
      "text": "8:30 pm"
    },
    {
      "value": "21:00",
      "text": "9:00 pm"
    },
    {
      "value": "21:30",
      "text": "9:30 pm"
    },
    {
      "value": "22:00",
      "text": "10:00 pm"
    },
    {
      "value": "22:30",
      "text": "10:30 pm"
    },
    {
      "value": "23:00",
      "text": "11:00 pm"
    },
    {
      "value": "23:30",
      "text": "11:30 pm"
    }
  ];

  async retrieveEvents(
    slug: any,
    from: Date,
    to: Date,
    calendarId: string
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
        calendar_id: calendarId,
      } as any;
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/calendars/${slug}/events/?${query}`,
        exclusive: false,
        type: `user-calendar-events-${slug}-${calendarId}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveEventTypes(
    slug: any
  ) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/calendars/${slug}/event-types/`,
        exclusive: true,
        type: `user-calendar-event-types-${slug}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveEventType(
    slug: any,
    eventTypeSlug: any,
  ) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/calendars/${slug}/event-types/${eventTypeSlug}/`,
        exclusive: true,
        type: 'user-calendar-event-type'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async createEvent(slug?: string, eventTypeSlug?: string, data?: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/calendars/${slug}/event-types/${eventTypeSlug}/reservations/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async listCalendars() {
    try {
      const { data } = await http.authorizedRequest({
        url: `/calendars/list-calendars/`,
        exclusive: true,
        type: 'list-calendars'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveLeadInfo(
    urlhash: string
  ) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/calendars/lead/${urlhash}/`,
        exclusive: true,
        type: 'calendar-lead-info'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveUserInfo(
    slug: any
  ) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/calendars/${slug}/`,
        exclusive: true,
        type: `user-calendar-${slug}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
const calendarsService = new CalendarsService();
export default calendarsService;
