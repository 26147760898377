import React, { useState, useContext } from 'react';
import { RealTimeCard, HapticButton } from '../components';
import { MonitorFiltersModal } from '../components/modals';
import { useLiveDBRef, useDebounce, useDarkTheme } from '../hooks';
import { AppContext } from '../context/AppContext';
import { IonCard, IonGrid, IonRow, IonCol, IonItem } from '@ionic/react';
import { funnel, play, pause } from 'ionicons/icons';
export interface ChangeObj {
  value: any;
  key: string;
}

const RealtimeActivity: React.FC = () => {
  const { state } = useContext(AppContext);
  const { clients, user, selectedClientId } = state;
  const clientIds: any = clients.map((it: any) => it.id);
  const isDarkTheme = useDarkTheme();
  const [staffOnly, setStaffOnly] = useState<boolean>(!!user.is_staff);
  const [typing, setTyping] = useState<any>();
  const [videoMeetings, setVideoMeetings] = useState<any>();
  const [calls, setCalls] = useState<any>();
  const [paused, setPaused] = useState(false);
  const [showCalls, setShowCalls] = useState<boolean>(true);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showTyping, setShowTyping] = useState<boolean>(true);
  const [showVideoMeetings, setShowVideoMeetings] = useState<boolean>(true);
  const [selectedClientOnly, setSelectedClientOnly] = useState<boolean>(false);

  const filterFunc = (it: any) => {
    if (!it.client) {
      it.client = it.clientId;
    }
    if (selectedClientOnly) {
      return it.client === selectedClientId;
    }
    return user.is_staff ? true : clientIds.includes(it.client)
  }

  const onActivity = useDebounce((val: any) => {
    const calls: any = val?.calls
      ? Object.values(val.calls).filter(filterFunc)
      : [];
    const videoMeetings: any = val?.videoMeetings
      ? Object.values(val.videoMeetings).filter(filterFunc)
      : [];
    const typing: any = val?.typing
      ? Object.values(val.typing).filter(filterFunc)
      : [];
    setCalls(calls);
    setVideoMeetings(videoMeetings);
    setTyping(typing);
  }, 30);

  useLiveDBRef(`/in-progress/`, onActivity, !paused);

  return (
    <IonCard color={isDarkTheme ? 'dark' : 'light'}>
      <IonItem lines='full'>
        <HapticButton
          onClick={() => setPaused(!paused)}
          icon={paused ? play : pause}
          slot='end'
          size="default"
        />
        <HapticButton
          icon={funnel}
          onClick={() => setShowFilters(true)}
          className='btn-sm-padding'
          slot='end'
          size="default"
        />
        Real-time Activity
      </IonItem>
      <IonGrid style={{ marginBottom: 30 }}>
        <IonRow>
          {!calls && !videoMeetings && !typing && (
            <IonCol>No currently active users</IonCol>
          )}
          {showVideoMeetings &&
            !!videoMeetings &&
            videoMeetings
              ?.filter((videoMeeting: any) =>
                staffOnly
                  ? videoMeeting.user?.is_staff
                  : user?.is_staff
                  ? true
                  : !videoMeeting.user?.is_staff
              )
              .map((videoMeeting: any) => {
                return (
                  <RealTimeCard
                    key={videoMeeting?.sid}
                    user={videoMeeting?.user}
                    clientId={videoMeeting?.client}
                    videoMeeting={videoMeeting}
                  />
                );
              })}
          {showCalls &&
            !!calls &&
            calls
              ?.filter((call: any) =>
                staffOnly
                  ? call.user_is_staff
                  : user?.is_staff
                  ? true
                  : !call.user?.user_is_staff
              )
              .map((call: any) => {
                const user: any = {
                  first_name: call.user_full_name?.split(' ')[0],
                  last_name: call.user_full_name?.split(' ')?.[1],
                  full_name: call.user_full_name,
                  id: call.user,
                  is_staff: call.user_is_staff,
                };
                return (
                  <RealTimeCard
                    key={call.call_sid}
                    user={user}
                    clientId={call?.client}
                    call={call}
                  />
                );
              })}

          {showTyping &&
            !!typing &&
            typing
              ?.filter((sms: any) =>
                staffOnly ? sms.isStaff : user?.is_staff ? true : !sms.isStaff
              )
              .map((sms: any, i: number) => {
                const user: any = {
                  first_name: sms.fullName?.split(' ')[0],
                  last_name: sms.fullName?.split(' ')?.[1],
                  full_name: sms.fullName,
                  id: sms.uid,
                  is_staff: sms.isStaff,
                };
                return (
                  <RealTimeCard
                    key={`sms-${i}`}
                    user={user}
                    clientId={sms?.clientId}
                    sms={sms}
                  />
                );
              })}
        </IonRow>
      </IonGrid>
      {showFilters && (
        <MonitorFiltersModal
          onDidDismiss={() => setShowFilters(false)}
          onApplyFilters={(filters) => {
            setShowFilters(false);
            setShowCalls(filters.showCalls);
            setShowVideoMeetings(filters.showVideoMeetings);
            setSelectedClientOnly(filters.selectedClientOnly);
            setShowTyping(filters.showTyping);
            setStaffOnly(filters.staffOnly);
          }}
          filters={{ showCalls, showTyping, showVideoMeetings, staffOnly, selectedClientOnly }}
        />
      )}
    </IonCard>
  );
};

export default RealtimeActivity;
