import React from 'react';
import { PopoverSelector } from '../';

interface ItemProps extends Object {
  id?: number;
  label?: string;
  value?: string;
}

interface FilterSpecialsProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSelect: (filter: string) => void;
}

const FilterSpecials: React.FC<FilterSpecialsProps> = ({
  isOpen,
  onDidDismiss,
  onSelect
}) => {
  return (
    <PopoverSelector
      isOpen={isOpen}
      items={[
        {
          id: 1,
          label: 'All',
          value: 'all'
        },
        {
          id: 2,
          label: 'Active',
          value: 'active'
        },
        {
          id: 3,
          label: 'Pending',
          value: 'pending'
        },
        {
          id: 4,
          label: 'Inactive',
          value: 'inactive'
        }
      ]}
      filter={(i: ItemProps) => i.label ?? ''}
      idField='id'
      renderLabel={(i: ItemProps) => i.label ?? ''}
      onSelectionMade={(e: ItemProps) => {
        onSelect(e.value ?? 'all')
      }}
      onDidDismiss={onDidDismiss}
    />
  )
};

export default FilterSpecials;