import { http } from '../core';
import moment from 'moment';

class ArchivedLeadsService {
  async getArchivedLeads(clientId: number, since: string, until: string) {
    try {
      const params = new URLSearchParams();
      if (since) params.append('since', since);
      if (until) params.append('until', until);

      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/client/${clientId}/users-archives/`,
        params,
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async getClientArchivedLeads(clientId: number, since: string, until: string) {
    try {
      const params = new URLSearchParams();
      if (since) params.append('since', since);
      if (until) params.append('until', until);

      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/client/${clientId}/archives/`,
        params,
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async listArchivedLeadsToday(clientId: number) {
    try {
      const since = moment().startOf('day').toISOString();
      const until = moment().endOf('day').toISOString();
      const data = this.getClientArchivedLeads(clientId, since, until);
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const archivedLeadsService = new ArchivedLeadsService();
export default archivedLeadsService;
