import React from 'react';
import css from 'classnames';
import { IonRippleEffect } from '@ionic/react';

export interface RippleContainerProps
  extends React.ComponentProps<typeof IonRippleEffect> {
  className?: string;
  children?: any;
}

const RippleContainer: React.FC<RippleContainerProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div className={css('ion-activatable ripple-parent', className)}>
      {children}
      <IonRippleEffect {...rest} />
    </div>
  );
};

export default RippleContainer;
