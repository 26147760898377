import React from 'react';
import TChart from './TChart';
const ExpandedDataTableRowTeamChart: React.FC<any> = (props: any) => {
  const labels = props?.data?.daysData?.map((it: any) => it.day);

  const datasets = [
    {
      label: 'Engagement Rate',
      data: [],
      fill: false,
      borderColor: '#93B5C6'
    },
    {
      label: 'Set Rate',
      data: [],
      fill: false,
      borderColor: '#DDEDAA'
    },
    {
      label: 'Show Rate',
      data: [],
      fill: false,
      borderColor: '#F0CF65'
    },
    {
      label: 'Sold Rate',
      data: [],
      fill: false,
      borderColor: '#D7816A'
    },
    {
      label: 'Avg Q',
      data: [],
      fill: false,
      borderColor: '#BD4F6C'
    }
  ] as any;

  const countsDatasets = [
    {
      label: 'New Leads',
      data: [],
      fill: false,
      borderColor: '#93B5C6',
      backgroundColor: '#93B5C6'
    },
    {
      label: 'Engaged Leads',
      data: [],
      fill: false,
      borderColor: '#DDEDAA',
      backgroundColor: '#DDEDAA'
    },
    {
      label: 'Set Appointments',
      data: [],
      fill: false,
      borderColor: '#F0CF65',
      backgroundColor: '#F0CF65'
    },
    {
      label: 'Shown Appointments',
      data: [],
      fill: false,
      borderColor: '#D7816A',
      backgroundColor: '#D7816A'
    },
    {
      label: 'Sold Appointments',
      data: [],
      fill: false,
      borderColor: '#DEC5E3',
      backgroundColor: '#DEC5E3'
    }
  ] as any;

  const cpslDatasets = [
    {
      label: 'FB CPSL',
      data: [],
      fill: false,
      borderColor: '#B6DCFE',
      backgroundColor: '#B6DCFE'
    }
  ] as any;
  props?.data?.daysData?.forEach((it: any) => {
    datasets[0].data.push((it.engagement_rate*100).toFixed(2));
    datasets[1].data.push((it.set_rate*100).toFixed(2));
    datasets[2].data.push((it.show_rate*100).toFixed(2));
    datasets[3].data.push((it.sold_rate*100).toFixed(2));
    datasets[4].data.push((it.avg_queue_count).toFixed(1));
    countsDatasets[0].data.push(it.new_leads_count);
    countsDatasets[1].data.push(it.engaged_new_leads_count);
    countsDatasets[2].data.push(it.sets_count);
    countsDatasets[3].data.push(it.shows_count);
    countsDatasets[4].data.push(it.solds_count);
    cpslDatasets[0].data.push(it.cpsl);
  });
  return (
    <>
      <TChart
        type="line"
        legend={true}
        datasets={datasets}
        labels={labels}
      />
      <TChart
        type="bar"
        legend={true}
        datasets={countsDatasets}
        labels={labels}
      />
      <TChart
        type="bar"
        legend={true}
        datasets={cpslDatasets}
        labels={labels}
      />
    </>
  );
};

export default ExpandedDataTableRowTeamChart;
