import React, { useCallback, useState } from 'react';
import { TReportsPage, TChart, TItemGroup } from '../components';
import { reportsService } from '../services';
import { LeadListModal } from '../components/modals';

import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { util } from '../core';

const sum = (arr: any, prop: string) => {
  return arr.reduce((a: any, curr: any) => a + (!!curr[prop] ? curr[prop] : 0), 0);
}

const LeadSourcesPage: React.FC = () => {
  const [modalData, setModalData] = useState<any>();
  const loadData = useCallback(async ({ since, until, clientId }: any) => {
  const [appointments, leads] = await Promise.all([
    reportsService.retrieveProviderAppointments(clientId, since, until),
    reportsService.retrieveProviderLeads(clientId, since, until)
  ]);
  // Combine leads and appointments in to 1 array
  const leadProviders: any = [];
  leads.data.forEach((el: any) => {
     const appts = appointments.data.find((o: any) => el.label === o.label);
     leadProviders.push({
       ...el,
       ...appts
     })
   });
    return {
      leadProviders
    };
  }, []);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
      downloadData={(leads) => {
        const rows = leads.leadProviders.map((lead: any) => {
          return {
            provider: lead.label,
            leads: lead.leads.length,
            set: lead.set_count,
            showed: lead.showed_count,
            sold: lead.sold_count
          }
        })
        rows.push({
          provider: 'Totals',
          leads: util.calculateTotal(rows, 'leads'),
          set: util.calculateTotal(rows, 'set'),
          showed: util.calculateTotal(rows, 'showed'),
          sold: util.calculateTotal(rows, 'sold')
        })
        return rows;
      }}
    >
      {({ state }) => {
        const { data } = state;
        const { leadProviders } = data;
        const totals = leadProviders?.map?.((it: any) => it.value);
        return (
          <TChart
            type="pie"
            title="Leads by Provider"
            data={totals}
            labels={leadProviders.map?.((it: any) => it.label)}
            legend
          >
            <IonList lines="full" className="reports">
              <TItemGroup title="Provider Breakdown">
                <IonItem
                  className="no-margin no-padding provider-grid"
                  lines="none"
                >
                  <IonGrid>
                    <IonRow className="column-header">
                      <IonCol>Provider</IonCol>
                      <IonCol>Leads</IonCol>
                      <IonCol>Set</IonCol>
                      <IonCol>Showed</IonCol>
                      <IonCol>Sold</IonCol>
                    </IonRow>
                    {leadProviders?.map?.((it: any) => (
                      <IonRow key={it?.label}>
                        <IonCol>{it?.label}</IonCol>
                        <IonCol
                          style={{cursor: 'pointer'}}
                          onClick={(e: any) => {
                            e.preventDefault();
                            setModalData({
                              title: `${it?.value} Leads`,
                              leadsCount: it?.value,
                              leads: it?.leads
                            })
                          }}
                        >
                          {util.formatNumber(it.value)}
                        </IonCol>
                        <IonCol
                          style={{cursor: 'pointer'}}
                          onClick={(e: any) => {
                            e.preventDefault();
                            setModalData({
                              leadsCount: it.set_count,
                              title: `${it.label} Set`,
                              leads: it.set_leads
                            })
                          }}
                        >
                          {util.formatNumber(it.set_count)}
                        </IonCol>
                        <IonCol
                          style={{cursor: 'pointer'}}
                          onClick={(e: any) => {
                            e.preventDefault();
                            setModalData({
                              leadsCount: it.showed_count,
                              title: `${it.label} Showed`,
                              leads: it.showed_leads
                            })
                          }}
                        >
                          {util.formatNumber(it.showed_count)}
                        </IonCol>
                        <IonCol
                          style={{cursor: 'pointer'}}
                          onClick={(e: any) => {
                            e.preventDefault();
                            setModalData({
                              leadsCount: it.sold_count,
                              title: `${it.label} Sold`,
                              leads: it.sold_leads
                            })
                          }}
                        >
                          {util.formatNumber(it.sold_count)}
                        </IonCol>
                      </IonRow>
                    ))}
                    <IonRow>
                      <IonCol>Totals</IonCol>
                      <IonCol>{sum(leadProviders, 'value')}</IonCol>
                      <IonCol>{sum(leadProviders, 'set_count')}</IonCol>
                      <IonCol>{sum(leadProviders, 'showed_count')}</IonCol>
                      <IonCol>{sum(leadProviders, 'sold_count')}</IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </TItemGroup>
              {modalData && (
                <LeadListModal
                  title={modalData.title}
                  isOpen={true}
                  leads={modalData.leads}
                  leadsCount={modalData.leadsCount}
                  onDidDismiss={() => {setModalData(undefined)}}
                />
              )}
            </IonList>
          </TChart>
        );
      }}
    </TReportsPage>
  );
};

export default LeadSourcesPage;
