import PagedService from './paged-service';
import { http } from '../core';
import qs from 'qs';

class MailDropService extends PagedService {

  async list({ clientId, params }: any) {
    try {
      params.page_size = 500;
      const query = qs.stringify(params);
      console.log(`/clients/${clientId}/maildrops/?${query}`)
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/maildrops/?${query}`,
        type: 'maildrops',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(clientId: number, id: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/maildrops/${id}/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, d: any) {
    try {
      const formData = new FormData();
      Object.keys(d).forEach((k: string) => {
        if (d[k] !== null && d[k] !== undefined) {
          formData.append(k, d[k]);
        }
      });
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/maildrops/`,
        data: formData
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async getTempDownloadToken() {
    try {
      const response = await http.authorizedRequest({
        method: 'POST',
        url: '/temp-download-token/',
      });
      return response.data.token;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async delete(clientId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/maildrops/${id}/`
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const mailDropService = new MailDropService();
export default mailDropService;
