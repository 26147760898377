import PagedService from './paged-service';
import { http } from '../core';

interface ListProps {
  clientId: number;
  path: string;
}

class SpecialsService extends PagedService {
  async list({ clientId, path }: ListProps) {
    const filter = () => {
      if (path === 'all') return '';
      if (path === 'pending') return `starts_at__gt=${encodeURIComponent(new Date().toISOString())}`;
      if (path === 'inactive') return 'filter=inactive';
      return `filter=active`;
    }

    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/specials/?${filter()}&ordering=-created_at/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, postData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/specials/`,
        data: postData
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async patch(clientId: number, patchData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/specials/${patchData.id}/`,
        data: patchData
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async end(clientId: number, id: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/specials/${id}/`
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const specialsService = new SpecialsService();
export default specialsService;