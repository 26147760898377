import React, { useContext, useState, useEffect } from 'react';
import { TPage, TSearchBar } from '../components';
import {
  IonList,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonText,
  IonBadge
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import useRequireAdmin from '../hooks/useRequireAdmin';
import { EditClientNumberModal } from '../components/modals';
import { clientNumbersService } from '../services';
import { util } from '../core';

const ClientNumbersPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { clientNumbers, selectedClientId } = state;
  const [searchValue, setSearchValue] = useState('');
  const [numbers, setNumbers] = useState(clientNumbers);
  const [selectedNumber, setSelectedNumber] = useState();

  useEffect(() => setNumbers(clientNumbers), [clientNumbers]);
  useRequireAdmin();

  const reloadNumbers = async () => {
    try {
      const res = await clientNumbersService.request(selectedClientId ?? 0);
      dispatch({
        type: 'set',
        value: {
          clientNumbers: res
        }
      });
      util.delay(() => {
        if (searchValue) {
          search(searchValue);
        }
      }, 1000);
    } finally {
    }
  };

  const search = (val: string) => {
    setSearchValue(val);
    setNumbers(
      val
        ? clientNumbers.filter((u: any) =>
            `${u.script.name} ${u.twilio_number}`
              .toLowerCase()
              .match(val?.toLowerCase())
          )
        : clientNumbers
    );
  };

  return (
    <TPage
      loading={false}
      preContent={
        <IonItemDivider>
          <TSearchBar value={searchValue} onSearch={search} />
        </IonItemDivider>
      }
    >
      <IonList lines="full">
        {numbers?.map?.((number: any) => (
          <IonItem
            key={number.id}
            className="ion-activatable pointer"
            onClick={() => setSelectedNumber(number)}
          >
            <IonLabel>
              <IonText className="ion-multiline">
                <h3>{number.script.name}</h3>
                <p className="detail">
                  {number.twilio_number} • {number.assigned_user ?? 'Unassigned'}
                </p>
              </IonText>
            </IonLabel>
            {number.mail_drop && (
              <IonBadge color="success" mode="ios" slot="end">
                Mailer
              </IonBadge>
            )}
            {number.default && (
              <IonBadge color="secondary" mode="ios" slot="end">
                Default
              </IonBadge>
            )}
          </IonItem>
        ))}
      </IonList>
      {selectedNumber && (
        <EditClientNumberModal
          number={selectedNumber}
          onDidDismiss={() => {
            reloadNumbers();
            setSelectedNumber(undefined);
          }}
        />
      )}
    </TPage>
  );
};

export default ClientNumbersPage;
