import React, { useState, useContext, useCallback } from 'react';
import {
  TInfiniteScrollPage,
  TSearchBar,
  CreditApplicationRow
} from '../components';
import { IonItemDivider } from '@ionic/react';
import { creditApplicationsService } from '../services';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';

const CreditApplicationsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const [search, setSearch] = useState<string | null>(null);

  const loadCreditApplications = useCallback(async () => {
    return await creditApplicationsService.list({
      clientId: state.selectedClientId,
      search
    });
  }, [state.selectedClientId, search]);

  const { next, error, loadNext, loading, data, refresher } = useDataLoader(
    loadCreditApplications,
    true
  );

  return (
    <TInfiniteScrollPage
      onLoadNextPage={loadNext}
      hasNext={!!next}
      toolbarLoadingIndicator={!!search && loading}
      onRefresh={refresher}
      toolbar={
        <IonItemDivider className="no-padding no-margin">
          <TSearchBar
            onSearch={(value: string) => {
              setSearch(value);
            }}
            style={{ width: '100%' }}
            placeholder="Search Applications"
          />
        </IonItemDivider>
      }
      zeroStateText={
        search ? 'No Applications Found Matching Criteria' : 'No Applications'
      }
      renderItem={(item: any) => (
        <CreditApplicationRow key={item.id} item={item} />
      )}
      items={data}
      loading={loading}
      error={error}
    />
  );
};

export default CreditApplicationsPage;
