import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import React, { useContext, useMemo, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { Menu, LeadSidebar, SwitchClient } from './components';
import { UpdateModal } from './components/modals';
import { AppContext } from './context/AppContext';
import { ChatMessageBoxContextProvider } from './context/ChatMessageBoxContext';
import { LeadContextProvider } from './context/LeadContext';
import { NotificationsContextProvider } from './context/NotificationsContext';
import appPages from './core/pages';
import { environment } from './core';
import {
  UserCalendarEventTypes,
  UserReserveCalendarSlot,
  Settings,
  MailDropList,
  AddMailDropStep1,
  AddMailDropStep2,
  AddMailDropStep3,
  ArchivedLeadsReport,
  CalendarSettings,
  Dashboard,
  TopClosers,
  EmailDeliveryReport,
  TextMessages,
  TextMessageConversation,
  PhoneCalls,
  Appointments,
  CreditApplications,
  QuickQualifications,
  Blasts,
  UpcomingBlasts,
  ColdLeadFollowupConfigs,
  ColdLeadEmailFollowupConfigs,
  ReEngagedLeadFollowupConfigs,
  VehicleAcquisitionLeadFollowupConfigs,
  BirthdayFollowupConfigs,
  ContestantDetails,
  ContestantDetailsItems,
  AppointmentReminders,
  PostAppointmentFollowup,
  PerLeadBilling,
  AppointmentLostFollowup,
  Monitor,
  Notifications,
  FormPost,
  KPIs,
  KPIReport,
  BlastReport,
  AppointmentReport,
  TeamReport,
  TeamCallReport,
  NeedsCallReport,
  MyActivity,
  TeamActivity,
  TeamCallActivity,
  TeamUserActivityReport,
  LeadSources,
  ClientSettings,
  ClientAutoReplies,
  ClientEmailAutoReplies,
  ClientUsers,
  ClientWidget,
  ClientEmbedCreditApp,
  ClientNumbers,
  CustomerBlastAccounting,
  EditClientUser,
  InboundQueue,
  NinjaReport,
  TypingUsers,
  VehicleShowcase,
  TrainingCategories,
  TrainingQuizzes,
  TrainingQuiz,
  Contests,
  ContestDetail,
  Surveys,
  PersonalizedLead,
  InboundQueueDebugView,
  FacebookAdCampaigns,
  FacebookAdSets,
  FacebookAdSet,
  FacebookAd,
  CovidInformation,
  CommissionReport,
  MyCommissionReport,
  SmsUsage,
  SmsUsageMaster,
  SmsDeliveryStatus,
  AddBlast,
  NinjaTeamCommissions,
  ChooseBlastPreset,
  Specials,
  AutoBotClientSettings,
  AutoBots,
  AutoBotSettings,
  Hours,
  AutoBotWizard,
} from './pages';
import { userService } from './services';
import { useLiveDBRef } from './hooks';
import { TrainingContextProvider } from './context/TrainingContext';
import { MailDropContextProvider } from './context/MailDropContext';

export interface AuthorizedViewProps {
  initialState: any;
  showUpdateModal: boolean;
  setShowUpdateModal: any;
}

const StandardRouter: React.FC = () => {
  return (
    <IonRouterOutlet id='main'>
      <Route exact path='/text-messages/' component={TextMessages} />
      <Route exact path='/inbound-queue/' component={InboundQueue} />
      <Route
        exact
        path='/inbound-queue/conversation/:clientId/:leadId/'
        component={TextMessageConversation}
      />
      <Route
        exact
        path='/needs-call/conversation/:clientId/:leadId/'
        component={TextMessageConversation}
      />
      <Route
        exact
        path='/text-messages/conversation/:clientId/:leadId/'
        component={TextMessageConversation}
      />
      <Route exact path='/phone-calls/' component={PhoneCalls} />
      <Route exact path='/appointments/' component={Appointments} />
      <Route
        exact
        path='/credit-applications/'
        component={CreditApplications}
      />
      <Route
        exact
        path='/quick-qualifications/'
        component={QuickQualifications}
      />
      <Route exact path='/surveys/' component={Surveys} />
      <Route exact path='/contests/' component={Contests} />
      <Route exact path='/contests/:contestId' component={ContestDetail} />
      <Route
        exact
        path='/contests/contestant/:contestantId/:objectType/'
        component={ContestantDetailsItems}
      />
      <Route
        exact
        path='/contests/contestant/:contestantId/'
        component={ContestantDetails}
      />
      <Route exact path='/notifications/' component={Notifications} />
      <Route exact path='/blasts/:type/' component={Blasts} />
      <Route
        exact
        path='/blasts/:type/choose-preset/'
        component={ChooseBlastPreset}
      />
      <Route
        exact
        path='/blasts/:type/add/:addType?/:presetId?'
        component={AddBlast}
      />
      <Route
        exact
        path='/followup-settings/'
        component={ColdLeadFollowupConfigs}
      />
      <Route
        exact
        path='/email-followup-settings/'
        component={ColdLeadEmailFollowupConfigs}
      />
      <Route
        exact
        path='/reengaged-lead-followup/'
        component={ReEngagedLeadFollowupConfigs}
      />
      <Route
        exact
        path='/vehicle-acquisition-lead-followup/'
        component={VehicleAcquisitionLeadFollowupConfigs}
      />
      <Route
        exact
        path='/birthday-followup/'
        component={BirthdayFollowupConfigs}
      />
      <Route
        exact
        path='/appointment-reminders/'
        component={AppointmentReminders}
      />
      <Route
        exact
        path='/post-appointment-:token/'
        component={PostAppointmentFollowup}
      />
      <Route
        exact
        path='/appointment-lost-followup/'
        component={AppointmentLostFollowup}
      />
      <Route exact path='/upcoming-blasts/:type/' component={UpcomingBlasts} />
      <Route exact path='/monitor/' component={Monitor} />
      <Route exact path='/reports/kpis/' component={KPIs} />
      <Route
        exact
        path='/reports/archived-leads/'
        component={ArchivedLeadsReport}
      />
      <Route
        exact
        path='/reports/per-lead-billing/'
        component={PerLeadBilling}
      />
      <Route exact path='/reports/kpi-report/' component={KPIReport} />
      <Route
        exact
        path='/reports/ninja-team-commissions/'
        component={NinjaTeamCommissions}
      />
      <Route exact path='/reports/:type-blasts/' component={BlastReport} />
      <Route
        exact
        path='/reports/:type-appointments/'
        component={AppointmentReport}
      />
      <Route exact path='/reports/teams/' component={TeamReport} />
      <Route exact path='/reports/team-calls/' component={TeamCallReport} />
      <Route exact path='/reports/needs-call/' component={NeedsCallReport} />
      <Route exact path='/reports/my-activity/' component={MyActivity} />
      <Route
        exact
        path='/reports/team-activity-old/'
        component={TeamActivity}
      />
      <Route
        exact
        path='/reports/team-activity/'
        component={TeamUserActivityReport}
      />
      <Route
        exact
        path='/reports/team-call-activity/'
        component={TeamCallActivity}
      />
      <Route
        exact
        path='/reports/customer-blast-accounting/'
        component={CustomerBlastAccounting}
      />
      <Route exact path='/reports/lead-sources/' component={LeadSources} />
      <Route exact path='/reports/sms-usage/' component={SmsUsage} />
      <Route exact path='/admin/sms-usage/' component={SmsUsageMaster} />
      <Route exact path='/reports/email-delivery/' component={EmailDeliveryReport} />
      <Route
        exact
        path='/admin/sms-delivery-status/'
        component={SmsDeliveryStatus}
      />
      <Route exact path='/reports/ninja/' component={NinjaReport} />
      <Route
        exact
        path='/reports/my-commissions/'
        component={MyCommissionReport}
      />
      <Route exact path='/reports/commissions/' component={CommissionReport} />
      <Route exact path='/maildrops/' component={MailDropList} />
      <Route exact path='/maildrops/add/' component={AddMailDropStep1} />
      <Route exact path='/maildrops/add/2/' component={AddMailDropStep2} />
      <Route exact path='/maildrops/add/3/' component={AddMailDropStep3} />

      <Route
        exact
        path='/reports/facebook-ads/:clientId/:campaignId/:adSetId/:adId/'
        component={FacebookAd}
      />
      <Route
        exact
        path='/reports/facebook-ads/:clientId/:campaignId/:adSetId/'
        component={FacebookAdSet}
      />
      <Route
        exact
        path='/reports/facebook-ads/:clientId/:campaignId/'
        component={FacebookAdSets}
      />
      <Route
        exact
        path='/reports/facebook-ads/'
        component={FacebookAdCampaigns}
      />
      <Route exact path='/client/settings/' component={ClientSettings} />
      <Route exact path='/client/auto-replies/' component={ClientAutoReplies} />
      <Route exact path='/client/email-auto-replies/' component={ClientEmailAutoReplies} />
      <Route exact path='/client/numbers/' component={ClientNumbers} />
      <Route exact path='/client/widget/' component={ClientWidget} />
      <Route exact path='/client/hours/' component={Hours} />
      <Route
        exact
        path='/client/embed-credit-app/'
        component={ClientEmbedCreditApp}
      />
      <Route exact path='/client/users/' component={ClientUsers} />
      <Route exact path='/d/:token/' component={PersonalizedLead} />
      <Route
        exact
        path='/d/:token/trade-:showTrade'
        component={PersonalizedLead}
      />
      <Route exact path='/d/:token/:feature/:id' component={PersonalizedLead} />
      <Route
        exact
        path='/client/:clientId/create/user/'
        component={EditClientUser}
      />
      <Route
        exact
        path='/client/users/:clientId/:id/'
        component={EditClientUser}
      />
      <Route exact path='/settings/' component={Settings} />
      <Route exact path='/calendar-settings/' component={CalendarSettings} />
      <Route exact path='/form-post/:url/' component={FormPost} />
      <Route exact path='/debug/typing-users/' component={TypingUsers} />
      <Route
        exact
        path='/debug/inbound-queue/'
        component={InboundQueueDebugView}
      />
      <Route
        exact
        path='/vehicle/:clientId/:leadId/:vehicleId/'
        component={VehicleShowcase}
      />
      <Route exact path='/training/' component={TrainingCategories} />
      <Route exact path='/training/:slug/' component={TrainingQuizzes} />
      <Route exact path='/training/:slug/:quizSlug/' component={TrainingQuiz} />
      <Route exact path='/c/:idOrSlug/' component={SwitchClient} />
      <Route
        exact
        path='/calendars/:slug/:leadHash?'
        component={UserCalendarEventTypes}
      />
      <Route
        exact
        path='/calendars/:slug/event/:eventTypeSlug/:leadHash?'
        component={UserReserveCalendarSlot}
      />
      <Route exact path='/covid-19/' component={CovidInformation} />
      <Route exact path='/' component={Dashboard} />
      <Route exact path='/top-closers' component={TopClosers} />
      <Route exact path='/autobots/wizard' component={AutoBotWizard} />
      <Route exact path='/autobots/specials' component={Specials} />
      <Route exact path='/autobots/settings' component={AutoBotClientSettings} />
      <Route exact path='/autobots/bots' component={AutoBots} />
      <Route exact path='/autobots/bots/:id' component={AutoBotSettings} />
    </IonRouterOutlet>
  );
};

const NinjaRouter: React.FC = () => {
  return (
    <IonRouterOutlet id='main'>
      <Route exact path='/surveys/' component={Surveys} />
      <Route exact path='/settings/' component={Settings} />
      <Route exact path='/notifications/' component={Notifications} />
      <Route exact path='/inbound-queue/' component={InboundQueue} />
      <Route exact path='/reports/ninja/' component={NinjaReport} />
      <Route
        exact
        path='/reports/my-commissions/'
        component={MyCommissionReport}
      />
      <Route exact path='/d/:token/' component={PersonalizedLead} />
      <Route
        exact
        path='/d/:token/trade-:showTrade'
        component={PersonalizedLead}
      />
      <Route exact path='/d/:token/:feature/:id' component={PersonalizedLead} />
      <Route
        exact
        path='/vehicle/:clientId/:leadId/:vehicleId/'
        component={VehicleShowcase}
      />
      <Route
        exact
        path='/inbound-queue/conversation/:clientId/:leadId/'
        component={TextMessageConversation}
      />
      <Route
        exact
        path='/needs-call/conversation/:clientId/:leadId/'
        component={TextMessageConversation}
      />
      <Route exact path='/training/' component={TrainingCategories} />
      <Route exact path='/training/:slug/' component={TrainingQuizzes} />
      <Route exact path='/training/:slug/:quizSlug/' component={TrainingQuiz} />
      <Route exact path='/c/:id/' component={SwitchClient} />
      <Route exact path='/covid-19/' component={CovidInformation} />
      <Route exact path='/' component={InboundQueue} />
    </IonRouterOutlet>
  );
};

const AuthorizedView: React.FC<AuthorizedViewProps> = ({
  initialState,
  showUpdateModal,
  setShowUpdateModal
}) => {
  const { state, dispatch } = useContext(AppContext);
  const { user, selectedClient } = state;
  const isNinja = useMemo(() => userService.isNinja(user), [user]);

  const pageFilter = useCallback(
    (it: any) =>
      (!it.canAccess || it.canAccess(user, selectedClient, state)) &&
      (!isNinja || it.allowNinjas === true),
    [user, selectedClient, isNinja, state]
  );

  const onClockInChanged = useCallback(
    async (isClockedIn: any) => {
      if (user) {
        // Instead of trusting the firebase socket,
        // go to the API as the single soure of truth.
        const res = await userService.fetchClockInStatus();
        user.is_clocked_in = res.is_clocked_in;
        dispatch({
          type: 'set',
          value: { isClockedIn: res.is_clocked_in, user },
        });
      }
    },
    [dispatch, user]
  );

  const canAccessInboundQueue = useMemo(
    () => userService.canAccessInboundQueue(user),
    [user]
  );

  useLiveDBRef(
    `/users-clocked-in/${user?.id}/`,
    onClockInChanged,
    canAccessInboundQueue
  );

  const pages = useMemo(() => {
    const filtered = [
      ...appPages.filter(pageFilter).map((it: any) => ({ ...it })),
    ];

    filtered.forEach((it: any) => {
      it.subpages = it.subpages?.filter?.(pageFilter);
    });

    return filtered;
  }, [pageFilter]);

  return (
    <IonSplitPane
      contentId='main'
      className='t-split-pane'
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    >
      <LeadContextProvider>
        <NotificationsContextProvider
          initialState={{
            unread: initialState?.unread ?? 0,
            unreadNotifications: initialState?.unreadNotifications ?? [],
          }}
        >
          <ChatMessageBoxContextProvider>
            <TrainingContextProvider>
              <MailDropContextProvider>
                <UpdateModal
                  isOpen={showUpdateModal}
                  handleClose={() => {
                    localStorage.setItem('buildNumber', environment.build)
                    setShowUpdateModal(false)
                  }}
                />
                <Menu pages={pages} />
                {isNinja ? <NinjaRouter /> : <StandardRouter />}
                <div id="waiting-lead-portal" />
                <LeadSidebar />
              </MailDropContextProvider>
            </TrainingContextProvider>
          </ChatMessageBoxContextProvider>
        </NotificationsContextProvider>
      </LeadContextProvider>
    </IonSplitPane>
  );
};

export default AuthorizedView;
