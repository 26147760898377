import React, { useState } from 'react';
import {
  IonSpinner,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react';

export interface TLoadingProps {
  visible?: boolean;
  paused?: boolean;
  absolute?: boolean;
  text?: string;
}

const TLoading: React.FC<TLoadingProps> = ({
  visible,
  text,
  paused = true,
  absolute = true
}) => {
  const [pause, setPause] = useState(paused);

  useIonViewDidEnter(() => setPause(false));
  useIonViewWillLeave(() => setPause(true));

  return (
    <div
      style={Object.assign(
        {
          alignItems: 'center',
          backgroundColor: 'var(--ion-background-color)',
          display: visible ? 'flex' : 'none',
          justifyContent: 'center',
          zIndex: 100
        },
        absolute
          ? { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }
          : { position: 'relative', height: '100%', width: '100%' }
      ) as any}
    >
      <IonSpinner
        paused={pause}
        style={{
          color: 'var(--ion-color-step-250)',
          height: 40,
          width: 40
        }}
      />
      {text && (
        <h4
          style={{
            color: 'var(--ion-color-step-500)',
            margin: 'auto 0 auto 10px',
            padding: 0
          }}
        >
          {text}
        </h4>
      )}
    </div>
  );
};

export default TLoading;
