import { useState, useRef, useCallback } from 'react';
export default function useDataRefresher() {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const resolver = useRef<any>();

  const handler = useCallback(async (e: any) => {
    await new Promise(resolve => {
      resolver.current = resolve;
      setIsRefreshing(true);
    });
  }, []);

  const onRefreshComplete = useCallback(() => {
    resolver.current?.();
    setIsRefreshing(false);
  }, []);

  return [isRefreshing, handler, onRefreshComplete] as any[];
}
