import React, { useState } from 'react';
import ButtonGrid from '../ButtonGrid';
import HapticButton from '../HapticButton';
import TModal from './TModal';
import TSelectItem from '../TSelectItem';
import styled from 'styled-components';
import TInput from '../TInput';
import { feedbackService } from '../../services';
import { appNotification, loadingIndicator } from '../../core';

const CommentsBox = styled(TInput)`
  border-top: solid 1px var(--ion-border-color);
  height: 100%;
  flex: 1 1 auto;

  .native-textarea {
    height: 100%;
    padding: 10px 15px;
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
`;

export interface FeedbackModalProps {
  onDidDismiss?: () => any;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ onDidDismiss }) => {
  const [type, setType] = useState('suggestion');
  const [comments, setComments] = useState('');

  const submitFeedback = async () => {
    try {
      if (!comments.trim()) {
        return appNotification.toast(
          'Please fill out the form completely.',
          'Error'
        );
      }
      await loadingIndicator.create();
      await feedbackService.submit(type, comments);
      appNotification.toast('Your feedback has been submitted.', 'Success');
      onDidDismiss?.();
    } catch (e) {
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TModal
      isOpen={true}
      title="Give us Feedback"
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <HapticButton
            onClick={submitFeedback}
            color="secondary"
            expand="block"
            fill="solid"
          >
            Submit
          </HapticButton>
        </ButtonGrid>
      }
    >
      <StyledContainer>
        <TSelectItem
          label="Type"
          value={type}
          options={[
            { value: 'suggestion', text: 'Suggestion' },
            { value: 'bug', text: 'Bug' }
          ]}
          onChange={(e: any) => setType(e.target.value)}
        />
        <CommentsBox
          placeholder="Comments"
          value={comments}
          onIonInput={(e: any) => setComments(e.detail.value)}
          multiline
        />
      </StyledContainer>
    </TModal>
  );
};

export default FeedbackModal;
