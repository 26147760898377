import React, { useState, useCallback, useEffect, useContext } from 'react';
import { TPage, TLoading, TAvatar, HapticButton } from '../components';
import { contestsService } from '../services';
import { IonItem, IonLabel, IonList, IonIcon, IonNote, IonText } from '@ionic/react';
import { useRouter } from '../hooks';
import { AppContext } from '../context/AppContext';
import { refresh, ribbon } from 'ionicons/icons';

const ContestDetail: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [contestants, setContestants] = useState<any>();
  const { state: appState } = useContext(AppContext);
  const { user } = appState;
  const router = useRouter();

  const goToContestantDetailPage = (contestantId: number) => {
    if (user.is_staff) {
      router.push(`/contests/contestant/${contestantId}/`, {})
    }
  }

  const loadContestDetail = useCallback(async () => {
    setLoading(true);
    let place = 1;
    const [allContestants, contest] = await Promise.all([
      contestsService.getStandings(router.query.contestId),
      contestsService.get(router.query.contestId)
    ])
    const numPrizes = contest.prizes_list.length;
    const contestants = allContestants.filter((it: any) => {
      it.avatarBadge = place > numPrizes ? undefined : <IonIcon icon={ribbon} />;
      if (place === 1) {
        it.badgeColor = 'secondary';
      } else if (place === 2) {
        it.badgeColor = 'light';
      } else if (place === 3) {
        it.badgeColor = 'danger';
      } else {
        it.badgeColor = 'medium';
      }
      it.prize = contest.prizes_list?.[place-1];
      it.place = place;
      place++;
      return (it.place <= numPrizes) || it.user === user.id;
    });
    setContestants(contestants);
    setLoading(false);
  }, [router.query.contestId, user.id]);

  useEffect(() => {
    loadContestDetail();
  }, [loadContestDetail]);

  return (
    <TPage
      headerTool={
        <HapticButton
          icon={refresh}
          onClick={() => {
            loadContestDetail();
          }}
          className="btn-sm-padding"
          slot="end"
        />
      }
      loading={loading}
      id="contest-detail"
    >
      <TLoading visible={loading} absolute={false} />
      <IonList>
        {contestants?.map((it: any) => (
          <IonItem key={it.id} className={user.is_staff ? 'pointer' : ''} onClick={() => goToContestantDetailPage(it.id)}>
            <TAvatar
              text={String(it.place)}
              badge={it?.avatarBadge}
              badgeTextColor="black"
              badgeColor={`var(--ion-color-${it.badgeColor})`}
              slot="start"
            />

            <IonLabel className="ion-multiline">
              <IonText>
                <h2>
                  {it.user_full_name} {it.user === user.id && (<em>(You)</em>)}
                </h2>
                <p className="detail">
                  {it.client_name} - {it.client_city}, {it.client_state}
                </p>
                {!!it.prize && (
                  <p className="detail">
                    {it.prize}
                  </p>
                )}
              </IonText>
            </IonLabel>
            <IonNote slot="end">{it.points} pts</IonNote>
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default ContestDetail;
