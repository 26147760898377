import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonGrid, TPage, AutoBotPromptInput } from '../components';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import { autobotService } from '../services';
import { appNotification } from '../core';
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonLabel,
  IonButton
} from '@ionic/react';
import '../styles/components/AutoBotSettings.scss';
import { AutoBotFormData } from '../types/AutoBot';

const AutoBotSettings = () => {
  const { id } = useParams() as any;
  const { state: appState } = useContext(AppContext);
  const { selectedClient, statuses, user } = appState;
  const [name, setName] = useState('');
  const [classification, setClassification] = useState('');
  const [statusIds, setStatusIds] = useState([]);
  const [lastMessageAge, setLastMessageAge] = useState(24);
  const [prompt, setPrompt] = useState('');

  const leadOptions = useMemo(() => {
    const options = ['Sales', 'Service', 'Employment', 'Collections'];
    if (user.is_staff) options.push('Service Canceled');
    return options;
  }, [user])

  const normalizeLineBreaks = (input: string) => input.replace(/\r\n/g, '\n');

  const handleSubmit = async () => {
    const data: AutoBotFormData = {
      last_message_age_hours: lastMessageAge,
      lead_classification: classification,
      pre_template: prompt,
      statuses: statusIds,
      name: name,
    }

    if (id === 'new') {
      await autobotService.create(selectedClient.id, data)
      appNotification.toast('Auto Bot created')
    } else {
      data.id = id
      await autobotService.patch(selectedClient.id, data)
      appNotification.toast('Auto Bot updated')
    }
  }

  const loadBot = useCallback(async () => {
    if (id === 'new') return;
    return await autobotService.single(selectedClient.id, id);
  }, [selectedClient, id])

  const {
    error,
    loading,
    data,
  } = useDataLoader(loadBot, false);

  useEffect(() => {
    if (!data || !id) return;
    setName(data.name)
    setStatusIds(data.statuses)
    setLastMessageAge(data.last_message_age_hours)
    setPrompt(normalizeLineBreaks(data.pre_template))
    setClassification(data.lead_classification)
  }, [data, id])

  return (
    <TPage loading={loading} error={error} headerTool={<></>}>
      <IonItem>
        <IonLabel position="stacked">Name</IonLabel>
        <IonInput
          value={name}
          onIonInput={(e: CustomEvent) =>{
            setName(e.detail.value)
          }}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Lead classification</IonLabel>
        <IonSelect
          value={classification}
          placeholder='Select the appropriate lead classification'
          multiple={false}
          onIonChange={(e) => {
            setClassification(e.detail.value)
          }}
        >
          {leadOptions.map((item) => (
            <IonSelectOption value={item} key={item}>{item}</IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Statuses</IonLabel>
        <IonSelect
          value={statusIds}
          placeholder='Select statuses to target'
          multiple={true}
          onIonChange={(e) => {
            setStatusIds(e.detail.value)
          }}
        >
          {statuses.map(stat => (
            <IonSelectOption value={stat.id} key={stat.id}>{stat.option_name}</IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Time to wait since last message</IonLabel>
        <IonInput
          type="number"
          value={lastMessageAge}
          onIonInput={(e) => {
            setLastMessageAge(Number(e.detail.value))
          }}
        />
      </IonItem>
      <AutoBotPromptInput state={prompt} setState={setPrompt} />
      <ButtonGrid>
        <IonButton
          color="secondary"
          expand="block"
          onClick={handleSubmit}
        >
          {!id ? 'Create Auto Bot' : 'Save'}
        </IonButton>
      </ButtonGrid>
    </TPage>
  )
}

export default AutoBotSettings;
