import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { TChart, TReportsPage, MetricCard } from '../components';
import { LeadListModal } from '../components/modals';
import { reportsService } from '../services';
import { util } from '../core';
import '../styles/Reports.scss';
import {
  IonLabel,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonBadge,
  IonList,
} from '@ionic/react';


const KPIs: React.FC = () => {
  const [modalData, setModalData] = useState<any>();
  const { state } = useContext(AppContext);
  const { selectedClient } = state;

  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const { classification, leadProvider, excludeSaleAppointments } = filters;
      const servicePromises = [
        reportsService.retrieveTeamActivityReport(
          clientId,
          since,
          until,
          classification,
          excludeSaleAppointments,
          leadProvider
        ),
      ];

      if (leadProvider === 20) {
        servicePromises.push(
          reportsService.retrieveFacebookAdAccountInsights(clientId, since, until),
          reportsService.retrieveAccountGrossProfit(clientId, since, until, leadProvider)
        );
      }

      const serviceData = await Promise.all(servicePromises);

      return {
        app: serviceData[0],
        leadProvider,
        adAccountInsights: serviceData[1],
        grossProfit: serviceData[2],
      };
    },
    []
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{
        showLeadProvider: true,
        showExcludeSaleAppointments: true,
      }}
    >
      {({ state }) => {
        const { data } = state;
        const soldUnits = data?.app?.appointments?.sold;
        const soldLeads = data?.app?.appointments?.sold_leads;
        const charts = [
          <TChart
            type='gauge'
            title='Engagement Rate'
            value={Math.round(
              (data?.app?.new_leads?.engaged / data?.app?.new_leads?.total) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>New Leads</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.total,
                        title: 'New Leads',
                        leads: data?.app?.new_leads?.total_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.new_leads?.total_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.new_leads?.total}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Engagement Rate</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.engaged_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.engaged,
                        title: 'Engaged Leads',
                        leads: data?.app?.new_leads?.engaged_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.new_leads?.engaged_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.new_leads?.engaged}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
          <TChart
            type='gauge'
            title='Set Rate'
            value={Math.round(
              (data?.app?.appointments?.total / data?.app?.new_leads?.engaged) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Engaged Leads</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.engaged_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.engaged,
                        title: 'Engaged Leads',
                        leads: data?.app?.new_leads?.engaged_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.new_leads?.engaged_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.new_leads?.engaged}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Appts Set</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.total,
                        title: 'Appts Set',
                        leads: data?.app?.appointments?.total_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.appointments?.total_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.appointments?.total}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
          <TChart
            type='gauge'
            title='Show Rate'
            value={Math.round(
              (data?.app?.appointments?.shows /
                data?.app?.appointments?.total) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Appts Set</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.total,
                        title: 'Appts Set',
                        leads: data?.app?.appointments?.total_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.appointments?.total_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.appointments?.total}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Appts Showed</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.shows_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.shows,
                        title: 'Appts Showed',
                        leads: data?.app?.appointments?.shows_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.appointments?.shows_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.appointments?.shows}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
          <TChart
            type='gauge'
            title='Sold Rate'
            value={Math.round(
              (soldUnits / data?.app?.appointments?.shows) * 100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Appts Showed</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.shows_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.shows,
                        title: 'Appts Showed',
                        leads: data?.app?.appointments?.shows_leads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.appointments?.total_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {data?.app?.appointments?.shows}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Appts Sold</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (soldLeads) {
                      setModalData({
                        leadsCount: soldUnits,
                        title: 'Appts Sold',
                        leads: soldLeads,
                      });
                    }
                  }}
                  style={{
                    cursor: data?.app?.appointments?.shows_leads
                      ? 'pointer'
                      : 'default',
                  }}
                  mode='ios'
                >
                  {soldUnits}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
        ];

        // Remove charts[1] avg response time if client is_full_service
        if (selectedClient.is_full_service) {
          charts.splice(1, 1);
        }

        return (
          <IonGrid className='reports'>
            {data.leadProvider === 20 && (
              <IonRow style={{marginBottom: 20}}>
                <IonCol>
                  <MetricCard
                    title='Spend'
                    value={util.formatDollar(data?.adAccountInsights?.spend)}
                  />
                </IonCol>

                <IonCol>
                  <MetricCard title='Solds' value={soldUnits} />
                </IonCol>
                <IonCol>
                  <MetricCard
                    title='Cost per Sold'
                    value={util.formatDollar(
                      data?.adAccountInsights?.spend / soldUnits
                    )}
                  />
                </IonCol>
                {/* <IonCol>
                  <MetricCard
                    title='Gross Profit'
                    value={util.formatDollar(data?.grossProfit?.gross_profit)}
                  />
                </IonCol> */}
              </IonRow>
            )}
            <IonRow>
              {charts.map((it, i) => (
                <IonCol
                  key={i}
                  sizeLg={charts.length === 7 && i === 0 ? '12' : '3'}
                  sizeXl={charts.length === 7 && i === 0 ? '12' : '3'}
                  sizeXs='12'
                  sizeSm={charts.length === 7 && i === 0 ? '12' : '6'}
                  sizeMd={charts.length === 7 && i === 0 ? '12' : '3'}
                >
                  {it}
                </IonCol>
              ))}
            </IonRow>
            {modalData && (
              <LeadListModal
                isOpen={true}
                leads={modalData.leads}
                title={modalData.title}
                leadsCount={modalData.leadsCount}
                onDidDismiss={() => {
                  setModalData(undefined);
                }}
              />
            )}
          </IonGrid>
        );
      }}
    </TReportsPage>
  );
};

export default KPIs;
