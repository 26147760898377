import React, { useCallback, useContext, useState } from 'react';
import { TInfiniteScrollPage, TFab } from '../../components';
import { appointmentLostFollowupsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { IonItem, IonLabel, IonButton, IonIcon, IonThumbnail } from '@ionic/react';
import { AppointmentLostFollowupFormModal } from '../../components/modals';
import { useDataLoader } from '../../hooks';
import styles from './AppointmentLostFollowup.module.scss';
import { add, ellipsisVertical } from 'ionicons/icons';
import { actionSheet, appNotification } from '../../core';
import moment from 'moment';
import { ActionSheetButton } from '../../types/Common';
import { AppointmentLostFollowUp } from '../../types/Appointments';

const AppointmentLostFollowups: React.FC = () => {
  const { state } = useContext(AppContext);

  const defaultConfig = {
    id: 0,
    client: state.selectedClientId,
    minutes_after_appointment: 120,
    order: 1,
    message: `Hi {{ first_name }}, I'm a manager with {{ dealership }}. I saw you visited us but didn't purchase a vehicle. Do you mind if I ask what kept you from purchasing?`
  };

  const loadAppointmentLostFollowups = useCallback(async () => {
    return await appointmentLostFollowupsService.list(state.selectedClientId);
  }, [state.selectedClientId]);

  const showMoreActionSheet = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, item: AppointmentLostFollowUp) => {
    e.stopPropagation();

    const buttons: ActionSheetButton[] = [
      {
        text: 'Delete',
        handler: () => {
          (async () => {
            await appointmentLostFollowupsService.delete(item.client, item.id);
            refresher();
            appNotification.toast(
              `Post Appointment Followup has been deleted.`,
              'Deleted'
            );
          })();
          return true;
        }
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ];

    actionSheet.show(buttons);
  };

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadAppointmentLostFollowups,
    true
  );

  const [appointmentReminder, setAppointmentReminder] = useState<AppointmentLostFollowUp>();

  return (
    <TInfiniteScrollPage
      className={styles.AppointmentLostFollowups}
      loading={loading}
      zeroStateText="Tap the green + to follow up with showed/lost appointments."
      items={data}
      error={error}
      renderItem={(item: AppointmentLostFollowUp) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setAppointmentReminder(item)}
        >
          {item.media && (
            <IonThumbnail slot="start">
              <img src={item.media} alt="Thumbnail" />
            </IonThumbnail>
          )}
          <IonLabel className="ion-multiline">
            <p>{item.message}</p>
            <p className="detail">Send {moment.duration(item.minutes_after_appointment, 'minutes').humanize()} after appointment.</p>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={(e) => {
              showMoreActionSheet(e, item)
            }}
          >
            <IonIcon slot="icon-only" icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
      fab={
        <TFab
          icon={add}
          onClick={() => setAppointmentReminder(defaultConfig)}
        />
      }
    >
      {appointmentReminder && (
        <AppointmentLostFollowupFormModal
          item={appointmentReminder}
          onDidDismiss={() => { setAppointmentReminder(undefined); refresher(); }}
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default AppointmentLostFollowups;
