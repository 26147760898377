import React, { useCallback, useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { TReportsPage, TSearchBar, ExpandedDataTableRowAccountBreakdown } from '../components';
import { IonToolbar } from '@ionic/react';
import { ninjaTeamReportService } from '../services';
import '../styles/Reports.scss';
import DataTable from 'react-data-table-component';
import { util } from '../core';
import { useDarkTheme } from '../hooks';

const customStyles: any = {
  cells: {
    style: {
      '&:nth-of-type(10)': {
        backgroundColor: '#8BC642',
      },
    },
  },
};

const TeamReport: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const isDark = useDarkTheme();
  const { state } = useContext(AppContext);
  const [reports, setReports] = useState<any>();
  const [filteredReports, setFilteredReports] = useState<any>();
  const doSearch = (val: string) => {
    const searchResults = reports?.filter((it:any) => {
      return it.team_name.toLowerCase().includes(val.toLowerCase());
    })
    setFilteredReports(searchResults);
  };

  const aggregateReport = (rpt: any) => {
    const nw: string = new Date().toISOString();
    const summedReport = {
      'cancels_count': 0,
      'cpsl': 0,
      'created_at': nw,
      'day': nw.split('T')[0],
      'engaged_new_leads_count': 0,
      'outbound_calls_count': 0,
      'avg_outbound_calls_per_account': 0,
      'engagement_rate': 0,
      'fb_ad_spend': 0,
      'fb_solds_count': 0,
      'misses_count': 0,
      'new_leads_count': 0,
      'ninja_team': 0,
      'set_rate': 0,
      'avg_queue_count': 0,
      'avg_queue_count_length': 0,
      'sets_count': 0,
      'tradeins_count': 0,
      'credit_apps_count': 0,
      'show_rate': 0,
      'shows_count': 0,
      'since': nw.split('T')[0],
      'sold_rate': 0,
      'solds_count': 0,
      'team_name': '',
      'daysData': rpt,
      'until': nw.split('T')[0]
    } as any;

    const accountBreakdownSums: any = {};

    rpt.forEach((it: any) => {
      summedReport.team_name = it.team_name;
      summedReport.new_leads_count += it.new_leads_count;
      summedReport.outbound_calls_count += it.outbound_calls_count;
      summedReport.cancels_count += it.cancels_count;
      summedReport.engaged_new_leads_count += it.engaged_new_leads_count;
      summedReport.sets_count += it.sets_count;
      summedReport.tradeins_count += it.tradeins_count;
      summedReport.credit_apps_count += it.credit_apps_count;
      summedReport.shows_count += it.shows_count;
      summedReport.solds_count += it.solds_count;
      summedReport.fb_solds_count += it.fb_solds_count;
      summedReport.fb_ad_spend += it.fb_ad_spend;
      summedReport.avg_outbound_calls_per_account += it.avg_outbound_calls_per_account;
      summedReport.avg_queue_count += it.avg_queue_count;
      summedReport.avg_queue_count_length += it.avg_queue_count ? 1 : 0;
      // add up the account breakdown numbers
      it?.account_breakdown?.forEach((account: any) => {
          if (!accountBreakdownSums[account.id]) {
              accountBreakdownSums[account.id] = { ...account };
          } else {
              accountBreakdownSums[account.id].outbound_calls_count += account.outbound_calls_count;
          }
      });
      // Convert the dictionary back to an array for your summedReport
      summedReport.account_breakdown = Object.values(accountBreakdownSums);
    });
    summedReport.cpsl = summedReport.fb_solds_count ? summedReport.fb_ad_spend/summedReport.fb_solds_count : 0;
    summedReport.engagement_rate = summedReport.new_leads_count ? summedReport.engaged_new_leads_count/summedReport.new_leads_count : 0;
    summedReport.set_rate = summedReport.engaged_new_leads_count ? summedReport.sets_count/summedReport.engaged_new_leads_count : 0;
    summedReport.avg_queue_count = summedReport.avg_queue_count_length ? summedReport.avg_queue_count/summedReport.avg_queue_count_length : 0;
    summedReport.show_rate = summedReport.sets_count ? summedReport.shows_count/summedReport.sets_count : 0;
    summedReport.sold_rate = summedReport.shows_count ? summedReport.solds_count/summedReport.shows_count : 0;
    return summedReport;
  }

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.team_name,
      sortable: true,
    },
    {
      name: 'New',
      selector: (row: any) => row.new_leads_count,
      sortable: true,
      right: true
    },
    {
      name: 'Engaged',
      selector: (row: any) => row.engaged_new_leads_count,
      sortable: true,
      right: true
    },
    {
      name: 'Outbound Calls',
      selector: (row: any) => row.outbound_calls_count,
      sortable: true,
      right: true
    },
    {
      name: 'Avg Calls Per Account',
      selector: (row: any) => row.avg_outbound_calls_per_account,
      sortable: true,
      right: true,
      format: (row: any) => row.avg_outbound_calls_per_account?.toFixed(1)
    },
  ];

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const loadData = useCallback(
    async ({ since, until, filters }: any) => {
      const ninjaTeams = filters.ninjaTeams || state.ninjaTeams;
      const requests:Array<any> = [];
      ninjaTeams.forEach((team:any) => {
        requests.push(ninjaTeamReportService.retrieveReport(
          since,
          until,
          team?.id
        ))
      });
      const reports = await Promise.all(requests);
      const teamReports:Array<any> = [];
      reports.forEach((it:any) => {
        teamReports.push(aggregateReport(it));
      });
      return {
        teamReports,
      };
    },
    [state.ninjaTeams]
  );

  const expandableRowsComponent = ({ data }: { data: any }) => <ExpandedDataTableRowAccountBreakdown data={data.account_breakdown} />;


  return (
    <TReportsPage
      segments={['Yesterday', 'Week', 'Month', 'Year']}
      onLoadData={loadData}
      filterProps={{ showNinjaTeamsFilter: true, showMultiClientSelector: false, hideClassification: true }}
    >
      {({ state }) => {
        const { data } = state;
        setReports(data.teamReports);
        return (
          <>
            <IonToolbar>
              <TSearchBar onSearch={doSearch} />
            </IonToolbar>

            <DataTable
              title="Team Report"
              columns={columns}
              data={filteredReports}
              theme={isDark ? 'tecobi-dark' : 'default'}
              pagination={filteredReports?.length > 10}
              paginationServer={false}
              highlightOnHover={true}
              customStyles={customStyles}
              expandableRows
              expandableRowsComponent={expandableRowsComponent}
            />
          </>
        );
      }}
    </TReportsPage>
  );
};

export default TeamReport;
