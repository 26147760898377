import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class NinjaTeamsService extends CachedService {
  constructor() {
    super(
      'ninjaTeams',
      'ninjaTeams',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.FIFTEENMINUTES
    );
  }

  async request() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/ninja-teams/',
      type: 'ninja-teams',
      exclusive: true
    });

    return res.data;
  }

  async get(id: number) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/ninja-teams/${id}/`
    });
    return res.data;
  }
}

const ninjaTeamsService = new NinjaTeamsService();
export default ninjaTeamsService;
