import React, { useContext, useState } from 'react';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import { IonItem, IonText, IonIcon, IonLabel } from '@ionic/react';
import { creditApplicationsService } from '../services';

import {
  document as documentIcon,
  open as openIcon,
  checkmarkCircleOutline,
  arrowUpCircleOutline,
} from 'ionicons/icons';
import moment from 'moment';
import TButtonItem from './TButtonItem';
import { util, loadingIndicator } from '../core';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';
import { SoftPullModal } from './modals';
import TToggleItem from './TToggleItem';

const LeadCredit: React.FC = () => {
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const { state, updateAndSaveLead } = useContext(LeadContext);
  const { lead } = state;
  const creditApps = lead?.credit_applications || [];
  const softPulls = lead?.quick_qualifications || [];
  const [softPull, setSoftPull] = useState<any>();
  const isSoftPull = lead?.client_soft_pulls === true;

  const append = (c: string) => {
    appendMessage(c);
    util.hideLeadSidebar();
  };

  const count = (creditApps?.length ?? 0) + (softPulls?.length ?? 0);
  const badgeValue = count ? count : lead?.no_credit_app_needed ? 'N/A' : 0;
  const badgeColor = badgeValue === 'N/A' ? 'warning' : undefined;

  return (
    <TExpandableItemGroup
      title='Credit'
      badge={badgeValue}
      badgeColor={badgeColor}
    >
      {!count && (
        <TToggleItem
          label='No Credit App Needed'
          checked={!!lead?.no_credit_app_needed}
          onIonChange={(e: any) => {
            const value = e.detail.checked ? new Date().toISOString() : null;
            const ce = new CustomEvent('', { detail: { value } });
            updateAndSaveLead('no_credit_app_needed', ce);
          }}
          wrapLabel
        />
      )}

      {creditApps?.map((app: any) => (
        <IonItem
          key={app.id}
          className='pointer'
          onClick={async () => await creditApplicationsService.openApplicationPDF(app.id)}
        >
          <IonIcon icon={documentIcon} slot='start' />
          <IonLabel className='ion-multiline'>
            <IonText>
              {lead?.full_name}
              <span className='detail'>
                Co-Buyer: {app.cobuyer_lead?.full_name}
              </span>
              <span className='detail'>{moment(app.created_at).fromNow()}</span>
            </IonText>
          </IonLabel>
          <IonIcon icon={openIcon} slot='end' />
        </IonItem>
      ))}
      {softPulls?.map((pull: any) => (
        <IonItem
          key={pull.guid}
          className='pointer'
          onClick={() => {
            setSoftPull(pull);
          }}
        >
          <IonIcon icon={checkmarkCircleOutline} slot='start' />
          <IonLabel className='ion-multiline'>
            <IonText>
              <span className='detail'>Soft Pull</span>
              <span>
                {pull.first_name} {pull.last_name}
              </span>
              <span className='detail'>
                {pull.bureau_name} • {pull.score_range}{' '}
                {pull.result_description ? ' • ' : ''} {pull.result_description}
              </span>
              <span className='detail'>
                {moment(pull.created_at).fromNow()}
              </span>
            </IonText>
          </IonLabel>
        </IonItem>
      ))}
      {isSoftPull && (
        <TButtonItem
          color='secondary'
          onClick={async () => {
            try {
              await loadingIndicator.create();
              const softPullUrl = `https://ca.tecobi.com/quick-qualify/${lead?.client}/${lead?.id}/`;
              const message = `Here is the link to quickly get pre-qualified: ${softPullUrl} Your PIN code is: ${lead?.pin_code}.`;
              append(message);
            } finally {
              loadingIndicator.dismiss();
            }
          }}
        >
          <IonIcon icon={arrowUpCircleOutline} slot='start' />
          Soft Pull Link
        </TButtonItem>
      )}
      <TButtonItem
        color='secondary'
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const simpleCreditAppUrl = `https://ca.tecobi.com/creditapp/short/${lead?.client}/${lead?.id}/`;
            const message = `Here is our application: ${simpleCreditAppUrl} Your PIN code is: ${lead?.pin_code}.`;
            append(message);
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={arrowUpCircleOutline} slot='start' />
        Short Credit App
      </TButtonItem>
      <TButtonItem
        color='secondary'
        onClick={async () => {
          try {
            await loadingIndicator.create();
            const fullCreditAppUrl = `https://ca.tecobi.com/creditapp/${lead?.client}/${lead?.id}/`;
            const message = `Here is our application: ${fullCreditAppUrl} Your PIN code is: ${lead?.pin_code}.`;
            append(message);
          } finally {
            loadingIndicator.dismiss();
          }
        }}
      >
        <IonIcon icon={arrowUpCircleOutline} slot='start' />
        Full Credit App
      </TButtonItem>
      {softPull && (
        <SoftPullModal
          isOpen={true}
          onDidDismiss={() => setSoftPull(null)}
          data={softPull}
        />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadCredit;
