import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  TPage,
  TItemGroup,
  TInputItem,
  TToggleItem,
  TSelectItem,
  TClickToEditItem,
  HapticButton,
  PopoverSelector,
  TItem
} from '../components';
import '../styles/components/ClientSettings.scss';
import { IonList, IonButton, IonIcon, IonText } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import { shareOutline, search } from 'ionicons/icons';
import {
  sharing,
  appNotification,
  util,
  sentry,
  loadingIndicator,
  browser
} from '../core';
import { clientService, facebookService } from '../services';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';
import { Client } from '../types/Client';

const ClientSettingsPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { selectedClient, selectedClientId, user } = state;
  const [clientId, setClientId] = useState(selectedClientId);
  const [changes, setChanges] = useState<Client>(selectedClient);
  const [facebookPages, setFacebookPages] = useState<any>([]);
  const [adAccounts, setAdAccounts] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [selectedAd, setSelectedAd] = useState<any>();
  const [showAdAccounts, setShowAdAccounts] = useState(false);
  const [showPages, setShowPages] = useState(false);

  useRequireSettingsAdmin();

  const loadFacebookData = useCallback(async () => {
    const [ads, pages] = await Promise.all([
      facebookService.loadAdAccounts(),
      facebookService.loadPages()
    ]);

    const none = { id: null, name: 'None' };

    setAdAccounts([none, ...util.sortByProp(ads, 'name')]);
    setFacebookPages([none, ...util.sortByProp(pages, 'name')]);

    const { fbpage, fb_ad_account } = selectedClient ?? {};

    setSelectedPage(fbpage ? pages?.find((it: any) => it.id === fbpage) : null);
    setSelectedAd(
      fb_ad_account
        ? ads?.find(
          (it: any) => it.account_id.toString() === fb_ad_account.toString()
        )
        : null
    );
  }, [selectedClient]);

  useEffect(() => {
    setClientId(selectedClientId);
    setChanges(selectedClient);
    if (user.is_admin) {
      loadFacebookData();
    }
  }, [selectedClient, selectedClientId, user, loadFacebookData]);

  const updateField = (key: string) => async (e: any) => {
    const val = e.detail.checked ?? e.detail.value;

    if (changes.id !== selectedClientId || changes[key] === val) {
      return;
    }

    try {
      const res = await clientService.update(changes.id, {
        [key]: val
      });

      setChanges(res);
      dispatch({
        type: 'set',
        value: { selectedClient: res }
      });

      appNotification.toast('Account settings saved.', 'Success');
    } finally {
    }
  };

  const changeAdAccount = async (item: any) => {
    try {
      await loadingIndicator.create();
      const updated = await facebookService.createAdAccount(clientId, item);
      setChanges(updated);
      setShowAdAccounts(false);
      appNotification.toast('Facebook ad account updated.', 'Success');
    } catch (e) {
      sentry.capture(e as Error);
      appNotification.toast(
        'There was a problem updating the facebook ad account.',
        'Error'
      );
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const changePage = async (item: any) => {
    try {
      await loadingIndicator.create();
      const updated = await facebookService.subscribeToPage(clientId, item);
      setChanges(updated);
      setShowPages(false);
      appNotification.toast('Facebook page updated.', 'Success');
    } catch (e) {
      sentry.capture(e as Error);
      appNotification.toast(
        'There was a problem updating the facebook page.',
        'Error'
      );
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const uploadCovidPolicy = async (file: any) => {
    if (!file) {
      return;
    }

    try {
      await loadingIndicator.create();
      const data = await clientService.uploadDocument(
        clientId,
        'health_policy',
        file
      );
      setChanges(data);
      dispatch({
        type: 'set',
        value: { selectedClient: data }
      });
      appNotification.toast(
        'Your policy has been uploaded successfully.',
        'Success'
      );
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TPage loading={false}>
      <IonList className="no-padding no-margin" lines="full">
        <TItemGroup title="Account Details">
          <TInputItem
            label="Name"
            disabled={true}
            value={changes.name}
            onBlur={updateField('name')}
          />
          <TInputItem
            label="Address"
            disabled={true}
            value={changes.address}
            onBlur={updateField('address')}
          />
          <TInputItem
            label="Suite"
            disabled={true}
            value={changes.address_2}
            onBlur={updateField('address_2')}
          />
          <TInputItem
            label="City"
            disabled={true}
            value={changes.city}
            onBlur={updateField('city')}
          />
          <TInputItem
            label="State"
            disabled={true}
            value={changes.state}
            onBlur={updateField('state')}
          />
          <TInputItem
            label="Zip Code"
            disabled={true}
            onBlur={updateField('postal_code')}
            value={changes.postal_code}
          />
          <TInputItem
            label="Phone Number"
            mask="+1 (999) 999-9999"
            type="tel"
            onBlur={updateField('phone')}
            validation={val => !!val && val.length === 12}
            valueModifier={/[\s_()-]+/g}
            value={changes.phone}
          />
        </TItemGroup>

        <TItemGroup title="Call Settings">
          <TToggleItem
            label="Record Calls"
            checked={!!changes.record_click_to_call}
            onIonChange={updateField('record_click_to_call')}
            wrapLabel
          />
          <TToggleItem
            label="State Requires Consent"
            checked={!!changes.two_party_recording_consent_state}
            onIonChange={updateField('two_party_recording_consent_state')}
            wrapLabel
          />
          <TClickToEditItem
            label="Recording Notification"
            labelPlacement="stacked"
            text={changes.click_to_call_recording_prompt}
            onChange={updateField('click_to_call_recording_prompt')}
          />
        </TItemGroup>

        <TItemGroup title="SMS Settings">
          <TToggleItem
            label="Append Opt-Out to All"
            checked={!!changes.append_opt_out_all}
            onIonChange={updateField('append_opt_out_all')}
            wrapLabel
          />
        </TItemGroup>
        <TItemGroup title="Needs Call Settings">
          <TToggleItem
            label="First Response Needs Call"
            checked={!!changes.first_response_needs_call}
            onIonChange={updateField('first_response_needs_call')}
            wrapLabel
          />
          <TToggleItem
            label="Soft Pull Needs Call"
            checked={!!changes.soft_pull_needs_call}
            onIonChange={updateField('soft_pull_needs_call')}
            wrapLabel
          />
          <TToggleItem
            label="Credit App Needs Call"
            checked={!!changes.credit_app_needs_call}
            onIonChange={updateField('credit_app_needs_call')}
            wrapLabel
          />
        </TItemGroup>
        <TItemGroup title="App Settings">
          <TToggleItem
            label="GIFs Enabled"
            checked={!!changes.gif_search_enabled}
            onIonChange={updateField('gif_search_enabled')}
            wrapLabel
          />
          <TSelectItem
            label="Force Clock-in"
            value={changes.force_clock_in}
            onChange={updateField('force_clock_in')}
            options={[
              { value: null, text: 'No' },
              { value: 'non_client_admins', text: 'Non Client Admins' },
              { value: 'all_client_users', text: 'All Client Users' }
            ]}
          />
        </TItemGroup>
        <TItemGroup title="Advanced Automation Features">
          <TToggleItem
            label="Admins Only"
            checked={!!changes.advanced_automation_admins_only}
            onIonChange={updateField('advanced_automation_admins_only')}
            wrapLabel
          />
        </TItemGroup>
        <TItemGroup title="Outbound CRM">
          <TInputItem
            label="CRM Email"
            value={changes.crm_email}
            onBlur={updateField('crm_email')}
          />
          <TToggleItem
            label="Instant Push"
            checked={!!changes.auto_push}
            onIonChange={updateField('auto_push')}
            wrapLabel
          />
          <TToggleItem
            label="Push on Appointment"
            checked={!!changes.send_on_appt}
            onIonChange={updateField('send_on_appt')}
            wrapLabel
          />
        </TItemGroup>

        <TItemGroup title="Inbound CRM">
          <TItem label="CRM Email">
            <div className="settings-text-item">
              <IonText>{changes.xml_address}</IonText>
              <IonButton
                slot="end"
                fill="clear"
                onClick={() =>
                  sharing.share(
                    `mailto:${changes.xml_address}`,
                    'Inbound CRM Email'
                  )
                }
              >
                <IonIcon icon={shareOutline} slot="icon-only" />
              </IonButton>
            </div>
          </TItem>
          <TToggleItem
            label="Accept ADF/XML"
            checked={!!changes.accept_adf_xml}
            onIonChange={updateField('accept_adf_xml')}
            wrapLabel
          />
          <TToggleItem
            label="Passthrough ADF/XML to CRM"
            onIonChange={updateField('pass_xml_through')}
            checked={!!changes.pass_xml_through}
            wrapLabel
          />
        </TItemGroup>

        <TItemGroup title="Documents">
          <TItem label="COVID-19 Policy">
            <div className="settings-text-item">
              <IonText style={{ marginRight: '.5rem' }}>
                {changes?.health_policy ? (
                  <button
                    className="link-button"
                    onClick={() => browser.open(changes.health_policy)}
                  >
                    View Current Policy
                  </button>
                ) : (
                  <span>No Policy On File</span>
                )}
              </IonText>
              <HapticButton
                fill="solid"
                color="secondary"
                slot="end"
                onClick={() =>
                  util.chooseFile(uploadCovidPolicy, '.pdf,.jpg,.jpeg,.png,.docx')
                }
              >
                Choose File
              </HapticButton>
            </div>
          </TItem>
        </TItemGroup>

        <TItemGroup title="Leads">
          <TSelectItem
            label="Delivery"
            value={changes.delivery_type}
            onChange={updateField('delivery_type')}
            options={[
              { value: 'manual', text: 'Manual' },
              { value: 'first_come', text: 'First Come' },
              { value: 'round_robin', text: 'Round Robin' }
            ]}
          />
          {changes.delivery_type === 'round_robin' && (
            <>
              <TToggleItem
                label="Round Robin Ignore Clock-in"
                checked={changes.round_robin_ignore_clock_in}
                onIonChange={updateField('round_robin_ignore_clock_in')}
              />
              <TSelectItem
                label="Fallback Delivery"
                value={changes.round_robin_fallback}
                onChange={updateField('round_robin_fallback')}
                options={[
                  { value: 'manual', text: 'Manual' },
                  { value: 'first_come', text: 'First Come' }
                ]}
              />
              <TSelectItem
                label="Queue Timeout"
                value={changes.new_lead_queue_timeout}
                onChange={updateField('new_lead_queue_timeout')}
                wrapLabel
                options={[
                  { value: 0, text: 'Never' },
                  { value: 900, text: '15 minutes' },
                  { value: 1800, text: '30 minutes' },
                  { value: 3600, text: '1 Hour' },
                  { value: 7200, text: '2 hours' }
                ]}
              />
              <TSelectItem
                label="Reassignment Timeout"
                value={changes.reassignment_timeout}
                onChange={updateField('reassignment_timeout')}
                wrapLabel
                options={[
                  { value: 0, text: 'Never' },
                  { value: 900, text: '15 minutes' },
                  { value: 1800, text: '30 minutes' },
                  { value: 3600, text: '1 Hour' },
                  { value: 7200, text: '2 hours' }
                ]}
              />
            </>
          )}
        </TItemGroup>

        <TItemGroup title="Surveys">
          <TToggleItem
            label="Send Post Sale"
            checked={changes.post_sale_survey_enabled}
            onIonChange={updateField('post_sale_survey_enabled')}
          />
          <TInputItem
            label="Send After Days"
            type="number"
            value={changes.post_sale_survey_days?.toString()}
            onBlur={updateField('post_sale_survey_days')}
          />
        </TItemGroup>

        {selectedClient.text_ninja && (
          <TItemGroup title="Ninja Delay">
            <TSelectItem
              label="Time before giving lead to Ninja"
              value={changes.ninja_delay}
              onChange={updateField('ninja_delay')}
              wrapLabel
              options={[
                { value: 0, text: 'Immediately' },
                { value: 60, text: '1 minute' },
                { value: 300, text: '5 minutes' },
                { value: 600, text: '10 minutes' },
                { value: 1200, text: '20 minutes' },
                { value: 1800, text: '30 minutes' },
                { value: 2700, text: '45 minutes' },
                { value: 3600, text: '1 hour' },
                { value: 7200, text: '2 hours' }
              ]}
            />
          </TItemGroup>
        )}

        {(user.is_admin || user.is_client_admin) && (
          <TItemGroup title="Facebook">
            <TItem label="Ad Account">
              <div className="settings-text-item">
                <IonText>{selectedAd?.name ?? 'Not Set'}</IonText>
                <HapticButton
                  slot="end"
                  onClick={() => setShowAdAccounts(true)}
                  icon={search}
                />
              </div>
            </TItem>
            <TItem label="Page">
              <div className="settings-text-item">
                <IonText>{selectedPage?.name ? `${selectedPage?.name} (${selectedPage?.id})` : 'Not Set'}</IonText>
                <HapticButton
                  slot="end"
                  onClick={() => setShowPages(true)}
                  icon={search}
                />
              </div>
            </TItem>
          </TItemGroup>
        )}

        <TItemGroup title="Auto Responses">
          <TInputItem
            label="Robot Name"
            value={changes.robot_name}
            onBlur={updateField('robot_name')}
          />
          <TClickToEditItem
            label="Widget"
            labelPlacement="stacked"
            text={changes.widget_auto_reply}
            onChange={updateField('widget_auto_reply')}
          />
          <TClickToEditItem
            label="Credit App Complete"
            labelPlacement="stacked"
            text={changes.credit_app_complete_message}
            onChange={updateField('credit_app_complete_message')}
          />
        </TItemGroup>
        {showAdAccounts && (
          <PopoverSelector
            isOpen={showAdAccounts}
            items={adAccounts}
            filter={(it: any) => it.name}
            renderLabel={(it: any) => it.name}
            idField="id"
            onSelectionMade={changeAdAccount}
            placeholder="Search Ad Accounts"
            onDidDismiss={() => setShowAdAccounts(false)}
          />
        )}

        {showPages && (
          <PopoverSelector
            isOpen={showPages}
            items={facebookPages}
            filter={(it: any) => it.name}
            renderLabel={(it: any) => `${it.name} (${it.id})`}
            idField="id"
            onSelectionMade={changePage}
            placeholder="Search Pages"
            onDidDismiss={() => setShowPages(false)}
          />
        )}
      </IonList>
    </TPage>
  );
};

export default ClientSettingsPage;
