import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  IonLabel,
  IonItem,
  IonList,
  IonText,
  IonIcon,
  IonItemGroup,
  IonItemDivider,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonAlert
} from '@ionic/react';
import {
  TPage,
  TSelectItem,
  TButtonItem,
  TAvatar
} from '../components';
import { AppContext } from '../context/AppContext';
import { add, trash, logoGoogle } from 'ionicons/icons';
import { userService, calendarsService, eventTypesService } from '../services';
import { EventTypeModal } from '../components/modals';

import {
  appNotification,
  loadingIndicator
} from '../core';

const CalendarSettingsPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const clientOptions = state.clients?.map((c: any) => {
    return {
      text: c.name,
      value: c.id
    }
  });
  const canSelectClient = state.clients?.length > 1;
  const user = state.user || {};
  const [calendarAvailability, setCalendarAvailability] = useState(JSON.parse(user.calendars_availability_json));
  const [userCalendars, setUserCalendars] = useState([]);
  const [userEventTypes, setUserEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState<any>();
  const [deleteItem, setDeleteItem] = useState<any>();

  const updateAndSave = async (k: string, subKey: string, e: CustomEvent) => {
    let value = e.detail.value;
    let availability = Object.assign({}, calendarAvailability);
    let previousValue = !!subKey ? availability[k][subKey] : availability[k];
    if (!canSelectClient) {
      availability.client = state.clients?.[0]?.id;
    }
    if (previousValue === value) {
      return; //value did not change
    }
    previousValue = value;
    if (subKey) {
      availability[k][subKey] = value;
      // set  both to null
      if (value === null) {
        const otherSubKey = subKey ===  'open' ? 'close' : 'open';
        availability[k][otherSubKey] = value;
      }
    } else {
      availability[k] = value;
    }
    const jsonStr: string = JSON.stringify(availability);
    await userService.update({ calendars_availability_json: jsonStr });
    user['calendars_availability_json'] = jsonStr;
    setCalendarAvailability(availability);
    dispatch({
      type: 'setUserInfo',
      value: user
    });
  };

  const userAvatar = user.profile_pic;

  const loadUserCalendars = useCallback(async () => {
    const data = await calendarsService.listCalendars();
    setUserCalendars(data);
  }, []);

  useEffect(() => {
    loadUserCalendars();
  }, [loadUserCalendars]);

  const loadEventTypes = useCallback(async () => {
    const data = await eventTypesService.list();
    setUserEventTypes(data);
  }, []);

  useEffect(() => {
    loadEventTypes();
  }, [loadEventTypes]);

  const deleteEventTypes = useCallback(async (id: number) => {
    loadingIndicator.create();
    await eventTypesService.delete(id);
    loadingIndicator.dismiss();
    appNotification.toast('Event type has been removed.', 'Success');
    loadEventTypes();
  }, [loadEventTypes]);

  return (
    <TPage loading={false} logoType="calendars">
      <IonList className="no-padding" lines="full">
        <IonItem>
          <IonLabel class="ion-multiline">
            <TAvatar
              src={userAvatar}
              alt={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
              style={{ margin: 'auto', fontSize: 35, height: 100, width: 100 }}
            />
            <IonText
              style={{
                display: 'block',
                marginTop: 15,
                textAlign: 'center'
              }}
            >
              <h1>
                {user.first_name} {user.last_name}
              </h1>
              <h2>Calendar Settings</h2>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://cal.tecobi.com/calendars/${user?.calendars_slug}`}
                >
                    {`https://cal.tecobi.com/calendars/${user?.calendars_slug}`}
                </a>
              </p>
            </IonText>
          </IonLabel>
        </IonItem>
        {userCalendars.length === 0 && (
          <TButtonItem
            color="secondary"
            size="default"
            onClick={() => {
              window.location.href = `https://tecobi.com/calendars/google-authorize/${user?.id}/?redirect=${window.location.href}`;
            }}
          >
            <IonIcon icon={logoGoogle} slot="start" />
            Connect Calendar
          </TButtonItem>
        )}
        {canSelectClient && (
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel>Calendar Client</IonLabel>
            </IonItemDivider>
            <TSelectItem
              value={calendarAvailability['client']}
              options={clientOptions}
              onChange={e => updateAndSave('client', '', e)}
              placeholder="Select"
              label="Client"
            />
          </IonItemGroup>
        )}

        {userCalendars.length > 0 && (
          <IonItemGroup>
            <IonItemDivider sticky>
              <IonLabel>Events</IonLabel>
            </IonItemDivider>
            <TSelectItem
              value={calendarAvailability['addToCalendar']}
              options={userCalendars}
              onChange={e => updateAndSave('addToCalendar', '', e)}
              label="Add to Calendar"
            />
            <TSelectItem
              value={calendarAvailability['checkForConflicts']}
              options={userCalendars}
              onChange={e => updateAndSave('checkForConflicts', '', e)}
              label="Check for Conflicts"
              multiple
            />
            <TSelectItem
              value={calendarAvailability['bufferMinutes']}
              options={[5, 10, 15, 20, 30, 45, 60]}
              onChange={e => updateAndSave('bufferMinutes', '', e)}
              label="Buffer Minutes"
            />
            <TSelectItem
              value={calendarAvailability['timeIntervalsMinutes']}
              options={[5, 10, 15, 20, 30, 60]}
              onChange={e => updateAndSave('timeIntervalsMinutes', '', e)}
              label="Time Intervals Minutes"
            />
          </IonItemGroup>
        )}
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Event Types</IonLabel>
          </IonItemDivider>
          {userEventTypes.map((it: any) => (
            <IonItemSliding key={it.id}>
              <IonItem
                detail={true}
                button
                onClick={() => setSelectedEventType(it)}
              >
                {it?.title}
              </IonItem>
              <IonItemOptions side="end">
                <IonItemOption
                  color="danger"
                  onClick={() => setDeleteItem(it)}
                >
                  <IonIcon icon={trash} slot="start" />
                  Delete
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>


          ))}
          <TButtonItem
            color="secondary"
            size="default"
            onClick={(e:any) => setSelectedEventType({})}
          >
            <IonIcon icon={add} slot="start" />
            Add Event Type
          </TButtonItem>
        </IonItemGroup>
        <IonItemGroup>
          <IonItemDivider sticky>
            <IonLabel>Schedule</IonLabel>
          </IonItemDivider>
          <TSelectItem
            value={calendarAvailability['timezone']}
            options={calendarsService.timezones}
            onChange={e => updateAndSave('timezone', '', e)}
            label="Timezone"
          />
          {Object.keys(calendarAvailability).filter((ky) => !isNaN(Number(ky))).map(key => (
            <React.Fragment key={key}>
              <TSelectItem
                lines="none"
                value={calendarAvailability[key]['open']}
                options={calendarsService.hoursChoices}
                onChange={e => updateAndSave(key, 'open', e)}
                label={`${calendarAvailability[key]['dayName']} Start`}
              />
              <TSelectItem
                value={calendarAvailability[key]['close']}
                options={calendarsService.hoursChoices}
                onChange={e => updateAndSave(key, 'close', e)}
                label={`${calendarAvailability[key]['dayName']} End`}
              />
            </React.Fragment>
          ))}
        </IonItemGroup>
      </IonList>
      {selectedEventType && (
        <EventTypeModal
          evType={selectedEventType}
          onDidDismiss={() => {
            loadEventTypes();
            setSelectedEventType(undefined);
          }}
        />
      )}

      <IonAlert
        isOpen={!!deleteItem}
        onDidDismiss={() => setDeleteItem(undefined)}
        header={'Confirm!'}
        message={`Really delete <strong>${deleteItem?.title}</strong>!!!`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel: blah');
            }
          },
          {
            text: 'Okay',
            handler: () => {
              console.log('Confirm Okay');
              deleteEventTypes(deleteItem?.id)
            }
          }
        ]}
      />


    </TPage>
  );
};

export default CalendarSettingsPage;
