import React from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonText, IonSpinner } from '@ionic/react';
import numeral from 'numeral';

export interface TTextItemProps extends TItemProps {
  text: any;
  textFormat?: string;
  showCopy?: boolean;
  loading?: boolean;
}

const TTextItem: React.FC<TTextItemProps> = ({
  text,
  textFormat,
  children,
  showCopy,
  loading,
  ...rest
}) => {
  return (
    <TItem copyText={showCopy && text} {...rest}>
      {loading && (
        <IonSpinner name="dots" />
      )}
      {!loading && (
        <>
          <IonText className="ion-multiline">
            <p>{textFormat ? numeral(text).format(textFormat) : text}</p>
          </IonText>
          {children}
        </>
      )}
    </TItem>
  );
};

export default TTextItem;
