import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonToast,
  IonLoading,
  IonInput,
  IonIcon,
  NavContext,
} from '@ionic/react';
import http from '../core/http';
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { AppContext } from '../context/AppContext';
import { Logo } from '../components';
import { PasswordResetModal } from '../components/modals';
import { loginService } from '../services';
import { useLocation, useHistory } from 'react-router';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { util, authorization, initialization } from '../core';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const navContext = useContext(NavContext);
  const form = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(
    !!location.pathname.match(/\/forgot-password|reset-password\//i)
  );
  const passwordInputRef = useRef<any>();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [hint, setHint] = useState('');
  const [height, setHeight] = useState(window.innerHeight);
  const { dispatch } = useContext(AppContext);
  util.setTitle('Login');

  // Login callback function
  const onLogin = useCallback(
    async (access: string, refresh: string) => {
      await authorization.setToken(access, refresh);
      const initial = await initialization.onAuthorized();
      dispatch({
        type: 'set',
        value: Object.assign(
          {
            selectedClientId: await authorization.getSelectedClientId(),
            authorizationToken: access,
            refreshToken: refresh,
            authorized: true,
          },
          initial
        ),
      });
    },
    [dispatch]
  );

  const login = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (loading || !form.current) {
        return;
      }

      setMessageType('Error');
      setMessage('');
      const data = new FormData(form.current);
      const email = data.get('email');
      const password = data.get('password');
      if (!email || !password) {
        return setMessage('Please fill out the form completely.');
      }

      try {
        setLoading(true);
        const response = await loginService.login(data);
        if (response.access) {
          onLogin(response.access, response.refresh);
        } else if (response.user_pk) {
          // Store user_pk in localStorage
          window.localStorage.setItem('user_pk', response.user_pk);
          // Store the URL the user was trying to get to originally
          const destinationUrl = window.location.pathname + window.location.search + window.location.hash;
          window.localStorage.setItem('destinationUrl', destinationUrl);
          dispatch({
            type: 'set',
            value: {
              otpData: response,
            },
          });
          navContext.navigate(`/otp-verify/`, 'forward');
        }
      } catch (e) {
        console.error(e);
        setMessage(http.getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, loading, navContext, onLogin]
  );
  function handleEnterPress(event: any) {
    if (event.keyCode === 13) {
      login(event);
    }
  }
  useEffect(() => {
    const handler = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  });

  return (
    <IonPage className='t-page'>
      <IonContent className='login'>
        <form method='POST' ref={form} onSubmit={login}>
          <IonGrid
            style={{
              height,
            }}
          >
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol style={{ maxWidth: 600, margin: 'auto' }}>
                <div className='ion-text-center ion-padding'>
                  <Logo />
                </div>
                <div className='ion-padding'>
                  <IonItem
                    className='input-background border-radius-top'
                    lines='full'
                  >
                    <IonInput
                      type='email'
                      autocomplete='email'
                      placeholder='Email'
                      onIonInput={() => setMessage('')}
                      id='login-email'
                      name='email'
                    />
                  </IonItem>

                  <IonItem
                    className='input-background border-radius-bottom'
                    lines='none'
                  >
                    <IonInput
                      type={showPassword ? 'text' : 'password'}
                      autocomplete='current-password'
                      placeholder='Password'
                      id='login-password'
                      name='password'
                      onKeyUp={handleEnterPress}
                      ref={passwordInputRef}
                    />
                    <IonIcon
                      slot='end'
                      icon={showPassword ? eyeOffOutline : eyeOutline}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </IonItem>
                </div>
                <div className='ion-padding'>
                  <IonButton
                    type='submit'
                    color='success'
                    className='no-margin'
                    disabled={loading}
                    onClick={login}
                    expand='block'
                  >
                    Login
                  </IonButton>
                  <div
                    className='forgot-password-link link-button'
                    onClick={() => {
                      setShowForgotPasswordModal(true);
                      setMessage('');
                      setHint(
                        new FormData(form.current).get('email') as string
                      );
                    }}
                  >
                    Forgot your password?
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <IonToast
          isOpen={!!message}
          position='top'
          header={messageType}
          message={message}
          buttons={[{ text: 'Dismiss', role: 'cancel' }]}
        />
        <PasswordResetModal
          isOpen={showForgotPasswordModal}
          onWillDismiss={({ message, email }) => {
            if (email && message) {
              setMessageType('Success');
              setMessage(message);
              const element = document.getElementById(
                'login-email'
              ) as HTMLIonInputElement;
              if (element) {
                element.value = email;
              }
            }
          }}
          onDidDismiss={() => {
            setShowForgotPasswordModal(false);
            history.replace('/');
          }}
          hint={hint}
        />
      </IonContent>
      <IonLoading isOpen={loading} />
    </IonPage>
  );
};

export default LoginPage;
