import React, { useCallback, useContext, useState } from 'react';
import { TInfiniteScrollPage, TFab } from '../../components';
import { postAppointmentFollowupsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { IonItem, IonLabel, IonButton, IonIcon, IonThumbnail, IonText } from '@ionic/react';
import { PostAppointmentFollowupFormModal } from '../../components/modals';
import { useDataLoader } from '../../hooks';
import styles from './PostAppointmentFollowup.module.scss';
import { add, ellipsisVertical } from 'ionicons/icons';
import { actionSheet, appNotification } from '../../core';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const PostAppointmentFollowups: React.FC = () => {

  const { token } = useParams<{ token?: any }>();

  const { state } = useContext(AppContext);

  const defaultConfig = token === 'followup' ? {
    client: state.selectedClientId,
    minutes_after_appointment: 120,
    order: 1,
    needs_call: false,
    message: 'Hi {{ first_name }}. Just wanted to follow up on your appointment with {{ company_name }}.'
  } : {
    client: state.selectedClientId,
    minutes_after_appointment: 5,
    order: 1,
    needs_call: false,
    message: 'Hi {{ first_name }}. Please check-in for your appointment with {{ company_name }}. {{ appointment.checkin_link }}'
  };

  const loadPostAppointmentFollowups = useCallback(async () => {
    const filters = token === 'followup' ? { minutes_after_appointment__gte: 60 } : { minutes_after_appointment__lt: 60 };
    return await postAppointmentFollowupsService.list(state.selectedClientId, filters);
  }, [state.selectedClientId, token]);

  const showMoreActionSheet = (e: any, item: any) => {

    e.stopPropagation();

    const buttons = [
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ] as any[];

    buttons.unshift({
      text: 'Delete',
      handler: () => {
        (async () => {
          await postAppointmentFollowupsService.delete(item.client, item.id);
          refresher();
          appNotification.toast(
            `Post Appointment Followup has been deleted.`,
            'Deleted'
          );
        })();
        return true;
      }
    });

    actionSheet.show(buttons);
  };

  const { next, data, error, loading, refresher, loadNext } = useDataLoader(
    loadPostAppointmentFollowups,
    true
  );

  const [appointmentReminder, setAppointmentReminder] = useState<any>();

  return (
    <TInfiniteScrollPage
      className={styles.PostAppointmentFollowups}
      loading={loading}
      zeroStateText={token === 'followup' ?
        'Tap the green + to follow up with past appointments.' :
        'Tap the green + to send appointment checkin links.'
      }
      items={data}
      error={error}
      renderItem={(item: any) => (
        <IonItem
          key={item.id}
          className="pointer ion-activatable"
          onClick={() => setAppointmentReminder(item)}
        >
          { item.media && (
            <IonThumbnail slot="start">
              <img src={item.media} alt="Thumbnail" />
            </IonThumbnail>
          )}
          <IonLabel className="ion-multiline">
            <p>{item.message}</p>
            <p className="detail">{!!item.needs_call && (<IonText color="success">Needs call&nbsp;&bull;&nbsp;</IonText>)}Send {moment.duration(item.minutes_after_appointment, 'minutes').humanize()} after appointment.</p>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={(e) => {
                 showMoreActionSheet(e, item)
            }}
          >
            <IonIcon slot="icon-only" icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      onRefresh={refresher}
      hasNext={!!next}
      onLoadNextPage={loadNext}
      fab={
        <TFab
          icon={add}
          onClick={() => setAppointmentReminder(defaultConfig)}
        />
      }
    >
    {appointmentReminder && !!token && (
      <PostAppointmentFollowupFormModal
        item={appointmentReminder}
        token={token}
        onDidDismiss={() => {setAppointmentReminder(undefined); refresher();}}
      />
    )}
    </TInfiniteScrollPage>
  );
};

export default PostAppointmentFollowups;
