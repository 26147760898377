import React, { useContext, useCallback, useState } from 'react';
import { TInfiniteScrollPage, TFab, SpecialRow } from '../components';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import { specialsService } from '../services';
import { add, funnel } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { AddSpecialModal, FilterSpecials } from '../components/modals';

const SpecialsPage: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [addSpecialOpen, setAddSpecialOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('all');

  const loadSpecials = useCallback(async () => {
    return await specialsService.list({
      clientId: selectedClient.id,
      path: filter
    });
  }, [selectedClient, filter]);

  const {
    refresher,
    data,
    error,
    loadNext,
    next,
    loading,
  } = useDataLoader(loadSpecials, true);

  return (
    <>
      <TInfiniteScrollPage
        loading={loading}
        items={data}
        error={error}
        hasNext={!!next}
        renderItem={(item: any) => (
          <SpecialRow
            key={item.id}
            item={item}
            refresher={refresher}
            clientId={selectedClient.id}
          />
        )}
        onLoadNextPage={loadNext}
        onRefresh={refresher}
        fab={
          <TFab
            icon={add}
            onClick={() => setAddSpecialOpen(true)}
          />
        }
        headerTool={(
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => setFilterOpen(true)}
          >
            <IonIcon slot="icon-only" color="primary" icon={funnel} />
          </IonButton>
        )}
        zeroStateText={loading ? '' : 'No specials found.'}
      />
      <AddSpecialModal
        isOpen={addSpecialOpen}
        onDidDismiss={() => {
          setAddSpecialOpen(false)
          refresher()
        }}
      />
      <FilterSpecials
        isOpen={filterOpen}
        onDidDismiss={() => {
          setFilterOpen(false)
          refresher()
        }}
        onSelect={(filter) => {
          setFilter(filter)
          setFilterOpen(false)
          refresher()
        }}
      />
    </>
  )
}

export default SpecialsPage;
