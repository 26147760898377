import { loadingController } from '@ionic/core';
class Loading {
  async create(message?: string, duration?: number) {
    await this.dismiss();
    const ind = await loadingController.create({ duration, message });
    await ind.present();
  }

  async dismiss() {
    try {
      await loadingController.dismiss();
    } catch (e) {}
  }
}

const loading = new Loading();
export default loading;
