import React, { useState } from 'react';
import ButtonGrid from '../ButtonGrid';
import HapticButton from '../HapticButton';
import TModal from './TModal';
import { IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import TInput from '../TInput';
import { lockOutService } from '../../services';
import { appNotification, loadingIndicator } from '../../core';

const ReasonBox = styled(TInput)`
  border-top: solid 1px var(--ion-border-color);
  height: 100%;
  flex: 1 1 auto;

  .native-textarea {
    height: 100%;
    padding: 10px 15px;
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
`;

export interface LockOutModalProps {
  user: any;
  onDidDismiss?: () => any;
}

const LockOutModal: React.FC<LockOutModalProps> = ({ user, onDidDismiss }) => {
  const [reason, setReason] = useState('');

  const submitFeedback = async () => {
    try {
      if (!reason.trim()) {
        return appNotification.toast(
          'Please fill out the reason.',
          'Error'
        );
      }
      await loadingIndicator.create();
      await lockOutService.create({reason, user: user?.id});
      appNotification.toast(`${user.first_name} ${user.last_name} is now locked out.`, 'Success');
      onDidDismiss?.();
    } catch (e) {
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TModal
      isOpen={true}
      title={`Lock ${user.first_name} ${user.last_name} Out`}
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <HapticButton
            onClick={submitFeedback}
            color="secondary"
            expand="block"
            fill="solid"
          >
            Submit
          </HapticButton>
        </ButtonGrid>
      }
    >
      <StyledContainer>
        <IonItem color="danger">
          <IonLabel>
            {`This will lock ${user.first_name} ${user.last_name} out of the system until they meet with a manager.`}
          </IonLabel>
        </IonItem>
        <ReasonBox
          placeholder="Reason"
          value={reason}
          onIonInput={(e: any) => setReason(e.detail.value)}
          multiline
        />
      </StyledContainer>
    </TModal>
  );
};

export default LockOutModal;
