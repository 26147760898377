import React from 'react';
import { IonIcon } from '@ionic/react';
import { happy } from 'ionicons/icons';
import { haptics } from '../core';

export interface EmojiPickerButtonProps {
  onClick?: (e: any) => void;
}

const EmojiPickerButton: React.FC<EmojiPickerButtonProps> = ({ onClick }) => {
  const showEmoji = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    haptics.lightImpact();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <IonIcon
      className="emoji-picker-button pointer"
      icon={happy}
      onClick={showEmoji}
    />
  );
};

export default EmojiPickerButton;
