import { http } from '../core';
import CachedService, { CacheStrategy } from './cached-service';
import qs from 'qs';
import { ClientUser } from '../types/ClientUser';

class ClientUsersService extends CachedService {
  constructor() {
    super('client-users', 'clientUsers', [], CacheStrategy.TIMEOUT);
  }

  async request(clientId: number) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/?page_size=500`
    });

    return res.data.results;
  }

  async getList(clientId: number) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/?page_size=500`
    });

    return res.data.results;
  }

  async get(clientId: number, id: number) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/${id}/`
    });

    return data;
  }

  getById(id: number, users: ClientUser[]) {
    return users.find((it: ClientUser) => it.id === id);
  }

  getUserName(user: ClientUser | undefined) {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  getNameById(id: number, users: ClientUser[]) {
    const user = this.getById(id, users);

    return this.getUserName(user);
  }

  async update(clientId: number, userId: number, changes: Partial<ClientUser>) {
    const res = await http.authorizedRequest({
      method: 'PATCH',
      url: `/clients/${clientId}/users/${userId}/`,
      data: changes
    });
    const { data } = res;
    this.evict();
    return data;
  }

  async create(clientId: number, user: ClientUser) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/`,
      data: user
    });

    this.evict();

    return data;
  }

  async delete(clientId: number, user: ClientUser) {
    const res = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/remove/`,
      data: user
    });

    this.evict();

    return res;
  }

  async add(clientId: number, user: Partial<ClientUser>) {
    const res = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/add/`,
      data: user
    });

    this.evict();

    return res;
  }

  async checkIntent(clientId: number, email: string, intent: string) {
    const params = { email__iexact: email, intent };
    const query = qs.stringify(params);
    return await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/?${query}`
    });
  }
}

const clientUsersService = new ClientUsersService();
export default clientUsersService;
