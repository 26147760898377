import React, { useContext } from 'react';
import { menuController } from '@ionic/core/components';
import { useParams } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { TPage } from '../components';

const FormPost: React.FC = () => {
  const params = useParams();
  const { url } = (params || {}) as any;
  const { state } = useContext(AppContext);
  menuController.enable(false);
  return (
    <TPage loading={true} noHeader noFooter>
      <form
        ref={e => e?.submit()}
        action={decodeURIComponent(url)}
        method="post"
      >
        <input
          type="hidden"
          id="token"
          name="Token"
          value={state.authorizationToken}
        />
      </form>
    </TPage>
  );
};

export default FormPost;
