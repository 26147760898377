import React, { useContext } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonButton
} from '@ionic/react';
import { modalController } from '@ionic/core';
import '../../styles/components/AppointmentModal.scss';
import {
  car,
  informationCircleOutline,
  call,
  mail,
  calendar as calendarIcon,
  flame,
  personCircle,
  checkmarkCircleOutline,
  checkmark,
  eyeOff,
  footsteps,
  closeCircle,
  cash
} from 'ionicons/icons';
import TModal from './TModal';
import ButtonGrid from '../ButtonGrid';
import {
  calendar,
  actionSheet,
  loadingIndicator,
  native,
  util
} from '../../core';
import { leadsService } from '../../services';
import { AppContext } from '../../context/AppContext';
import { Appointment } from '../../types/Appointments';

export interface AppointmentModalProps {
  appointment: Appointment;
  isOpen?: boolean;
  onDidDismiss?: () => void;
  onAppointmentChanged?: (appt: Appointment) => void;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({
  appointment,
  onDidDismiss,
  onAppointmentChanged,
  isOpen = false
}) => {
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const {
    client,
    get_lead_info,
    status,
    appointment_time,
    get_script_info,
    missed,
    cancelled,
    sold,
    showed
  } = appointment;
  const {
    name,
    classification,
    phone_number,
    email,
    assigned_to
  } = get_lead_info;

  const leadId = get_lead_info.id;

  const { script } = get_script_info;

  const isInvalid = missed || cancelled;

  const textUrl = `/text-messages/conversation/${client}/${leadId}/`;

  const markShowed = async () => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentShowed(
        client,
        leadId,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();
      modalController.dismiss();
    }
  };

  const markMissed = async () => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentMissed(
        client,
        leadId,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();
      modalController.dismiss();
    }
  };


  const markShowedLost = async () => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentShowedLost(
        client,
        leadId,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();
      modalController.dismiss();
    }
  };

  const markCancelled = async () => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.cancelAppointment(
        client,
        leadId,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();
      modalController.dismiss();
    }
  };

  const markSold = () => {
    const doMarkSold = (status: number, str: string) => () => {
      (async () => {
        try {
          await loadingIndicator.create();
          const { appt } = await leadsService.appointmentSold(
            client,
            leadId,
            appointment,
            status
          );
          onAppointmentChanged?.(appt);
        } finally {
          loadingIndicator.dismiss();
          modalController.dismiss();
        }
      })();
      return true;
    };

    actionSheet.show([
      {
        text: 'Sold New',
        handler: doMarkSold(13, 'New')
      },
      {
        text: 'Sold Used',
        handler: doMarkSold(14, 'Used')
      },
      {
        text: 'Sold Unknown',
        handler: doMarkSold(15, 'Unknown')
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ]);
  };

  let toolbar = <></>;

  if (!cancelled && !missed && !sold && !showed) {
    toolbar = (
      <ButtonGrid columns={[6, 6, 6, 6]}>
        <IonButton
          fill="outline"
          expand="block"
          color="secondary"
          onClick={markShowed}
        >
          <IonIcon icon={checkmark} slot="start" />
          Showed
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          color="tertiary"
          onClick={markShowedLost}
        >
          <IonIcon icon={footsteps} slot="start" />
          Showed/Lost
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          color="warning"
          onClick={markMissed}
        >
          <IonIcon icon={eyeOff} slot="start" />
          Missed
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          color="danger"
          onClick={markCancelled}
        >
          <IonIcon icon={closeCircle} slot="start" />
          Cancelled
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          color="success"
          onClick={markSold}
        >
          <IonIcon icon={cash} slot="start" />
          Sold
        </IonButton>
      </ButtonGrid>
    );
  }

  return (
    <TModal
      title="Appointment"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      toolbar={toolbar}
      footer={
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              if (native.isNative) {
                calendar.createEventInteractively(
                  name,
                  util
                    .timezoneMoment(appointment_time, selectedClient?.timezone)
                    .toDate(),
                  util
                    .timezoneMoment(appointment_time, selectedClient?.timezone)
                    .add(1, 'hours')
                    .toDate()
                );
              } else {
                calendar.downloadICS(
                  {
                    title: name as string,
                    start: util
                      .timezoneMoment(
                        appointment_time,
                        selectedClient?.timezone
                      )
                      .toDate(),
                    duration: { hours: 1, minutes: 0 },
                    url: `https://app.tecobi.com${textUrl}`
                  },
                  `${name}-${appointment_time}.ics`
                );
              }
            }}
          >
            Add To Calendar
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => modalController.dismiss()}
            routerLink={textUrl}
          >
            View Lead
          </IonButton>
        </ButtonGrid>
      }
      className="appointment-modal"
    >
      <IonList lines="full">
        <IonItem>
          <IonIcon icon={personCircle} slot="start" />
          <IonLabel position="stacked">Name</IonLabel>
          <IonText className="ion-multiline">
            <p>{name}</p>
          </IonText>
        </IonItem>

        <IonItem>
          <IonIcon icon={calendarIcon} slot="start" />
          <IonLabel position="stacked">Date/Time</IonLabel>
          <IonText className="ion-multiline">
            <p
              style={{
                textDecoration: isInvalid ? 'line-through' : undefined
              }}
            >
              {util
                .timezoneMoment(appointment_time, selectedClient?.timezone)
                .format('dddd MMMM D, YYYY h:mma')}
            </p>
          </IonText>
        </IonItem>

        <IonItem>
          <IonIcon icon={flame} slot="start" />
          <IonLabel position="stacked">Source</IonLabel>
          <IonText className="ion-multiline">
            <p>{script}</p>
          </IonText>
        </IonItem>

        <IonItem>
          <IonIcon icon={car} slot="start" />
          <IonLabel position="stacked">Type</IonLabel>
          <IonText className="ion-multiline">
            <p>{classification}</p>
          </IonText>
        </IonItem>

        <IonItem>
          <IonIcon icon={informationCircleOutline} slot="start" />
          <IonLabel position="stacked">Status</IonLabel>
          <IonText className="ion-multiline ion-text-capitalize">
            <p>{status}</p>
          </IonText>
        </IonItem>

        {phone_number && (
          <IonItem>
            <IonIcon icon={call} slot="start" />
            <IonLabel position="stacked">Phone</IonLabel>
            <IonText className="ion-multiline">
              <p>{phone_number}</p>
            </IonText>
          </IonItem>
        )}

        {email && (
          <IonItem>
            <IonIcon icon={mail} slot="start" />
            <IonLabel position="stacked">Email</IonLabel>
            <IonText className="ion-multiline">
              <p>{email}</p>
            </IonText>
          </IonItem>
        )}

        <IonItem>
          <IonIcon icon={checkmarkCircleOutline} slot="start" />
          <IonLabel position="stacked">Assigned To</IonLabel>
          <IonText className="ion-multiline">
            <p>{assigned_to}</p>
          </IonText>
        </IonItem>
      </IonList>
    </TModal>
  );
};

export default AppointmentModal;
