import { useEffect, useRef } from 'react';
import { Shortcuts } from 'shortcuts';

const instance = new Shortcuts();

export default function useKeyboardShortcuts(mapping: any) {
  const savedMapping = useRef<any>();

  const unbind = () => {
    if (savedMapping.current) {
      const keys = Object.keys(savedMapping.current);
      instance.remove(keys.map((shortcut: any) => ({ shortcut })));
    }
  };

  useEffect(() => {
    unbind();
    const keys = Object.keys(mapping);
    savedMapping.current = mapping;
    instance.add(
      keys.map((shortcut: any) => ({
        shortcut,
        handler: e => {
          e.preventDefault();
          e.stopPropagation();
          mapping[shortcut](e);
          return true;
        }
      }))
    );

    return () => unbind();
  }, [mapping]);
}
