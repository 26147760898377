import React, { useState, useCallback, useContext, useEffect } from 'react';
import TModal from './TModal';
import TLoading from '../TLoading';
import { LeadContext } from '../../context/LeadContext';
import { leadsService } from '../../services';

export interface FacebookAdPreviewModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const FacebookAdPreviewModal: React.FC<FacebookAdPreviewModalProps> = ({
  onDidDismiss,
  isOpen = false
}) => {
  const { state } = useContext(LeadContext);
  const [loading, setLoading] = useState(true);
  const [na, setNa] = useState(false);
  const [ad, setAd] = useState<any>();

  const { lead } = state;
  const { id } = lead || {};

  const load = useCallback(async () => {
    if (!id) {
      return;
    }

    setLoading(true);
    const data = await leadsService.getAdPreview(id);
    if (data) {
      setAd(data);
    } else {
      setNa(true);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [isOpen, load]);

  return (
    <TModal
      title="Ad Preview"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      className="ad-preview-modal"
    >
      <TLoading visible={loading} />
      {na ? (
        <div style={{ textAlign: 'center', margin: '30px auto' }}>
          Ad is not available.
        </div>
      ) : (
        <div
          style={{ maxHeight: '100%', maxWidth: '100%' }}
          dangerouslySetInnerHTML={{ __html: ad }}
        />
      )}
    </TModal>
  );
};

export default FacebookAdPreviewModal;
