import React, { useEffect, useState } from 'react';
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonItem,
  IonList,
  IonLabel,
  IonCardTitle,
  IonCardContent,
  IonBadge,
  IonText,
  IonIcon,
  IonFooter,
  IonSkeletonText,
  IonButton,
} from '@ionic/react';
import { ribbon } from 'ionicons/icons';
import { PaginatedLeadListModal } from '../components/modals';
import { clientTopClosersService } from '../services';
import moment from 'moment';

export interface LeaderboardProps {
  selectedClientId: number;
  limit?: number;
  userType: string;
  showSeeMore?: boolean;
  title?: string;
  startDate?: Date;
  endDate?: Date;
}

const EmptyState = () => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol className='ion-text-center'>
          <IonText color='medium'>No closers available</IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const Leaderboard: React.FC<LeaderboardProps> = ({
  selectedClientId,
  userType,
  showSeeMore,
  title,
  limit,
  startDate,
  endDate,
}) => {
  const start = startDate ? moment(startDate) : moment().startOf('month');
  const end = endDate ? moment(endDate) : moment().endOf('month');
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const openModal = (
    user: any,
    endpoint: string,
    dateTimeField: string,
    title: string,
    dateLabel: string
  ) => {
    setModalData({ user, endpoint, dateTimeField, title, dateLabel });
    setShowModal(true);
  };
  useEffect(() => {
    async function fetchLeaderboardData() {
      try {
        setLoading(true);
        setLeaderboardData([]);
        const start = startDate ? moment(startDate) : moment().startOf('month');
        const end = endDate ? moment(endDate) : moment().endOf('month');
        const data = await clientTopClosersService.list(
          selectedClientId,
          userType,
          start.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), // Use the selected startDate
          end.format('YYYY-MM-DDTHH:mm:ss.SSSZ') // Use the selected endDate
        );
        setLeaderboardData(data);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchLeaderboardData();
  }, [selectedClientId, userType, startDate, endDate]);

  const renderLeaderboard = () => {
    const closersToShow = leaderboardData.slice(0, limit ?? 10);
    if (closersToShow.length === 0) {
      return <EmptyState />;
    }
    return closersToShow.map((item: any, index: number) => (
      <IonRow key={index} className={index === 0 ? 'winner' : ''}>
        <IonCol sizeSm='6' sizeXs='6' sizeMd='6' sizeLg='6' sizeXl='6'>
          <IonBadge
            color={
              index === 0
                ? 'secondary'
                : index === 1
                ? 'danger'
                : index === 2
                ? 'warning'
                : undefined
            }
            style={{
              margin: '0 6px 0 0',
              borderRadius: '50%',
              width: 20,
              paddingRight: 0,
              paddingLeft: 0,
              verticalAlign: 'middle',
              height: 20,
            }}
          >
            {' '}
            {index < 4 ? <IonIcon icon={ribbon} /> : index + 1}
          </IonBadge>{' '}
          {item.first_name} {item.last_name}
        </IonCol>
        <IonCol
          sizeSm='2'
          sizeXs='2'
          sizeMd='2'
          sizeLg='2'
          sizeXl='2'
          className='ion-text-right'
          style={{ cursor: 'pointer' }}
          onClick={(e: any) => {
            openModal(
              item,
              `clients/${selectedClientId}/top-closer-leads`,
              'created_at',
              'Leads',
              'Created'
            );
          }}
        >
          <IonText color='secondary'>{item.total_leads}</IonText>
        </IonCol>
        <IonCol
          sizeSm='2'
          sizeXs='2'
          sizeMd='2'
          sizeLg='2'
          sizeXl='2'
          className='ion-text-right'
          style={{ cursor: 'pointer' }}
          onClick={(e: any) => {
            openModal(
              item,
              `clients/${selectedClientId}/top-closer-solds`,
              'created_at',
              'Solds',
              'Sold'
            );
          }}
        >
          <IonText color='success'>{item.total_sold}</IonText>
        </IonCol>
        <IonCol
          sizeSm='2'
          sizeXs='2'
          sizeMd='2'
          sizeLg='2'
          sizeXl='2'
          className='ion-text-right'
        >
          <IonText color='tertiary'>
            {(Number(item.closing_ratio) * 100).toFixed(2)}<span className="ion-hide-sm-down">%</span>
          </IonText>
        </IonCol>
      </IonRow>
    ));
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{title ?? 'Leaderboard'}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeSm='6' sizeXs='6' sizeMd='6' sizeLg='6' sizeXl='6'>
              Name
            </IonCol>
            <IonCol
              sizeSm='2'
              sizeXs='2'
              sizeMd='2'
              sizeLg='2'
              sizeXl='2'
              className='ion-text-right'
            >
              <IonText color='secondary'><span className="ion-hide-sm-down">New </span>Leads</IonText>
            </IonCol>
            <IonCol
              sizeSm='2'
              sizeXs='2'
              sizeMd='2'
              sizeLg='2'
              sizeXl='2'
              className='ion-text-right'
            >
              <IonText color='success'>Solds</IonText>
            </IonCol>
            <IonCol
              sizeSm='2'
              sizeXs='2'
              sizeMd='2'
              sizeLg='2'
              sizeXl='2'
              className='ion-text-right'
            >
              <IonText color='tertiary'><span className="ion-hide-sm-down">Closing </span>%</IonText>
            </IonCol>
          </IonRow>
          {renderLeaderboard()}
          {loading && (
            <IonList>
              {Array.from(Array(3).keys()).map((it: any, i: number) => (
                <IonItem key={i}>
                  <IonLabel>
                    <IonSkeletonText key={i} animated />
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          )}
        </IonGrid>
      </IonCardContent>
      {showSeeMore && (
        <IonFooter style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <IonButton fill='clear' routerLink='/top-closers'>
            See more
          </IonButton>
        </IonFooter>
      )}
      {showModal && (
        <PaginatedLeadListModal
          isOpen={true}
          useSinceUntil={true}
          userIdInParams={true}
          dateLabel={modalData?.dateLabel}
          userId={modalData?.user?.id}
          since={start.toDate()}
          until={end.toDate()}
          tableTitle={modalData?.title}
          title={`${modalData?.user?.first_name} ${modalData?.user?.last_name}`}
          onDidDismiss={() => {
            setShowModal(false);
          }}
          endpoint={modalData?.endpoint}
          userType={userType}
          dateTimeField={modalData?.dateTimeField}
        />
      )}
    </IonCard>
  );
};

export default Leaderboard;
