import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TPage, HapticButton } from '../components';
import { useLiveDBRef, useRequireAdmin } from '../hooks';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { play, pause } from 'ionicons/icons';
import { util } from '../core';
import moment from 'moment';
import { AppContext } from '../context/AppContext';

const findDuplicates = (arr: any) => {
  let sorted_arr = arr.slice().sort();
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

const dateFormat = 'h:mm:ss.SSSa MM/DD';

const DebugPage: React.FC = () => {
  useRequireAdmin();
  const appContext = useContext(AppContext);
  const { clientUsers } = appContext.state;
  const [working, setWorking] = useState<any>([]);
  const [added, setAdded] = useState<any>([]);
  const [served, setServed] = useState<any>([]);
  const [paused, setPaused] = useState(false);
  const [dupes, setDupes] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>({});

  useEffect(() => {
    setProfiles(
      clientUsers.reduce((obj: any, it: any) => {
        obj[it.id] = it;
        return obj;
      }, {})
    );
  }, [clientUsers]);

  const onQueueItemRemoved = useCallback(
    (val: any) =>
      setServed((s: any) => {
        s = s ?? [];
        val.served = new Date().toISOString();
        s.unshift(val);
        s.length = 100;

        setDupes(
          findDuplicates(s.map((it: any) => `${it.id}${it.last_message}`))
        );

        return s;
      }),
    []
  );

  const onQueueItemAdded = useCallback((val: any) => {
    setAdded((s: any) => {
      s = s ?? [];
      val.added = new Date().toISOString();
      s.unshift(val);
      s.length = 100;

      setDupes(
        findDuplicates(s.map((it: any) => `${it.id}${it.last_message}`))
      );

      return s;
    });
  }, []);

  const onServed = useCallback((val: any) => {
    const arr = Object.keys(val ?? {}).reduce((o: any, it: any) => {
      o.push({ id: it, ...val[it] });
      return o;
    }, []);

    arr.length = 100;

    util.sortByProp(arr, 'ts', true);
    setWorking(arr);
  }, []);

  useLiveDBRef('/ninja/queue', onQueueItemAdded, !paused, 'child_added');
  useLiveDBRef('/ninja/queue', onQueueItemRemoved, !paused, 'child_removed');
  useLiveDBRef('/ninja/served', onServed, !paused);

  return (
    <TPage
      loading={false}
      headerTool={
        <HapticButton
          onClick={() => setPaused(!paused)}
          icon={paused ? play : pause}
          color={paused ? 'warning' : 'primary'}
          slot="end"
        />
      }
    >
      <IonGrid className="inbound-queue-debug">
        <IonRow style={{ minHeight: '100vh' }}>
          <IonCol
            sizeMd="6"
            sizeLg="6"
            sizeXl="6"
            sizeSm="12"
            sizeXs="12"
            style={{ borderRight: `solid 3px var(--ion-border-color)` }}
          >
            <h2>Served</h2>
            <table
              style={{
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>UID</th>
                  <th>Served</th>
                  <th>Last Message</th>
                </tr>
              </thead>
              <tbody>
                {working.map((it: any) => (
                  <tr key={it.id}>
                    <td>{it.id}</td>
                    <td>{profiles[it.uid]?.full_name ?? it.uid}</td>
                    <td>{moment(it.ts).format(dateFormat)}</td>
                    <td>{moment(it.lm).format(dateFormat)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </IonCol>
          <IonCol
            sizeMd="3"
            sizeLg="3"
            sizeXl="3"
            sizeSm="12"
            sizeXs="12"
            style={{ borderRight: `solid 3px var(--ion-border-color)` }}
          >
            <h2>Removed</h2>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Removed</th>
                </tr>
              </thead>

              <tbody>
                {served?.map((it: any, i: number) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor:
                        dupes.indexOf(`${it.id}${it.last_message}`) > -1
                          ? 'rgba(var(--ion-color-warning-rgb), .4)'
                          : ''
                    }}
                  >
                    <td>{it.id}</td>
                    <td>{moment(it.served).format(dateFormat)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </IonCol>
          <IonCol
            sizeMd="3"
            sizeLg="3"
            sizeXl="3"
            sizeSm="12"
            sizeXs="12"
            style={{ borderRight: `solid 3px var(--ion-border-color)` }}
          >
            <h2>Added</h2>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Added</th>
                </tr>
              </thead>

              <tbody>
                {added?.map((it: any, i: number) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor:
                        dupes.indexOf(`${it.id}${it.last_message}`) > -1
                          ? 'rgba(var(--ion-color-warning-rgb), .4)'
                          : ''
                    }}
                  >
                    <td>{it.id}</td>
                    <td>{moment(it.added).format(dateFormat)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </IonCol>
        </IonRow>
      </IonGrid>
    </TPage>
  );
};

export default DebugPage;
