import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowUp } from 'ionicons/icons';
import css from 'classnames';

export interface SendButtonProps
  extends React.ComponentProps<typeof IonButton> {
  outline?: boolean;
}

const SendButton: React.FC<SendButtonProps> = ({
  children,
  className,
  outline,
  ...rest
}) => {
  return (
    <IonButton
      size="small"
      className={css('send-button', className, {
        outline
      })}
      {...rest}
    >
      <IonIcon size="small" slot="icon-only" icon={arrowUp} />
    </IonButton>
  );
};

export default SendButton;
