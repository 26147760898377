import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import useEventListener from './useEventListener';

export default function useDeepLinks() {
  const history = useHistory();
  const onDeepLink = useCallback(
    (e: CustomEvent) => {
      const url = new URL(e.detail);
      const newUrl = url.pathname + url.search;
      history.replace(newUrl, {});
    },
    [history]
  );
  useEventListener('native:deeplink', onDeepLink);
}
