import {
  IonLoading,
  IonLabel,
  IonText,
  IonIcon,
  IonCard,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { time as timeIcon, videocam, pin, earth } from 'ionicons/icons';
import { useParams } from 'react-router-dom';
import { TDatePicker, TPage, TAvatar } from '../components/';
import moment from 'moment';
import { AddCalendarAppointmentModal } from '../components/modals';
import 'moment-timezone';
import '../styles/pages/UserReserveCalendarSlot.scss';

import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { util } from '../core';
import { calendarsService } from '../services';
import { useLocation } from 'react-router-dom';

//const { timezones } = calendarsService;
const UserReserveCalendarSlotPage: React.FC = () => {
  const { slug, eventTypeSlug, leadHash } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [eventType, setEventType] = useState<any>();
  const timezone = moment.tz.guess(true);
  const [events, setEvents] = useState([]);
  const [excludeTimes, setExcludeTimes] = useState([]);
  const [duration, setDuration] = useState();
  const [timeIntervals, setTimeIntervals] = useState(30);
  const [calendarDateTime, setCalendarDateTime] = useState<string | null>(moment().startOf('day').toISOString());
  const [selectedAppointment, setSelectedAppointment] = useState<any>();
  const [crossCheckSlugs, setCrossCheckSlugs] = useState<any>();
  const [time, setTime] = useState<any>();
  const [day, setDay] = useState<any>();
  const location = useLocation<any>();
  const [showModal, setShowModal] = useState<any>(false);
  const [weekday, setWeekday] = useState<any>();
  const [since, setSince] = useState<any>();
  const [until, setUntil] = useState<any>();
  const [leadInfo, setLeadInfo] = useState<any>();
  const [minTime,  setMinTime] = useState<any>();
  const [maxTime,  setMaxTime] = useState<any>();
  const [maxDate,  setMaxDate] = useState<any>();
  const [initialGuestEmails,  setInitialGuestEmails] = useState<any>();
  util.setTitle('Calendar');
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const crossCheck = query.get('crossCheck');
    setCrossCheckSlugs(crossCheck ? crossCheck.split(',') : []);
  }, [setCrossCheckSlugs, location]);

  useEffect(() => {
    const isToday = moment(calendarDateTime).isSame(new Date(), 'day');
    const schedule =  eventType?.user?.schedule[weekday];
    const timeZone =  eventType?.user?.schedule?.timezone;
    const minTimeMom = moment.tz(schedule?.open, 'H:mm', timeZone);
    const maxTimeMom = moment.tz(schedule?.close, 'H:mm', timeZone);
    const isClosed = schedule?.close === null || schedule?.open === null || (isToday && maxTimeMom.isBefore(moment()));
    const todayStart = moment.tz(timeZone).isBefore(minTimeMom) ? minTimeMom : moment.tz(timeZone).add(15, 'minutes');
    const minTime = (isClosed ? moment().endOf('day').toDate() : isToday ? todayStart.toDate() : minTimeMom.toDate())
    const maxTime = (isClosed ? moment().endOf('day').toDate() : maxTimeMom.toDate());
    setMinTime(minTime);
    setMaxTime(maxTime);
    setDuration(eventType?.duration_minutes);
    setMaxDate(moment().startOf('day').add(eventType?.days_out, 'days').toDate())
  }, [eventType, weekday, calendarDateTime]);

  const loadEvents = useCallback(async () => {
    setLoading(true);
    const evtType = await calendarsService.retrieveEventType(slug, eventTypeSlug);
    const usersConfigs = [evtType?.user];
    const buffer = (evtType?.user?.schedule?.bufferMinutes ?? 15) - 1;
    const timeIntervals = evtType?.user?.schedule?.timeIntervalsMinutes ??  30;
    setTimeIntervals(timeIntervals);
    // Go fetch the calendar configs for the crossCheck users.
    const otherUserReqs = [] as any;
    crossCheckSlugs?.forEach((userSlug: string) => {
      otherUserReqs.push(calendarsService.retrieveUserInfo(userSlug));
    });
    const otherUserConfigs:Array<any> = await Promise.all(otherUserReqs);
    setInitialGuestEmails(otherUserConfigs.map((it: any) => {
      return it?.schedule?.addToCalendar;
    }));
    const allUserConfigs = [].concat.apply(usersConfigs, otherUserConfigs);

    let evts = [] as any;
    const reqs = [] as any;

    allUserConfigs?.forEach((userConfig: any) => {
      const calendarIds = userConfig?.schedule?.checkForConflicts;
      const userSlug = userConfig?.calendars_slug;
      calendarIds?.forEach((calendarId: string) => {
        reqs.push(calendarsService.retrieveEvents(
          userSlug,
          since,
          until,
          calendarId
        ));
      });
    });

    const cals:Array<any> = await Promise.all(reqs);
    evts = [].concat.apply([], cals)
    setEventType(evtType);
    // Apply buffer to start and end of each event.
    evts?.forEach((e: any) => {
      const subtractor = buffer + evtType.duration_minutes;
      const bufferedStart = moment(e.start.dateTime ?? e.start.date).add((subtractor*-1), 'minutes');
      // round the buffered start to the closest time interval
      const rounding = timeIntervals * 60 * 1000; /*ms*/
      e.bufferedStart = moment(Math.ceil((+bufferedStart) / rounding) * rounding);
      e.bufferedEnd = moment(e.end.dateTime ?? e.end.date).add((buffer), 'minutes');
    });
    setEvents(evts);
    setLoading(false);
  }, [since, until, slug, eventTypeSlug, crossCheckSlugs]);

  const loadLeadInfo = useCallback(async () => {
    if (!leadHash) {
      return false;
    }
    const data = await calendarsService.retrieveLeadInfo(leadHash);
    setLeadInfo(data);
  }, [leadHash]);

  useEffect(() => {
    loadLeadInfo();
  }, [loadLeadInfo]);

  useEffect(() => {
    if (time !== '0:00') {
      setShowModal(time);
    }
  }, [time]);

  useEffect(() => {
    setSince(moment.tz(day, timezone)
      .startOf('day')
      .toDate())
    setUntil(moment.tz(day, timezone)
      .endOf('day')
      .toDate())
  }, [day, timezone]);

  useEffect(() => {
    const endDateTime = moment(calendarDateTime).add(duration, 'minutes').toISOString();
    const calendarDateTimeMom = moment(calendarDateTime);
    const isoWeekday = calendarDateTimeMom.isoWeekday();
    // Need to convert weekday 7 to 0 for config
    setWeekday(isoWeekday > 6 ? 0 : isoWeekday);
    setTime(calendarDateTimeMom.format('H:mm'));
    setDay(calendarDateTimeMom.format('YYYY-MM-DD'));
    setSelectedAppointment({start: calendarDateTime, end: endDateTime});
  }, [calendarDateTime, duration]);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useEffect(() => {
    const excTimes = [] as any;
    events?.forEach((e: any, i: number) => {
      const diff = e.bufferedEnd.diff(e.bufferedStart, 'minutes');
      const multiplier = diff/timeIntervals;
      for (let i=0; i < multiplier; i++) {
        const trueStart = moment(e.bufferedStart.toDate());
        const addMinutes = i * timeIntervals;
        const excludeTimeMom = trueStart.add(addMinutes, 'minutes');
        const excludeTime = excludeTimeMom.tz(timezone).toDate();
        excTimes.push(excludeTime);
      }
    });
    setExcludeTimes(excTimes);
  }, [events, timeIntervals, timezone]);

  const onAppointmentConfirmed = () => {
    loadEvents();
  };

  return (
    <TPage loading={false} logoType="calendars">
      <IonCard className="user-reserve-calendar-slot">
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6" className="col-1">
              <IonLabel class="ion-multiline">
                <TAvatar
                  src={eventType?.user?.profile_pic}
                  alt={`${eventType?.user?.first_name ?? ''} ${eventType?.user?.last_name ?? ''}`}
                  style={{ margin: 'auto', fontSize: 35, height: 100, width: 100 }}
                />
                <IonText
                  style={{
                    display: 'block',
                    marginTop: 15,
                    textAlign: 'center'
                  }}
                >
                  <h2 className="ion-text-wrap">
                    {eventType?.user?.first_name} {eventType?.user?.last_name}
                  </h2>
                  <br/>
                  <h1 className="ion-text-wrap">
                    {eventType?.title}
                  </h1>
                  <br/>
                  <h2 className="ion-text-wrap"><IonIcon slot="start" icon={timeIcon} />{eventType?.duration_minutes} min</h2>
                  <br/>
                  {eventType?.location && (
                    <h2 className="ion-text-wrap"><IonIcon slot="start" icon={pin} /> {eventType?.location}</h2>
                  )}
                  {eventType && !eventType.location && (
                    <h2 className="ion-text-wrap"><IonIcon slot="start" icon={videocam} /> Web conferencing details provided upon confirmation.</h2>
                  )}
                  <br/>
                  <h2 className="ion-text-wrap">{eventType?.description}</h2>
                  <br/>
                </IonText>
              </IonLabel>
            </IonCol>
            <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6" className="col-2">
              <IonText
                style={{
                  display: 'block',
                  marginTop: 15,
                  textAlign: 'center',
                  width: '100%'
                }}
              >
              <TDatePicker
                label="Select a Date &amp; Time"
                minDate={new Date()}
                dateString={calendarDateTime}
                onDateChange={setCalendarDateTime}
                timezone={timezone}
                maxDate={maxDate}
                minTime={minTime}
                maxTime={maxTime}
                excludeTimes={excludeTimes}
                dateFormat="MM/dd/yyyy h:mma"
                timeIntervals={timeIntervals}
                withPortal={false}
                inline={true}
                showTimeSelect
              />
              <br/>
              <p className="ion-text-wrap"><IonIcon slot="start" icon={earth} /> {timezone}</p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={loading} />
        {showModal && (
          <AddCalendarAppointmentModal
            isOpen={true}
            timezone={timezone}
            onAppointmentConfirmed={onAppointmentConfirmed}
            onDidDismiss={() => setShowModal(false)}
            appointment={selectedAppointment}
            initialData={leadInfo}
            initialGuestEmails={initialGuestEmails}
            eventType={eventType}
          />
        )}
      </IonCard>
    </TPage>
  );
};

export default UserReserveCalendarSlotPage;
