import React, { useContext } from 'react';
import { LeadContext } from '../../context/LeadContext';
import '../../styles/components/MediaPreviewModal.scss';
import { IonIcon, IonModal } from '@ionic/react';
import { closeCircle, shareOutline, openOutline } from 'ionicons/icons';
import { modalController } from '@ionic/core';
import { sharing } from '../../core';

export interface MediaPreviewModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const MediaPreviewModal: React.FC<MediaPreviewModalProps> = ({
  onDidDismiss,
  isOpen = false
}) => {
  const { state } = useContext(LeadContext);
  const height = state.mediaPreview?.height ?? 100;
  const width = state.mediaPreview?.width ?? 100;

  const isSquare = width === height;
  const isLandscape = width > height;
  const aspect = isLandscape ? height / width : width / height;
  const vw = window.innerWidth * 0.9;
  const vh = window.innerHeight * 0.9;
  const vmin = Math.min(vh, vw);

  let h, w;

  if (isLandscape) {
    w = Math.min(vmin, width);
    h = w * aspect;
  } else if (isSquare) {
    h = Math.min(vmin, height);
    w = h;
  } else {
    h = Math.min(vmin, height);
    w = h * aspect;
  }

  const style = { height: h, width: w };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      className="media-preview"
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `.media-preview { --width: ${w}px; --height: ${h}px; }`
        }}
      />
      <div style={style}>
        <div className="header-tools">
          <IonIcon
            className="share-icon"
            icon={shareOutline}
            onClick={async () => {
              if (state.mediaPreview) {
                sharing.share(state.mediaPreview?.src, 'Image');
              }
            }}
          />
          <IonIcon
            className="share-icon"
            icon={openOutline}
            onClick={() => {
              if (state.mediaPreview) {
                window.open(state.mediaPreview.src, '_blank');
              }
            }}
          />
          <IonIcon
            className="close-icon"
            icon={closeCircle}
            onClick={() => modalController.dismiss()}
          />
        </div>
        <img
          style={style}
          src={state.mediaPreview?.src ?? 'about:blank'}
          alt={state.mediaPreview?.alt ?? state.mediaPreview?.src}
        />
      </div>
    </IonModal>
  );
};

export default MediaPreviewModal;
