import React, { createContext, useReducer, Dispatch } from 'react';

export interface TrainingState {
  module?: string;
  category: any;
  quiz?: any;
  session?: any;
  question?: any;
  loading: boolean;
}

export interface TrainingContextProps {
  state: TrainingState;
  dispatch: Dispatch<any>;
}

export const TrainingContext = createContext({} as TrainingContextProps);

let reducer = (state: TrainingState, value: any) => {
  const s = { ...state };
  return Object.assign(s, value || {});
};

export const TrainingContextProvider = (props: any) => {
  const init = props.initialState || {};
  const initialState: TrainingState = Object.assign(
    {
      loading: true,
      category: {}
    },
    init
  );

  let [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch
  } as TrainingContextProps;

  return (
    <TrainingContext.Provider value={value}>
      {props.children}
    </TrainingContext.Provider>
  );
};

export const TrainingContextConsumer = TrainingContext.Consumer;
