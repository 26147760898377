import React, { useRef } from 'react';
import { IonItem, IonTextarea, IonButton } from '@ionic/react';

interface PromptInputProps {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  outline?: boolean;
  showLabel?: boolean;
}

const AutoBotPromptInput: React.FC<PromptInputProps> = ({
  state,
  setState,
  placeholder = '',
  outline = false,
  showLabel = false
}) => {
  const textareaRef = useRef<HTMLIonTextareaElement>(null);

  const promptOptions: string[] = [
    '{{ client }}',
    '{{ client.specials_ai_text }}',
    '{{ company_type }}',
    '{{ lead }}',
    '{{ pin }}',
    '{{ short_credit_app_link }}',
    '{{ soft_pull_link }}',
    '{{ credit_app_link }}',
    '{{ survey_link }}',
    '{{ dealership }}',
    '{{ robot_name }}',
    '{{ assignee }}',
    '{{ company_name }}',
    '{{ first_name }}',
    '{{ last_name }}',
  ];

  const normalizeLineBreaks = (input: string) => input.replace(/\r\n/g, '\n');

  const insertOption = (insertedText: string) => {
    const textarea = textareaRef.current?.getInputElement();

    if (textarea) {
      textarea.then((textarea) => {
        const startPosition = textarea.selectionStart ?? 0;
        const endPosition = textarea.selectionEnd ?? 0;

        const beforeCursor = normalizeLineBreaks(state.slice(0, startPosition));
        const afterCursor = normalizeLineBreaks(state.slice(endPosition));

        const newText = beforeCursor + insertedText + afterCursor;
        setState(newText)

        const cursorPosition = startPosition + insertedText.length;
        setTimeout(() => {
          textarea.setSelectionRange(cursorPosition, cursorPosition);
          textarea.focus();
        }, 0)
      })
    }
  }

  const formatOption = (option: string) => {
    return option.split('{{ ')[1].split(' }}')[0].replaceAll('_', ' ')
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ margin: `${outline ? '8px 0' : '8px'}` }}>
        {promptOptions.map(option => (
          <IonButton
            key={option}
            color="secondary"
            size="small"
            onClick={() => insertOption(option)}
          >
            {formatOption(option)}
          </IonButton>
        ))}
      </div>
      <IonItem className="ion-margin-top ion-margin-bottom">
        <IonTextarea
          fill={outline ? 'outline' : undefined}
          label={showLabel ? "Prompt" : undefined}
          labelPlacement="stacked"
          placeholder={placeholder}
          ref={textareaRef}
          autoGrow
          value={state}
          onIonInput={(e: CustomEvent) => {
            const newValue = e.detail.value as string;
            setState(newValue)
          }}
          className="hide-ion-scrollbar"
        />
      </IonItem >
    </div>
  )
}

export default AutoBotPromptInput;