import React from 'react';
import { useRequireAdmin } from '../hooks';

import {
  TPage,
  RealtimeActivity
} from '../components';

const MonitorPage: React.FC = () => {
  useRequireAdmin();
  return (
    <TPage loading={false}>
      <RealtimeActivity />
    </TPage>
  );
};

export default MonitorPage;
