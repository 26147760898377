import React, { useState, useCallback, useContext } from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonRadio,
  IonLabel,
  IonRadioGroup
} from '@ionic/react';
import HapticButton from './HapticButton';
import { appNotification } from '../core';
import { TrainingContext } from '../context/TrainingContext';
import { trainingService } from '../services';

const TrainingQuestion: React.FC = () => {
  const { state, dispatch } = useContext(TrainingContext);
  const { quiz, question, session } = state;
  const [selectedAnswer, setSelectedAnswer] = useState();

  const answerQuestion = useCallback(async () => {
    try {
      if (!selectedAnswer) {
        return appNotification.toast('Please choose an answer.', 'Error');
      }

      const updated = await trainingService.logAnswer(
        quiz.id,
        session.id,
        question.id,
        selectedAnswer
      );

      const nextOrder = question.order + 1;
      const nextQuestion = quiz?.quiz_questions?.find(
        (q: any) => q.order === nextOrder
      );

      dispatch({ session: updated, question: nextQuestion });
    } catch (e) {console.error(e)}
  }, [selectedAnswer, session, question, quiz, dispatch]);

  return (
    <IonCard className="training-question">
      <IonCardHeader>
        <IonCardTitle>Question #{question?.order}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <h2 className="question-text">{question?.question}</h2>
        <IonList className="question-answers" lines="none">
          <IonRadioGroup value={selectedAnswer}>
            {question?.question_choices?.map((a: any) => (
              <IonItem key={a.id} onClick={() => setSelectedAnswer(a.id)}>
                <IonRadio slot="start" value={a.id} />
                <IonLabel>{a.choice}</IonLabel>
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
        <HapticButton color="secondary" fill="solid" onClick={answerQuestion}>
          Next Question
        </HapticButton>
      </IonCardContent>
    </IonCard>
  );
};

export default TrainingQuestion;
