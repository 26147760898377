import React, { useState, useCallback, useEffect } from 'react';
import TModal from './TModal';
import { modalController } from '@ionic/core';
import {
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonThumbnail,
  IonImg,
} from '@ionic/react';
import { TInputItem } from '../';
import '../../styles/components/DocumentModal.scss';
import { leadsService, leadDocumentService } from '../../services';
import { loadingIndicator, appNotification } from '../../core';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';

const getIconSource = (url: string, contentType: string): string => {
  if (contentType.includes('image')) {
    return url; // Display the image itself if it's an image type
  }

  const extension = url?.split('?')?.[0].split('.').pop()?.toLowerCase();

  // Mapping of extensions to icon files
  const iconMapping: Record<string, string> = {
    pdf: '/assets/document-icons/pdf.png',
    doc: '/assets/document-icons/word.png',
    docx: '/assets/document-icons/word.png',
    ppt: '/assets/document-icons/file.png', // No specific icon for ppt, using default
    pptx: '/assets/document-icons/file.png', // No specific icon for pptx, using default
    xls: '/assets/document-icons/excel.png',
    xlsx: '/assets/document-icons/excel.png',
    txt: '/assets/document-icons/txt.png',
    rtf: '/assets/document-icons/txt.png', // Using txt icon for rtf
    csv: '/assets/document-icons/csv.png',
    m4v: '/assets/document-icons/video.png',
    '3gp': '/assets/document-icons/video.png',
    mov: '/assets/document-icons/video.png',
    mp4: '/assets/document-icons/video.png',
    mkv: '/assets/document-icons/video.png',
    avi: '/assets/document-icons/video.png',
    mpg: '/assets/document-icons/video.png',
    mpeg: '/assets/document-icons/video.png',
    wmv: '/assets/document-icons/video.png',
    zip: '/assets/document-icons/file.png', // No specific icon for zip, using default
  };
  return extension && iconMapping.hasOwnProperty(extension)
    ? iconMapping[extension]
    : '/assets/document-icons/file.png';
};

export interface DocumentModalProps {
  lead: any;
  messageId: number;
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  onDidDismiss,
  lead,
  messageId,
  isOpen = false,
}) => {
  const [message, setMessage] = useState<any>();
  const [mediaIds, setMediaIds] = useState<any>();

  const saveToDocumentCenter = useCallback(async () => {
    if (!message) {
      return null;
    }
    await loadingIndicator.create();
    try {
      const reqs: any = message?.smsmedia_set
        ?.filter((it: any) => mediaIds.includes(it.id))
        ?.map((it: any) => {
          // trim off the signed auth url
          const url = it.media?.split('?')[0];
          const doc = {
            document_type: it.content_type,
            name: it.fileName ?? url?.split('/')?.pop(),
            url,
          };
          return leadDocumentService.create(lead?.client, lead?.id, doc);
        });
      await Promise.all(reqs);
      await appNotification.toast(
        'Items have been added to document center.',
        'Success'
      );
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  }, [lead, message, mediaIds]);

  const addOrRemove = (mediaId: number) =>
    mediaIds.includes(mediaId)
      ? setMediaIds(mediaIds.filter((i: number) => i !== mediaId))
      : setMediaIds([...mediaIds, mediaId]);

  const documentLead = useCallback(() => {
    saveToDocumentCenter();
  }, [saveToDocumentCenter]);

  const fetchMessage = useCallback(async () => {
    const data: any = await leadsService.getMessage(
      lead.client,
      lead.id,
      messageId
    );
    setMediaIds(data?.smsmedia_set?.map((it: any) => it.id));
    return setMessage(data);
  }, [lead, messageId]);

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);

  return (
    <TModal
      isOpen={isOpen}
      title='Save to Document Center'
      onDidDismiss={onDidDismiss}
      className='document-modal'
      footer={
        <>
          <IonItem lines='none'>
            <IonButton
              slot='end'
              color='success'
              fill='clear'
              size='default'
              disabled={!mediaIds?.length}
              onClick={documentLead}
            >
              Save to Document Center
            </IonButton>
          </IonItem>
        </>
      }
    >
      <div className='document-container'>
        <IonList>
          {message?.smsmedia_set?.map((it: any, index: number) => (
            <TInputItem
              key={it.id}
              label='Name'
              labelPlacement='stacked'
              value={it?.media?.split('/')?.pop()?.split('?')[0]}
              required
              onChange={(e: any) => {
                const messageCopy = Object.assign({}, message);
                messageCopy.smsmedia_set = messageCopy.smsmedia_set.map(
                  (md: any) => {
                    if (md.id === it.id) {
                      md.fileName = e.detail.value;
                    }
                    return md;
                  }
                );
                setMessage(messageCopy);
              }}
            >
              <IonThumbnail slot='start'>
                <IonImg
                  style={{ background: 'white' }}
                  src={getIconSource(it.media, it.content_type)}
                />
              </IonThumbnail>
              <IonButton
                onClick={(e: any) => addOrRemove(it.id)}
                size='large'
                fill='clear'
                slot='end'
                color='primary'
              >
                <IonIcon
                  icon={
                    mediaIds?.includes(it.id)
                      ? checkmarkCircle
                      : checkmarkCircleOutline
                  }
                />
              </IonButton>
            </TInputItem>
          ))}
        </IonList>
      </div>
    </TModal>
  );
};

export default DocumentModal;
