import React from 'react';
import TModal from './TModal';
import { IonList, IonItemDivider, IonIcon } from '@ionic/react';
import TItem from '../TItem';
import TButtonItem from '../TButtonItem';
import moment from 'moment';
import { person, people, time, videocam } from 'ionicons/icons';
export interface VideoMeetingModalProps {
  isOpen?: boolean;
  videoMeeting: any;
  user: any;
  onDidDismiss: () => any;
}

const VideoMeetingModal: React.FC<VideoMeetingModalProps> = ({
  isOpen,
  videoMeeting,
  user,
  onDidDismiss
}) => {

  return (
    <TModal
      isOpen={isOpen}
      title={`${user.full_name} Video Meeting`}
      onDidDismiss={onDidDismiss}
    >
      <IonList lines="full">
      {videoMeeting && (
        <>
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={person} />
            &nbsp;Lead
          </IonItemDivider>
          <TItem
            detail={true}
            label={videoMeeting.lead.full_name}
            routerLink={`/text-messages/conversation/${videoMeeting?.client}/${videoMeeting?.lead?.id}/`}
            onClick={onDidDismiss}
            className="pointer"
            lines="full"
          />
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={time} />
            &nbsp;Started
          </IonItemDivider>
          <TItem
            label={moment(videoMeeting.created_at).fromNow()}
            lines="full"
          />
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={people} />
            &nbsp;Participants
          </IonItemDivider>
          {videoMeeting && videoMeeting?.participants?.map((participant: any, idx: any) => {
            return (
              <TItem key={participant.sid} label={participant.identity}>
                {participant.status} {moment(participant.start_time).fromNow()}
              </TItem>
            );
          })}
          <TButtonItem
            color="secondary"
            href={videoMeeting.join_url}
            target="_blank"
            onClick={onDidDismiss}
          >
            <IonIcon icon={videocam} slot="start" />
            Join Meeting
          </TButtonItem>
        </>
      )}
      </IonList>
    </TModal>
  );
};

export default VideoMeetingModal;
