import PagedService from './paged-service';
import { http } from '../core';

interface BotOrder {
  id: number;
  order: number;
}

class AutoBotService extends PagedService {
  async single(clientId: number, botId: number) {
    try {
      const {data} = await http.authorizedRequest({
        url: `/clients/${clientId}/ai-prompts/${botId}/`
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async list(clientId: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/ai-prompts/`
      })

      return data;
    } catch (e) {
      http.onHttpError(e)
    }
  }

  async create(clientId: number, postData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/ai-prompts/`,
        data: postData
      })

      return data;
    } catch (e) {
      http.onHttpError(e)
    }
  }

  async patch(clientId: number, patchData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/ai-prompts/${patchData.id}/`,
        data: patchData,
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getClientSettings(clientId: number) {
    try {
      return await http.authorizedRequest({
        url: `/clients/${clientId}/`
      })
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async patchClientSettings(clientId: number, patchData: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/`,
        data: patchData
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async updateOrder(clientId: number, patchData: BotOrder[]) {
    try {
      const responses = Promise.all(
        patchData.map(async (dataPoint) => {
          http.authorizedRequest({
            method: 'PATCH',
            url: `/clients/${clientId}/ai-prompts/${dataPoint.id}/`,
            data: {order: dataPoint.order}
          })
        })
      )
      return responses;
    } catch (e) {
      http.onHttpError(e)
    }
  }

  async delete(clientId: number, id: number) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/ai-prompts/${id}/`
      })

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const autobotService = new AutoBotService();
export default autobotService;