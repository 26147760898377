import React from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import css from 'classnames';
import { copy } from 'ionicons/icons';
import { util } from '../core';
import HapticButton from './HapticButton';

export interface TItemProps {
  label?: string;
  labelElement?: React.ReactNode;
  href?: string;
  target?: string;
  children?: any;
  className?: string;
  onClick?: any;
  routerLink?: any;
  icon?: any;
  wrapLabel?: boolean;
  lines?: HTMLIonItemElement['lines'];
  labelPlacement?: HTMLIonLabelElement['position'];
  copyText?: string | null;
  nocontain?: boolean;
  detail?: boolean;
}

const TItem: React.FC<TItemProps> = ({
  label,
  labelElement,
  href,
  target,
  labelPlacement,
  children,
  routerLink,
  onClick,
  className,
  wrapLabel = true,
  icon,
  lines,
  copyText,
  nocontain,
  detail
}) => {
  return (
    <IonItem
      lines={lines}
      routerLink={routerLink}
      onClick={onClick}
      detail={detail}
      href={href}
      target={target}
      className={css(className, { 'no-contain': nocontain })}
    >
      {icon && (
        <IonIcon icon={icon} slot="start" className="ion-hide-sm-down" />
      )}
      {label && (
        <IonLabel
          position={labelPlacement}
          className={css({
            'light-text-color': labelPlacement === 'stacked',
            'ion-text-wrap': wrapLabel
          })}
        >
          {label}
        </IonLabel>
      )}
      {labelElement && (
        <IonLabel
          position={labelPlacement}
          className={css({
            'light-text-color': labelPlacement === 'stacked',
            'ion-text-wrap': wrapLabel
          })}
        >
          {labelElement}
        </IonLabel>
      )}
      <div style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        justifyContent: `${labelPlacement === 'stacked' ? 'flex-start' : 'flex-end'}`,
      }}>
        {children}
      </div>
      {copyText && (
        <HapticButton
          icon={copy}
          slot="end"
          onClick={() => util.copyToClipboard(copyText)}
        />
      )}
    </IonItem>
  );
};

export default TItem;
