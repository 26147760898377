import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { haptics } from '../core';
import '../styles/components/ActionBar.scss';

export interface ActionBarItem {
  slot?: string;
  icon?: any;
  label?: string;
  style?: object;
  disabled?: boolean;
  haptics?: boolean;
  action?: (e?: any) => void;
  routerLink?: string;
}

export interface ActionBarProps {
  items: Array<ActionBarItem>;
}

const ActionBar: React.FC<ActionBarProps> = (props: ActionBarProps) => {
  return (
    <div className="action-bar no-margin no-padding">
      {props.items.map(item => (
        <IonButton
          key={item.label}
          className="action-bar-item"
          fill="clear"
          routerLink={item.routerLink}
          onClick={
            item.disabled
              ? undefined
              : (e: any) => {
                  if (item.haptics) {
                    haptics.lightImpact();
                  }
                  if (item.action) {
                    item.action(e);
                  }
                }
          }
          style={item.style}
          disabled={item.disabled}
        >
          {item.label}
          {item.icon && <IonIcon slot={item.slot} icon={item.icon} />}
        </IonButton>
      ))}
    </div>
  );
};

export default ActionBar;
