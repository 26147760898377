import { http, appNotification } from '../core';
import qs from 'qs';
import PagedService from './paged-service';

export enum PHONE_CALLS_DEFAULT_DURATION_RANGE {
  Min = 0,
  Max = 7200,
}

class PhoneCallsService extends PagedService {
  async list({
    clientId,
    user,
    search,
    direction,
    provider,
    duration__gte,
    duration__lte,
    created_at__gte,
    created_at__lte,
    category__icontains,
    emotions__icontains,
  }: any) {
    try {
      const params = {} as any;
      if (user) {
        params.user = user;
      }
      if (search) {
        params.search = search;
      }
      if (direction) {
        params.direction = direction;
      }
      if (provider) {
        params.lead__master_provider = provider;
      }
      if (duration__gte) {
        params.duration__gte = duration__gte;
      }
      if (created_at__gte) {
        params.created_at__gte = created_at__gte;
      }
      if (created_at__lte) {
        params.created_at__lte = created_at__lte;
      }
      if (emotions__icontains) {
        params.emotions__icontains = emotions__icontains;
      }
      if (category__icontains) {
        params.category__icontains = category__icontains;
      }
      if (
        duration__lte &&
        duration__lte < PHONE_CALLS_DEFAULT_DURATION_RANGE.Max
      ) {
        params.duration__lte = duration__lte;
      }
      const query = qs.stringify(params);
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/calls/?${query}`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async listUserCalls({
    user,
    search,
    direction,
    provider,
    duration__gte,
    duration__lte,
    created_at__gte,
    created_at__lte,
  }: any) {
    try {
      const params = {} as any;
      if (user) {
        params.user = user;
      }
      if (search) {
        params.search = search;
      }
      if (direction) {
        params.direction = direction;
      }
      if (provider) {
        params.lead__master_provider = provider;
      }
      if (duration__gte) {
        params.duration__gte = duration__gte;
      }
      if (
        duration__lte &&
        duration__lte < PHONE_CALLS_DEFAULT_DURATION_RANGE.Max
      ) {
        params.duration__lte = duration__lte;
      }
      if (created_at__gte) {
        params.created_at__gte = created_at__gte;
      }
      if (created_at__lte) {
        params.created_at__lte = created_at__lte;
      }
      const query = qs.stringify(params);
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/call-report/?${query}`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async callBarge(
    clientId: string | number,
    callId: string | number,
    mode: string
  ) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `clients/${clientId}/call-barge/${callId}/`,
        data: { mode },
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async call(leadId: string | number, name: string, numberType?: string) {
    try {
      const params: any = !!numberType ? { number_type: numberType } : {};
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/ivr/conference-start/${leadId}/?${query}`,
        data: {},
      });

      if (data.call) {
        appNotification.toast(
          `Answer your phone when it rings.`,
          `Calling ${name}`,
          {
            duration: 0,
          }
        );
      } else {
        const { assigned_to } = data;
        appNotification.toast(
          `Sorry, ${assigned_to.first_name} ${assigned_to.last_name} picked up this customer first.`,
          'Call Failed'
        );
      }

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const phoneCallsService = new PhoneCallsService();
export default phoneCallsService;
