import React, { useState, useContext } from 'react';
import { IonList, IonButton } from '@ionic/react';
import TToggleItem from '../TToggleItem';
import ButtonGrid from '../ButtonGrid';
import { TModal } from '.';
import { AppContext } from '../../context/AppContext';

export interface MonitorFiltersModalProps {
  isOpen?: boolean;
  filters: any;
  onDidDismiss?: () => void;
  onApplyFilters?: (filters: any) => void;
}

const MonitorFiltersModal: React.FC<MonitorFiltersModalProps> = ({
  onDidDismiss,
  onApplyFilters,
  filters,
  isOpen = false
}) => {
  const { state } = useContext(AppContext);
  const { user, clients } = state;
  const [staffOnly, setStaffOnly] = useState<boolean>(filters.staffOnly);
  const [showCalls, setShowCalls] = useState<boolean>(filters.showCalls);
  const [showTyping, setShowTyping] = useState<boolean>(filters.showTyping);
  const [selectedClientOnly, setSelectedClientOnly] = useState<boolean>(filters.selectedClientOnly);
  const [showVideoMeetings, setShowVideoMeetings] = useState<boolean>(filters.showVideoMeetings);

  return (
    <TModal
      isOpen={true}
      onDidDismiss={onDidDismiss}
      title="Filters"
      footer={
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              onApplyFilters?.({showCalls: true, showTyping: true, showVideoMeetings: true, staffOnly: true});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              onApplyFilters?.({showCalls, showTyping, showVideoMeetings, staffOnly, selectedClientOnly});
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full">
        {user.is_staff && (
          <TToggleItem
            label="Staff Only"
            checked={staffOnly}
            onIonChange={() => setStaffOnly(!staffOnly)}
            wrapLabel
          />
        )}
        <TToggleItem
          label="Calls"
          checked={showCalls}
          onIonChange={() => setShowCalls(!showCalls)}
          wrapLabel
        />
        <TToggleItem
          label="Typing"
          checked={showTyping}
          onIonChange={() => setShowTyping(!showTyping)}
          wrapLabel
        />
        <TToggleItem
          label="Video Meetings"
          checked={showVideoMeetings}
          onIonChange={() => setShowVideoMeetings(!showVideoMeetings)}
          wrapLabel
        />
        {(user.is_staff || clients.length > 1) && (
          <TToggleItem
            label="Selected Client Only"
            checked={selectedClientOnly}
            onIonChange={() => setSelectedClientOnly(!selectedClientOnly)}
            wrapLabel
          />
        )}
      </IonList>
    </TModal>
  );
};

export default MonitorFiltersModal;
