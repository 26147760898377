import React, { useContext } from 'react';
import { TPage } from '../components';
import { IonIcon, IonText, IonButton } from '@ionic/react';
import { sad } from 'ionicons/icons';
import { AppContext } from '../context/AppContext';
import { network, appNotification } from '../core';

const OfflinePage: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  return (
    <TPage loading={false} noClientSelector>
      <div className="offline-container">
        <div className="offline-text-icon-container">
          <IonIcon icon={sad} className="offline-icon" />
          <IonText>
            <h4>You don't currently have an internet connection.</h4>
            <p className="detail">
              We'll keep retrying and refresh automatically when internet is
              available.
            </p>
          </IonText>
        </div>
        <IonButton
          color="success"
          onClick={async () => {
            const isOnline = await network.check();
            if (!isOnline) {
              appNotification.toast(
                `You still don't seem to have an internet connection.`,
                'No Connection'
              );
            }
            dispatch({
              type: 'set',
              value: { isOnline }
            });
          }}
        >
          Retry
        </IonButton>
      </div>
    </TPage>
  );
};

export default OfflinePage;
