import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

type Account = {
  name: string;
  outbound_calls_count: number;
};

const columns: TableColumn<Account>[] = [
  {
    name: 'Name',
    selector: (row: Account) => row.name,
    sortable: true,
  },
  {
    name: 'Outbound Calls Count',
    selector: (row: Account) => row.outbound_calls_count,
    sortable: true,
  },
];

const ExpandedDataTableRowAccountBreakdown: React.FC<any> = (props: any) => {
  const { data } = props;

  return (
    <DataTable
      title="Accounts Breakdown"
      columns={columns}
      data={data}
    />
  );
};

export default ExpandedDataTableRowAccountBreakdown;
