import React, { useContext, useState, useEffect, useCallback } from 'react';
import { ClientUser } from '../types/ClientUser';
import { TPage, TAvatar, TSearchBar, HapticButton } from '../components';
import {
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge,
  IonItemDivider,
  IonIcon
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';
import { personAdd, ellipsisVertical, calendar, construct } from 'ionicons/icons';
import { appNotification, loadingIndicator, http, actionSheet } from '../core';
import {
  clientUsersService,
  leadsService,
  userService
} from '../services';
import { ReassignLeadsModal } from '../components/modals';

const ClientUsersPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { clientUsers, selectedClientId, user } = state;
  const authUser = state.user;
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState(clientUsers);
  const [leadAssignModalUser, setLeadAssignModalUser] = useState<any>();
  const [leadAssignCount, setLeadAssignCount] = useState(0);
  const [showStaff, setShowStaff] = useState(false);

  useEffect(() => setUsers(clientUsers), [clientUsers, selectedClientId]);

  useEffect(() => {
    if (!showStaff) {
      setUsers(clientUsers.filter((u: any) => !userService.isStaff(u)));
    } else {
      setUsers(clientUsers);
    }
  }, [clientUsers, showStaff]);

  useRequireSettingsAdmin();

  const search = useCallback(
    (val: string) => {
      console.log('searching', val);
      setSearchValue(val);
      setUsers(
        val
          ? clientUsers
            .filter((u: any) =>
              `${u.full_name} ${u.email} ${u.cell_phone}`
                .toLowerCase()
                .includes(val.toLowerCase())
            )
            .filter((u: any) => (showStaff ? true : !userService.isStaff(u)))
          : clientUsers.filter((u: any) => (showStaff ? true : !userService.isStaff(u)))
      );
    },
    [clientUsers, showStaff] // Dependencies to update search when clientUsers or showStaff changes
  );

  const removeUser = async (user: ClientUser) => {
    try {
      await clientUsersService.delete(selectedClientId, user);
      clientUsersService.evict();
      const updated = await clientUsersService.request(selectedClientId);
      dispatch({
        type: 'set',
        value: { clientUsers: updated }
      });
      setUsers(updated);
      setSearchValue('');
    } catch (e) {
      http.onHttpError(e);
    }
  };

  const onLeadsReassigned = async () => {
    try {
      await loadingIndicator.create();
      await removeUser(leadAssignModalUser);
      setLeadAssignModalUser(null);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const checkLeads = async (user: ClientUser) => {
    try {
      await loadingIndicator.create();
      const leadCount = await leadsService.getAssignedCount(
        selectedClientId,
        user.id
      );

      if (leadCount > 0) {
        setLeadAssignCount(leadCount);
        setLeadAssignModalUser(user);
      } else {
        await removeUser(user);
      }
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const confirmRemove = (user: ClientUser) => {
    appNotification.alert({
      message: `Are you sure you want to remove ${user.full_name}?`,
      header: 'Remove User?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Continue',
          handler() {
            checkLeads(user);
          }
        }
      ]
    });
  };

  const updateClockInStatus = async (user: ClientUser) => {
    await loadingIndicator.create();
    try {
      const clockInInValue = !user.is_clocked_in;
      await clientUsersService.update(selectedClientId, user.id, {
        is_clocked_in: clockInInValue
      })
      clientUsersService.evict();
      const updated = await clientUsersService.getList(selectedClientId);
      await dispatch({
        type: 'set',
        value: { clientUsers: updated }
      });
      setSearchValue('');
      loadingIndicator.dismiss();
    } catch (e) {
      http.onHttpError(e)
      loadingIndicator.dismiss();
    }
  }

  const showUserActionSheet = (u: ClientUser) => {
    const buttons = [
      {
        text: 'Remove User',
        role: 'destructive',
        handler() {
          confirmRemove(u);
          return true;
        }
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ]
    if (user.is_admin || user.is_client_admin) {
      buttons.push({
        text: u.is_clocked_in ? 'Clock Out' : 'Clock In',
        role: 'update',
        handler: () => {
          updateClockInStatus(u);
          return true;
        }
      })
    }

    actionSheet.show(buttons);
  };

  return (
    <TPage
      headerTool={<></>}
      loading={false}
      preContent={
        <IonItemDivider>
          <TSearchBar value={searchValue} onSearch={search} />
          {authUser.is_admin && (
            <HapticButton
              title="Show staff"
              icon={construct}
              color={showStaff ? 'secondary' : 'primary'}
              onClick={() => setShowStaff(!showStaff)}
            />
          )}
          <HapticButton
            title="Add new user"
            icon={personAdd}
            routerLink={`/client/${selectedClientId}/create/user/`}
          />
        </IonItemDivider>
      }
    >
      <IonList>
        {users?.map?.((user: any) => (
          <IonItem
            key={user.id}
            routerLink={`/client/users/${selectedClientId}/${user.id}/`}
          >
            <TAvatar slot="start" alt={user.full_name} email={user.email} />
            <IonLabel className="ion-multiline">
              <IonText>
                <h3>{user.full_name}</h3>
                <p className="detail ion-text-wrap">
                  {user.cell_phone || 'No Phone'} • {user.email}
                </p>
                {authUser.is_staff && (
                  <p className="detail clock-status">
                    <IonIcon
                      icon={calendar}
                      style={{ marginRight: 5 }}
                      color={user.calendars_connected ? 'success' : 'danger'}
                    />
                    <span>Calendar {user.calendars_connected ? 'Connected' : 'Not Connected'}</span>
                  </p>
                )}
              </IonText>
            </IonLabel>
            {userService.isStaff(user) && (
              <IonBadge color="success" mode="ios" slot="end">
                Staff
              </IonBadge>
            )}
            {!userService.isStaff(user) && user.is_client_admin && (
              <IonBadge color="secondary" mode="ios" slot="end">
                Admin
              </IonBadge>
            )}
            {!userService.isStaff(user) && user.is_settings_admin && (
              <IonBadge color="tertiary" mode="ios" slot="end">
                Settings
              </IonBadge>
            )}
            {userService.isRoundRobinEnabled(user) && (
              <IonBadge color="warning" mode="ios" slot="end">
                Round Robin
              </IonBadge>
            )}
            {user.is_clocked_in && (
              <IonBadge color="primary" mode="ios" slot="end">
                Clocked In
              </IonBadge>
            )}
            <HapticButton
              title="Options"
              icon={ellipsisVertical}
              slot="end"
              className="btn-sm-padding"
              onClick={(e: any) => showUserActionSheet(user)}
              cancelBubble
            />
          </IonItem>
        ))}
        {leadAssignModalUser && (
          <ReassignLeadsModal
            user={leadAssignModalUser}
            onConfirm={onLeadsReassigned}
            leadCount={leadAssignCount}
            onDidDismiss={() => {
              setLeadAssignCount(0);
              setLeadAssignModalUser(null);
            }}
          />
        )}
      </IonList>
    </TPage>
  );
};

export default ClientUsersPage;
