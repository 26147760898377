import React from 'react';
import TModal from './TModal';
import { IonList } from '@ionic/react';
import TTextItem from '../TTextItem';
import ButtonGrid from '../ButtonGrid';
import HapticButton from '../HapticButton';
import { useHistory } from 'react-router-dom';
import { modalController } from '@ionic/core';
import { browser } from '../../core';

export interface SoftPullModalProps {
  isOpen: boolean;
  onDidDismiss?: () => any;
  data: any;
}

const SoftPullModal: React.FC<SoftPullModalProps> = ({
  isOpen,
  onDidDismiss,
  data
}) => {
  const history = useHistory();
  const handleViewLead = () => {
    modalController.dismiss();
    history.push(`/text-messages/conversation/${data.client.id}/${data.lead}/`);
  };
  return (
    <TModal
      title={`${data?.first_name} ${data?.last_name}`}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      footer={
        data?.iframe_code && (
          <ButtonGrid>
            <HapticButton
              expand="block"
              color="primary"
              fill="solid"
              onClick={handleViewLead}
            >
              Go to Lead
            </HapticButton>
            <HapticButton
              expand="block"
              color="secondary"
              fill="solid"
              onClick={() => data && browser.open(data.iframe_code)}
            >
              View Report
            </HapticButton>
          </ButtonGrid>
        )
      }
    >
      <IonList className="no-padding no-margin" lines="full">
        <TTextItem
          label="Agreed to Terms"
          text={data?.terms_agreed ? 'Yes' : 'No'}
        />
        <TTextItem label="Has SSN" text={data?.has_ssn ? 'Yes' : 'No'} />
        <TTextItem label="Credit Bureau" text={data?.bureau_name} />
        <TTextItem label="Result" text={data?.result_description} />
        <TTextItem label="Scorecard" text={data?.scorecard_name} />
        <TTextItem lines="none" label="Score Range" text={data?.score_range} />
      </IonList>
    </TModal>
  );
};

export default SoftPullModal;
