import React, { useState } from 'react';
import VirtualList, { Props, ItemInfo } from 'react-tiny-virtual-list';
import AutoSizer from 'react-virtualized-auto-sizer';
import { IonRefresher, IonRefresherContent } from '@ionic/react';

export interface TVirtualListProps extends Props {
  onIonRefresh: (e: any) => void;
  itemCount: number;
  itemSize: number;
  renderItem(itemInfo: ItemInfo): React.ReactNode;
}

const TVirtuaList: React.FC<TVirtualListProps> = props => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { itemCount, itemSize, renderItem, onIonRefresh, ...rest } = props;

  return (
    <AutoSizer>
      {({ height, width }: { height: number, width: number }) => (
        <>
          <IonRefresher
            slot="fixed"
            onIonRefresh={onIonRefresh}
            disabled={scrollPosition > 10}
          >
            <IonRefresherContent/>
          </IonRefresher>
          <VirtualList
            width={width}
            itemCount={itemCount}
            itemSize={itemSize}
            renderItem={renderItem}
            onScroll={setScrollPosition}
            {...rest}
          />
        </>
      )}
    </AutoSizer>
  );
};

export default TVirtuaList;
