import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class ClientProvidersService extends CachedService {
  constructor() {
    super(
      'client-providers',
      'clientProviders',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.HOUR
    );
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/master-providers/`
    });

    return res.data;
  }
}

const clientProvidersService = new ClientProvidersService();
export default clientProvidersService;
