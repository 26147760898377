import React, { useState, useEffect, useRef } from 'react';
import { TItemProps, default as TItem } from './TItem';
import { ChromePicker } from 'react-color';
import HapticButton from './HapticButton';
import { colorPalette } from 'ionicons/icons';
import css from 'classnames';
import '../styles/components/TColorPickerItem.scss';
import { usePortal, useOnClickOutside } from '../hooks';
import ReactDOM from 'react-dom';

export interface TColorPickerItemProps extends TItemProps {
  value?: any;
  onChange?: (e: any) => any;
}

const TColorPickerItem: React.FC<TColorPickerItemProps> = ({
  value,
  onChange,
  children,
  className,
  ...rest
}) => {
  const ref = useRef<any>();
  const portal = usePortal();
  const [color, setColor] = useState(value);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useOnClickOutside(ref, () => setPickerVisible(false));
  useEffect(() => setColor(value), [value]);

  return (
    <TItem
      className={css(
        't-color-picker-item',
        { picker: pickerVisible },
        className
      )}
      {...rest}
    >
      {pickerVisible &&
        ReactDOM.createPortal(
          <div
            ref={ref}
            className="color-picker"
            style={{
              top: y,
              left: x
            }}
          >
            <ChromePicker
              color={color}
              onChange={(c: any) => {
                setColor(c.hex);
                onChange?.(
                  new CustomEvent('color:changed', { detail: { value: c.hex } })
                );
              }}
            />
          </div>,
          portal
        )}
      <div
        className="selected-color"
        style={{
          backgroundColor: color
        }}
      />
      <HapticButton
        color="primary"
        onClick={(e: any) => {
          setY(e.pageY);
          setX(e.pageX);
          setPickerVisible(true);
        }}
        cancelBubble
        icon={colorPalette}
      />
      {children}
    </TItem>
  );
};

export default TColorPickerItem;
