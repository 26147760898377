import React, { useContext, useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader'
import {
  IonList,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonItem,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { useRouter } from '../hooks';
import {
  TPage
} from '../components';

import {
  checkmarkCircle,
  ellipseOutline
} from 'ionicons/icons';
import TSelectItem from '../components/TSelectItem';
import { MailDropContext } from '../context/MailDropContext';
import ButtonGrid from '../components/ButtonGrid';

const availableColumns = [
  {
      value: 'first_name',
      name: 'First Name',
      required: true
  },
  {
      value: 'last_name',
      name: 'Last Name',
      required: true
  },
  {
      value: 'email_address',
      name: 'Email Address',
      required: false
  },
  {
      value: 'address',
      name: 'Street Address',
      required: true
  },
  {
      value: 'address_2',
      name: 'Apt/Unit Number',
      required: false
  },
  {
      value: 'city',
      name: 'City',
      required: true
  },
      {
      value: 'state',
      name: 'State',
      required: true
  },
  {
      value: 'postal_code',
      name: 'Postal Code',
      required: true
  },
  {
      value: 'postal_code_4',
      name: 'Postal Code +4',
      required: false
  },
  {
      value: 'vehicle_year',
      name: 'Vehicle Year',
      required: false
  },
  {
      value: 'vehicle_make',
      name: 'Vehicle Make',
      required: false
  },
  {
      value: 'vehicle_model',
      name: 'Vehicle Model',
      required: false
  },
  {
      value: 'vehicle_value',
      name: 'Vehicle Value',
      required: false
  },
  {
      value: 'vehicle_vin',
      name: 'Vehicle VIN',
      required: false
  },
  {
      value: 'pd_1',
      name: 'Pass through data 1',
      required: false
  },
  {
      value: 'pd_2',
      name: 'Pass through data 2',
      required: false
  },
  {
      value: 'pd_3',
      name: 'Pass through data 3',
      required: false
  },
  {
      value: 'pd_4',
      name: 'Pass through data 4',
      required: false
  },
  {
      value: 'pd_5',
      name: 'Pass through data 5',
      required: false
  },
  {
      value: 'pd_6',
      name: 'Pass through data 6',
      required: false
  },
  {
      value: 'pd_7',
      name: 'Pass through data 7',
      required: false
  },
  {
      value: 'pd_8',
      name: 'Pass through data 8',
      required: false
  },
  {
      value: 'pd_9',
      name: 'Pass through data 9',
      required: false
  },
  {
      value: 'pd_10',
      name: 'Pass through data 10',
      required: false
  },
  {
      value: 'pd_11',
      name: 'Pass through data 11',
      required: false
  },
  {
      value: 'pd_12',
      name: 'Pass through data 12',
      required: false
  },
  {
      value: 'pd_13',
      name: 'Pass through data 13',
      required: false
  },
  {
      value: 'pd_14',
      name: 'Pass through data 14',
      required: false
  },
  {
      value: 'pd_15',
      name: 'Pass through data 15',
      required: false
  },
  {
      value: 'pd_16',
      name: 'Pass through data 16',
      required: false
  },
  {
      value: 'pd_17',
      name: 'Pass through data 17',
      required: false
  },
  {
      value: 'pd_18',
      name: 'Pass through data 18',
      required: false
  },
  {
      value: 'pd_19',
      name: 'Pass through data 19',
      required: false
  },
  {
      value: 'pd_20',
      name: 'Pass through data 20',
      required: false
  },
];

const AddMailDropStep2Page: React.FC = () => {
  const router = useRouter();
  const { dispatch } = useContext(MailDropContext);
  const [fileUploaded, setFileUploaded] = useState('');
  const [columnMap, setColumnMap] = useState<string[]>([]);
  const [columnMapValues, setColumnMapValues] = useState<any>({});
  const [headers, setHeaders] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleChange = (e: any, it: any) => {
    const { value } = e.target;
    const name = it.value;
    if (value !== undefined) {
      console.log(name, value)
      setColumnMapValues((columnMapValues: any) => ({
          ...columnMapValues,
          [name]: value
      }));
    }

  };

  useEffect(() => {
    const dataArray: any = [];
    for (let o in columnMapValues) {
      dataArray[columnMapValues[o]] = o;
    }
    setColumnMap(dataArray);
    const requiredFields = availableColumns.filter((it:any) => it.required).map((it: any) => it.value);
    const buttonDisabled = requiredFields.map((k: any) => (dataArray.some((el: any) => el === k))).some((el: any) => el === false);
    setButtonDisabled(buttonDisabled);
  }, [columnMapValues]);

  const onFileLoaded = (data:any, fileInfo:any, originalFile:any) => {
    console.log(data, fileInfo, originalFile);
    setHeaders(data?.[0]);
    setFileUploaded(originalFile);
  }

  return (
    <TPage
      customFooterContent={
        <ButtonGrid>
          <IonButton
            disabled={buttonDisabled}
            color="secondary"
            onClick={()=>{
              dispatch({
                columnMap,
                fileUploaded
              });
              router.push(`/maildrops/add/3/`, {});
            }}
            expand="block"
          >
            Next Step
          </IonButton>
        </ButtonGrid>
      }
    >

      <IonGrid className="training-quiz">
        <IonRow>
          <IonCol size="12" className="lesson-name">
            <strong className="lesson-category">Map Your Data</strong>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="training-progress">
            <div className="training-progress-item">
              <IonIcon
                color="success"
                icon={checkmarkCircle}
                slot="start"
              />
              <span>Settings</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Data</span>
            </div>
            <div className="training-progress-item">
              <IonIcon
                icon={ellipseOutline}
                slot="start"
              />
              <span>Review</span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonList lines="full" className="no-padding">
        <IonItemDivider sticky>
            Upload CSV Data File
        </IonItemDivider>
        <IonItem>
          <CSVReader
            onFileLoaded={onFileLoaded}
          />
        </IonItem>
        {!!fileUploaded && availableColumns.map((it: any, index: number) => (
          <TSelectItem
            key={`map_${index}`}
            value={columnMapValues[it.value]}
            label={`${it.required ? (it.name + '*') : it.name}`}
            onChange={(e: any) => handleChange(e, it)}
            options={[{text: "Don't import", value: undefined}, ...headers.map((text: any, value: number) => ({text, value}))]}
          />
        ))}
      </IonList>
    </TPage>
  );
};

export default AddMailDropStep2Page;
