import React, { useState, useEffect, useMemo, useCallback } from 'react';
import '../styles/components/FacebookCampaignOverview.scss';
import { LeadListModal } from '../components/modals';

import {
  IonRow,
  IonCol,
  IonGrid,
  IonSelect,
  IonSelectOption,
  IonText,
  IonProgressBar,
  useIonViewWillEnter
} from '@ionic/react';
import { util } from '../core';
import { facebookService } from '../services';
import TLoading from './TLoading';
import css from 'classnames';
import { useEventListener, useRouter, useDataLoader } from '../hooks';

export interface FacebookCampaignOverviewProps {
  clientId: any;
  campaignId?: string;
  adSetId?: string;
  adId?: string;
}

const FacebookCampaignOverview: React.FC<FacebookCampaignOverviewProps> = ({
  clientId,
  campaignId,
  adSetId,
  adId
}) => {
  const router = useRouter();
  const [modalData, setModalData] = useState<any>();
  const [datePreset, setDatePreset] = useState<string>(
    window.sessionStorage.getItem('fba-date-range') ??
    facebookService.defaultDatePreset
  );

  useEffect(() => {
    window.sessionStorage.setItem('fba-date-range', datePreset);
  }, [datePreset]);

  useIonViewWillEnter(() => {
    setDatePreset(
      window.sessionStorage.getItem('fba-date-range') ??
      facebookService.defaultDatePreset
    );
  });

  useEventListener('client:changed', ({ detail }) => {
    if (detail !== clientId && campaignId) {
      router.replace('/reports/facebook-ads/', {});
    }
  });

  const loadData = useCallback(async () => {
    return await facebookService.loadAdKPIs(
      clientId,
      datePreset,
      campaignId,
      adSetId,
      adId
    );
  }, [clientId, datePreset, campaignId, adSetId, adId]);

  const { loading, data } = useDataLoader(loadData);

  const lifetime_budget = useMemo(
    () => facebookService.getTotal(data, 'remote_read.lifetime_budget') / 100,
    [data]
  );

  const sold = useMemo(
    () => facebookService.getTotal(data, 'insights.sold_count'),
    [data]
  );
  const appts = useMemo(
    () => facebookService.getTotal(data, 'insights.set_count'),
    [data]
  );
  const showed = useMemo(
    () => facebookService.getTotal(data, 'insights.showed_count'),
    [data]
  );
  const leads = useMemo(
    () => facebookService.getTotal(data, 'insights.lead_count'),
    [data]
  );
  const spend = useMemo(
    () => facebookService.getTotal(data, 'insights.spend'),
    [data]
  );
  const grossProfit = useMemo(
    () => facebookService.getTotal(data, 'insights.gross_profit'),
    [data]
  );
  const startDate = useMemo(
    () => facebookService.getInsightDate(data, 'date_range.[0]'),
    [data]
  );
  const stopDate = useMemo(
    () => facebookService.getInsightDate(data, 'date_range.[1]'),
    [data]
  );
  const hasGrossProfitEnabled =
    // If data is an array, use .some() to check if any insights contain gross
    Array.isArray(data) ?
      data.some((it: any) => {
        return it?.insights?.gross_profit;
      }) : facebookService.getInsightData(data, 'gross_profit') !== null;

  const renderMetric = (metric: any, label: string, sublabel?: string, onClick?: any) => (
    <IonCol className={css('kpi-metric')}>
      <IonText>
        <h3
          style={{ cursor: !!onClick ? 'pointer' : 'default' }}
          onClick={onClick}
        >
          {metric}
        </h3>
        <span>{label}</span>
        {sublabel && <div className="sublabel">{sublabel}</div>}
      </IonText>
    </IonCol>
  );

  return (
    <div className="facebook-campaign-overview">
      <div className="facebook-campaign-overview-kpis">
        <TLoading visible={loading} absolute paused={!loading} />
        {!loading && (
          <IonGrid>
            <IonRow>
              <IonCol size="6" push="3">
                <IonSelect
                  mode="md"
                  value={datePreset}
                  onIonChange={({ detail }) => setDatePreset(detail.value)}
                >
                  {Object.keys(facebookService.datePresets).map((it: any) => (
                    <IonSelectOption key={it} value={it}>
                      {facebookService.datePresets[it]}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow className="header-stats">
              <IonCol className="kpi-metric">
                {datePreset === 'lifetime' && (
                  <label>
                    Lifetime
                  </label>
                )}
                {datePreset !== 'lifetime' && (
                  <label>
                    {startDate} - {stopDate}
                  </label>
                )}
              </IonCol>
              <IonCol className="kpi-metric profit">
                {hasGrossProfitEnabled && (
                  <>
                    <label>Gross Profit:</label>
                    <strong>{util.formatDollar(grossProfit)}</strong>
                  </>
                )}
              </IonCol>
              <IonCol className="kpi-metric">
                <label>Ad Spend:</label>
                {datePreset !== 'lifetime' && (
                  <strong>{util.formatDollar(spend)}</strong>
                )}
                {datePreset === 'lifetime' && !lifetime_budget && (
                  <strong>{util.formatDollar(spend)}</strong>
                )}
                {datePreset === 'lifetime' && !!lifetime_budget && (
                  <>
                    <strong>{util.formatDollar(spend)}/${lifetime_budget}</strong>
                    <div style={{ width: '80%', margin: '0 auto' }}><IonProgressBar color="success" value={spend / lifetime_budget} /></div>
                  </>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              {renderMetric(util.formatNumber(leads), 'Leads', undefined, (e: any) => {
                e.preventDefault();
                if (leads > 0) {
                  setModalData({
                    title: 'Leads',
                    leads: data?.insights?.leads,
                    renderBarChart: false,
                  });
                }
              })}
              {renderMetric(util.formatNumber(appts), 'Appts')}
              {renderMetric(util.formatNumber(showed), 'Shows')}
              {renderMetric(util.formatNumber(sold), 'Sold', undefined, (e: any) => {
                e.preventDefault();
                if (sold > 0) {
                  setModalData({
                    title: 'Sold Leads',
                    leads: data?.insights?.solds,
                    renderBarChart: true,
                  });
                }
              })}
            </IonRow>

            <IonRow>
              {renderMetric(
                facebookService.calcMetric(spend, leads),
                'CPL',
                'Cost Per Lead'
              )}
              {renderMetric(
                facebookService.calcMetric(spend, appts),
                'CPA',
                'Cost Per Appt'
              )}
              {renderMetric(
                facebookService.calcMetric(spend, showed),
                'CPSH',
                'Cost Per Show'
              )}
              {renderMetric(
                facebookService.calcMetric(spend, sold),
                'CPSL',
                'Cost Per Sold'
              )}
            </IonRow>
          </IonGrid>
        )}
      </div>
      {!!modalData?.leads?.length && (
        <LeadListModal
          isOpen={!!modalData?.leads}
          leads={modalData?.leads}
          title={modalData?.title}
          leadsCount={data.insights.solds.length}
          onDidDismiss={() => { setModalData(undefined) }}
          renderBarChart={modalData?.renderBarChart}
        />
      )}
    </div>
  );
};

export default FacebookCampaignOverview;
