import React, { useCallback } from 'react';
import {
  FacebookCampaignOverview,
  TPage,
  TNumberItem,
  TTextItem
} from '../components';
import { facebookService } from '../services';
import { IonItemDivider, IonList, IonItemGroup, IonBadge } from '@ionic/react';
import { useRouter, useDataLoader } from '../hooks';
import { util } from '../core';

const FacebookAdPage: React.FC = () => {
  const router = useRouter();
  const { campaignId, adSetId, clientId, adId } = router.query;

  const load = useCallback(async () => {
    return await facebookService.loadAdReport(
      clientId,
      campaignId,
      adSetId,
      adId
    );
  }, [clientId, campaignId, adSetId, adId]);

  const { loading, error, data } = useDataLoader(load);

  return (
    <TPage
      loading={loading}
      error={error}
      preContent={
        <>
          {!loading && (
            <IonItemDivider>
              <div style={{width: '100%'}} className="ion-text-center">
                {data.remote_read?.status && (
                  <IonBadge color={util.getFacebookAdStatusBadgeColor(data.remote_read?.status)} mode="ios">
                    {data.remote_read?.status}
                  </IonBadge>
                )}
                {data?.name}
              </div>
            </IonItemDivider>
          )}
          <FacebookCampaignOverview
            clientId={clientId}
            campaignId={campaignId}
            adSetId={adSetId}
            adId={adId}
          />
          {!loading && (
            <IonItemDivider>
              {data?.insights?.campaign_name} &gt; {data?.insights?.adset_name} &gt;{' '}
              {data?.name}
            </IonItemDivider>
          )}
        </>
      }
    >
      <div
        className="ad-preview"
        style={{
          borderBottom: 'solid 1px var(--ion-border-color)',
          display: 'flex',
          justifyContent: 'center'
        }}
        dangerouslySetInnerHTML={{
          __html: data?.preview_iframe
        }}
      />
      <IonList className="no-padding no-margin" lines="full">
        <IonItemGroup>
          <IonItemDivider sticky>Metrics</IonItemDivider>
          <TTextItem label="Date Start" text={data?.insights?.date_start} />
          <TTextItem label="Date End" text={data?.insights?.date_stop} />
          <TNumberItem label="Clicks" number={data?.insights?.clicks} />
          <TNumberItem
            label="Impressions"
            number={data?.insights?.impressions}
          />
          <TNumberItem label="Reach" number={data?.insights?.reach} />
        </IonItemGroup>
      </IonList>
    </TPage>
  );
};

export default FacebookAdPage;
