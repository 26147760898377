import {
  useEffect,
  useReducer,
  useRef,
  useMemo,
  useState,
  useCallback
} from 'react';
import { useDataRefresher } from '.';
import _ from 'lodash';
import { http } from '../core';

export interface DataLoaderState {
  next?: string;
  data?: any;
  count: number;
  error: boolean;
  loading: boolean;
}

export interface DispatchUpdate {
  type: string;
  value: any;
}

export const reducer = (state: DataLoaderState, update: DispatchUpdate) => {
  const { type, value } = update;
  switch (type) {
    case 'remove':
      const data = [...state.data];
      _.remove(data, value);
      return { ...state, data };
    case 'dataFunction':
      return { ...state, data: value(state.data) };
    case 'prepend':
      const v = Array.isArray(value) ? value : [value];
      return { ...state, data: [...v, ...state.data] };
    case 'append':
      const vv = Array.isArray(value) ? value : [value];
      return { ...state, data: [...state.data, ...vv] };
    case 'set':
      const s = { ...state };
      return Object.assign(s, value || {});
  }
};

export interface UseDataLoaderProps extends DataLoaderState {
  dispatch: any;
  refresher: () => Promise<any>;
  loadNext: () => Promise<any>;
  hasNext: boolean;
  forceRefresh: () => any;
}

export default function useDataLoader(
  fn: () => Promise<any>,
  paginated?: boolean,
  descending?: boolean
) {
  const params = useRef<any>();
  const [forced, setForceRefresh] = useState<any>();
  const forceRefresh = useCallback(() => setForceRefresh({}), []);
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: false,
    count: 0
  });

  const [isRefreshing, refresher, onRefreshComplete] = useDataRefresher();

  useEffect(() => {
    async function load() {
      try {
        //Don't reload if a refresh just occurred and the variable changed
        if (
          isRefreshing === false &&
          params.current?.isRefreshing === true &&
          params.current?.fn === fn
        ) {
          params.current.isRefreshing = false;
          return;
        }

        if (!isRefreshing) {
          dispatch({
            type: 'set',
            value: {
              loading: true,
              error: false
            }
          });
        }

        const res = await fn();

        const d = paginated ? res.results : res;
        dispatch({
          type: 'set',
          value: {
            data: descending ? _.reverse(d) : d,
            count: paginated ? res.count : 0,
            next: paginated ? res.next : null,
            loading: false
          }
        });
      } catch (e: any) {
        if (!e.cancelled) {
          console.error(e);
          dispatch({
            type: 'set',
            value: {
              error: true,
              loading: false
            }
          });
        }
      } finally {
        params.current = { isRefreshing, fn };
        onRefreshComplete();
      }
    }

    load();
  }, [onRefreshComplete, isRefreshing, fn, paginated, descending, forced]);

  const loadNext = async () => {
    try {
      if (!state.next) return;

      const res = await http.loadNext(state.next);
      dispatch({
        type: 'set',
        value: {
          data: [...state.data, ...res.results],
          count: res.count,
          next: res.next
        }
      });
    } catch (e) {}
  };

  const hasNext = useMemo(() => paginated && !!state.next, [
    paginated,
    state.next
  ]);

  return {
    ...state,
    dispatch,
    refresher,
    loadNext,
    hasNext,
    forceRefresh
  } as UseDataLoaderProps;
}
