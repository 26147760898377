import Hammer from 'hammerjs';
import { useLayoutEffect, useRef, useCallback } from 'react';

export default function useHammer(
  id: string | null,
  type: string,
  handler: (e: any) => any
) {
  const instance = useRef<any>();
  const config = useCallback(() => {
    if (instance.current) {
      instance.current.destroy();
    }
    if (id) {
      const element: HTMLElement | null = id.startsWith('.')
        ? document.querySelector(id)
        : document.getElementById(id);

      if (element) {
        instance.current = new Hammer(element);
        instance.current.on(type, handler);
      }
    } else {
      instance.current = new Hammer(document.body);
      instance.current.on(type, handler);
    }
  }, [id, type, handler]);
  useLayoutEffect(() => config(), [config]);

  return instance.current;
}
