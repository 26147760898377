import React, { useCallback } from 'react';
import { FacebookCampaignOverview, TPage } from '../components';
import { facebookService } from '../services';
import { IonItemDivider, IonItem, IonLabel, IonList, IonBadge } from '@ionic/react';
import { useRouter, useDataLoader } from '../hooks';
import { util } from '../core';

const FacebookAdSetPage: React.FC = () => {
  const router = useRouter();
  const { campaignId, adSetId, clientId } = router.query;

  const load = useCallback(async () => {
    return await facebookService.loadAdReport(clientId, campaignId, adSetId);
  }, [clientId, campaignId, adSetId]);

  const { loading, error, data } = useDataLoader(load);

  return (
    <TPage
      loading={loading}
      error={error}
      preContent={
        <>
          {!loading && (
          <IonList lines="none">
            <IonItem>
              <div style={{width: '100%'}} className="ion-text-center">
                {data.remote_read?.status && (
                  <IonBadge color={util.getFacebookAdStatusBadgeColor(data.remote_read?.status)} mode="ios">
                    {data.remote_read?.status}
                  </IonBadge>
                )}
                {data?.name}
              </div>
              </IonItem>
            </IonList>
          )}
          <FacebookCampaignOverview
            clientId={clientId}
            campaignId={campaignId}
            adSetId={adSetId}
          />
          {!loading && (
            <IonItemDivider>
              {data?.insights?.campaign_name} &gt;
              {data?.name} &gt; Ads
            </IonItemDivider>
          )}
        </>
      }
    >
      <IonList lines="full" className="no-margin no-padding">
        {data?.ads?.map?.((item: any) => (
          <IonItem
            key={item.id}
            routerLink={`/reports/facebook-ads/${clientId}/${campaignId}/${adSetId}/${item.id}/`}
            detail
          >
            <IonLabel className="ion-multiline">
              {item.remote_read?.status && (
                <IonBadge color={util.getFacebookAdStatusBadgeColor(item.remote_read?.status)} mode="ios">
                  {item.remote_read?.status}
                </IonBadge>
              )}
              {item.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default FacebookAdSetPage;
